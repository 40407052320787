import { ACTION_TYPES } from '../../../constants'

export const pushRootDashboard = (dashboardId, dashboardName) => ({
	type: ACTION_TYPES.PUSH_ROOT_DASHBOARD_STACK,
	payload: { data: { dashboardId, dashboardName } },
})

export const pushDashboard = (dashboardId, dashboardName, filterColumn, filterValue) => ({
	type: ACTION_TYPES.PUSH_DASHBOARD_STACK,
	payload: {
		data: {
			dashboardId,
			dashboardName,
			filterColumn,
			filterValue,
		},
	},
})

export const popDashboard = (dashIdxToPop) => ({
	type: ACTION_TYPES.POP_DASHBOARD_STACK,
	payload: {
		popIdx: dashIdxToPop,
	},
})
