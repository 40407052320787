import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash'
import PermSelectBox from '../PermSelectBox'
import { useSelector } from 'react-redux'
import { getImgUrl } from 'utils'
import { ROLE_MODULES } from 'constants/modules'

SharedList.propTypes = {
	permissions: PropTypes.array,
	isMobileView: PropTypes.bool,
	sharedUsers: PropTypes.array,
	sharedTeams: PropTypes.array,
	owner: PropTypes.object,
	activeFormModule: PropTypes.string,
	onChangeUpdtPermSelBox: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
	listRoot: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	listItem: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	listItemText: {
		paddingRight: 60,
	},
	listItemAction: {
		right: theme.spacing(1),
	},
	divider: {
		marginLeft: 56,
	},
}))

const getUserItemData = (user) => {
	return {
		id: _.get(user, 'user_id'),
		resource_id: _.get(user, 'resource_id'),
		title: _.join([_.get(user, 'first_name'), _.get(user, 'last_name')].filter(Boolean), ' '),
		profilePic: _.get(user, 'profile_pic_url', null),
		secondaryTitle: _.get(user, 'resource_name'),
		permission: _.get(user, 'permission'),
	}
}

const getTeamItemData = (team) => {
	return {
		id: _.get(team, 'tu_code'),
		resource_id: _.get(team, 'resource_id'),
		title: _.get(team, 'tu_name'),
		avatarText: _.get(team, 'tu_name'),
		secondaryTitle: _.get(team, 'resource_name'),
		permission: _.get(team, 'permission'),
	}
}

const getShareListData = (formModule, sharedUser, sharedTeams) => {
	const isUserModule = formModule === ROLE_MODULES.USER_MANAGEMENT
	const arryToIterate = isUserModule ? sharedUser : sharedTeams
	const returnFunc = isUserModule ? getUserItemData : getTeamItemData
	return _.map(arryToIterate, returnFunc)
}

const SharedListItem = ({ classes, avatar, title, secondaryTitle, actionNode }) => {
	const avatarTxt = !_.isNull(avatar) ? _.get(title, '[0]') : null
	return (
		<ListItem dense className={classes.listItem}>
			<ListItemAvatar>
				<Avatar src={avatar}>{avatarTxt}</Avatar>
			</ListItemAvatar>
			<ListItemText primary={title} className={classes.listItemText} secondary={secondaryTitle} />
			<ListItemSecondaryAction className={classes.listItemAction}>{actionNode}</ListItemSecondaryAction>
		</ListItem>
	)
}

function SharedList({ permissions, isMobileView, sharedUsers, sharedTeams, onChangeUpdtPermSelBox, owner, activeFormModule }) {
	const classes = useStyles()
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const getPicUrl = useCallback(
		(picLoc) => {
			return _.isEmpty(picLoc) ? '' : getImgUrl(accessToken, picLoc, 50)
		},
		[accessToken]
	)

	const renderOwnerListItem = useCallback(
		(owner) => {
			return (
				<SharedListItem
					title={_.join([_.get(owner, 'first_name'), _.get(owner, 'last_name')].filter(Boolean), ' ')}
					avatar={getPicUrl(_.get(owner, 'profile_pic_url', 50))}
					secondaryTitle={_.get(owner, 'resource_name')}
					actionNode={
						<Typography variant='body2' color='textSecondary'>
							Owner
						</Typography>
					}
					classes={classes}
				/>
			)
		},
		[classes, getPicUrl]
	)

	const renderSharedListItem = useCallback(
		(permissions, isMobileView, onChangePermSelBox) => {
			const listData = getShareListData(activeFormModule, sharedUsers, sharedTeams)
			return _.map(listData, (data, idx) => {
				return (
					<div key={_.get(data, 'resource_id') + _.get(data, 'id')}>
						<SharedListItem
							classes={classes}
							actionNode={
								<PermSelectBox
									permissions={permissions}
									showIconOnly={isMobileView}
									value={_.get(data, 'permission')}
									onChange={(e) => onChangePermSelBox(e, data)}
								/>
							}
							avatar={_.get(data, 'profilePic') && getPicUrl(_.get(data, 'profilePic', 50))}
							{...data}
						/>
						{!_.isEqual(idx, listData.length - 1) && <Divider variant='inset' className={classes.divider} component='li' />}
					</div>
				)
			})
		},
		[activeFormModule, getPicUrl, sharedUsers, sharedTeams, classes]
	)

	return (
		<div>
			<List className={classes.listRoot}>
				{activeFormModule !== 'team_management' && renderOwnerListItem(owner)}
				<Divider variant='inset' className={classes.divider} component='li' />
				{renderSharedListItem(permissions, isMobileView, onChangeUpdtPermSelBox)}
			</List>
		</div>
	)
}

export default SharedList
