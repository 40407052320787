import React from 'react'
import { AccessDeniedPage } from 'illustrators'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '95vw',
		height: '100vh',
		boxSizing: 'border-box',
		margin: '0 auto',
	},
	desc: {
		color: theme.palette.grey[500],
	},
	infoIco: {
		fontSize: '20em',
		[theme.breakpoints.down('xs')]: {
			fontSize: '15em',
		},
	},
	errTxtWrapper: {
		textAlign: 'center',
		padding: theme.spacing(2),
		boxSizing: 'border-box',
	},
	goBackBtn: {
		marginTop: theme.spacing(2),
	},
}))

const PageNotAllowed = ({ isGoBack }) => {
	const classes = useStyles()
	const history = useHistory()

	const onClickNavBtn = () => {
		if (isGoBack) history.goBack()
		else history.replace({ pathname: '/' })
	}

	return (
		<div className={classes.root}>
			<AccessDeniedPage className={classes.infoIco}></AccessDeniedPage>
			<div className={classes.errTxtWrapper}>
				<Typography variant='h4' component='h1' gutterBottom>
					We are sorry...
				</Typography>
				<Typography variant='body1' className={classes.desc}>
					The page you're trying to access has restricted access.
				</Typography>
				<Typography variant='body1' className={classes.desc} gutterBottom>
					Please contact your administrator.
				</Typography>
				<Button color='primary' onClick={onClickNavBtn} className={classes.goBackBtn}>
					{isGoBack ? 'Go Back' : 'Go To Home Page'}
				</Button>
			</div>
		</div>
	)
}

PageNotAllowed.propTypes = {
	isGoBack: PropTypes.bool,
}

PageNotAllowed.defaultProps = {
	isGoBack: false,
}

export default PageNotAllowed
