import React from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	makeStyles,
	Slide,
	TextField,
	Typography,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import DiscoverxApi from 'services/discoverx/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch } from 'react-redux'
import { getErrMsg, getSuccessMsg } from 'utils'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: 400,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
		},
	},
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(2),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	newCategoryName: {
		width: '100%',
		marginTop: '4px',
		'& .MuiFormHelperText-contained': {
			marginLeft: 0,
		},
	},
	closeBtn: {
		color: theme.palette.error.main,
	},
}))
const categorySchema = Yup.object().shape({
	categoryName: Yup.string().trim().required('Category name is required'),
})

const NewCategoryDialog = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { open, handleClose, isEditMode, setIsEditMode, refreshFxn, categoryVal } = props

	const handleClosePopup = () => {
		handleClose()
		categoryFormik?.resetForm()
		setIsEditMode(false)
	}

	const onCreateCategory = (value) => {
		dispatch(showLoader('Loading please wait...'))
		const categoryName = _.get(value, 'categoryName', '')
		const onSuccess = (res) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			dispatch(hideLoader())
			handleClosePopup()
			refreshFxn()
		}
		const onFailure = (err) => {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			dispatch(hideLoader())
		}
		isEditMode
			? DiscoverxApi.updateExtCategory(categoryVal?.category_id, categoryName).then(onSuccess, onFailure)
			: DiscoverxApi.createNewCategory(categoryName).then(onSuccess, onFailure)
	}

	const categoryFormik = useFormik({
		initialValues: {
			categoryName: _.isEmpty(categoryVal?.category_name) ? '' : categoryVal?.category_name,
		},
		enableReinitialize: true,
		onSubmit: onCreateCategory,
		validationSchema: categorySchema,
	})

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			maxWidth={'sm'}
			open={open}
			onClose={handleClose}
		>
			<DialogTitle className={classes.dialogTitle} disableTypography={true}>
				<Typography variant='h6'>{isEditMode ? 'Edit Category' : 'Create New Category'}</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<form onSubmit={categoryFormik?.handleSubmit}>
					<Grid container spacing={1} alignItems='center'>
						<Grid item lg={12}>
							<TextField
								variant='outlined'
								margin='dense'
								name='categoryName'
								id='categoryName'
								fullWidth
								size='small'
								label='Enter Category Name'
								className={classes.newCategoryName}
								value={categoryFormik?.values?.categoryName}
								error={Boolean(categoryFormik?.errors?.categoryName)}
								helperText={categoryFormik?.errors?.categoryName && categoryFormik?.errors?.categoryName}
								onChange={categoryFormik?.handleChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton size='small' onClick={categoryFormik.handleReset}>
												<Icon fontSize='small'>close</Icon>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button variant='outlined' size='medium' color='primary' onClick={handleClosePopup}>
					Cancel
				</Button>
				<Button variant='contained' size='medium' color='primary' disableElevation onClick={categoryFormik?.handleSubmit}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default NewCategoryDialog
