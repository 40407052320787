import React from 'react'
import { colors } from '@material-ui/core'
import Board from 'react-trello'
import LaneHeader from './LaneHeader'
import Card from './GlassCard'

const data = {
	lanes: [
		{
			id: 'lane1',
			title: 'Loading Lane 1',
			label: '2/2',
			cards: [
				{
					id: 'Card1',
					cardDraggable: false,
					title: 'Write Blog',
					description: 'Can AI make memes',
					assignee: {
						id: 'user_1',
						first_name: 'Laksha Papa Praveen Selvakumar',
					},
					dueDate: new Date(),
					labels: [
						{ color: 'darkgoldenrod', name: '' },
						{ color: 'rebeccapurple', name: '' },
					],
				},
				{
					id: 'Card2',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
					labels: [{ color: 'darkcyan', name: '' }],
					image:
						'https://images.unsplash.com/photo-1615114814213-a245ffc79e9a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
				},
			],
		},
		{
			id: 'lane2',
			title: 'Loading Lane 2',
			label: '0/0',
			cards: [
				{
					id: 'Card3',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
					assignee: {
						id: 'user_1',
						first_name: 'Laksha Papa Praveen Selvakumar',
					},
					labels: [{ color: 'darkcyan', name: '' }],
					image:
						'https://images.unsplash.com/photo-1615114814213-a245ffc79e9a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
				},
			],
		},
		{
			id: 'lane3',
			title: 'Loading Lane 3',
			label: '0/0',
			cards: [
				{
					id: 'Card4',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
					dueDate: new Date(),
					assignee: {
						id: 'user_1',
						first_name: 'Laksha Papa Praveen Selvakumar',
					},
				},
				{
					id: 'Card5',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
				},
				{
					id: 'Card6',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
					dueDate: new Date(),
				},
			],
		},
		{
			id: 'lane4',
			title: 'Loading Lane 4',
			label: '2/2',
			cards: [
				{
					id: 'Card7',
					cardDraggable: false,
					title: 'Write Blog',
					description: 'Can AI make memes',
					assignee: {
						id: 'user_1',
						first_name: 'Laksha Papa Praveen Selvakumar',
					},
					dueDate: new Date(),
					labels: [
						{ color: 'darkgoldenrod', name: '' },
						{ color: 'rebeccapurple', name: '' },
					],
				},
				{
					id: 'Card8',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
				},
				{
					id: 'Card9',
					title: 'Pay Rent',
					description: 'Transfer via NEFT',
					metadata: { sha: 'be312a1' },
					labels: [{ color: 'darkcyan', name: '' }],
					image:
						'https://images.unsplash.com/photo-1615114814213-a245ffc79e9a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
				},
			],
		},
	],
}

const LoadingBoard = () => {
	return (
		<Board
			editable={false}
			data={data}
			draggable={false}
			cardDraggable={false}
			laneDraggable={false}
			style={{
				background: colors.common.white,
				width: '100%',
				flex: 1,
				height: '100%',
				boxSizing: 'border-box',
			}}
			components={{ Card, LaneHeader }}
		/>
	)
}

export default LoadingBoard
