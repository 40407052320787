import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PAGE_MODES } from '../../../../constants'
import Validator from './validator'
import { Field, Form } from 'formik'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader } from 'services/loader/actions'
import Slide from '@material-ui/core/Slide'
import { createTeam, updateTeam, addTeamMembers } from 'services/teams/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action, isRootTeam) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return isRootTeam ? 'New Team Unit' : 'New Sub Team Unit'
		case PAGE_MODES.EDIT:
			return 'Edit Team Unit'
		default:
			return ''
	}
}

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 400,
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(1),
	},
}))

const TeamUnitFormDialog = ({ open, teamCode, teamName, parentTuCode, action, handleClose, addIntitialUser = false }) => {
	const dialogTitle = getDialogTitle(action, _.isEmpty(parentTuCode))
	const currentUserId = useSelector((state) => state?.session?.userId || '')
	const classes = useStyles()
	const [extTeamState, setTeamState] = useState({ tu_name: '' })
	const formRef = useRef()
	const dispatch = useDispatch()

	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)

	const onSubmitForm = (teamDetails) => {
		const successCallback = (resp) => {
			if (addIntitialUser) {
				dispatch(
					addTeamMembers(
						resp?.data?.data?.tu_code,
						[currentUserId],
						() => {
							handleClose(null, false, action, _.get(resp, 'data.data'))
						},
						() => {
							dispatch(hideLoader())
						}
					)
				)
			} else {
				handleClose(null, false, action, _.get(resp, 'data.data'))
			}
		}
		const closeErrDialog = () => {
			dispatch(hideLoader())
		}
		if (isCreateMode) {
			dispatch(createTeam(_.trim(teamDetails.tu_name), parentTuCode, successCallback, closeErrDialog))
		} else if (isEditMode) {
			dispatch(updateTeam(teamCode, _.trim(teamDetails.tu_name), successCallback, closeErrDialog))
		}
	}
	const onClickActionBtn = () => {
		formRef.current && formRef.current.submitForm()
	}

	//reset exsting role details on form dialog close event
	useEffect(() => {
		if (!open) setTeamState({ tu_name: '' })
		else setTeamState({ tu_name: teamName })
	}, [open, teamName])

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			maxWidth={'md'}
			open={open}
			onClose={handleClose}
			aria-labelledby='role-form-title'
		>
			<Validator formMode={action} initialFormData={extTeamState} onSubmit={onSubmitForm} ref={formRef}>
				{() => {
					return (
						<>
							<DialogTitle className={classes.dialogTitle} disableTypography={true} id='role-dialog-title'>
								<Typography variant='h6'>{dialogTitle}</Typography>
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<Form id='role-form'>
									<div className={classes.tabPanel}>
										<Field type='text' name='tu_name'>
											{({ form, field }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													label={'Team Unit Name*'}
													type='text'
													autoComplete='off'
													onChange={field.onChange}
													onBlur={field.onBlur}
													value={field.value || ''}
													className={classes.textField}
													helperText={form.touched[field.name] && form.errors[field.name]}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</div>
								</Form>
							</DialogContent>
							<DialogActions className={classes.dialogFooter}>
								<Button variant='outlined' onClick={handleClose} color='primary'>
									Cancel
								</Button>
								<Button variant='contained' onClick={onClickActionBtn} color='primary' disableElevation>
									Save
								</Button>
							</DialogActions>
						</>
					)
				}}
			</Validator>
		</Dialog>
	)
}

TeamUnitFormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	roleId: PropTypes.string,
	action: PropTypes.string,
	parentTucode: PropTypes.string,
}

export default TeamUnitFormDialog
