import React, { useRef, useEffect, useReducer, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {
	Avatar,
	FormHelperText,
	Grid,
	Icon,
	LinearProgress,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputLabels from 'components/InputLabels'
import Attachments from 'components/Attachments'
import update from 'immutability-helper'
import _ from 'lodash'
import { GLASS_ROLES, PRIORITY_LIST } from '../../constants'
import { Formik } from 'formik'
import * as Yup from 'yup'
import GlassApi from '../../services/glass/api'
import { initialState, reducer } from './reducer'
import Axios from 'axios'
import { convertLocalTimeToGmtStr, getErrMsg, getImgUrl, getSuccessMsg, stringToHslColor } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import ProductList from './ProductList'
import { DateTimePicker } from '@material-ui/pickers'
import ResultsList from './ResultList'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { createSticky } from 'services/glass/actions'
import RichTextEditor from 'components/RichTextEditor'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},

	dialogContent: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
	},
	priorityItem: {
		justifyContent: 'center',
		gap: '5px',
	},
	userPic: {
		width: 37,
		height: 37,
		marginRight: theme.spacing(1),
	},
	disableOnLoading: {
		opacity: (props) => (props.showLoader ? 0.6 : 1),
		pointerEvents: (props) => (props.showLoader ? 'none' : 'unset'),
	},
	overflowTextDots: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	dialogRoot: {
		// '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
		// 	width: '1',
		// 	background: 'transparent',
		// 	zIndex: 9999,
		// 	position: 'relative',
		// },
		// '& ::-webkit-scrollbar-thumb': {
		// 	background: theme.palette.almostBlack[400],
		// 	height: '10px',
		// 	zIndex: 9999,
		// 	position: 'relative',
		// },
	},
	checkboxSec: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: '6px 0px 0px 6px',
		'& .MuiTypography-body1': {
			color: theme.palette.almostBlack[600],
			fontSize: 12,
		},
		'& .PrivateSwitchBase-root-95': {
			padding: theme.spacing(0, 1, 0, 1.4),
		},
		'& .MuiButtonBase-root': {
			padding: '4px',
		},
	},
}))

const getFormInitialValues = (filesToAttach = []) => {
	return {
		sticky_title: '',
		sticky_desc: '',
		glass_code: null,
		priority: null,
		due_date: null,
		labels: [],
		attachments: filesToAttach,
		assigned_to: null,
		report_to: null,
	}
}

AddStickyDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	attachments: PropTypes.array,
	stickyFor: PropTypes.string,
	stickySourceId: PropTypes.string,
	stickyIntentDetails: PropTypes.object,
}

export default function AddStickyDialog({ open, handleClose, filesToAttach, stickyFor, stickySourceId, stickyIntentDetails, onStickySuccess }) {
	const [state, dispatch] = useReducer(reducer, initialState)
	const { isFetchingGlass, glassList, isFetchingAssignee, assignees, isFetchingLabels, labels, showLoader, productList, errProdIds, searchResults } =
		state
	const classes = useStyles({ showLoader })
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const formRef = useRef()
	const glassFetchCancelExec = useRef()
	const assigneeFetchCancelExec = useRef()
	const labelApiCancelExec = useRef()
	const reduxDispatch = useDispatch()

	const taskPerms = usePermission(ROLE_MODULES.GLASS)
	const [isAddLabelAllowed, isEditLabelAllowed, isDeleteLabelAllowed] = useMemo(
		() => [
			_.get(taskPerms, PERMISSION_TYPE.CREATE, false),
			_.get(taskPerms, PERMISSION_TYPE.EDIT, false),
			_.get(taskPerms, PERMISSION_TYPE.DELETE, false),
		],
		[taskPerms]
	)
	// console.log('fromsticky', stickyFor, stickyIntentDetails, productList, stickySourceId)
	const delayedGlassQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 0),
		[]
	)

	const delayedAssigneeQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 0),
		[]
	)

	const onDeleteResult = useCallback((searchId) => {
		dispatch({ type: 'deleteSearchResult', idx: searchId })
	}, [])

	useEffect(() => {
		if (stickySourceId?.products) {
			dispatch({
				type: 'setProductList',
				data: _.get(stickySourceId, 'products', []),
			})
		} else if (stickyIntentDetails?.discover) {
			dispatch({
				type: 'setResultsList',
				data: _.get(stickyIntentDetails, 'discover', []),
			})
		}
	}, [stickyIntentDetails, stickySourceId])

	const delProdFun = useCallback((prodIdx) => {
		dispatch({ type: 'deleteProduct', idx: prodIdx })
	}, [])

	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const getPicUrl = useCallback(
		(picLoc) => {
			return _.isEmpty(picLoc) ? '' : getImgUrl(accessToken, picLoc, 50)
		},
		[accessToken]
	)

	const onTypeGlassAutoComplete = useCallback(
		(e, inputValue) => {
			delayedGlassQuery(inputValue, (q) => {
				dispatch({ type: 'fetchGlassPending' })
				GlassApi.fetchAllGlasses(
					q,
					0,
					10,
					[],
					glassFetchCancelExec,
					(res) => {
						dispatch({
							type: 'fetchGlassCompleted',
							data: _.get(res, 'data.data.glass', []),
						})
					},
					(err) => {
						if (!Axios.isCancel(err)) dispatch({ type: 'fetchGlassCompleted', data: [] })
					}
				)
			})
		},
		[delayedGlassQuery, dispatch]
	)

	const onTypeReportAutoComplete = (e, inputValue) => {
		// console.log('inputValue', inputValue)
	}

	const onTypeAssigneeAutoComplete = useCallback(
		(e, inputValue, action, fbGlassId) => {
			delayedAssigneeQuery(inputValue, (q) => {
				const glassCode = _.get(formRef.current.values, 'glass_code.glass_code', fbGlassId)
				dispatch({ type: 'fetchAssigneePending' })
				assigneeFetchCancelExec.current && assigneeFetchCancelExec.current('cancel')
				if (glassCode)
					GlassApi.fetchGlassMembers(
						glassCode,
						{ search_text: q, offset: 0, limit: 10, is_active: true },
						new CancelToken((exec) => {
							assigneeFetchCancelExec.current = exec
						})
					).then(
						(res) => {
							dispatch({
								type: 'fetchAssigneeCompleted',
								data: _.filter(_.get(res, 'data.data.glass_members', []), (member) => !_.isEqual(member.role, GLASS_ROLES.GUEST)),
							})
						},
						(err) => {
							if (!Axios.isCancel(err)) dispatch({ type: 'fetchAssigneeCompleted', data: [] })
						}
					)
			})
		},
		[delayedAssigneeQuery, dispatch]
	)

	const fetchLabels = useCallback((glassCode) => {
		dispatch({ type: 'fetchLabelsPending' })
		GlassApi.fetchGlassLabels(glassCode, labelApiCancelExec).then(
			(res) => {
				dispatch({
					type: 'fetchLabelsCompleted',
					data: _.get(res, 'data.data.labels', []),
				})
			},
			(err) => {
				if (!Axios.isCancel(err)) dispatch({ type: 'fetchLabelsCompleted', data: [] })
			}
		)
	}, [])

	const validationSchema = Yup.object().shape({
		glass_code: Yup.object().nullable().required('Board is mandatory.'),
		sticky_title: Yup.string().required('Job Name is mandatory'),
		due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	})

	const onUpdtLblListChanges = (uptLblList) => {
		dispatch({ type: 'fetchLabelsCompleted', data: uptLblList })
	}

	const onSubmit = (values) => {
		const dueDateMoment = _.get(values, 'due_date')
		const jsonFormData = {
			sticky_title: _.get(values, 'sticky_title', ''),
			sticky_desc: _.get(values, 'sticky_desc', ''),
			due_date: dueDateMoment ? convertLocalTimeToGmtStr(dueDateMoment) : '',
			priority: _.get(values, 'priority.value', ''),
			assigned_to: _.get(values, 'assigned_to.user_id', ''),
			reporting_to: _.get(values, 'report_to.user_id', ''),
			is_approval: _.toString(_.get(values, 'is_approval')),
			labels: _.join(_.get(values, 'labels'), ','),
			attachments: _.get(values, 'attachments'),
			sticky_for: stickyFor,
			sticky_source_id: stickyFor === 'products' ? null : stickySourceId,
			sticky_intent_details: !_.isEmpty(searchResults)
				? JSON.stringify(
						_.map(searchResults, (result) => ({
							id: _.get(result, 'id'),
							ds_id: _.get(result, 'ds_id'),
						}))
				  )
				: {},
			productx_details: !_.isEmpty(productList)
				? JSON.stringify(
						_.map(productList, (product) => ({
							product_id: _.get(product, 'product_id'),
							product_code: _.get(product, 'product_code'),
						}))
				  )
				: {},
		}

		const onSuccess = (res) => {
			reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			handleClose()
			dispatch({ type: 'hideLoader' })
			onStickySuccess && onStickySuccess()
		}

		const onFailure = (err) => {
			reduxDispatch(showSnackbarWithTimeout(getErrMsg(err), 2500))
			dispatch({ type: 'hideLoader' })
			// 		dispatch({
			// 			type: 'setProdErrors',
			// 			data: _.get(err, 'response.data.data.product_id_exists'),
			// 		})
		}

		dispatch({ type: 'showLoader' })
		reduxDispatch(createSticky(_.get(values, 'glass_code.glass_code', ''), jsonFormData, null, onSuccess, onFailure))
	}

	const onClickAddBtn = () => {
		formRef.current.submitForm()
	}

	useEffect(() => {
		if (open) {
			onTypeGlassAutoComplete({}, '')
			formRef.current && formRef.current.resetForm()
		} else dispatch({ type: 'resetState' })
	}, [open, onTypeGlassAutoComplete])

	const shwErrProdIds = useMemo(() => {
		return _.map(
			_.filter(productList, (product) => _.indexOf(errProdIds, _.get(product, 'product_id')) > -1),
			(product) => _.get(product, 'product_id')
		)
	}, [productList, errProdIds])

	return (
		<Dialog
			open={open}
			fullScreen={fullScreen}
			className={classes.dialogRoot}
			onClose={(event, reason) => {
				if (/* reason !== 'backdropClick' && */ !showLoader) {
					handleClose(event, reason)
				}
			}}
			aria-labelledby='task-add-dialog'
			scroll='paper'
		>
			{showLoader && <LinearProgress variant='indeterminate' />}
			<DialogContent className={classes.disableOnLoading}>
				<Formik
					innerRef={formRef}
					enableReinitialize={true}
					initialValues={getFormInitialValues(filesToAttach)}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{(props) => {
						const { values, touched, errors, setFieldValue, handleChange, handleBlur } = props
						return (
							<>
								<Grid className={classes.dialogContent} container spacing={2}>
									<Grid item xs={12}>
										<TextField
											name='sticky_title'
											variant='outlined'
											margin='none'
											size='small'
											fullWidth
											label={stickyFor === 'products' ? 'Job Name*' : 'Takedown Title*'}
											onChange={handleChange}
											onBlur={handleBlur}
											value={_.get(values, 'sticky_title', '')}
											error={errors.sticky_title && touched.sticky_title}
											helperText={touched.sticky_title && errors.sticky_title}
											type='text'
											autoComplete='off'
											className={classes.textField}
										/>
									</Grid>
									<Grid item xs={12}>
										<RichTextEditor
											name={'sticky_desc'}
											placeholder={stickyFor === 'products' ? 'Brief Case Notes' : 'Objectives and Key Results'}
											value={values?.sticky_desc}
											onChange={handleChange}
											hideToolbarOnFocus={false}
											delayedUpdate={300}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											name='priority'
											options={PRIORITY_LIST}
											size={'small'}
											classes={{
												root: classes.autocompleteRoot,
												inputRoot: classes.autoCompleteInputRoot,
											}}
											getOptionLabel={(option) => _.startCase(option.name)}
											getOptionSelected={(option, value) => option.value === value.value}
											renderOption={(option) => {
												return (
													<Grid key={option.value} className={classes.priorityItem} container alignItems='center'>
														<Grid item>
															<Icon style={{ color: option.color }} fontSize='small'>
																{option.icon}
															</Icon>
														</Grid>
														<Grid item xs>
															<Typography variant='body2'>{option.name}</Typography>
														</Grid>
													</Grid>
												)
											}}
											filterSelectedOptions
											onChange={(e, value) => {
												handleChange({
													target: {
														name: 'priority',
														value,
													},
												})
											}}
											onBlur={handleBlur}
											value={_.get(values, 'priority')}
											renderInput={(params) => <TextField {...params} variant='outlined' label={'Priority'} />}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<DateTimePicker
											label='Due Date and Time'
											fullWidth
											clearable={true}
											inputVariant='outlined'
											value={_.get(values, 'due_date', null)}
											size={'small'}
											disablePast={true}
											error={errors.due_date}
											format={'DD MMM YYYY, hh:mm A'}
											onChange={(date) => {
												handleChange({
													target: {
														name: 'due_date',
														value: date,
													},
												})
											}}
										/>
										{errors?.due_date && <FormHelperText error>{errors.due_date}</FormHelperText>}
									</Grid>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											name='glass_code'
											options={glassList}
											size={'small'}
											classes={{
												root: classes.autocompleteRoot,
												inputRoot: classes.autoCompleteInputRoot,
											}}
											onChange={(e, value) => {
												//reset dependent field
												setFieldValue('assigned_to', null)
												setFieldValue('report_to', null)
												setFieldValue('labels', [])
												const glassCode = _.get(value, 'glass_code')
												//call label api
												fetchLabels(glassCode)
												if (!_.isEmpty(glassCode)) {
													onTypeAssigneeAutoComplete({}, '', null, glassCode)
												}
												handleChange({
													target: {
														name: 'glass_code',
														value,
													},
												})
											}}
											loading={isFetchingGlass}
											onBlur={handleBlur}
											value={_.get(values, 'glass_code')}
											getOptionLabel={(option) => option.glass_name}
											onInputChange={onTypeGlassAutoComplete}
											getOptionSelected={(option, value) => option.glass_code === value.glass_code}
											renderInput={(params) => (
												<TextField
													{...params}
													name='glass_code'
													variant='outlined'
													label={'Select Board*'}
													error={(errors.glass_code && touched.glass_code) || false}
													helperText={errors.glass_code && touched.glass_code && errors.glass_code}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											name='assigned_to'
											options={assignees}
											loading={isFetchingAssignee}
											size={'small'}
											onInputChange={onTypeAssigneeAutoComplete}
											onChange={(e, value) => {
												handleChange({
													target: {
														name: 'assigned_to',
														value,
													},
												})
											}}
											disabled={_.isEmpty(_.get(values, 'glass_code'))}
											onBlur={handleBlur}
											value={_.get(values, 'assigned_to')}
											getOptionLabel={(option) => _.join([option.first_name, option.last_name], ' ')}
											classes={{
												root: classes.autocompleteRoot,
												inputRoot: classes.autoCompleteInputRoot,
											}}
											renderInput={(params) => <TextField {...params} name='assigned_to' variant='outlined' label={'Assignee'} />}
											renderOption={(option) => {
												const picUrl = !_.isEmpty(_.trim(option.profile_pic_url)) ? getPicUrl(option.profile_pic_url) : ''
												return (
													<Grid key={option.user_id} wrap='nowrap' container alignItems='center'>
														<Grid item>
															<Avatar
																style={{ backgroundColor: stringToHslColor(`${option?.first_name} ${option?.last_name}`) }}
																src={picUrl}
																size='small'
																className={classes.userPic}
															>
																{_.upperCase(option?.first_name?.substring(0, 1))}
															</Avatar>
														</Grid>
														<Grid item>
															<Typography className={classes.overflowTextDots} variant='body2'>
																{_.join([option.first_name, option.last_name], ' ')}
															</Typography>
															<Typography className={classes.overflowTextDots} variant='body2' color='textSecondary'>
																{option.email}
															</Typography>
														</Grid>
													</Grid>
												)
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											name='report_to'
											options={assignees}
											loading={isFetchingAssignee}
											size={'small'}
											onInputChange={onTypeAssigneeAutoComplete}
											onChange={(e, value) => {
												handleChange({
													target: {
														name: 'report_to',
														value,
													},
												})
												setFieldValue('is_approval', false)
											}}
											onFocus={() => {
												_.isEmpty(_.get(values, 'report_to')) && onTypeAssigneeAutoComplete({}, '', null, _.get(values, 'glass_code'))
											}}
											disabled={_.isEmpty(_.get(values, 'glass_code'))}
											onBlur={handleBlur}
											value={_.get(values, 'report_to')}
											getOptionLabel={(option) => _.join([option.first_name, option.last_name], ' ')}
											classes={{
												root: classes.autocompleteRoot,
												inputRoot: classes.autoCompleteInputRoot,
											}}
											renderInput={(params) => <TextField {...params} name='report_to' variant='outlined' label={'Reporting To'} />}
											renderOption={(option) => {
												const picUrl = !_.isEmpty(_.trim(option.profile_pic_url)) ? getPicUrl(option.profile_pic_url) : ''
												return (
													<Grid key={option.user_id} wrap='nowrap' container alignItems='center'>
														<Grid item>
															<Avatar
																style={{ backgroundColor: stringToHslColor(`${option?.first_name} ${option?.last_name}`) }}
																src={picUrl}
																size='small'
																className={classes.userPic}
															>
																{_.upperCase(option?.first_name?.substring(0, 1))}
															</Avatar>
														</Grid>
														<Grid item>
															<Typography className={classes.overflowTextDots} variant='body2'>
																{_.join([option.first_name, option.last_name], ' ')}
															</Typography>
															<Typography className={classes.overflowTextDots} variant='body2' color='textSecondary'>
																{option.email}
															</Typography>
														</Grid>
													</Grid>
												)
											}}
										/>
										<div className={classes.checkboxSec}>
											<FormControlLabel
												control={
													<Checkbox
														checked={_.isEmpty(_.get(values, 'report_to')) ? false : Boolean(values?.is_approval)}
														disabled={_.isEmpty(_.get(values, 'report_to'))}
														name='is_approval'
														onChange={(e) => {
															setFieldValue('is_approval', !values?.is_approval)
														}}
														color='primary'
														size='small'
													/>
												}
												label={stickyFor === 'products' ? 'Does this job need an approval?' : 'Does this Takedown need an approval?'}
											/>
										</div>
									</Grid>
									<Grid item xs={12}>
										<InputLabels
											name='labels'
											glassCode={_.get(values, 'glass_code.glass_code')}
											labels={labels}
											loading={isFetchingLabels}
											disabled={_.isEmpty(_.get(values, 'glass_code'))}
											onChange={handleChange}
											onUpdtLblListChanges={onUpdtLblListChanges}
											value={_.get(values, 'labels', [])}
											hideAddLabel={!isAddLabelAllowed}
											hideEditLabel={!isEditLabelAllowed}
											hideDeleteLabel={!isDeleteLabelAllowed}
										/>
									</Grid>
									{!_.isEmpty(productList) && (
										<Grid item xs={12}>
											<ProductList data={productList} onClickDelProduct={delProdFun} errProdIds={shwErrProdIds} />
										</Grid>
									)}
									{!_.isEmpty(searchResults) && (
										<Grid item xs={12}>
											<Typography variant='overline' component='div'>
												{stickyFor === 'products' ? 'Products Added' : 'Search Results'}
											</Typography>
											<ResultsList
												showCheckBox={false}
												showDeleteBtn={true}
												onDeleteResult={onDeleteResult}
												data={searchResults}
												isMobileView={fullScreen}
											/>
										</Grid>
									)}
									<Grid item xs={12}>
										<Attachments
											name='attachments'
											attachmentList={_.get(values, 'attachments')}
											onAddAttachments={(e) => {
												handleChange({
													target: {
														name: 'attachments',
														value: [..._.get(values, 'attachments', []), ...e.target.files],
													},
												})
											}}
											onDeleteAttachment={(fileObjToDelete) => {
												const exstAttachments = _.get(values, 'attachments')
												handleChange({
													target: {
														name: 'attachments',
														value: update(exstAttachments, {
															$splice: [[_.indexOf(exstAttachments, fileObjToDelete), 1]],
														}),
													},
												})
											}}
										/>
									</Grid>
								</Grid>
							</>
						)
					}}
				</Formik>
			</DialogContent>
			<DialogActions className={classnames(classes.dialogFooter, classes.disableOnLoading)}>
				<Button variant='outlined' onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button disableElevation variant='contained' onClick={onClickAddBtn} color='primary'>
					{stickyFor === 'products' ? 'Add Job' : 'Add Takedown'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
