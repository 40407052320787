import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import { stringToHslColor } from 'utils'
import { getAvatarTxt } from 'utils'
import PopoverMenus from 'components/PopoverMenus'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import _ from 'lodash'

Header.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	onBackBtnClick: PropTypes.func.isRequired,
	glassCode: PropTypes.string.isRequired,
	isOwner: PropTypes.bool.isRequired,
	onClickEditBtn: PropTypes.func,
	onClickDeleteBtn: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		flexWrap: 'unset',
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3, 0),
		},
	},
	avatarWrapper: {
		width: 'unset',
	},
	avatar: {
		width: '45px',
		height: '45px',
		backgroundColor: (props) => stringToHslColor(props.title),
	},
	title: {
		width: '100%',
		fontWeight: '400',
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			lineHeight: 'unset',
		},
		'& span:first-child': {
			display: '-webkit-box',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
		},
	},
	subtitle: {
		width: '100%',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	titleWrapper: {
		paddingLeft: theme.spacing(1),
		width: 'unset',
		flex: '1',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
		},
	},
	actionBtn: {
		margin: theme.spacing(0, 1),
	},
}))

const actionMenus = [
	{
		label: 'Edit',
		icon: 'edit',
		actionName: 'edit',
		permission: PERMISSION_TYPE.EDIT,
	},
	{
		label: 'Delete',
		icon: 'delete',
		actionName: 'delete',
		permission: PERMISSION_TYPE.DELETE,
	},
]

function Header({ glassCode, title, subtitle, onBackBtnClick, isOwner, onClickEditBtn, onClickDeleteBtn }) {
	const avatarTxt = getAvatarTxt(title)
	const classes = useStyles({ title: avatarTxt })
	const [showActionMenus, openActionMenus] = useState(false)
	const actionsElem = useRef()
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const actionMenusToShow = _.filter(actionMenus, (menu) => isOwner && _.get(glassPerms, menu?.permission, false))

	const onActionClick = (actionName, glassCode) => {
		switch (actionName) {
			case 'edit':
				onClickEditBtn()
				//TODO edit operation
				break
			case 'delete':
				onClickDeleteBtn()
				//TODO delete operation
				break
			default:
				break
		}
		toggleActionMenus()
	}

	const toggleActionMenus = () => {
		openActionMenus(!showActionMenus)
	}

	return (
		<Grid className={classes.root} container>
			<Grid className={classes.avatarWrapper} container item justifyContent='center' alignItems='center'>
				<IconButton size='small' onClick={onBackBtnClick}>
					<Icon fontSize='small'>arrow_back_ios</Icon>
				</IconButton>
				<Avatar className={classes.avatar} color='primary' variant='square'>
					{avatarTxt}
				</Avatar>
			</Grid>
			<Grid container item direction='column' justifyContent='center' className={classes.titleWrapper} alignItems='center'>
				<Typography variant='h6' component='h1' className={classes.title}>
					<span>{title}</span>
					{isOwner && !_.isEmpty(actionMenusToShow) && (
						<span>
							<IconButton ref={actionsElem} color='primary' className={classes.actionBtn} size='small' onClick={toggleActionMenus}>
								<Icon fontSize='small'>more_horiz</Icon>
							</IconButton>
						</span>
					)}
				</Typography>
				<Typography variant='body2' color='textSecondary' component='h2' className={classes.subtitle}>
					{subtitle}
				</Typography>
			</Grid>
			{isOwner && !_.isEmpty(actionMenusToShow) && (
				<PopoverMenus
					open={showActionMenus}
					id={glassCode}
					menus={actionMenusToShow}
					anchorEl={actionsElem?.current}
					onClickAction={onActionClick}
					onClose={toggleActionMenus}
				/>
			)}
		</Grid>
	)
}

export default Header
