import { Grid, makeStyles, TextField, IconButton, Typography, Icon } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import React, { useMemo, useState, useEffect } from 'react'
import CategoryDialog from '../CategoryForm'
import RichTextEditor from './RichTextEditor'

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: theme.spacing(2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiAutocomplete-root': {
			'& .MuiFormControl-root': {
				'& .Mui-disabled': {
					color: '#ceced2',
				},
			},
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			fontSize: '22px',
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '12.5px',
		},
		'& .MuiAutocomplete-input': {
			padding: '8px 4px 8px 8px !important',
		},
	},
	chipTextField: {
		marginBottom: theme.spacing(2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			fontSize: '22px',
		},
		'& .MuiOutlinedInput-root': {
			padding: '12px 8px 8px 8px !important',
		},
	},

	textArea: {
		marginBottom: theme.spacing(2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			fontSize: '22px',
		},
		'& .MuiInputBase-root': {
			paddingTop: '12.5px',
		},
	},
	templateFields: {
		margin: '8px 0px',
	},
	formContentView: {
		padding: theme.spacing(2, 4, 0),
		'& .MuiInputBase-root.Mui-disabled': {
			marginTop: '5px',
		},
		'& .MuiFormLabel-root': {
			fontWeight: 'bold',
		},
	},
	formContent: {
		padding: '8px 24px 8px 24px',
	},
	linkContainer: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
		},
	},
	newLink: {
		'& .MuiLink-root': {
			fontSize: '14px',
		},
	},
	reqText: {
		color: '#9c9ca6',
		margin: '8px 0px 0px 8px',
	},
	resText: {
		fontWeight: 'bold',
		marginLeft: '15px',
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// paddingBottom: '8px',
	},
}))

const TemplateForm = (props) => {
	const classes = useStyles()
	const { values, errors, touched, handleBlur, resetForm, setFieldValue, resetErr, handleChange, isViewMode, isEditMode, modules, setModules } = props
	const [openPopup, setOpenPopup] = useState(false)
	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)
	const isCreateAllowed = React.useMemo(() => _.get(projectPerms, PERMISSION_TYPE.CREATE, false), [projectPerms])
	const { moduleList, isFetchingCategory, newCategoryCreated } = modules
	const [module, setModule] = useState({})
	const [body, Setbody] = useState('')

	const togglePopup = () => {
		setOpenPopup(!openPopup)
	}

	let modulesOnedit = _.get(values, 'module', [])

	useEffect(() => {
		Setbody(values.body_of_mail)
	}, [values])

	const dynamicSuggestions = useMemo(
		() =>
			_.map(module?.dynamic_fields || modulesOnedit?.dynamic_fields, (fields) => ({
				text: fields?.field,
				value: fields?.value,
				url: 'javascript:void(0);',
			})),
		[module, modulesOnedit]
	)

	return (
		<div className={isViewMode ? classes.formContentView : classes.formContent}>
			<CategoryDialog open={openPopup} handleClose={togglePopup} newCategoryCreated={newCategoryCreated} setModules={setModules} />
			<Grid container>
				<Grid item xs={12} lg={12}>
					<TextField
						fullWidth
						size='small'
						type='text'
						margin='dense'
						onBlur={handleBlur}
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('temp_name', '')
						}}
						value={values.temp_name}
						autoComplete='off'
						disabled={isViewMode}
						label={isViewMode ? 'Template Name' : 'Template Name'}
						variant='outlined'
						error={errors.temp_name && touched.temp_name}
						helperText={errors.temp_name && touched.temp_name && errors.temp_name}
						name='temp_name'
						className={isViewMode ? classes.textField : classes.templateFields}
						inputProps={{
							maxLength: 64,
						}}
					/>
				</Grid>
				<Grid container spacing={2} className={classes.nameContainer}>
					<Grid item xs={12} lg={12}>
						<Autocomplete
							options={moduleList || []}
							name='module'
							size={'small'}
							noOptionsText={isFetchingCategory ? 'Loading...' : 'No module found'}
							loading={isFetchingCategory}
							onChange={(e, value) => {
								handleChange({
									target: {
										name: 'module',
										value,
									},
								})
								setModule(value)
							}}
							onBlur={handleBlur}
							disabled={isViewMode}
							value={_.get(values, 'module', [])}
							getOptionLabel={(option) => option?.module_name || ''}
							getOptionSelected={(option, value) => option?.module_id === value?.module_id}
							// filterSelectedOptions
							loadingText='Fetching modules...'
							autoComplete
							renderInput={(params) => (
								<TextField
									{...params}
									name='module'
									variant='outlined'
									margin='dense'
									disabled={isViewMode}
									label={isViewMode ? 'Module' : 'Choose Module'}
									error={errors.module && touched.module}
									className={isViewMode ? classes.textField : classes.templateFields}
									helperText={errors.module && touched.module && errors.module}
								/>
							)}
						/>
					</Grid>
					{/* {!isViewMode && ( */}
					{/* <Grid item xs={2} lg={1} className={classes.newLink} style={{ marginTop: errors.category && touched.category && '-1.5rem' }}> */}
					{/* <Link onClick={togglePopup}>+ New Category</Link> */}
					{/* <IconButton color='primary' size='small' onClick={togglePopup}>
								<Icon>add</Icon>
							</IconButton> */}
					{/* </Grid> */}
					{/* )} */}
				</Grid>
				<Grid item xs={12} lg={12}>
					<TextField
						fullWidth
						size='small'
						type='text'
						margin='dense'
						onBlur={handleBlur}
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('subject', '')
						}}
						value={values.subject}
						autoComplete='off'
						disabled={isViewMode}
						label={isViewMode ? 'Subject' : 'Subject'}
						variant='outlined'
						error={errors.subject && touched.subject}
						helperText={errors.subject && touched.subject && errors.subject}
						name='subject'
						className={isViewMode ? classes.textField : classes.templateFields}
						inputProps={{
							maxLength: 100,
						}}
					/>
				</Grid>

				{!isViewMode && (
					<Typography variant='body2' className={classes.reqText}>
						To : 'Requested User'
					</Typography>
				)}
				<Grid container spacing={1}>
					<Grid item xs={12} lg={6} className={classes.chipInputHolder}>
						<Autocomplete
							multiple
							autoSelect
							name='cc'
							size='small'
							freeSolo
							options={[]}
							disabled={isViewMode}
							getOptionLabel={(option) => option}
							value={values.cc}
							onChange={(e, value) => {
								handleChange({
									target: {
										name: 'cc',
										value,
									},
								})
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									name='cc'
									label={isViewMode ? 'Cc' : 'Cc'}
									margin='dense'
									variant='outlined'
									disabled={isViewMode}
									error={errors.cc && touched.cc}
									className={isViewMode ? classes.chipTextField : classes.templateFields}
									helperText={errors.cc && touched.cc && errors.cc}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Autocomplete
							name='bcc'
							multiple
							autoSelect
							size='small'
							freeSolo
							options={[]}
							disabled={isViewMode}
							getOptionLabel={(option) => option}
							value={values.bcc}
							onChange={(e, value) => {
								handleChange({
									target: {
										name: 'bcc',
										value,
									},
								})
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									name='bcc'
									label={isViewMode ? 'Bcc' : 'Bcc'}
									margin='dense'
									variant='outlined'
									disabled={isViewMode}
									error={errors.bcc && touched.bcc}
									className={isViewMode ? classes.chipTextField : classes.templateFields}
									helperText={errors.bcc && touched.bcc && errors.bcc}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={isViewMode ? 12 : 12}>
					<>
						<Typography variant={isViewMode ? 'body1' : 'caption'} className={isViewMode ? classes.resText : classes.reqText}>
							Mail Content
						</Typography>
						<RichTextEditor
							// renderKey={loading}
							// rootClass={classes.activityEditerRoot}
							name={'body_of_mail'}
							placeholder='wrote something...'
							value={body}
							disabled={isViewMode}
							onBlur={handleBlur}
							error={errors.body_of_mail && touched.body_of_mail}
							helperText={errors.body_of_mail && touched.body_of_mail && errors.body_of_mail}
							onChange={(e) => {
								handleChange(e)
								e.target.value === ' ' && setFieldValue('body_of_mail', '')
							}}
							hideToolbarOnFocus={false}
							// readOnly={loading}
							suggestions={dynamicSuggestions}
						/>
					</>
					{/* )} */}
				</Grid>

				<Grid item xs={12} lg={isViewMode ? 6 : 12}>
					<TextField
						fullWidth
						size='small'
						type='text'
						margin='dense'
						onBlur={handleBlur}
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('attachment_name', '')
						}}
						value={values.attachment_name}
						autoComplete='off'
						disabled={isViewMode}
						label={isViewMode ? 'Attachment Name' : 'Attachment Name'}
						variant='outlined'
						error={errors.attachment_name && touched.attachment_name}
						helperText={errors.attachment_name && touched.attachment_name && errors.attachment_name}
						name='attachment_name'
						className={isViewMode ? classes.textField : classes.templateFields}
					/>
				</Grid>
				{/* <Grid item xs={12} lg={isViewMode ? 6 : 12}>
					<TextField
						fullWidth
						size='small'
						type='text'
						margin='dense'
						onBlur={handleBlur}
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('signature', '')
						}}
						value={values.signature}
						autoComplete='off'
						disabled={isViewMode}
						label={isViewMode ? 'Signature' : 'Signature'}
						variant='outlined'
						error={errors.signature && touched.signature}
						helperText={errors.signature && touched.signature && errors.signature}
						name='signature'
						multiline
						className={isViewMode ? classes.textArea : classes.templateFields}
						inputProps={{
							maxLength: 64,
						}}
					/>
				</Grid> */}
			</Grid>
		</div>
	)
}

export default TemplateForm
