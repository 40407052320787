import _ from 'lodash'
import update from 'immutability-helper'

export const initialState = {
	isFetchingGlass: false,
	glassList: [],
	isFetchingAssignee: false,
	assignees: [],
	isFetchingLabels: false,
	labels: [],
	showLoader: false,
	productList: [],
	ids: [],
	searchResults: [],
	errProdIds: [],
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchGlassPending':
			return {
				...state,
				isFetchingGlass: true,
				glassList: [],
				assignees: [],
				labels: [],
			}
		case 'fetchGlassCompleted':
			return { ...state, isFetchingGlass: false, glassList: action.data }
		case 'fetchAssigneePending':
			return { ...state, isFetchingAssignee: true, assignees: [] }
		case 'fetchAssigneeCompleted':
			return { ...state, isFetchingAssignee: false, assignees: action.data }
		case 'fetchLabelsPending':
			return { ...state, isFetchingLabels: true, labels: [] }
		case 'fetchLabelsCompleted':
			return { ...state, isFetchingLabels: false, labels: action.data }
		case 'setProductList':
			return { ...state, productList: action.data }
		case 'setResultsList':
			return { ...state, searchResults: action.data }
		case 'deleteSearchResult':
			return {
				...state,
				searchResults: update(_.get(state, 'searchResults', []), {
					$splice: [[action.idx, 1]],
				}),
			}
		case 'deleteProduct':
			return {
				...state,
				productList: update(_.get(state, 'productList', []), {
					$splice: [[action.idx, 1]],
				}),
			}
		case 'showLoader':
			return { ...state, showLoader: true }
		case 'hideLoader':
			return { ...state, showLoader: false }
		case 'setProdErrors':
			return { ...state, errProdIds: action.data }
		case 'resetState':
			return initialState
		default:
			throw new Error()
	}
}
