import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
	Drawer,
	Icon,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	MenuItem,
	MenuList,
	Paper,
	Popover,
	Typography,
	withWidth,
} from '@material-ui/core'
import _ from 'lodash'

PopoverMenus.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	menus: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			icon: PropTypes.string,
			actionName: PropTypes.string,
		})
	),
	onClickAction: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	anchorEl: PropTypes.any,
}

const useStyles = makeStyles((theme) => ({
	menuIco: {
		minWidth: 30,
	},
	sortIco: {
		minWidth: 30,
		marginLeft: 'auto',
	},
}))

function PopoverMenus({ open, anchorEl, onClose, menus, id, sortBy, activityState, activityIcon, onClickAction, width, className }) {
	const classes = useStyles()
	const list = useCallback(
		() => (
			<List>
				{_.map(menus, (menu) => (
					<ListItem
						button
						key={menu.label}
						selected={activityState !== undefined ? menu.label === activityState : menu.label === 'None'}
						onClick={() =>
							sortBy ? sortBy(menu.label, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward') : onClickAction(menu.actionName, id)
						}
					>
						{sortBy ? (
							<>
								<ListItemText primary={_.startCase(menu.label)} />
								{/* <ListItemIcon>
									<Icon>{menu.icon}</Icon>
								</ListItemIcon> */}
							</>
						) : (
							<>
								<ListItemIcon>
									<Icon>{menu.icon}</Icon>
								</ListItemIcon>
								<ListItemText primary={menu.label} />
							</>
						)}
					</ListItem>
				))}
			</List>
		),
		[menus, onClickAction, sortBy, id]
	)

	const menuList = useCallback(
		() => (
			<MenuList>
				{_.map(menus, (menu) => (
					<MenuItem
						key={menu.label}
						selected={activityState !== undefined ? menu.label === activityState : menu.label === 'None'}
						onClick={() =>
							sortBy ? sortBy(menu.label, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward') : onClickAction(menu.actionName, id)
						}
					>
						{sortBy ? (
							<>
								<Typography variant='body2'>{_.startCase(menu.label)}</Typography>
								{/* <ListItemIcon className={classes.sortIco}>
									<Icon fontSize='small' style={{ fontSize: menu?.iconSize ? menu?.iconSize : '1.25rem' }}>
										{menu.icon}
									</Icon>
								</ListItemIcon> */}
							</>
						) : (
							<>
								<ListItemIcon className={classes.menuIco}>
									<Icon fontSize='small' style={{ fontSize: menu?.iconSize ? menu?.iconSize : '1.25rem' }}>
										{menu.icon}
									</Icon>
								</ListItemIcon>

								<Typography variant='body2'>{menu.label}</Typography>
							</>
						)}
					</MenuItem>
				))}
			</MenuList>
		),
		[menus, classes, sortBy, onClickAction, id]
	)

	return (
		<div>
			{_.isEqual(width, 'xs') ? (
				<Drawer anchor={'bottom'} open={open} onClose={onClose}>
					{list()}
				</Drawer>
			) : (
				<Popover
					className={className}
					open={open}
					anchorEl={anchorEl}
					onClose={onClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Paper>{menuList()}</Paper>
				</Popover>
			)}
		</div>
	)
}

export default withWidth()(PopoverMenus)
