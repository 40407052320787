import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, List, ListItemIcon, ListItem, ListItemText, makeStyles, Popover, Box } from '@material-ui/core'
import _ from 'lodash'
import SearchBox from 'components/PainSearchBox'

ShowColumnsMenu.propTypes = {
	open: PropTypes.bool.isRequired,
	position: PropTypes.objectOf({ left: PropTypes.number, top: PropTypes.number }),
	handleClose: PropTypes.func.isRequired,
	columnDefs: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.string.isRequired,
			headerName: PropTypes.string.isRequired,
			hide: PropTypes.bool.isRequired,
		})
	),
	onChangeVisible: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	listItemIcon: {
		minWidth: 'unset',
	},
	checkbox: {
		padding: '0 9px',
	},
	searchBox: {
		paddingLeft: theme.spacing(1),
	},
}))

function ShowColumnsMenu({ open, position, handleClose, columnDefs, onChangeVisible }) {
	const [searchText, setSearchText] = useState('')
	const classes = useStyles()

	const delayedSearchQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	useEffect(() => {
		setSearchText('')
	}, [open])

	const onChangeSearch = (e) => {
		delayedSearchQuery(e.target.value, (val) => setSearchText(val))
	}

	const columnsToShow = useMemo(
		() => (_.isEmpty(searchText) ? columnDefs : _.filter(columnDefs, (col) => new RegExp(_.escapeRegExp(searchText), 'i').test(col?.headerName))),
		[columnDefs, searchText]
	)

	return (
		<Popover
			open={open}
			anchorReference='anchorPosition'
			anchorPosition={position}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Box paddingLeft={1} paddingRight={1} paddingTop={1}>
				<SearchBox size='small' onChange={onChangeSearch} className={classes.searchBox} />
			</Box>
			<List dense className={classes.root}>
				{_.map(columnsToShow, (column, index) => (
					<ListItem
						key={column?.field}
						role={undefined}
						dense={true}
						className={classes.listItemIcon}
						button
						onClick={() => {
							onChangeVisible(column?.field, column?.hide)
						}}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Checkbox
								edge='start'
								checked={!column?.hide}
								tabIndex={-1}
								className={classes.checkbox}
								size='small'
								disableRipple
								inputProps={{ 'aria-labelledby': column?.headerName }}
							/>
						</ListItemIcon>
						<ListItemText id={column?.field} primary={column?.headerName} />
					</ListItem>
				))}
			</List>
		</Popover>
	)
}

export default ShowColumnsMenu
