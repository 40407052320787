import React, { useEffect, useState, useRef } from 'react'
import { Dialog, makeStyles, Button, DialogContent, DialogActions, DialogTitle, Slide, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import ProjectForm from './ProjectForm'
import { PAGE_MODES } from '../../../constants'
import _ from 'lodash'
import projectApi from '../../../services/projects/api'
import { useDispatch } from 'react-redux'
import { hideLoader, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(0),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Add Project'
		case PAGE_MODES.EDIT:
			return 'Edit Project'
		case PAGE_MODES.VIEW:
			return 'View Project'
		default:
			return ''
	}
}

const FormDialog = ({ open, handleClose, action, dataId, changeEditMode, refreshData, isEditAllowed }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)
	const [isFetchingProject, setFetchingProject] = useState(false)
	const formikRef = useRef()
	const [values, setValues] = useState({
		created_by: '',
		modified_by: '',
		project_code: '',
		project_name: '',
		project_desc: '',
		tu_name: '',
		modified_date: '',
		created_date: '',
		tu_code: '',
	})
	const dialogTitle = getDialogTitle(action)
	const validationSchema = Yup.object({
		project_name: Yup.string().trim().required('Please enter the Project name'),
		project_desc: Yup.string().required('Please enter the Project Objectives and Key Results'),
		tu_code: Yup.string().nullable().required('Please select the Team unit'),
	})
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const getSingleUser = (idx) => {
		setFetchingProject(true)
		dispatch(showLoader('Please wait, getting project details'))
		projectApi.fetchSingleProject(
			idx,
			(res) => {
				setFetchingProject(false)
				setValues({
					...values,
					created_by: _.get(res, 'data.data.created_by', ''),
					modified_by: _.get(res, 'data.data.modified_by', ''),
					project_code: _.get(res, 'data.data.project_code', ''),
					project_name: _.get(res, 'data.data.project_name', ''),
					project_desc: _.get(res, 'data.data.project_desc', ''),
					tu_name: _.get(res, 'data.data.tu_name', ''),
					tu_code: _.get(res, 'data.data.tu_code', ''),
					modified_date: _.get(res, 'data.data.modified_date', ''),
					created_date: _.get(res, 'data.data.created_date', ''),
				})
				dispatch(hideLoader())
			},
			(err) => {
				setFetchingProject(false)
				handleClose()
				dispatch(
					showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
						dispatch(hideLoader())
					})
				)
			}
		)
	}

	useEffect(() => {
		if (!_.isEmpty(dataId)) {
			getSingleUser(dataId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataId])
	useEffect(() => {
		if (isCreateMode) {
			setValues({
				created_by: '',
				modified_by: '',
				project_code: '',
				project_name: '',
				project_desc: '',
				tu_name: '',
				tu_code: '',
				modified_date: '',
				created_date: '',
			})
		}
	}, [isCreateMode])

	const handleSubmitForm = (value) => {
		if (isCreateMode) {
			dispatch(showLoader('Please wait...'))
			const data = { project_name: _.trim(value.project_name), project_desc: value.project_desc, tu_code: value.tu_code }
			projectApi.createProject(
				data,
				(res) => {
					dispatch(hideLoader())
					handleClose()
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
					setValues({
						created_by: '',
						modified_by: '',
						project_code: '',
						project_name: '',
						project_desc: '',
						tu_name: '',
						tu_code: '',
						modified_date: '',
						created_date: '',
					})
					formikRef.current?.resetForm()
					refreshData()
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		} else if (isEditMode) {
			dispatch(showLoader('Please wait...'))
			const data = { project_name: value.project_name, project_desc: value.project_desc }
			projectApi.editProject(
				data,
				dataId,
				(res) => {
					dispatch(hideLoader())
					handleClose()
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Success!'), 2000))
					refreshData()
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		}
	}
	const [resetErr, setResetErr] = useState(false)

	const onClickClose = () => {
		setResetErr(true)
		handleClose()
	}

	const onClickCancel = () => {
		handleClose()
	}

	useEffect(() => {
		if (open) setResetErr(false)
	}, [open])
	useEffect(() => {
		setResetErr(true)
	}, [])
	return (
		<>
			{!isFetchingProject && (
				<Dialog
					classes={{ paper: classes.dialog }}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					fullWidth
					fullScreen={fullScreen}
					maxWidth={'sm'}
					onClose={isViewMode ? onClickCancel : onClickClose}
					aria-labelledby='add-project-form'
				>
					<DialogTitle>{dialogTitle}</DialogTitle>
					<Divider />
					<Formik innerRef={formikRef} enableReinitialize initialValues={values} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
						{(props) => (
							<>
								<DialogContent className={classes.dialogContent}>
									<ProjectForm
										{...props}
										resetErr={resetErr}
										cancelBtnClick={onClickClose}
										isEditMode={isEditMode}
										isCreateMode={isCreateMode}
										isViewMode={isViewMode}
										changeEditMode={changeEditMode}
										isEditAllowed={isEditAllowed}
										onClickCancel={onClickCancel}
									/>
								</DialogContent>
								<DialogActions className={classes.dialogFooter}>
									<Button
										variant='outlined'
										size={'medium'}
										onClick={
											isViewMode
												? onClickCancel
												: () => {
														props.resetForm()
														handleClose()
												  }
										}
										color='primary'
									>
										{isEditMode ? 'Cancel' : 'Close'}
									</Button>
									{isViewMode && !isEditAllowed ? null : (
										<Button
											variant='contained'
											onClick={isViewMode && isEditAllowed ? changeEditMode : props.handleSubmit}
											size={'medium'}
											color='primary'
											disableElevation
										>
											{isViewMode && isEditAllowed ? 'Edit' : 'Save'}
										</Button>
									)}
								</DialogActions>
							</>
						)}
					</Formik>
				</Dialog>
			)}
		</>
	)
}
FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default FormDialog
