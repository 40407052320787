import { useCallback } from 'react'
import html2canvas from 'html2canvas'

export function useCapture(domRefToCapture) {
	const takeScreenshot = useCallback((node, options) => {
		return html2canvas(node, options).then(function (canvas) {
			var croppedCanvas = document.createElement('canvas')
			var croppedCanvasContext = croppedCanvas.getContext('2d') // init data
			var cropPositionTop = 0
			var cropPositionLeft = 0
			var cropWidth = canvas.width
			var cropHeight = canvas.height
			croppedCanvas.width = cropWidth
			croppedCanvas.height = cropHeight
			croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)
			var base64Image = croppedCanvas.toDataURL()
			return base64Image
		})
	}, [])

	const getImage = useCallback(
		(options = {}) =>
			new Promise((resolve, reject) => {
				takeScreenshot(domRefToCapture.current, options).then((base64Img) => {
					fetch(base64Img)
						.then((res) => res.blob())
						.then((blob) => {
							const file = new File([blob], `screenshot-${new Date().toISOString()}.png`, { type: 'image/png' })
							resolve(file)
						})
				})
			}),
		[domRefToCapture, takeScreenshot]
	)

	return getImage
}
