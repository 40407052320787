import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetails, Icon, makeStyles, Typography } from '@material-ui/core'
import Accordion from '../../Components/Accordion'
import AccordionSummary from '../../Components/AccordionSummary'
import MembersTable from 'components/MembersTable'
import SearchFabWrapper from '../../Components/SearchFabWrapper'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { GLASS_ROLES } from '../../../../../constants'
import { removeGlassMember, updateGlassMember } from 'services/glass/actions'
import { isModuleHasPerm } from 'utils'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import SocketContext from '../../../socket-context'

const useStyles = makeStyles((theme) => ({
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
}))

const UsersAccordion = ({ onChangeMemberRole, onRemoveMember, label, isOwner, data }) => {
	const classes = useStyles()
	return (
		<Accordion defaultExpanded={true}>
			<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>} aria-controls={label} id={label}>
				<Typography className={classes.accTitle} variant='body2'>
					{label}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accSummary}>
				<MembersTable onChangeMemberRole={onChangeMemberRole} onRemoveMember={onRemoveMember} isOwner={isOwner} data={data} />
			</AccordionDetails>
		</Accordion>
	)
}

UsersAccordion.propTypes = {
	label: PropTypes.string.isRequired,
	isOwner: PropTypes.bool.isRequired,
	onChangeMemberRole: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
}

const Members = ({ isOwner, glassCode, openAddMemDialog }) => {
	const classes = useStyles()
	const allGlassMembers = useSelector((state) => _.get(state, 'glass.glass_members'))
	const glassMembers = useMemo(() => _.filter(allGlassMembers, (member) => member?.is_active), [allGlassMembers])
	const [searchText, setSearchText] = useState('')
	const dispatch = useDispatch()
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)

	const { sessionId } = useContext(SocketContext)

	const onChangeSearchBox = (searchTxt) => {
		setSearchText(searchTxt)
	}
	const onChangeMemberRole = (userId, role) => {
		dispatch(updateGlassMember(sessionId, glassCode, userId, role, true))
	}

	const onRemoveMember = (userId) => {
		dispatch(removeGlassMember(sessionId, glassCode, userId, true))
	}

	const ownersData = useMemo(() => (_.isEmpty(searchText) ? _.filter(glassMembers, { role: GLASS_ROLES.OWNER }) : []), [glassMembers, searchText])
	const membersData = useMemo(
		() => (_.isEmpty(searchText) ? _.filter(glassMembers, (user) => user?.role !== GLASS_ROLES.OWNER) : []),
		[glassMembers, searchText]
	)

	const onClickAddMemFab = () => {
		openAddMemDialog()
	}

	const searchListData = useMemo(
		() =>
			_.isEmpty(searchText)
				? []
				: _.filter(
						glassMembers,
						(user) =>
							new RegExp(_.escapeRegExp(searchText), 'i').test(_.join([user?.first_name, user?.last_name], ' ')) ||
							new RegExp(_.escapeRegExp(searchText), 'i').test(user?.email)
				  ),
		[searchText, glassMembers]
	)

	return (
		<>
			<SearchFabWrapper
				isOwner={isAccessible}
				searchPlaceholder='Search Members'
				onChangeSearchBox={onChangeSearchBox}
				onClickFab={onClickAddMemFab}
				fabIcon='person_add'
			>
				{_.isEmpty(searchText) ? (
					<>
						<UsersAccordion
							onChangeMemberRole={onChangeMemberRole}
							onRemoveMember={onRemoveMember}
							data={ownersData}
							classes={classes}
							label='Owners'
							isOwner={isAccessible}
						/>
						<UsersAccordion
							onChangeMemberRole={onChangeMemberRole}
							onRemoveMember={onRemoveMember}
							data={membersData}
							classes={classes}
							label='Members & Guests'
							isOwner={isAccessible}
						/>
					</>
				) : (
					<MembersTable isOwner={isAccessible} data={searchListData} />
				)}
			</SearchFabWrapper>
		</>
	)
}

Members.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
}

export default Members
