import { Icon, makeStyles, Grid, IconButton, Box, Typography, Button } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import _ from 'lodash'

// Import Swiper styles
import 'swiper/swiper.scss'
import ReactPlayer from 'react-player'
import { getImgUrl } from 'utils'

const useStyles = makeStyles((theme) => ({
	swiperRoot: {
		height: '100%',
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '2.25rem',
		},
	},
	slideContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		padding: '5px',
		boxSizing: 'border-box',
	},
	slideImage: {
		maxHeight: '90vh',
		maxWidth: '100%',
	},
	mobileStepper: {
		width: '100%',
		borderRadius: '10px',
	},
	navBtn: {
		color: theme.palette.common.white,
	},
	noPrevRoot: {
		backgroundColor: '#4c494c',
		color: theme.palette.common.white,
		borderRadius: '10px',
		textAlign: 'center',
	},
	downloadBtn: {
		marginTop: theme.spacing(2),
	},
}))

const isImage = (mediaType) => {
	return ['image/gif', 'image/jpeg', 'image/png'].indexOf(mediaType) > -1
}

const isPlayerSupport = (mediaType) => {
	return ['video/mp4', 'video/3gpp', 'audio/mpeg'].indexOf(mediaType) > -1
}

const SwiperCarousel = ({ fileList, accessToken, activeIdx, setActiveIdx }) => {
	const classes = useStyles()
	const [swiper, setSwiper] = useState(null)
	const params = {
		onSwiper: setSwiper,
		autoHeight: true,
		slidesPerView: 1,
	}

	const handleNext = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slideNext()
			setActiveIdx(activeIdx + 1)
		}
	}

	const handleBack = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slidePrev()
			setActiveIdx(activeIdx - 1)
		}
	}

	const onClickDownload = (filePath) => {
		window.open(filePath, '_blank')
	}

	useEffect(() => {
		if (swiper && swiper.activeIndex !== activeIdx) {
			swiper.slideTo(activeIdx, 0)
		}
	}, [activeIdx, swiper])

	const filesToShow = useMemo(() => _.map(fileList, (file) => ({ ...file, url: getImgUrl(accessToken, file?.file_path) })), [fileList, accessToken])

	return (
		<Grid className={classes.swiperRoot} container alignItems='center' justifyContent='center'>
			<Grid item lg={1} xs={1} container justifyContent='center'>
				<IconButton className={classes.navBtn} disabled={activeIdx === 0} onClick={handleBack} size='small'>
					<Icon fontSize='small'>chevron_left</Icon>
				</IconButton>
			</Grid>
			<Grid item lg={10} xs={10}>
				<Swiper className={classes.swiperContainer} {...params}>
					{_.map(filesToShow, (file, index) => (
						<SwiperSlide key={file?.attachment_id} className={classes.slideContent}>
							{isImage(file?.file_type) ? (
								<img className={classes.slideImage} alt={''} src={file?.url} />
							) : isPlayerSupport(file?.file_type) ? (
								<ReactPlayer className={classes.reactPlayer} url={file?.url} width={'100%'} height={'90vh'} playing={false} controls={true} />
							) : (
								<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
									<Typography variant='body1'>No preview available</Typography>
									<Button
										startIcon={<Icon>get_app</Icon>}
										size='small'
										variant='contained'
										disableElevation
										color='primary'
										className={classes.downloadBtn}
										onClick={() => {
											onClickDownload(getImgUrl(accessToken, file?.file_path))
										}}
									>
										Download
									</Button>
								</Box>
							)}
						</SwiperSlide>
					))}
				</Swiper>
			</Grid>
			<Grid item lg={1} xs={1} container justifyContent='center'>
				<IconButton className={classes.navBtn} disabled={_.isEqual(activeIdx, fileList?.length - 1)} onClick={handleNext} size='small'>
					<Icon fontSize='small'>chevron_right</Icon>
				</IconButton>
			</Grid>
		</Grid>
	)
}

export default SwiperCarousel
