import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import Header from './Header'
import SettingsTabs from './Tabs'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { updateGlassDetailsAfterEdit } from 'services/glass/actions'
import { hideLoader, showConfirmMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import GlassApi from '../../../services/glass/api'
import { getSuccessMsg, getErrMsg } from 'utils'
import GlassFormDialog from 'components/GlassFormDialog'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		boxSizing: 'border-box',
		backgroundColor: theme.palette.background.default,
		zIndex: 99,
		overflowY: 'auto',
	},
	contentWrapper: {
		maxWidth: '1200px',
		margin: '0 auto',
	},
}))

Settings.propTypes = {
	glassName: PropTypes.string.isRequired,
	glassCode: PropTypes.string.isRequired,
	projectName: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	isOwner: PropTypes.bool.isRequired,
	openAddMemDialog: PropTypes.func.isRequired,
	sessionId: PropTypes.string,
}

function Settings({ isOwner, sessionId, glassCode, dashboard, glassName, projectName, onClose, selectedData, openAddMemDialog }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [openEditPopup, setOpenPopup] = useState(false)
	const history = useHistory()
	const handleClose = () => {
		setOpenPopup(false)
	}

	const onClickEditBtn = () => {
		setOpenPopup(true)
	}

	const deleteGlassFxn = (glassCode) => {
		dispatch(showLoader('Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			history.push('/glassx')
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
		}
		const onFailure = (err) => {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			dispatch(hideLoader())
		}

		GlassApi.deleteGlass(glassCode).then(onSuccess, onFailure)
	}

	const onClickDeleteBtn = (data) => {
		dispatch(
			showConfirmMessage(
				['Are you sure, you want to delete the ', <b>{glassName}</b>, ' GlassX board?'],
				'',
				'Confirm',
				(e) => {
					deleteGlassFxn(glassCode)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	const refreshList = (formData) => {
		dispatch(updateGlassDetailsAfterEdit(formData))
	}

	// useEffect(() => {
	// 	let url = window.location.pathname
	// 	window.history.pushState(null, null, url)
	// 	window.addEventListener('popstate', () => {
	// 		console.log('history.action', history.action)
	// 		if (history.action === 'POP') {
	// 			history.push(url)
	// 		}
	// 	})
	// 	return () => {
	// 		window.history.pushState(null, null, url)
	// 	}
	// }, [history])

	return (
		<div className={classes.root}>
			<div className={classes.contentWrapper}>
				<Header
					isOwner={isOwner}
					glassCode={glassCode}
					onClickEditBtn={onClickEditBtn}
					onClickDeleteBtn={onClickDeleteBtn}
					title={glassName}
					subtitle={projectName}
					onBackBtnClick={onClose}
				/>
				<SettingsTabs
					openAddMemDialog={openAddMemDialog}
					glassCode={glassCode}
					dashboard={dashboard}
					isOwner={isOwner}
					onClickEditBtn={onClickEditBtn}
					onClickDeleteBtn={onClickDeleteBtn}
				/>
				<GlassFormDialog
					selectedData={selectedData}
					open={openEditPopup}
					showConfirmPopup={true}
					sessionId={sessionId}
					refreshList={refreshList}
					handleClose={handleClose}
				/>
			</div>
		</div>
	)
}

export default Settings
