import React, { useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import SettingsList from '../../Components/SettingsList'
import PopoverMenus from 'components/PopoverMenus'
import { useState } from 'react'
import SearchFabWrapper from '../../Components/SearchFabWrapper'
import DialogForm from '../../Components/DialogForm'
import ActivityForm from './ActivityForm'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg, isModuleHasPerm } from 'utils'
import { addGlassActType, removeGlassActType, updateGlassActType } from 'services/glass/actions'
import SocketContext from '../../../socket-context'

const useStyles = makeStyles((theme) => ({
	settingsList: {
		paddingTop: theme.spacing(1.5),
	},
}))

const actionMenus = [
	{
		label: 'Edit',
		icon: 'edit',
		actionName: PERMISSION_TYPE.EDIT,
	},
	{
		label: 'Delete',
		icon: 'delete',
		actionName: PERMISSION_TYPE.DELETE,
	},
]

const ActivityTypes = ({ glassCode, isOwner }) => {
	const classes = useStyles()
	const actFormRef = useRef()
	const dispatch = useDispatch()

	const [pageQuery, setPageQuery] = useUrlSearchParams({ openActForm: false, actTypeId: null }, { openActForm: Boolean, actTypeId: String }, true)

	const { sessionId } = useContext(SocketContext)

	const glassActTypes = useSelector((state) => _.get(state, 'glass.settings.activity_type'))
	const [actionMenuState, setActionMenuState] = useState({ openActions: false, anchorEl: null, actTypeId: '' })
	const [searchText, setSearchText] = useState('')

	const { openActions, anchorEl, actTypeId } = actionMenuState

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isCreateAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)
	const actionMenusToShow = _.filter(actionMenus, (menu) => isOwner && isModuleHasPerm(glassPerms, menu?.actionName))

	const onClickActTypeAction = (e, data) => {
		setActionMenuState({ openActions: true, anchorEl: e.currentTarget, actTypeId: data?.id })
	}

	const onActFormSubmitBtn = () => {
		actFormRef.current.submitForm()
	}

	const onCloseActTypeAction = () => {
		setActionMenuState((prev) => ({ ...prev, openActions: false }))
	}

	const performActTypeAction = (actionName, actTypeId) => {
		switch (actionName) {
			case PERMISSION_TYPE.EDIT:
				setPageQuery({ openActForm: true, actTypeId })
				break
			case PERMISSION_TYPE.DELETE:
				deleteActType(actTypeId)
				break
			default:
				break
		}
		onCloseActTypeAction()
	}

	const onClickAddActivity = () => {
		setPageQuery({ openActForm: true, labelId: '' }, true)
	}

	const closeActivityForm = () => {
		setPageQuery({ openActForm: false, labelId: '', actTypeId: null }, true)
	}

	const onChangeSearchBox = (searchTxt) => {
		setSearchText(searchTxt)
	}

	const listData = useMemo(
		() =>
			_.map(
				_.isEmpty(searchText)
					? glassActTypes
					: _.filter(glassActTypes, (actType) => new RegExp(_.escapeRegExp(searchText), 'i').test(actType?.activity_name)),
				(actType) => ({
					id: actType?.activity_id,
					name: actType?.activity_name,
					position: actType?.activity_position,
				})
			),
		[searchText, glassActTypes]
	)

	const initialValues = useMemo(() => {
		if (pageQuery?.openActForm) {
			return _.find(glassActTypes, { activity_id: pageQuery?.actTypeId })
		} else {
			actFormRef.current?.resetForm()
		}
	}, [pageQuery, glassActTypes])

	const hideAnyInfoDialog = () => {
		dispatch(hideLoader())
	}

	const onSubmitActForm = (values) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			closeActivityForm()
			actFormRef.current?.resetForm()
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
			actFormRef.current?.resetForm()
		}
		const isAddingActType = _.isEmpty(values?.activity_id)
		dispatch(showLoader(isAddingActType ? 'Creating Activity Type...' : 'Updating Activity Type...'))
		dispatch(
			isAddingActType
				? addGlassActType(sessionId, glassCode, values?.activity_name, onSuccess, onError)
				: updateGlassActType(sessionId, glassCode, values?.activity_id, values?.activity_name, onSuccess, onError)
		)
	}

	const askDeleteConfirm = (onConfirm, onCancel) => {
		dispatch(showConfirmMessage(`Are you sure, you want to delete this Activity Type?`, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const deleteActType = (actTypeId) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		askDeleteConfirm(
			() => {
				dispatch(showLoader('Deleting Activity Type...'))
				dispatch(removeGlassActType(sessionId, glassCode, actTypeId, onSuccess, onError))
			},
			() => {
				hideAnyInfoDialog()
			}
		)
	}

	return (
		<SearchFabWrapper
			isOwner={isCreateAccessible}
			searchPlaceholder='Search for activity types'
			onChangeSearchBox={onChangeSearchBox}
			onClickFab={onClickAddActivity}
			fabIcon='add'
		>
			<>
				{_.isEmpty(listData) ? (
					<Typography variant='body2' color='textSecondary'>
						No activity types found
					</Typography>
				) : (
					<SettingsList
						hideActionMenu={_.isEmpty(actionMenusToShow)}
						onClickAction={onClickActTypeAction}
						data={listData}
						className={classes.settingsList}
					/>
				)}
				{!_.isEmpty(actionMenusToShow) && (
					<PopoverMenus
						open={openActions}
						id={actTypeId}
						menus={actionMenusToShow}
						anchorEl={anchorEl}
						onClickAction={performActTypeAction}
						onClose={onCloseActTypeAction}
					/>
				)}
				<DialogForm
					dialogTitle={pageQuery?.actTypeId ? 'Edit Activity Type' : 'Add Activity Type'}
					open={pageQuery?.openActForm}
					onClose={closeActivityForm}
					onClickCancelBtn={closeActivityForm}
					onClickOkBtn={onActFormSubmitBtn}
				>
					<ActivityForm initialValues={initialValues} onSubmit={onSubmitActForm} ref={actFormRef} />
				</DialogForm>
			</>
		</SearchFabWrapper>
	)
}

ActivityTypes.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
}

export default ActivityTypes
