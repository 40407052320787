import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchAppConfigs: () => {
		return Axios.get(API_ENDPOINTS.APP_CONFIG, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	saveAppConfigs: (formData) => {
		return Axios.put(API_ENDPOINTS.APP_CONFIG, formData, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
