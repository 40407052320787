import { ACTION_TYPES } from '../../../constants'
import update from 'immutability-helper'

const initialState = {
	dashboards: [],
}

const popupDashStack = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.PUSH_ROOT_DASHBOARD_STACK:
			return {
				dashboards: [{ ...action.payload.data }],
			}
		case ACTION_TYPES.PUSH_DASHBOARD_STACK:
			return update(state, { dashboards: { $push: [action.payload.data] } })
		case ACTION_TYPES.POP_DASHBOARD_STACK:
			const delIdx = action.payload.popIdx
			return update(state, {
				dashboards: { $splice: [[delIdx, state.dashboards.length - 1]] },
			})
		default:
			return state
	}
}

export default popupDashStack
