import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@material-ui/core'
import _ from 'lodash'

/* GET CONDITIONAL VALUE FIELD BASED ON COLUMN TYPE AND CONDITION */
const FilterValueField = React.memo(
	({ dataSourceIdx, filterIdx, fieldDataType, condition, value, handleFilterValueChange, label }) => {
		switch (true) {
			// case fieldDataType === DATA_TYPES.STRING && condition === DATA_CONDITIONS.IS_IN:
			//     return(
			//         <FormControl className="filter-list-formcontrol">
			//             <InputLabel htmlFor="filter-value">Values</InputLabel>
			//             <Input id="filter-value"
			//              disabled={!condition}
			//              onChange={(e)=> { handleFilterValueChange(dataSourceIdx, index, e.target.value) }}
			//              value={(values && values[0]) || ""}
			//              aria-describedby="filter-values-input" />
			//         </FormControl>
			//     )
			default:
				return (
					<FormControl margin='normal' className='filter-list-formcontrol'>
						<TextField
							disabled={_.isEmpty(condition)}
							value={(value && value[0]) || ''}
							onChange={(e) => {
								handleFilterValueChange(dataSourceIdx, filterIdx, e.target.value)
							}}
							size='small'
							label='Value'
							variant='outlined'
						/>
					</FormControl>
				)
		}
	},
	(prevProps, nextProps) => {
		return _.isEqual(prevProps, nextProps)
	}
)

FilterValueField.propTypes = {
	dataSourceIdx: PropTypes.number.isRequired,
	filterIdx: PropTypes.number.isRequired,
	handleFilterValueChange: PropTypes.func.isRequired,
	fieldDataType: PropTypes.string,
	condition: PropTypes.string,
	value: PropTypes.array,
}

export default FilterValueField
