import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	progressbarContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
		alignItems: 'center',
	},
	progressbarTotal: {
		backgroundColor: theme.palette.grey[300],
		height: 10,
		width: '100%',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'row',
	},
	inProgressbar: {
		backgroundColor: theme.palette.progress.inprogress,
		height: 10,
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
	completedBar: {
		backgroundColor: theme.palette.progress.completed,
		height: 10,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	progressContent: {
		'& .MuiTypography-body1': {},
	},
	completedText: {
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
}))

const Progressbar = ({ progressData, totalList }) => {
	const [progressPercentage, setProgressPercentage] = useState({
		total: totalList,
		completed: 0,
		inprogress: 0,
	})

	useEffect(() => {
		if (!_.isEmpty(progressData)) {
			let completedArr = _.filter(
				progressData,
				(item) => _.isEqual(item?.sticky_status?.status, 'completed') || _.isEqual(item?.sticky_status?.status, 'approved')
			)
			let inprogressArr = _.filter(progressData, (item) => _.isEqual(item?.sticky_status?.status, 'inprogress'))
			setProgressPercentage((prevState) => ({
				...prevState,
				completed: _.floor((completedArr.length / totalList) * 100),
				inprogress: _.floor((inprogressArr.length / totalList) * 100),
				total: totalList,
			}))
		}
	}, [progressData, totalList])

	const classes = useStyles()
	return (
		<div className={classes.progressbarContainer}>
			<div className={classes.progressbarTotal}>
				<div
					title={`Completed - ${progressPercentage?.completed}%`}
					style={{ width: `${progressPercentage?.completed}%` }}
					className={classes.completedBar}
				></div>
				<div
					title={`In Progress - ${progressPercentage?.inprogress}%`}
					style={{ width: `${progressPercentage?.inprogress}%` }}
					className={classes.inProgressbar}
				></div>
			</div>
			<div className={classes.progressContent}>
				<Typography variant='caption' color='textSecondary' className={classes.completedText}>
					{`${progressPercentage?.completed}% Completed`}
				</Typography>
			</div>
		</div>
	)
}

export default Progressbar
