import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Icon, IconButton, makeStyles, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import _ from 'lodash'
import { convertGmtToLocalTime, formTheName, getImgUrl, msToDurationTxt, stringToHslColor } from 'utils'
import { useSelector } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: 30,
		height: 30,
		fontSize: 14,
	},
	duration: {
		fontWeight: '500',
	},
	tableCellSmall: {
		[theme.breakpoints.down('xs')]: {
			padding: 6,
			fontSize: 13,
		},
	},
	thCell: {
		fontSize: 12,
	},
	showRed: {
		color: theme.palette.error.main,
	},
}))

const getLoggedTimeInHrs = (from, to) => {
	return msToDurationTxt(moment(to).valueOf() - moment(from).valueOf())
}

const LogTableRow = ({ classes, token, item, disabled, onClick, onClickRemoveBtn }) => {
	const onClickRow = () => {
		if (!disabled) onClick(item)
	}

	const onClickDeleteBtn = (e) => {
		e.stopPropagation() //stop event bubbling
		if (!disabled) onClickRemoveBtn(item)
	}

	return (
		<TableRow className={classnames({ [classes.showRed]: item?.is_manual })} onClick={onClickRow}>
			<TableCell classes={{ sizeSmall: classes.tableCellSmall }} style={{ width: 10 }}>
				<Avatar
					size='small'
					title={`${item?.created_by?.first_name} ${item?.created_by?.last_name}\n ${item?.created_by?.email}`}
					className={classes.avatar}
					src={getImgUrl(token, item?.created_by?.profile_img) || null}
					style={{ backgroundColor: stringToHslColor(formTheName(item?.created_by?.first_name, item?.created_by?.last_name)) }}
				>
					{_.slice(_.words(formTheName(item?.created_by?.first_name, item?.created_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
				</Avatar>
			</TableCell>
			<TableCell align='center' classes={{ sizeSmall: classes.tableCellSmall }}>
				{convertGmtToLocalTime(item?.from, 'MMM DD, hh:mm a')}
			</TableCell>
			<TableCell align='center' classes={{ sizeSmall: classes.tableCellSmall }}>
				{convertGmtToLocalTime(item?.to, 'MMM DD, hh:mm a')}
			</TableCell>
			<TableCell align='center' classes={{ sizeSmall: classes.tableCellSmall }} style={{ fontWeight: '500' }}>
				{getLoggedTimeInHrs(item?.from, item?.to)}
			</TableCell>
			{!disabled && (
				<TableCell align='center' classes={{ sizeSmall: classes.tableCellSmall }} style={{ width: 10 }}>
					<IconButton size='small' onClick={onClickDeleteBtn}>
						<Icon fontSize='small'>close</Icon>
					</IconButton>
				</TableCell>
			)}
		</TableRow>
	)
}

const LogsTable = ({ data, disabled, onTableRowClick, onRemoveLog }) => {
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const classes = useStyles()

	return !_.isEmpty(data) ? (
		<Table size='small'>
			<TableHead>
				<TableRow>
					<TableCell className={classes.thCell} classes={{ sizeSmall: classes.tableCellSmall }} align='center'>
						WHO
					</TableCell>
					<TableCell className={classes.thCell} classes={{ sizeSmall: classes.tableCellSmall }} align='center'>
						FROM
					</TableCell>
					<TableCell className={classes.thCell} classes={{ sizeSmall: classes.tableCellSmall }} align='center'>
						TO
					</TableCell>
					<TableCell className={classes.thCell} classes={{ sizeSmall: classes.tableCellSmall }} align='center'>
						TOTAL
					</TableCell>
					<TableCell className={classes.thCell} classes={{ sizeSmall: classes.tableCellSmall }} align='center'></TableCell>
				</TableRow>
			</TableHead>
			{_.map(data, (item) => (
				<LogTableRow
					key={item?.time_log_id}
					classes={classes}
					token={token}
					item={item}
					disabled={disabled}
					onClick={disabled ? null : onTableRowClick}
					onClickRemoveBtn={onRemoveLog}
				/>
			))}
		</Table>
	) : (
		<Typography variant='caption' align='center' component='div'>
			No time logs found
		</Typography>
	)
}

LogsTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			time_log_id: PropTypes.string,
			created_by: PropTypes.shape({
				first_name: PropTypes.string,
				last_name: PropTypes.string,
				email: PropTypes.string,
				profile_img: PropTypes.string,
			}),
			from: PropTypes.string,
			to: PropTypes.string,
		})
	),
	disabled: PropTypes.bool,
	onTableRowClick: PropTypes.func,
	onClickRemoveBtn: PropTypes.func,
}

export default LogsTable
