import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'

const getFileExtension = (file) => {
	const logoImg = file.name
	return logoImg.substring(logoImg.lastIndexOf('.'), logoImg.length)
}

function isUrlValid(userInput) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	) // fragment locator
	return pattern.test(userInput)
}

const validateLogoFile = (file, acceptsExts, acceptRes, isTestRatio, maxSize) => {
	return new Promise((resolve, reject) => {
		const checkResolution = (file, acceptRes) => {
			try {
				return new Promise((resolve, reject) => {
					let img = new Image()
					img.src = URL.createObjectURL(file)
					img.onload = () => {
						if (isTestRatio) {
							let neededRatio = acceptRes[0] / acceptRes[1]
							let currRatio = img.width / img.height
							let ratioMatchPercentage = currRatio / neededRatio
							let isRatioTestPass = ratioMatchPercentage <= 1.05 && ratioMatchPercentage >= 0.95
							resolve(isRatioTestPass && img.width >= acceptRes[0] && img.height >= acceptRes[1])
						} else {
							resolve(img.width === acceptRes[0] && img.height === acceptRes[1])
						}
					}
				})
			} catch (err) {
				reject(err)
			}
		}

		//If file is already uploaded
		if (file && typeof file === 'string') resolve()
		else if (!file) {
			resolve('Please choose an Image!')
		} else if (!_.isEmpty(acceptsExts) && acceptsExts.indexOf(getFileExtension(file).toLowerCase()) === -1) {
			resolve(`Image file should be ${_.join(acceptsExts, ' (or) ')} format!`)
		} else if (!_.isEmpty(acceptRes)) {
			checkResolution(file, acceptRes).then((isValidRes) => {
				if (!isValidRes) {
					resolve(`Image file should be minimum resolution of ${_.join(acceptRes, 'X')} px or higher with Aspect ratio of 4:1 Resolution`)
				} else {
					resolve()
				}
			})
		}
	})
}

const Validator = (props) => {
	const validateForm = (values) => {
		props.checkForChange(values)
		return Promise.all([
			validateLogoFile(values.logoImg, ['.png', '.jpg', 'jpeg'], [320, 80], true),
			validateLogoFile(values.logoFavicon, ['.png', '.jpg', 'jpeg', '.ico'], [48, 48], false),
		])
			.then(([logoErrMsg, favIconErrMsg]) => {
				let errors = {}
				if (!values.labelName) {
					errors.labelName = 'Please enter the Label Adjective'
				}
				if (!values.labelTitle) {
					errors.labelTitle = 'Please enter the Company Name'
				}
				if (logoErrMsg) {
					errors.logoImg = logoErrMsg
				}
				if (favIconErrMsg) {
					errors.logoFavicon = favIconErrMsg
				}

				if (values.workflowURL && !isUrlValid(values.workflowURL)) {
					errors.workflowURL = 'Please enter the valid URL'
				}

				if (values.dataflowURL && !isUrlValid(values.dataflowURL)) {
					errors.dataflowURL = 'Please enter the valid URL'
				}

				return errors
			})
			.catch((err) => {
				return err
			})
	}

	const initialValues = props.initVals

	const initialTouched =
		initialValues &&
		_.keys(initialValues).reduce((touchObj, key, idx) => {
			touchObj = typeof touchObj === 'object' ? touchObj : { [touchObj]: true }
			return { ...touchObj, [key]: true }
		})

	return (
		<Formik
			initialValues={
				initialValues || {
					labelTitle: 'CounterfeitX',
					labelName: 'counterfeit',
					logoImg: '',
					logoFavicon: '',
				}
			}
			enableReinitialize
			initialTouched={initialTouched}
			validate={validateForm}
			onSubmit={props.onSubmit || null}
		>
			{props.children}
		</Formik>
	)
}

export default Validator
