import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Icon, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Users from './Users'
import Roles from './Roles'
import _ from 'lodash'
import TeamUnits from './TeamUnits'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

const MODULES = {
	USERS: 'users',
	ROLES: 'roles',
	TEAM_UNITS: 'teamunits',
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		margin: '16px auto',
		minHeight: 'unset',
		boxSizing: 'border-box',
		height: '38px',
	},
	tabsContainer: {
		'& > button:first-child': {
			borderLeft: `1px solid ${theme.palette.primary.main}86!important`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderRight: '0px',
		},
		'& > button:last-child': {
			borderRight: `1px solid ${theme.palette.primary.main}86`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderLeft: '0px',
		},
		'& > button:not(:last-child):not(:first-child)': {
			border: `1px solid ${theme.palette.primary.main}86`,
		},
	},
	tabsIndicator: {
		display: 'none',
	},
	tabRoot: {
		letterSpacing: '2px',
		textTransform: 'none',
		minHeight: 'unset',
		minWidth: '100px',
		width: 'auto',
		'&:hover:not($tabSelected)': {
			// color:theme.palette.background.paper,
			background: `${theme.palette.primary.light}52`,
		},
	},
	tabIco: {
		verticalAlign: 'top',
	},
	tabPanel: {
		display: 'block',
		width: '100%',
	},
	tabSelected: {
		background: theme.palette.primary.dark,
		color: theme.palette.common.white,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
}))

const getModuleIdx = (moduleName) => {
	switch (moduleName) {
		case MODULES.USERS:
			return 0
		case MODULES.ROLES:
			return 1
		case MODULES.TEAM_UNITS:
			return 2
		default:
			return -1
	}
}

const getModuleNameByIdx = (moduleIdx) => {
	switch (moduleIdx) {
		case 0:
			return MODULES.USERS
		case 1:
			return MODULES.ROLES
		case 2:
			return MODULES.TEAM_UNITS
		default:
			return undefined
	}
}

const isModuleHasViewPerm = (modulePerms) => _.get(modulePerms, PERMISSION_TYPE.VIEW, false)

const UserManagement = ({ history, match }) => {
	const userPerms = usePermission(ROLE_MODULES.USER_MANAGEMENT)
	const rolePerms = usePermission(ROLE_MODULES.ROLE_MANAGEMENT)
	const teamPerms = usePermission(ROLE_MODULES.TEAM_MANAGEMENT)

	const isUsrEnabled = useMemo(() => isModuleHasViewPerm(userPerms), [userPerms])
	const isRoleEnabled = useMemo(() => isModuleHasViewPerm(rolePerms), [rolePerms])
	const isTeamEnabled = useMemo(() => isModuleHasViewPerm(teamPerms), [teamPerms])

	const defModule = useMemo(() => {
		return (isUsrEnabled && MODULES.USERS) || (isRoleEnabled && MODULES.ROLES) || (isTeamEnabled && MODULES.TEAM_UNITS) || null
	}, [isRoleEnabled, isTeamEnabled, isUsrEnabled])

	const module = _.get(match, 'params.module')
	const action = _.get(match, 'params.action', '')
	const dataId = _.get(match, 'params.id', '')

	const activeTabIdx = getModuleIdx(module)

	const classes = useStyles()
	const tabClasses = {
		root: classes.tabRoot,
		selected: classes.tabSelected,
	}

	const onChangeTab = (e, tabIdx) => {
		history.push(`/usermanagement/${getModuleNameByIdx(tabIdx)}`)
	}

	useEffect(() => {
		if (_.isEmpty(module) && !_.isEmpty(defModule)) history.replace(`/usermanagement/${defModule}`)
	}, [module, history, defModule])

	//IF MODULE IS EMPTY
	if (!module) return null

	return (
		<div className={classnames('container-no-fluid', 'page-content')}>
			<Grid container>
				<Grid item container xs={12}>
					<Tabs
						orientation='horizontal'
						value={activeTabIdx}
						classes={{
							indicator: classes.tabsIndicator,
							flexContainer: classes.tabsContainer,
						}}
						className={classes.tabs}
						onChange={onChangeTab}
						aria-label='User Management Tabs'
					>
						{isUsrEnabled && (
							<Tab
								classes={tabClasses}
								value={0}
								label={
									<span>
										<Icon fontSize='small' className={classes.tabIco}>
											person
										</Icon>{' '}
										Users
									</span>
								}
								disableRipple
							></Tab>
						)}
						{isRoleEnabled && (
							<Tab
								classes={tabClasses}
								value={1}
								label={
									<span>
										<Icon fontSize='small' className={classes.tabIco}>
											security
										</Icon>{' '}
										Roles
									</span>
								}
								disableRipple
							></Tab>
						)}
						{isTeamEnabled && (
							<Tab
								classes={tabClasses}
								value={2}
								label={
									<span>
										<Icon fontSize='small' className={classes.tabIco}>
											account_tree
										</Icon>{' '}
										Team Units
									</span>
								}
								disableRipple
							></Tab>
						)}
					</Tabs>
					<TabPanel value={activeTabIdx} index={0} className={classes.tabPanel}>
						<Users action={action} userId={dataId} history={history} perms={userPerms}></Users>
					</TabPanel>
					<TabPanel value={activeTabIdx} index={1} className={classes.tabPanel}>
						<Roles action={action} roleId={dataId} history={history} perms={rolePerms}></Roles>
					</TabPanel>
					<TabPanel value={activeTabIdx} index={2} className={classes.tabPanel}>
						<TeamUnits action={action} teamId={dataId} history={history} perms={teamPerms} />
					</TabPanel>
				</Grid>
			</Grid>
		</div>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && (
				<Box marginLeft={1} marginRight={1}>
					{children}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default UserManagement
