import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import {
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	TableRow,
	TableCell,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core'
import _ from 'lodash'
import { PERMISSION_TYPE_LABEL } from '../../../constants/modules'
import classnames from 'classnames'
import update from 'immutability-helper'

var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: '0 1px 2px 0 rgba(60,64,67,0), 0 2px 6px 2px rgba(60,64,67,0.15)',
	},
	listRoot: {
		width: '100%',
	},
	cbCell: {
		padding: 0,
	},
	cbCellGreyBg: {
		backgroundColor: theme.palette.grey[100],
	},
	moduleNameCell: {
		padding: 0,
		paddingLeft: theme.spacing(2),
		verticalAlign: 'middle',
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
		},
	},
	permissionCb: {
		margin: '0 auto',
	},
	permissionIco: {
		padding: theme.spacing(0.5),
		color: theme.palette.grey[800],
	},
	permissionInfo: {
		color: theme.palette.grey[500],
	},
	colorGreen: {
		color: theme.palette.success.main,
	},
	colorInfo: {
		color: theme.palette.info.main,
	},
	tableRow: {
		'&:hover': {
			backgroundColor: theme.palette.grey[50],
		},
	},
}))

const ModulePermissionItem = React.memo(({ isMobileView, moduleName, moduleDisplayName, onChange, viewMode, modulePermissions, value }) => {
	const classes = useStyles()

	const { mobPermInfo, moduleCbProps } = useMemo(() => {
		const permissionLen = modulePermissions.length
		const enabledPermsLen = _.get(
			_.filter(modulePermissions, (permission) => _.get(value, permission, false)),
			'length',
			0
		)
		const isIndeterminate = enabledPermsLen > 0 && permissionLen !== enabledPermsLen
		return {
			mobPermInfo:
				enabledPermsLen === 0
					? 'No Permissions'
					: permissionLen === enabledPermsLen
					? 'All Permissions'
					: pluralize('Permission', enabledPermsLen, true),
			moduleCbProps: {
				indeterminate: isIndeterminate,
				checked: isIndeterminate || permissionLen === enabledPermsLen,
			},
		}
	}, [value, modulePermissions])

	const onChangePermCb = useCallback(
		(permission, cbVal) => {
			const actionToPerform = { [permission]: { $set: cbVal } }
			onChange(moduleName, update(value, actionToPerform))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value, moduleName, onChange, modulePermissions]
	)

	const onChangeModuleCb = useCallback(
		(e) => {
			const isChecked = e.target.checked
			const actionToPerform = {
				$set: _.reduce(
					modulePermissions,
					(result, permission) => {
						result[permission] = isChecked
						return result
					},
					{}
				),
			}
			onChange(moduleName, update(value, actionToPerform))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[moduleName, onChange, modulePermissions]
	)

	if (!isMobileView) {
		return (
			<TableRow className={classes.tableRow} key={moduleName}>
				<TableCell component='th' className={classes.moduleNameCell} scope='row'>
					{!viewMode && (
						<span>
							<Checkbox
								edge='start'
								disableRipple
								size='small'
								onChange={onChangeModuleCb}
								inputProps={{
									'aria-labelledby': `${moduleName} toggle all permissions`,
								}}
								{...moduleCbProps}
							/>
						</span>
					)}
					<span>{moduleDisplayName}</span>
				</TableCell>
				{_.map(_.keys(PERMISSION_TYPE_LABEL), (permission, index) => (
					<TableCell
						key={index}
						className={classnames(classes.cbCell, {
							[classes.cbCellGreyBg]: index % 2 === 0,
						})}
						align='center'
					>
						{_.indexOf(modulePermissions, permission) > -1 ? (
							!viewMode ? (
								<Checkbox
									edge='start'
									className={classes.permissionCb}
									checked={_.get(value, permission, false)}
									disableRipple
									onChange={(e) => {
										onChangePermCb(permission, e.target.checked)
									}}
									size='small'
									inputProps={{
										'aria-labelledby': PERMISSION_TYPE_LABEL[permission],
									}}
								/>
							) : (
								<Icon fontSize='small' className={classes.permissionIco}>
									{_.get(value, permission, false) && 'check'}
								</Icon>
							)
						) : null}
					</TableCell>
				))}
			</TableRow>
		)
	} else {
		return (
			<Accordion className={classes.root}>
				<AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					{!viewMode && (
						<Checkbox
							edge='start'
							disableRipple
							size='small'
							onClick={(event) => event.stopPropagation()}
							onFocus={(event) => event.stopPropagation()}
							onChange={onChangeModuleCb}
							inputProps={{
								'aria-labelledby': `${moduleName} toggle all permissions`,
							}}
							{...moduleCbProps}
						/>
					)}
					<div>
						<Typography variant='body2' component='div'>
							{moduleDisplayName}
						</Typography>
						<Typography
							variant='caption'
							className={classnames(
								classes.permissionInfo,
								{ [classes.colorGreen]: mobPermInfo === 'All Permissions' },
								{
									[classes.colorInfo]: ['No Permissions', 'All Permissions'].indexOf(mobPermInfo) === -1,
								}
							)}
							component='div'
						>
							{mobPermInfo}
						</Typography>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<List dense className={classes.listRoot}>
						{_.map(modulePermissions, (permission) => (
							<ListItem
								key={permission}
								role={undefined}
								dense={true}
								disabled={viewMode}
								button
								onClick={(e) => {
									onChangePermCb(permission, !_.get(value, permission, false))
								}}
							>
								<ListItemIcon>
									<Checkbox
										edge='start'
										checked={_.get(value, permission, false)}
										tabIndex={-1}
										disableRipple
										size='small'
										disabled={viewMode}
										inputProps={{
											'aria-labelledby': PERMISSION_TYPE_LABEL[permission],
										}}
									/>
								</ListItemIcon>
								<ListItemText primary={PERMISSION_TYPE_LABEL[permission]} />
								{/* <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <Icon fontSize="small">edit</Icon>
                                    </IconButton>
                                </ListItemSecondaryAction> */}
							</ListItem>
						))}
					</List>
				</AccordionDetails>
			</Accordion>
		)
	}
})

ModulePermissionItem.propTypes = {
	isMobileView: PropTypes.bool.isRequired,
	moduleName: PropTypes.string.isRequired,
	moduleDisplayName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	modulePermissions: PropTypes.array,
	value: PropTypes.object,
	viewMode: PropTypes.bool,
}

export default ModulePermissionItem
