import React, { useRef } from 'react'
import _ from 'lodash'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, makeStyles, Icon, CircularProgress } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import SelectBox from 'components/SelectBox'

const useStyles = makeStyles((theme) => ({
	dialogTitleContainer: {
		fontSize: theme.typography.pxToRem(18),
		fontWeight: 500,
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: `4px`,
		boxShadow: `0px 0px 1px 0px rgb(100 100 100 / 70%)`,
	},
	dialogContentContainer: {
		padding: theme.spacing(2, 3),
	},
	dialogActionContainer: {
		boxShadow: `0px 0px 1px 0px rgb(100 100 100 / 70%)`,
	},
	circularProgress: {
		'&.MuiCircularProgress-colorPrimary': {
			color: 'white',
		},
	},
}))

const validationSchema = Yup.object().shape({
	name: Yup.string().trim().required('Filter name is required'),
	ds_id: Yup.string().trim().required('Datasource ID is required'),
	column: Yup.string().trim().required('Column is required'),
	condition: Yup.string().trim().required('Condition is required'),
	component: Yup.string().trim().required('Component is required'),
})

const conditionPerDataType = {
	text: ['must', 'must_not', 'should'],
	keyword: ['must', 'must_not', 'should'],
	integer: ['must', 'must_not', 'should', 'number_range'],
	float: ['must', 'must_not', 'should', 'number_range'],
	long: ['must', 'must_not', 'should', 'number_range'],
	double: ['must', 'must_not', 'should', 'number_range'],
	short: ['must', 'must_not', 'should', 'number_range'],
	date: ['range'],
}

const componentPerCondition = {
	must: [
		{ id: 'dropdown', name: 'Dropdown' },
		{ id: 'autocomplete', name: 'Autocomplete' },
	],
	must_not: [
		{ id: 'dropdown', name: 'Dropdown' },
		{ id: 'autocomplete', name: 'Autocomplete' },
	],
	should: [
		{ id: 'dropdown', name: 'Dropdown' },
		{ id: 'autocomplete', name: 'Autocomplete' },
	],
	range: [{ id: 'daterange', name: 'DateRange' }],
	number_range: [{ id: 'numberrange', name: 'Range Input' }],
}

const EditFilterDialog = (props) => {
	const { isOpen = false, handleClose = () => null, filterData = {}, dsData = {}, handleFilterSave = () => null, isLoading = false } = props
	const isCreateMode = _.isEmpty(filterData)
	const classes = useStyles()
	const formRef = useRef()

	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth>
			<Formik
				innerRef={formRef}
				onSubmit={(e, f) => {
					handleFilterSave(e)
				}}
				validationSchema={validationSchema}
				initialValues={{
					id: filterData?.id || '',
					name: filterData?.name || '',
					ds_id: dsData?.ds_id || '',
					column: filterData?.column || '',
					condition: filterData?.condition || '',
					component: filterData?.component || '',
				}}
			>
				{(formik) => {
					return (
						<>
							<DialogTitle className={classes.dialogTitleContainer} disableTypography>
								<Icon>playlist_add</Icon>
								{isCreateMode ? 'Add Filter' : 'Edit Filter'}
							</DialogTitle>
							<DialogContent className={classes.dialogContentContainer}>
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12}>
										<TextField
											label='Name*'
											name='name'
											value={formik?.values?.name}
											onChange={formik?.handleChange}
											helperText={formik.touched?.name && formik.errors?.name}
											error={formik.touched?.name && Boolean(formik.errors?.name)}
											fullWidth
											margin='dense'
											size='small'
											variant='outlined'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField label='Datasource*' margin='dense' size='small' variant='outlined' fullWidth value={dsData?.ds_name || ''} disabled />
									</Grid>
									<Grid item xs={12} sm={6}>
										<SelectBox
											errTxt={formik.touched?.column && formik.errors?.column}
											isError={formik.touched?.column && Boolean(formik.errors?.column)}
											name='column'
											label='Field*'
											margin='dense'
											options={dsData?.ds_details || []}
											value={formik?.values?.column}
											onChange={(e) => {
												formik.handleChange({
													target: { name: 'column', value: e },
												})
												formik.setTouched({
													...formik.touched,
													condition: false,
													component: false,
												})
												formik.setValues({
													...formik.values,
													column: e,
													condition: '',
													component: '',
												})
											}}
											labelKey='column_name'
											valueKey='column_name'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<SelectBox
											errTxt={formik.touched?.condition && formik.errors?.condition}
											isError={formik.touched?.condition && Boolean(formik.errors?.condition)}
											name='condition'
											label='Condition*'
											margin='dense'
											options={_.get(
												conditionPerDataType,
												_.find(dsData?.ds_details, (o) => o?.column_name === formik?.values?.column)?.data_type || 'text',
												[]
											).map((o) => ({ id: o, name: o }))}
											value={formik?.values?.condition}
											onChange={(e) =>
												formik.handleChange({
													target: { name: 'condition', value: e },
												})
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<SelectBox
											errTxt={formik.touched?.component && formik.errors?.component}
											isError={formik.touched?.component && Boolean(formik.errors?.component)}
											name='component'
											label='Component*'
											margin='dense'
											options={_.get(componentPerCondition, formik?.values?.condition || 'must', [])}
											value={formik?.values?.component}
											onChange={(e) =>
												formik.handleChange({
													target: { name: 'component', value: e },
												})
											}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className={classes.dialogActionContainer}>
								<Button size='small' color='primary' variant='outlined' disableElevation onClick={handleClose}>
									Cancel
								</Button>
								<Button
									size='small'
									color='primary'
									variant='contained'
									disableElevation
									onClick={!isLoading && formik.handleSubmit}
									{...(isLoading
										? {
												startIcon: <CircularProgress size={16} className={classes.circularProgress} />,
										  }
										: {})}
								>
									{isLoading ? 'Saving' : 'Save'}
								</Button>
							</DialogActions>
						</>
					)
				}}
			</Formik>
		</Dialog>
	)
}

export default EditFilterDialog
