import React, { useEffect, useReducer, useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Tabs, Tab, Badge, Icon, IconButton, useMediaQuery, CircularProgress, LinearProgress, Avatar } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import SharedList from './SharedList'
import ShareForm from './ShareForm'
import { reducer, initialState, permissions, formModules } from './reducer'
import _ from 'lodash'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { useDispatch } from 'react-redux'
import { fetchSharedList, saveSharedListChanges, shareResource } from 'services/share/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import { showConfirmMessage, hideLoader } from 'services/loader/actions'
import classnames from 'classnames'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	disableOnUpdate: {
		opacity: (props) => (props.isUpdating ? 0.8 : 1),
		pointerEvents: (props) => (props.isUpdating ? 'none' : 'unset'),
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		position: 'relative',
	},
	closeBtn: {
		position: 'absolute',
		top: theme.spacing(0.5),
		right: theme.spacing(0.5),
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 500,
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	badge: {
		marginLeft: theme.spacing(1.5),
	},
	dialogIco: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(1),
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		width: theme.spacing(4.5),
		height: theme.spacing(4.5),
		[theme.breakpoints.down('xs')]: {
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
		},
	},
	loadingContent: {
		textAlign: 'center',
		padding: theme.spacing(2),
	},
}))

function checkIsUserModule(formModule) {
	return _.isEqual(formModule, ROLE_MODULES.USER_MANAGEMENT)
}

const ShareDialog = ({ open, handleClose, resourceId, resourceParams, shareListApiUrl, shareActionApiUrl }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [state, dispatch] = useReducer(reducer, initialState)
	const reduxDispatch = useDispatch()

	const [isUpdating, setUpdating] = useState(false)

	const classes = useStyles({ isUpdating })

	const {
		fetchingSharedList,
		tabIdx,
		sharedUsersList,
		permission,
		message,
		sharedTeamsList,
		deletedUsers,
		deletedTeams,
		selectedTeams,
		selectedUsers,
		owner,
		updateSharedUsers,
		updateSharedTeams,
	} = state

	const isUpdateMode = useMemo(() => {
		return !(_.isEmpty(updateSharedUsers) && _.isEmpty(updateSharedTeams) && _.isEmpty(deletedUsers) && _.isEmpty(deletedTeams))
	}, [updateSharedUsers, updateSharedTeams, deletedTeams, deletedUsers])

	const activeFormModule = formModules[tabIdx]
	const isUserFormModule = checkIsUserModule(activeFormModule)

	const discardChngConfirmDialog = useCallback(() => {
		reduxDispatch(
			showConfirmMessage(
				'Discard unsaved changes?',
				'',
				'Discard',
				() => {
					reduxDispatch(hideLoader())
					handleClose()
				},
				'Cancel',
				() => {
					reduxDispatch(hideLoader())
				},
				'announcement'
			)
		)
	}, [reduxDispatch, handleClose])

	const closeDialog = useCallback(
		(skipDiscard = false) => {
			if (isUpdateMode && !skipDiscard) discardChngConfirmDialog()
			else handleClose()
		},
		[discardChngConfirmDialog, handleClose, isUpdateMode]
	)

	const onChangeTab = (e, tabIdxToChange) => {
		if (!_.isEqual(tabIdxToChange, tabIdx)) dispatch({ type: 'changeTab', payload: { tabIdx: tabIdxToChange } })
	}

	const onChangePermSelBox = (e) => {
		dispatch({ type: 'changePermSelBox', payload: { value: e.target.value } })
	}

	const onChangeMsgInput = (e) => {
		dispatch({ type: 'changeMsgInput', payload: { value: e.target.value } })
	}

	const onChangeUpdtPermSelBox = (e, data) => {
		const actionVal = e.target.value
		if (!_.isEqual(actionVal, PERMISSION_TYPE.DELETE)) {
			const actionType = activeFormModule === ROLE_MODULES.USER_MANAGEMENT ? 'updateSharedUsersList' : 'updateSharedTeamsList'
			dispatch({
				type: actionType,
				payload: { id: _.get(data, 'id'), resource_id: _.get(data, 'resource_id'), permission: e.target.value },
			})
		} else {
			const actionType = activeFormModule === ROLE_MODULES.USER_MANAGEMENT ? 'deleteSharedUser' : 'deleteSharedTeam'
			dispatch({ type: actionType, payload: { id: _.get(data, 'id'), resource_id: _.get(data, 'resource_id') } })
		}
	}

	const onClickActionBtn = () => {
		const bodyParams = {
			...resourceParams,
			user_id: _.map(selectedUsers, (user) => _.get(user, 'user_id')),
			tu_code: _.map(selectedTeams, (team) => _.get(team, 'tu_code')),
			permission,
			message,
		}

		setUpdating(true)
		reduxDispatch(
			shareResource(
				shareActionApiUrl,
				bodyParams,
				() => {
					setUpdating(false)
					closeDialog()
					reduxDispatch(showSnackbarWithTimeout('Shared Successfully', 1500))
				},
				(err) => {
					setUpdating(false)
					reduxDispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		)
	}

	const resetForm = useCallback(
		(retainVals) => {
			dispatch({ type: 'resetForm', payload: retainVals })
		},
		[dispatch]
	)

	//reset exsting details on form dialog close event
	useEffect(() => {
		if (!open) resetForm()
	}, [open, resetForm])

	const getFinalSharedList = useCallback((isUserFormModule, sharedList, updateList, deletedList) => {
		const idKey = isUserFormModule ? 'user_id' : 'tu_code'
		// const updateGroupList = _.groupBy(updateList, idKey)
		const sharedListDelRmvd = []

		for (const item of sharedList) {
			if (!deletedList.some((delItem) => delItem[idKey] === item[idKey] && delItem.resource_id === item.resource_id)) {
				sharedListDelRmvd.push(item)
			}
		}
		// const sharedListDelRmvd = sharedList.filter(
		// 	(item) => !deletedList.some((delItem) => delItem.user_id === item.user_id && delItem.resource_id === item.resource_id)
		// )

		for (const item of sharedListDelRmvd) {
			if (updateList.some((user) => user[idKey] === item[idKey] && user?.resource_id === item?.resource_id)) {
				const updatedPermission = _.findLast(updateList, function (user) {
					return user[idKey] === item[idKey] && user?.resource_id === item?.resource_id && user?.permission
				})

				item.permission = updatedPermission?.permission
			}
		}

		return sharedListDelRmvd

		// return _.map(sharedListDelRmvd, (data) => ({
		// 	...data,
		// 	permission: _.get(updateGroupList, `[${data[idKey]}][0].permission`, data.permission),
		// }))
	}, [])

	const finalSharedUsrList = useMemo(() => {
		return getFinalSharedList(isUserFormModule, sharedUsersList, updateSharedUsers, deletedUsers)
	}, [getFinalSharedList, isUserFormModule, sharedUsersList, updateSharedUsers, deletedUsers])

	const finalSharedTeamList = useMemo(() => {
		return getFinalSharedList(isUserFormModule, sharedTeamsList, updateSharedTeams, deletedTeams)
	}, [getFinalSharedList, isUserFormModule, sharedTeamsList, updateSharedTeams, deletedTeams])

	const sharedUsersCount = finalSharedUsrList?.length
	const sharedTeamsCount = finalSharedTeamList?.length

	const isFormMode = useMemo(() => !_.isEmpty(selectedUsers) || !_.isEmpty(selectedTeams), [selectedTeams, selectedUsers])

	const updateSharedListChanges = useCallback(
		(successCallback) => {
			setUpdating(true)
			reduxDispatch(
				saveSharedListChanges(
					shareListApiUrl,
					{
						resourceId,
						usersToUpdate: updateSharedUsers,
						teamsToUpdate: updateSharedTeams,
						deletedUsers,
						deletedTeams,
					},
					//success
					() => {
						setUpdating(false)
						reduxDispatch(showSnackbarWithTimeout('Permissions Updated', 1500))
						successCallback && successCallback()
					},
					//error
					(err) => {
						setUpdating(false)
						reduxDispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
					}
				)
			)
		},
		[reduxDispatch, resourceId, shareListApiUrl, resetForm, finalSharedUsrList, finalSharedTeamList, deletedUsers, deletedTeams]
	)

	const onChangeAutoComplete = useCallback(
		(e, value) => {
			const callAutocompleteAction = (refreshList = false) => {
				dispatch({
					type: 'changeOnAutoComplete',
					payload: {
						formModule: activeFormModule,
						refreshList,
						data: _.uniqBy(value, (item) => _.get(item, isUserFormModule ? 'user_id' : 'tu_code')),
					},
				})
			}

			if (isUpdateMode) {
				//TODO: call update api (on success : call "callAutocompleteAction" function)
				updateSharedListChanges(() => {
					callAutocompleteAction(true)
				})
			} else callAutocompleteAction()
		},
		[updateSharedListChanges, dispatch, isUpdateMode, activeFormModule, isUserFormModule]
	)

	const onClickCancelBtn = useCallback(() => {
		if (isFormMode)
			resetForm({
				fetchingSharedList: false,
				tabIdx,
				sharedUsersList,
				sharedTeamsList,
				owner,
			})
		else if (isUpdateMode) {
			updateSharedListChanges(() => {
				closeDialog(true)
			})
		} else closeDialog()
	}, [updateSharedListChanges, tabIdx, sharedUsersList, sharedTeamsList, owner, closeDialog, isUpdateMode, isFormMode, resetForm])

	useEffect(() => {
		if (fetchingSharedList && resourceId) {
			reduxDispatch(
				fetchSharedList(
					shareListApiUrl,
					resourceId,
					(resp) => {
						dispatch({
							type: 'setSharedList',
							payload: _.get(resp, 'data.data'),
						})
					},
					(err) => {
						console.log('error callback', err)
					}
				)
			)
		}
	}, [dispatch, reduxDispatch, shareListApiUrl, fetchingSharedList, resourceId])

	const shareFormPerms = useMemo(() => _.filter(permissions, (perm) => !_.isEqual(_.get(perm, 'name'), PERMISSION_TYPE.DELETE)), [])

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			disableBackdropClick={isUpdating}
			maxWidth={'sm'}
			open={open}
			onClose={closeDialog}
			aria-labelledby='role-form-title'
		>
			{isUpdating && <LinearProgress variant='indeterminate' />}

			{!fetchingSharedList ? (
				<>
					<DialogTitle className={classnames(classes.dialogTitle, classes.disableOnUpdate)} disableTypography={true} id='role-dialog-title'>
						<Typography variant='h6' className={classes.titleWrapper} component={'div'}>
							<Avatar component='span' size='small' className={classes.dialogIco}>
								<Icon fontSize='small'>person_add_alt_1</Icon>
							</Avatar>
							<span> Share with users and team units </span>
						</Typography>

						<IconButton className={classes.closeBtn} onClick={closeDialog} aria-label='back button'>
							<Icon fontSize='small'>close</Icon>
						</IconButton>

						<Tabs
							orientation='horizontal'
							value={tabIdx}
							indicatorColor='primary'
							onChange={onChangeTab}
							variant='fullWidth'
							aria-label='sharing tabs users, teamunits'
						>
							<Tab
								disableRipple
								className={classes.tab}
								label={
									<Typography className={classes.customTabTitle}>
										Users {Boolean(sharedUsersCount) && <Badge color='error' className={classes.badge} badgeContent={sharedUsersCount} />}
									</Typography>
								}
							/>
							<Tab
								disableRipple
								className={classes.tab}
								label={
									<Typography className={classes.customTabTitle}>
										Team Units {Boolean(sharedTeamsCount) && <Badge color='error' className={classes.badge} badgeContent={sharedTeamsCount} />}
									</Typography>
								}
							/>
						</Tabs>
					</DialogTitle>
					<DialogContent className={classnames(classes.dialogContent, classes.disableOnUpdate)}>
						<ShareForm
							isFormMode={isFormMode}
							isMobileView={fullScreen}
							onChangeAutoComplete={onChangeAutoComplete}
							onChangePermSelBox={onChangePermSelBox}
							onChangeMsgInput={onChangeMsgInput}
							selectedUsers={selectedUsers}
							selectedTeams={selectedTeams}
							message={message}
							permissions={shareFormPerms}
							permission={permission}
							formModule={activeFormModule}
						/>
						{!isFormMode && (
							<SharedList
								permissions={permissions}
								isMobileView={fullScreen}
								sharedUsers={finalSharedUsrList}
								sharedTeams={finalSharedTeamList}
								activeFormModule={activeFormModule}
								onChangeUpdtPermSelBox={onChangeUpdtPermSelBox}
								owner={owner}
							/>
						)}
					</DialogContent>
					<DialogActions className={classnames(classes.dialogFooter, classes.disableOnUpdate)}>
						{isUpdateMode && (
							<Typography variant='body2' color='textSecondary'>
								Pending changes
							</Typography>
						)}
						<Button
							size={fullScreen ? 'small' : 'medium'}
							variant={isFormMode ? 'outlined' : 'contained'}
							onClick={onClickCancelBtn}
							color='primary'
							disableElevation
						>
							{isFormMode ? 'Cancel' : isUpdateMode ? 'Save' : 'Done'}
						</Button>
						{isFormMode && (
							<Button size={fullScreen ? 'small' : 'medium'} variant='contained' onClick={onClickActionBtn} color='primary' disableElevation>
								Share
							</Button>
						)}
					</DialogActions>
				</>
			) : (
				<DialogContent className={classes.dialogContent}>
					<div className={classes.loadingContent}>
						<CircularProgress />
					</div>
				</DialogContent>
			)}
		</Dialog>
	)
}

ShareDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	resourceId: PropTypes.string,
	resourceParams: PropTypes.object,
	shareListApiUrl: PropTypes.string,
	shareActionApiUrl: PropTypes.string,
}

export default ShareDialog
