export const initialState = {
	isFetchingResults: false,
	results: [],
	totalSearches: 0,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchingResultsPending':
			return { ...state, isFetchingResults: true }
		case 'fetchingResultsSuccess':
			return {
				...state,
				isFetchingResults: false,
				results: action.data,
				totalSearches: action.totleResults,
			}
		case 'fetchingResultsError':
			return {
				...state,
				isFetchingResults: false,
				results: [],
				totalSearches: 0,
			}
		default:
			return state
	}
}
