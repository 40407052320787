import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, InputAdornment, TextField, makeStyles, Typography, Grid } from '@material-ui/core'
import { useState } from 'react'
import _ from 'lodash'
import { IconButton } from '@material-ui/core'

//Estimated input configs
const ESTIMATED_TIME_REGEX = /^(\d+w\s?)?(\d+d\s?)?(\d+h\s?)?(\d+m)?$/i
const DAY_PER_HOURS = 8
const DAYS_PER_WEEK = 5

EstimationInputBox.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	name: PropTypes.string,
	className: PropTypes.string,
}

const getMinsByMetric = (number, metric) => {
	switch (metric) {
		case 'w':
			return number * DAYS_PER_WEEK * DAY_PER_HOURS * 60
		case 'd':
			return number * DAY_PER_HOURS * 60
		case 'h':
			return number * 60
		case 'm':
			return number
		default:
			throw new Error(`Invalid metric found ${metric}!`)
	}
}

const getMetricValByMins = (value, metric) => {
	switch (metric) {
		case 'w':
			return value / (DAYS_PER_WEEK * DAY_PER_HOURS * 60)
		case 'd':
			return (value / (DAY_PER_HOURS * 60)) % DAYS_PER_WEEK
		case 'h':
			return (value / 60) % DAY_PER_HOURS
		case 'm':
			return value % 60
		default:
			throw new Error(`Invalid metric found ${metric}!`)
	}
}

const getValueInMins = (val) => {
	var matches = _.trim(val).match(ESTIMATED_TIME_REGEX)
	var totalMins = 0
	if (matches) {
		for (let i = 1; i < matches.length; i++) {
			if (matches[i]) {
				let charMatches = _.trim(matches[i]).match(/(\d+)(\w$)/i)
				totalMins += getMinsByMetric(_.toNumber(charMatches[1]), _.toLower(charMatches[2]))
			}
		}
	}
	return totalMins > 0 ? totalMins : val
}

const getTextValFromMins = (value) => {
	var metrics = ['w', 'd', 'h', 'm']
	return _.trim(
		!_.isEmpty(_.trim(value))
			? _.reduce(
					metrics,
					(result, metric) => {
						const metricVal = Math.floor(getMetricValByMins(value, metric))
						if (metricVal > 0) result += `${metricVal}${metric} `
						return result
					},
					''
			  )
			: ''
	)
}

const useStyles = makeStyles((theme) => ({
	errorIco: {
		color: theme.palette.error.main,
	},
	noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
	priorityItem: {
		cursor: 'pointer',
	},
}))

function EstimationInputBox({ onChange, readOnly, name, value, error, helperText, fullWidth = true, ...otherProps }) {
	const classes = useStyles()

	const [{ val, isError }, setState] = useState({ val: '', isError: false })
	const [isEstimationOpen, setEstimationOpen] = useState(false)

	const toggleEstimation = () => {
		setEstimationOpen(!isEstimationOpen)
	}

	useEffect(() => {
		setState({ val: value ? getTextValFromMins(_.toNumber(value)) : '' })
	}, [value])

	const onChangeTextBox = (e) => {
		setState({ val: e.target.value, isError: false })
	}

	const onBlur = (e) => {
		toggleEstimation()
		if (!_.isEmpty(_.trim(val)) && !ESTIMATED_TIME_REGEX.test(_.trim(val))) {
			setState((prevState) => ({ ...prevState, isError: true }))
		} else {
			const minsVal = getValueInMins(val)
			setState({ val: getTextValFromMins(_.toNumber(minsVal)), isError: false })
			onChange && onChange({ target: { name, value: _.toString(minsVal) } })
		}
	}

	return (
		<>
			{isEstimationOpen ? (
				<TextField
					name={name}
					value={val}
					onChange={onChangeTextBox}
					error={isError || error}
					fullWidth={fullWidth}
					dense={true}
					autoFocus={true}
					helperText={isError ? 'Your estimation should be in the format of 2w 4d 6h 45m' : helperText}
					InputProps={
						isError
							? {
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton size='small'>
												<Icon className={classes.errorIco} fontSize='small'>
													warning
												</Icon>
											</IconButton>
										</InputAdornment>
									),
									readOnly: readOnly,
							  }
							: { readOnly: readOnly }
					}
					onBlur={onBlur}
					{...otherProps}
				></TextField>
			) : (
				<div role='button' onClick={readOnly ? null : toggleEstimation}>
					{!_.isEmpty(val) ? (
						<Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
							<Grid item xs>
								<Typography variant='body2'>{val}</Typography>
							</Grid>
						</Grid>
					) : (
						<p className={classes.noneSection}>None</p>
					)}
				</div>
			)}
		</>
	)
}

export default EstimationInputBox
