import { ACTION_TYPES } from '../../../constants'

const clientId = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_CLIENT_ID_PENDING: {
			return {
				...state,
				isFetchingClientId: true,
				isFetchingClientIdFailed: false,
				isFetchingClientIdError: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.GET_CLIENT_ID_FULFILLED: {
			return {
				...state,
				isFetchingClientId: false,
				isFetchingClientIdFailed: false,
				isFetchingClientIdError: undefined,
				data: action.payload.data,
			}
		}
		case ACTION_TYPES.GET_CLIENT_ID_REJECTED: {
			return {
				...state,
				isFetchingClientId: false,
				isFetchingClientIdFailed: true,
				isFetchingClientIdError: action.payload,
				data: {},
			}
		}
		default:
			return state
	}
}

export default clientId
