import React, { useState, useEffect } from 'react'
import {
	makeStyles,
	Accordion,
	AccordionSummary,
	Icon,
	Grid,
	Typography,
	AccordionDetails,
	ListItem,
	ListItemIcon,
	List,
	Checkbox,
	ListItemText,
	Button,
	CircularProgress,
	RadioGroup,
	FormControlLabel,
	Radio,
	IconButton,
	Badge,
} from '@material-ui/core'
import SearchBox from '../../PainSearchBox'
// import { useDispatch } from 'react-redux';
import _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	expansionPanelRoot: {
		borderBottom: 0,
		background: 'transparent',
		boxShadow: 'none',
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
		'&:not(:last-child)': {
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
		'& .MuiAccordionSummary-expandIcon': {
			color: theme.palette.almostBlack[600],
		},
	},
	expanded: {},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	root: {
		padding: theme.spacing(0),
		'&.Mui-expanded': {
			minHeight: 'unset',
			// margin: theme.spacing(0.5,0)
		},
		'& .MuiIconButton-colorPrimary:hover': {
			backgroundColor: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			// color: `${theme.palette.primary.dark}`
		},
		'& .MuiTypography-body1': {
			// lineHeight: 0,
			fontWeight: 400,
		},
		'& .MuiFormGroup-row': {
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	muiPanelSummaryRoot: {
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
	},
	iconRoot: {
		minWidth: 'auto',
	},
	listRoot: {},
	listItemRoot: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		alignItems: 'center',
	},
	filterPanelHeading: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	listText: {
		color: theme.palette.almostBlack[800],
		textTransform: 'uppercase',
		'& .MuiTypography-body1': {
			// lineHeight: 0,
			fontWeight: 400,
		},
	},
	searchContainer: {
		padding: theme.spacing(1, 0),
	},
	capitaliseText: {
		textTransform: 'capitalize !important',
	},
	searchIcon: {
		color: theme.palette.almostBlack[400],
	},
	anchorOriginTopRightRectangle: {
		top: '50%',
		right: '-20px',
		'&.MuiBadge-dot': {
			top: '10%',
			right: '0px',
		},
		[theme.breakpoints.down('lg')]: {
			top: 0,
			right: 0,
			fontWeight: '700',
			fontSize: theme.typography.pxToRem(10),
			width: 14,
			height: 14,
			minWidth: 14,
			borderRadius: 14,
		},
	},
}))

const FilterPanel = ({
	title,
	clearFilter,
	handleClearThisFilter,
	capitaliseText,
	isPreLoaded,
	isAllRetailersLoaded,
	noSort,
	isSearchNeeded,
	isRadio,
	isLoading,
	options,
	optionKey,
	textKey,
	handleSearch,
	handleCheckBoxClick,
	selectedOptions,
	loadMoreFxn,
	hasExtraHeight,
}) => {
	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)

	const [radioValue, setRadioValue] = useState(selectedOptions)

	const handleRadioChange = (e) => {
		setRadioValue(e.target.value)
		handleCheckBoxClick(e.target.value)
	}

	useEffect(() => setRadioValue(selectedOptions), [selectedOptions])

	const [searchQuery, setSearchQuery] = useState('')

	const isDataChecked = (optionName) => {
		return !_.isEmpty(selectedOptions) && selectedOptions.indexOf(optionName) > -1
	}

	const handleSearchInput = (keyValue) => {
		setSearchQuery(keyValue)
		handleSearch(keyValue.replace(/[^\w\s]/gi, ''))
	}

	const handleResetClick = (e) => {
		e.stopPropagation()
		handleClearThisFilter()
	}

	const emptyOption = { [optionKey]: '', [textKey]: '-N/A-' }

	const removeEmptyOptions = options.map((option) => (_.isNil(option[optionKey]) ? emptyOption : option))
	const unOrderedList = removeEmptyOptions.map((option) => ({
		...option,
		isChecked: isDataChecked(_.get(option, optionKey, '-')),
	}))
	const orderedList = _.orderBy(unOrderedList, ['isChecked', optionKey], ['desc', 'asc'])
	const optionsList = noSort && noSort === true ? unOrderedList : _.sortedUniqBy(_.orderBy(orderedList), optionKey)
	const filteredList = _.isEmpty(searchQuery)
		? optionsList
		: optionsList.filter((dataObj) => dataObj[textKey].toLowerCase().startsWith(searchQuery.replace(/[^\w\s]/gi, '').toLowerCase()))

	// console.log("filteredList ----------------------------------------00000000000000000000000000000000000", filteredList[0][optionKey])

	return (
		<>
			<Accordion
				expanded={isExpanded}
				classes={{
					root: classes.expansionPanelRoot,
					expanded: classes.expanded,
				}}
				onChange={() => setIsExpanded(!isExpanded)}
				elevation={0}
			>
				<AccordionSummary classes={{ root: classes.root, content: classes.muiPanelSummaryRoot }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					<div className={classes.filterPanelTitleContainer}>
						<div className={classes.filterTitleWithChip}>
							<Badge
								badgeContent={isRadio ? (selectedOptions === options[0][optionKey] ? 0 : selectedOptions) : selectedOptions.length}
								max={9}
								variant={_.isArray(selectedOptions) ? 'standard' : 'dot'}
								color='error'
								classes={{
									anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
								}}
							>
								<Typography className={classes.filterPanelHeading} variant='h6'>
									{title}
								</Typography>
							</Badge>
							{/* <Chip size='small' clickable={false} color='primary' label='1' /> */}
						</div>
						{/* {
                        handleClearThisFilter && <Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>RESET</Typography>
                    } */}
						{
							handleClearThisFilter ? (
								isRadio && selectedOptions !== options[0][optionKey] ? (
									<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
										RESET
									</Typography>
								) : !isRadio && selectedOptions.length !== 0 ? (
									<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
										RESET
									</Typography>
								) : null
							) : null
							// :null
						}
					</div>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.root }}>
					<Grid container>
						{isSearchNeeded && isSearchNeeded ? (
							<Grid item xs={12} className={classes.searchContainer}>
								<SearchBox
									onChange={(e) => handleSearchInput(e.target.value)}
									value={searchQuery}
									adornment={
										isLoading ? (
											<CircularProgress style={{ marginRight: '4px' }} size={20} />
										) : searchQuery === '' ? (
											<Icon className={classes.searchIcon}>search</Icon>
										) : (
											<IconButton onClick={() => setSearchQuery('')} size='small' title='Clear Search'>
												<Icon className={classes.searchIcon}>close</Icon>
											</IconButton>
										)
									}
								/>
							</Grid>
						) : null}
						{isRadio && isRadio === true ? (
							<>
								<RadioGroup value={radioValue} onChange={handleRadioChange} row>
									{filteredList &&
										filteredList.map((option, idx) => {
											return (
												<FormControlLabel
													key={option + idx}
													value={_.get(option, optionKey)}
													control={<Radio color='secondary' />}
													label={_.get(option, textKey)}
													labelPlacement='end'
												/>
											)
										})}
								</RadioGroup>
							</>
						) : (
							<>
								<Grid item xs={12} style={hasExtraHeight ? { maxHeight: '380px', overflowY: 'auto' } : { maxHeight: '200px', overflowY: 'auto' }}>
									<List className={classes.listRoot}>
										{filteredList && _.isEmpty(filteredList) && !isLoading && searchQuery !== '' ? (
											<Grid item container justifyContent='center' alignItems='baseline'>
												<Typography variant='subtitle1' component='span'>
													No Match Found
												</Typography>
												<Button color='primary' onClick={() => setSearchQuery('')}>
													Clear Search
												</Button>
											</Grid>
										) : (
											filteredList &&
											filteredList.map((option, idx) => {
												return (
													<ListItem
														key={_.get(option, optionKey, idx)}
														onClick={() => handleCheckBoxClick(_.get(option, optionKey, ''))}
														disableGutters
														classes={{ root: classes.listItemRoot }}
													>
														<ListItemIcon classes={{ root: classes.iconRoot }}>
															<Checkbox edge='start' color='secondary' checked={_.get(option, 'isChecked', false)} disableRipple size='small' />
														</ListItemIcon>
														<ListItemText
															primary={_.isNil(_.get(option, textKey, '')) ? 'No StyleCode' : _.get(option, textKey, '')}
															className={classnames(classes.listText, {
																[classes.capitaliseText]: capitaliseText,
															})}
														/>
													</ListItem>
												)
											})
										)}
									</List>
									{loadMoreFxn && isPreLoaded === false && searchQuery === '' && !isLoading ? (
										<Grid item container justifyContent='center'>
											<Button color='primary' onClick={loadMoreFxn}>
												Load More
											</Button>
										</Grid>
									) : null}
								</Grid>
							</>
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	)
}

export default FilterPanel
