import React, { useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography, AccordionDetails, Icon } from '@material-ui/core'
import SettingsList from '../../Components/SettingsList'
import PopoverMenus from 'components/PopoverMenus'
import { useState } from 'react'
import SearchFabWrapper from '../../Components/SearchFabWrapper'
import DialogForm from '../../Components/DialogForm'
import LabelForm from './LabelForm'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import { addGlassLabel, removeGlassLabel, updateGlassLabel } from 'services/glass/actions'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg, isModuleHasPerm } from 'utils'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import Accordion from '../../Components/Accordion'
import AccordionSummary from '../../Components/AccordionSummary'
import SocketContext from '../../../socket-context'

const useStyles = makeStyles((theme) => ({
	settingsList: {
		paddingTop: theme.spacing(1.5),
	},
	labelStatus: {
		paddingTop: theme.spacing(1.5),
	},
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
}))

const actionMenus = [
	{
		label: 'Edit',
		icon: 'edit',
		actionName: PERMISSION_TYPE.EDIT,
	},
	{
		label: 'Delete',
		icon: 'delete',
		actionName: PERMISSION_TYPE.DELETE,
	},
]
const LabelStatus = ({ label, className }) => {
	return (
		<Typography variant='body2' color='textSecondary' className={className}>
			{label}
		</Typography>
	)
}
const LabelAccordion = ({ label, actionMenusToShow, onClickLabelAction, listData }) => {
	const classes = useStyles()
	return (
		<Accordion defaultExpanded={true}>
			<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>} aria-controls={label} id={label}>
				<Typography className={classes.accTitle} variant='body2'>
					{label}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accSummary}>
				{_.isEmpty(listData) ? (
					<LabelStatus label='No labels found' className={classes.labelStatus} />
				) : (
					<SettingsList
						hideActionMenu={_.isEmpty(actionMenusToShow)}
						onClickAction={onClickLabelAction}
						data={listData}
						className={classes.settingsList}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

const Labels = ({ glassCode, isOwner }) => {
	const classes = useStyles()
	const lblFormRef = useRef()
	const [pageQuery, setPageQuery] = useUrlSearchParams({ openLblForm: false, labelId: null }, { openLblForm: Boolean, labelId: String }, true)
	const dispatch = useDispatch()

	const { sessionId } = useContext(SocketContext)

	const glassLabels = useSelector((state) => _.get(state, 'glass.settings.labels'))
	const [actionMenuState, setActionMenuState] = useState({ openActions: false, anchorEl: null, labelId: '' })
	const [searchText, setSearchText] = useState('')

	const { openActions, anchorEl, labelId } = actionMenuState

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isCreateAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)
	const actionMenusToShow = _.filter(actionMenus, (menu) => isOwner && isModuleHasPerm(glassPerms, menu?.actionName))

	console.log('actionMenusToShow', actionMenusToShow)

	const onClickLabelAction = (e, data) => {
		setActionMenuState({ openActions: true, anchorEl: e.currentTarget, labelId: data?.id })
	}

	const onLblFormSubmitBtn = () => {
		lblFormRef.current.submitForm()
	}

	const onCloseLabelAction = () => {
		setActionMenuState((prev) => ({ ...prev, openActions: false }))
	}

	const performLabelAction = (actionName, labelId) => {
		switch (actionName) {
			case PERMISSION_TYPE.EDIT:
				setPageQuery({ openLblForm: true, labelId: labelId })
				break
			case PERMISSION_TYPE.DELETE:
				deleteLbl(labelId)
				break
			default:
				break
		}
		onCloseLabelAction()
	}

	const onClickAddLabel = () => {
		setPageQuery({ openLblForm: true, labelId: '' }, true)
	}

	const closeLabelFormDialog = () => {
		setPageQuery({ openLblForm: false, labelId: '' }, true)
	}

	const onChangeSearchBox = (searchTxt) => {
		setSearchText(searchTxt)
	}

	const listData = useMemo(
		() =>
			_.map(
				_.isEmpty(searchText) ? glassLabels : _.filter(glassLabels, (label) => new RegExp(_.escapeRegExp(searchText), 'i').test(label?.label_name)),
				(label) => ({
					id: label?.label_id,
					name: label?.label_name,
					color: label?.label_color,
					position: label?.label_position,
					isEdit: label?.is_edit,
				})
			),
		[searchText, glassLabels]
	)

	const initialValues = useMemo(() => {
		if (pageQuery?.openLblForm) {
			return _.find(glassLabels, { label_id: pageQuery?.labelId })
		} else {
			lblFormRef.current?.resetForm()
		}
	}, [pageQuery, glassLabels])

	const hideAnyInfoDialog = () => {
		dispatch(hideLoader())
	}

	const onSubmitLblForm = (values) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			closeLabelFormDialog()
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		const isAddingLbl = _.isEmpty(values?.label_id)
		dispatch(showLoader(isAddingLbl ? 'Creating Label...' : 'Updating Label...'))
		dispatch(
			isAddingLbl
				? addGlassLabel(sessionId, glassCode, values?.label_name, values?.label_color, onSuccess, onError)
				: updateGlassLabel(sessionId, glassCode, values?.label_id, values?.label_name, values?.label_color, onSuccess, onError)
		)
	}

	const askDeleteConfirm = (onConfirm, onCancel) => {
		dispatch(showConfirmMessage(`Are you sure, you want to delete this Label?`, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const deleteLbl = (labelId) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		askDeleteConfirm(
			() => {
				dispatch(showLoader('Deleting Label...'))
				dispatch(removeGlassLabel(sessionId, glassCode, labelId, onSuccess, onError))
			},
			() => {
				hideAnyInfoDialog()
			}
		)
	}

	return (
		<SearchFabWrapper
			isOwner={isCreateAccessible}
			searchPlaceholder='Search for labels'
			onChangeSearchBox={onChangeSearchBox}
			onClickFab={onClickAddLabel}
			fabIcon='add'
		>
			<>
				{_.isEmpty(searchText) ? (
					<>
						{/* <LabelAccordion label='Default' listData={listData.filter((item) => !item?.isEdit)} /> */}

						<LabelAccordion
							label='Labels'
							onClickLabelAction={onClickLabelAction}
							actionMenusToShow={actionMenusToShow}
							listData={listData.filter((item) => item?.isEdit)}
						/>
					</>
				) : _.isEmpty(listData) ? (
					<LabelStatus label='No labels found' />
				) : (
					<SettingsList
						hideActionMenu={_.isEmpty(actionMenusToShow)}
						onClickAction={onClickLabelAction}
						data={listData}
						className={classes.settingsList}
					/>
				)}
				{!_.isEmpty(actionMenusToShow) && (
					<PopoverMenus
						open={openActions}
						id={labelId}
						menus={actionMenusToShow}
						anchorEl={anchorEl}
						onClickAction={performLabelAction}
						onClose={onCloseLabelAction}
					/>
				)}
				<DialogForm
					dialogTitle={pageQuery?.labelId ? 'Edit Label' : 'Add Label'}
					open={pageQuery?.openLblForm}
					onClose={closeLabelFormDialog}
					onClickCancelBtn={closeLabelFormDialog}
					onClickOkBtn={onLblFormSubmitBtn}
				>
					<LabelForm initialValues={initialValues} ref={lblFormRef} onSubmit={onSubmitLblForm} />
				</DialogForm>
			</>
		</SearchFabWrapper>
	)
}

Labels.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
}

export default Labels
