import { put, call, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import api from '../api'

function* fetchAppConfigs() {
	yield put({ type: ACTION_TYPES.FETCH_APP_CONFIGS_PENDING })
	try {
		const data = yield call(api.fetchAppConfigs)
		yield put({ type: ACTION_TYPES.FETCH_APP_CONFIGS_FULFILLED, payload: data })
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_APP_CONFIGS_REJECTED })
	}
}

export function* watchFetchAppConfigs() {
	yield takeLatest(SAGA_ACTIONS.FETCH_APP_CONFIGS, fetchAppConfigs)
}
