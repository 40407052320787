import React from 'react'
import { Dialog } from '@material-ui/core'

const FullScreenDialog = (props) => {
	return (
		<Dialog fullWidth fullScreen={true} open={props.open} onClose={props.onClose}>
			{props.children}
		</Dialog>
	)
}

export default FullScreenDialog
