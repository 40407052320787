import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import { Collapse, Menu, MenuItem, Typography, Button, Icon, ButtonGroup } from '@material-ui/core'
// import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import _ from 'lodash'
import { PAGE_MODES } from '../../constants'
import { Checkbox } from '@material-ui/core'
import classnames from 'classnames'

const initialState = {
	mouseX: null,
	mouseY: null,
	node: null,
}

function MinusSquare(props) {
	return (
		<SvgIcon fontSize='inherit' style={{ width: 20, height: 20 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path
				fill='currentColor'
				d='M19,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H19A2,2 0 0,1 21,8H21L4,8V18L6.14,10H23.21L20.93,18.5C20.7,19.37 19.92,20 19,20Z'
			/>
		</SvgIcon>
	)
}

function PlusSquare(props) {
	return (
		<SvgIcon fontSize='inherit' style={{ width: 20, height: 20 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path
				fill='currentColor'
				d='M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25'
			/>
		</SvgIcon>
	)
}

function CloseSquare(props) {
	return (
		<SvgIcon className='close' fontSize='inherit' style={{ width: 20, height: 20 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path fill='currentColor' d='M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z' />
		</SvgIcon>
	)
}

function TransitionComponent(props) {
	// const style = useSpring({
	//   from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
	//   to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
	// });

	return (
		// <animated.div style={style}>
		<Collapse {...props} />
		// </animated.div>
	)
}

TransitionComponent.propTypes = {
	/**
	 * Show the component; triggers the enter or exit states
	 */
	in: PropTypes.bool,
}

const StyledTreeItem = withStyles((theme) => ({
	root: {
		'&.Mui-selected > .MuiTreeItem-content > .MuiTreeItem-label, &.Mui-selected > .MuiTreeItem-content > .MuiTreeItem-label:hover, &.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label':
			{
				// border: `1px solid ${theme.palette.primary.main}`,
				backgroundColor: 'transparent',
			},
	},
	iconContainer: {
		color: theme.palette.grey[500],
		// '& .close': {
		//   opacity: 0.3,
		// },
	},
	content: {
		padding: 2,
		alignItems: 'flex-start',
	},
	label: {
		userSelect: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},
		fontSize: 15,
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
	},
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />)

const useStyles = makeStyles({
	root: {
		width: '100%',
		flexGrow: 1,
	},
	radioBtn: {
		padding: 0,
		marginRight: 4,
	},
	iconContainer: {
		width: 40,
		justifyContent: 'flex-start',
	},
	treeItemRoot: {
		'&.Mui-selected > .MuiTreeItem-content > .MuiTreeItem-label': {
			border: 'none',
		},
	},
})

const WrapCheckBox = ({ name, showCheckBox = false, className, onClickChkBox, value, checked, children }) => {
	return (
		<>
			{showCheckBox && <Checkbox name={name} className={className} value={value} checked={checked} onClick={onClickChkBox} />}
			{children}
		</>
	)
}

const TreeItemLabel = withStyles((theme) => ({
	root: {
		display: 'flex',
		background: `${alpha(theme.palette.primary.main, 0.1)}`,
	},
	text: {
		// flex: 1,
		paddingRight: theme.spacing(1),
	},
	icon: {
		color: theme.palette.primary.main,
		fontSize: '0.90rem!important',
		borderRadius: '0',
		padding: 0,
		border: 0,
	},
}))(({ label, classes, showViewOption, showCreateOption, showEditOption, showDelOption, node, onNodeAction }) => {
	const onClickActionBtn = (e, node, action) => {
		e?.stopPropagation()
		onNodeAction(node, action)
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.text} variant='body2' component='div'>
				{label}
			</Typography>
			<ButtonGroup size='small' aria-label='small outlined button group'>
				{showViewOption && (
					<Button
						className={classes.icon}
						onClick={(e) => onClickActionBtn(e, node, PAGE_MODES.VIEW)}
						size='small'
						title='View'
						aria-label='view team-unit'
					>
						<Icon fontSize='small'>visibility</Icon>
					</Button>
				)}
				{showEditOption && (
					<Button
						className={classes.icon}
						onClick={(e) => onClickActionBtn(e, node, PAGE_MODES.EDIT)}
						size='small'
						title='Edit'
						aria-label='edit team-unit'
					>
						<Icon fontSize='small'>edit</Icon>
					</Button>
				)}
				{showCreateOption && (
					<Button
						className={classes.icon}
						onClick={(e) => onClickActionBtn(e, node, PAGE_MODES.CREATE)}
						size='small'
						title='Add sub-unit'
						aria-label='add sub-unit'
					>
						<Icon fontSize='small'>create_new_folder</Icon>
					</Button>
				)}
				{showDelOption && (
					<Button
						className={classes.icon}
						onClick={(e) => onClickActionBtn(e, node, PAGE_MODES.DELETE)}
						size='small'
						title='Delete'
						aria-label='delete team-unit'
					>
						<Icon fontSize='small'>delete</Icon>
					</Button>
				)}
			</ButtonGroup>
		</div>
	)
})

export default function TeamsTreeView({
	data,
	onNodeSelect,
	onNodeAction,
	showViewOption,
	name,
	defaultExpanded = [],
	isCreateAllowed,
	isEditAllowed,
	isDeleteAllowed,
	makeInput,
	value,
	onChange,
	activeNodeId,
}) {
	const classes = useStyles()
	const [state, setState] = React.useState(initialState)
	const expandedTreeList = useRef(defaultExpanded)
	const isShowContextMenu = showViewOption || isCreateAllowed || isEditAllowed || isDeleteAllowed

	const handleClick = (event, node) => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			node: node,
		})
	}
	const handleClose = (e, actionName) => {
		setState((prevState) => {
			onNodeAction(prevState.node, actionName)
			return initialState
		})
	}

	const wrapChkBox = (icon, onClickChkBox, value, checked = false, name) => {
		return (
			<WrapCheckBox name={name} showCheckBox={makeInput} value={value} onClickChkBox={onClickChkBox} checked={checked} className={classes.radioBtn}>
				{icon}
			</WrapCheckBox>
		)
	}

	const onClickChkBoxBtn = (e) => {
		e.stopPropagation()
		onChange(e)
	}

	const renderTree = (nodes, onContextMenu, makeInput, fieldVal, name) => {
		const isChkBoxChecked = !_.isEmpty(
			_.find(fieldVal, (val) => {
				return _.isEqual(val, nodes.id)
			})
		)
		return (
			<StyledTreeItem
				key={nodes.id}
				nodeId={nodes.id}
				label={
					isShowContextMenu && _.isEqual(activeNodeId, nodes.id) ? (
						<TreeItemLabel
							showViewOption={showViewOption}
							showCreateOption={isCreateAllowed}
							showEditOption={isEditAllowed}
							showDelOption={isDeleteAllowed}
							onNodeAction={onNodeAction}
							node={nodes}
							label={nodes.name}
						/>
					) : (
						<Typography variant='body2' component='div'>
							{nodes.name}
						</Typography>
					)
				}
				classes={{
					root: classnames({ [classes.treeItemRoot]: makeInput }),
					iconContainer: classnames({ [classes.iconContainer]: makeInput }),
				}}
				collapseIcon={wrapChkBox(<MinusSquare />, onClickChkBoxBtn, nodes.id, isChkBoxChecked, name)}
				expandIcon={wrapChkBox(<PlusSquare />, onClickChkBoxBtn, nodes.id, isChkBoxChecked, name)}
				endIcon={wrapChkBox(<CloseSquare />, onClickChkBoxBtn, nodes.id, isChkBoxChecked, name)}
				onContextMenu={(e) => {
					e.stopPropagation()
					onContextMenu(e, nodes)
				}}
			>
				{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, onContextMenu, makeInput, fieldVal, name)) : null}
			</StyledTreeItem>
		)
	}

	return (
		<TreeView
			className={classes.root}
			defaultExpanded={expandedTreeList.current}
			defaultCollapseIcon={<MinusSquare />}
			defaultExpandIcon={<PlusSquare />}
			defaultEndIcon={<CloseSquare />}
			onNodeSelect={onNodeSelect}
		>
			{_.map(data, (parentNode) => renderTree(parentNode, handleClick, makeInput, value, name))}

			{isShowContextMenu && (
				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference='anchorPosition'
					anchorPosition={state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX } : undefined}
				>
					{showViewOption && (
						<MenuItem dense onClick={(e) => handleClose(e, PAGE_MODES.VIEW)}>
							View
						</MenuItem>
					)}
					{isEditAllowed && (
						<MenuItem dense onClick={(e) => handleClose(e, PAGE_MODES.EDIT)}>
							Edit
						</MenuItem>
					)}
					{isCreateAllowed && (
						<MenuItem dense onClick={(e) => handleClose(e, PAGE_MODES.CREATE)}>
							Add sub-unit
						</MenuItem>
					)}
					{isDeleteAllowed && (
						<MenuItem dense onClick={(e) => handleClose(e, PAGE_MODES.DELETE)}>
							Delete
						</MenuItem>
					)}
				</Menu>
			)}
		</TreeView>
	)
}

TeamsTreeView.propTypes = {
	data: PropTypes.array,
	makeInput: PropTypes.bool,
	activeNodeId: PropTypes.string,
}
