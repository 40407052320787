import React, { useCallback, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, List, ListItem, ListItemText, makeStyles, Typography, useTheme, useMediaQuery, Grid, TablePagination } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { initialState, reducer } from './reducer'
import SearchBox from 'components/SearchBox'
import CardApi from '../../../../../../services/glass/cards/api'
import ResultSkeleton from 'components/ResultSkeleton'
import withDashboardModal from 'hoc/withDashboardModal'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(1),
		padding: '0px 20px 8px 20px',
	},
	cbSection: {
		minWidth: 'unset',
		margin: 0,
	},
	title: {
		fontWeight: '500',
	},
	docType: {
		color: theme.palette.primary.main,
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	timeInfo: {
		marginTop: theme.spacing(0.7),
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
			marginRight: theme.spacing(0.3),
		},
	},
	listItemRoot: {
		[theme.breakpoints.up('md')]: {},
		'& .MuiListItemText-multiline': {
			margin: 0,
		},
	},
	listRootWithoutCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
		'& .MuiListItemText-multiline': {
			margin: 8,
		},
	},
	desc: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	checkbox: {
		paddingLeft: theme.spacing(0.5),
		paddingTop: theme.spacing(3),
	},
	infoTitle: {
		marginBottom: theme.spacing(1),
	},
	titleSec: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		'& .searchbar-container': {
			padding: 0,
		},
	},
	noDataTxt: {
		textAlign: 'center',
		margin: '10% 0',
	},
}))

ResultsList.propTypes = {
	glassId: PropTypes.string.isRequired,
	cardId: PropTypes.string.isRequired,
}

function ResultsList({ glassId, cardId, openDashboard, history }) {
	const classes = useStyles()
	const [state, dispatch] = useReducer(reducer, initialState)
	const theme = useTheme()
	const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
	const [params, setParams] = useState({
		limit: 5,
		offset: 0,
		search_text: '',
		search_fields: [],
	})

	useEffect(() => {
		dispatch({ type: 'fetchingResultsPending' })
		const onSuccess = (res) => {
			dispatch({
				type: 'fetchingResultsSuccess',
				data: _.get(res, 'data.discoverx', []),
				totleResults: _.get(res, 'data.total_records', 0),
			})
		}
		const onFailure = (err) => {
			dispatch({ type: 'fetchingResultsError' })
		}

		if (glassId && cardId) {
			CardApi.fetchSearchResults(glassId, cardId, params).then(onSuccess, onFailure)
		}
	}, [glassId, cardId, params])

	const onPageChange = (e, pageNo) => {
		setParams({ ...params, offset: pageNo })
	}
	const onRowChange = (e) => {
		setParams({ ...params, limit: parseInt(e.target.value), offset: 0 })
	}
	const onSearchChange = useCallback(
		_.debounce((value) => {
			setParams({ ...params, search_text: value })
		}, 300),
		[]
	)

	const onClickResult = (e, result) => {
		openDashboard(result?.dashboard_id, {
			title: result?.title,
			filterColumn: result?.dashboard_filter_column,
			filterValue: result?.dashboard_filter_column_value,
			dsId: result?.ds_id,
			history: history,
			subtitle: result?.data_source,
			tag: result?.doc_type,
			viewLink: result?.url,
		})
	}

	return (
		<>
			<div className={classes.root}>
				<Grid container className={classes.titleSec}>
					<Grid item lg={6} xs={12} sm={6}>
						<Typography variant='overline' component='div'>
							Search Results
						</Typography>
					</Grid>
					<Grid item lg={6} xs={12} sm={6}>
						<SearchBox
							placeholder='Search'
							size='small'
							onChange={(e) => {
								onSearchChange(e.target.value)
							}}
							containerClassName={classes.zeroPadding}
						/>
					</Grid>
				</Grid>
				{state.isFetchingResults ? (
					<ResultSkeleton />
				) : (
					<>
						{_.isEmpty(_.get(state, 'results', [])) ? (
							<Typography component='p' variant='body2' className={classes.noDataTxt}>
								No results found!
							</Typography>
						) : (
							<>
								<List className={classes.listRoot}>
									{_.map(_.get(state, 'results', []), (result) => (
										<ListItem
											onClick={(e) => {
												onClickResult(e, result)
											}}
											classes={{ root: classes.listRootWithoutCheckbox }}
											key={_.get(result, 'id')}
											disableGutters
											alignItems='flex-start'
											button
										>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className={classes.infoTitle} variant='caption' color='textSecondary'>
															{_.get(result, 'data_source')} &bull;
															<span className={classes.docType}>{_.get(result, 'doc_type')}</span>
														</Typography>
														<Typography
															variant={isMobileView ? 'body2' : 'body1'}
															fontWeight='bold'
															color='textPrimary'
															dangerouslySetInnerHTML={{
																__html: _.get(result, 'title'),
															}}
															className={classes.title}
														/>
													</React.Fragment>
												}
												secondary={
													<React.Fragment>
														<Typography
															dangerouslySetInnerHTML={{
																__html: _.get(result, 'description'),
															}}
															component='span'
															gutterBottom
															className={classes.desc}
															variant='body2'
															color='textSecondary'
														/>
														{!_.isEmpty(_.get(result, 'date')) && (
															<Typography component='span' className={classes.timeInfo} variant='caption'>
																<Icon fontSize='inherit'>today</Icon>
																<span>{moment(_.get(result, 'date')).format('DD MMMM YYYY')}</span>
															</Typography>
														)}
													</React.Fragment>
												}
											/>
										</ListItem>
									))}
								</List>
								{!_.get(state, 'isFetchingResults', false) && !_.isEmpty(state.results) && (
									<TablePagination
										component='div'
										count={_.get(state, 'totalSearches', 0)}
										page={params.offset}
										rowsPerPageOptions={[5, 10, 15]}
										onChangePage={onPageChange}
										rowsPerPage={params.limit}
										onChangeRowsPerPage={onRowChange}
									/>
								)}
							</>
						)}
					</>
				)}
			</div>
		</>
	)
}

export default withDashboardModal(ResultsList)
