import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, Divider, makeStyles } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},

		'& .MuiDialog-paperScrollPaper': {
			width: '100%',
		},
	},
	dialogTitle: {
		paddingBottom: 10,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(3),
	},
	dialogFooter: {
		padding: '10px 15px 10px 15px',
	},
}))

function GoogleDriveDialog({ triggerClose, dialogProps, submitForm, isEditMode }) {
	const classes = useStyles()
	const dispatch = useDispatch()

	//   const [checked, setChecked] = useState(true);
	const [state, setState] = useState({
		allChecked: false,
		list: [
			{ id: 1, name: 'pdf', isChecked: false },
			{ id: 2, name: 'doc', isChecked: false },
			{ id: 3, name: 'png', isChecked: false },
		],
	})

	const [selectedDocs, setSelectedDocs] = useState([])

	useEffect(() => {
		if (state?.allChecked) {
			setSelectedDocs(['All'])
		} else if (!_.isEmpty(state?.list)) {
			let tempArr = []
			_.map(state?.list, (docType) => {
				if (docType.isChecked) {
					tempArr.push(docType.name)
				}
			})
			setSelectedDocs(tempArr)
		} else {
			setSelectedDocs([])
		}
	}, [state])

	const onClickCreate = () => {
		if (_.isEmpty(selectedDocs)) {
			dispatch(showSnackbarWithTimeout('Please select atleast one document type', 1000))
		} else {
			submitForm(selectedDocs)
		}
	}

	const handleChange = (e) => {
		let itemName = e.target.name
		let checked = e.target.checked
		setState((prevState) => {
			let { list, allChecked } = prevState
			if (itemName === 'checkAll') {
				allChecked = checked
				list = list.map((item) => ({ ...item, isChecked: checked }))
			} else {
				list = list.map((item) => (item.name === itemName ? { ...item, isChecked: checked } : item))
				allChecked = list.every((item) => item.isChecked)
			}
			return { list, allChecked }
		})
	}

	useEffect(() => {
		if (!_.isEmpty(dialogProps?.data?.drive_files)) {
			let group = dialogProps.data.drive_files.reduce((r, a) => {
				r[a.mimeType] = [...(r[a.mimeType] || []), { id: a.id, name: a.mimeType }]
				return r
			}, {})
			console.log('group', group)
		}
	}, [dialogProps])

	return (
		<div className={classes.dialogContainer}>
			<Dialog open={dialogProps?.open} onClose={triggerClose} className={classes.dialog}>
				<DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>
					{'Choose files to add to this section'}
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<FormGroup column>
						<FormControlLabel
							control={<Checkbox onChange={handleChange} name='checkAll' checked={state.allChecked} color='primary' />}
							label='All Files'
						/>
						{state.list.map((item, i) => (
							<FormControlLabel
								key={`gdrive_dialog_check_${i}__${item.id}`}
								control={
									<Checkbox key={item.id} name={item.name} value={item.name} checked={item.isChecked} onChange={handleChange} color='primary' />
								}
								label={item.name}
							/>
						))}
					</FormGroup>
				</DialogContent>
				<Divider />
				<DialogActions className={classes.dialogFooter}>
					<Button onClick={triggerClose} color='primary' variant='outlined' autoFocus disableElevation>
						Close
					</Button>
					<Button onClick={onClickCreate} color='primary' variant='contained' autoFocus disableElevation>
						{isEditMode ? 'Save' : 'Create'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default GoogleDriveDialog
