import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { SAGA_ACTIONS } from '../../../constants'
import { showLoader, hideLoader, showErrorMessage } from '../../loader/actions'
import api from '../api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import Axios from 'axios'
import { cancelled } from 'redux-saga/effects'
import { getErrMsg, getSuccessMsg } from 'utils'

function* createNewUser(action) {
	yield put(showLoader('Please wait saving user...'))
	const { userDetails, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.saveUser, userDetails)
		yield put(hideLoader())
		yield call(successCallback, resp)
		yield put(showSnackbarWithTimeout(getSuccessMsg(resp, 'Saved Successfully!'), 2500))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* createNewUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.CREATE_NEW_USER, createNewUser)
}

function* fetchAllUsers(action) {
	const { filters, successCallback, errorCallback } = action.payload
	const cancelSource = Axios.CancelToken.source()
	try {
		const req = yield call(api.fetchAllUsers, filters, cancelSource)
		yield call(successCallback, req)
	} catch (err) {
		yield call(errorCallback, err)
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchAllUsersWatcher() {
	yield takeLatest(SAGA_ACTIONS.FETCH_ALL_USERS, fetchAllUsers)
}

function* fetchUser(action) {
	yield put(showLoader('Please wait, getting user details...'))
	const { userId, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.fetchUser, userId)
		yield call(successCallback, resp)
		yield put(hideLoader())
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* fetchUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.FETCH_USER, fetchUser)
}

function* updateUser(action) {
	yield put(showLoader('Please wait updating user...'))
	const { userId, userDetails, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.updateUser, userId, userDetails)
		yield put(hideLoader())
		yield call(successCallback, resp)
		yield put(showSnackbarWithTimeout(getSuccessMsg(resp, 'Updated Successfully!'), 2500))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* updateUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER, updateUser)
}

function* fetchUserSpecPermissions(action) {
	yield put(showLoader('Please wait, loading...'))
	const { userId, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.getSpecPermissions, userId)
		yield call(successCallback, resp)
		yield put(hideLoader())
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* fetchUserSpecPermWatcher() {
	yield takeEvery(SAGA_ACTIONS.FETCH_USER_PERMISSIONS, fetchUserSpecPermissions)
}

function* updateUserSpecPerms(action) {
	const { userId, permissions, loaderMsg, successCallback, dialogCloseBtnCallback } = action.payload
	yield put(showLoader(loaderMsg))
	try {
		const resp = yield call(api.updateSpecPermissions, userId, permissions)
		yield put(hideLoader())
		yield call(successCallback, resp)
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* updateUserSpecPermsWatcher() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_PERMISSIONS, updateUserSpecPerms)
}

function* deleteUser(action) {
	yield put(showLoader('Deleting user...'))
	const { userId, formData, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.deleteUser, userId, formData)
		yield call(successCallback, resp)
		yield put(hideLoader())
		yield put(showSnackbarWithTimeout(getSuccessMsg(resp, 'User Deleted Successfully!'), 2500))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* deleteUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.DELETE_USER, deleteUser)
}

//all the saga watchers
// eslint-disable-next-line import/no-anonymous-default-export
export default [
	createNewUserWatcher(),
	fetchAllUsersWatcher(),
	fetchUserWatcher(),
	updateUserWatcher(),
	fetchUserSpecPermWatcher(),
	updateUserSpecPermsWatcher(),
	deleteUserWatcher(),
]
