import { ACTION_TYPES } from '../../../constants'
import _ from 'lodash'

const initialState = {
	company_name: '',
	company_adjective: '',
	logo: '',
	favicon: '',
}

const appSettings = (state = { data: initialState, isLoading: false, isError: false }, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_APP_CONFIGS_PENDING: {
			return { ...state, isError: false, isLoading: true }
		}
		case ACTION_TYPES.FETCH_APP_CONFIGS_FULFILLED: {
			const data = {
				company_name: _.get(action, 'payload.data.data.company_name', ''),
				company_adjective: _.get(action, 'payload.data.data.company_adjective', ''),
				logo: _.get(action, 'payload.data.data.logo', ''),
				favicon: _.get(action, 'payload.data.data.favicon', ''),
				tenant_name: _.get(action, 'payload.data.data.tenant_name', ''),
				workflow_url: _.get(action, 'payload.data.data.workflow_url', ''),
				dataflow_url: _.get(action, 'payload.data.data.dataflow_url', ''),
			}
			return { data, isLoading: false, isError: false }
		}
		case ACTION_TYPES.FETCH_APP_CONFIGS_REJECTED: {
			return { ...state, isError: true, isLoading: false }
		}
		default:
			return state
	}
}

export default appSettings
