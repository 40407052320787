export const initialState = {
	projectList: [],
	teamList: [],
	isFetchingProjects: false,
	isFetchingTeam: false,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchProjectsPending':
			return { ...state, isFetchingProjects: true, projectList: [] }
		case 'fetchProjectsCompleted':
			return { ...state, isFetchingProjects: false, projectList: action.data }
		case 'fetchTeamsPending':
			return { ...state, isFetchingTeam: true, teamList: [] }
		case 'fetchTeamsCompleted':
			return { ...state, isFetchingTeam: false, teamList: action.data }
		default:
			throw new Error()
	}
}
