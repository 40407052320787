import React from 'react'
import { makeStyles, Grid, Dialog, DialogTitle, DialogContent, Typography, IconButton, Icon } from '@material-ui/core'
import _ from 'lodash'
import SwiperCarousel from 'scences/DiscoverXResults/DiscoverXSlider/SliderDetails/SwiperCarousel'

const useStyles = makeStyles((theme) => ({
	imgInPopUpTitle: {
		width: '50px',
		height: '50px',
		border: `1px solid ${theme.palette.almostBlack[300]}`,
		marginRight: theme.spacing(2),
		borderRadius: '5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& img': {
			width: '100%',
			height: '100%',
			borderRadius: '5px',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			fontSize: '0.75rem',
		},
	},
	muiDialogPaper: {
		minWidth: '700px',
		// borderRadius: '20px',
		// paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(4),
	},
	muiDialogContentRoot: {
		padding: theme.spacing(1),
	},
	titleTextContainer: {
		height: '100%',
		width: `calc(100% - 70px)`,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		'& h5': {
			maxWidth: '700px',
			width: '100%',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
	},
	subtitle: {
		textTransform: 'uppercase',
		color: theme.palette.almostBlack[700],
	},
	closeButtonInDialog: {
		position: 'absolute',
		top: '1%',
		right: '1%',
	},
	closeButtonContainer: {
		position: 'relative',
	},
	imageContainer: {
		width: '100%',
		minWidth: '500px',
		// maxWidth: '700px',
		// minHeight: '400px',
		// maxHeight: '500px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		'& img': {
			width: 'auto',
			maxWidth: '100%',
			// maxHeight: '100%'
		},
	},
}))

const ImagePopUp = ({ isOpen, imgProps }) => {
	const classes = useStyles()
	const { imgKey, secImgKeys, title, subTitle, record, closeFxn } = imgProps

	const imgSrc = _.get(record, imgKey, '-')
	const secImgSrc = _.get(record, secImgKeys, '-')
	let secImgSrcCount = secImgSrc !== '-' ? secImgSrc.length - 1 : -1
	const loadSecondarySrc = (e) => {
		if (secImgSrcCount !== -1) {
			e.target.src = secImgSrc[secImgSrcCount]
			secImgSrcCount -= 1
		}
	}
	return (
		<>
			<Dialog maxWidth='lg' fullWidth={false} scroll='body' open={isOpen} onClose={closeFxn} classes={{ paper: classes.muiDialogPaper }}>
				<DialogTitle id='new_product_view'>
					<Grid container>
						<Grid item xs={11} container alignItems='center' justifyContent='flex-start'>
							<div className={classes.titleTextContainer}>
								<Typography variant='h5' title={_.get(record, title, '')}>
									{_.get(record, title, '')}
								</Typography>
								<Typography variant='body2' className={classes.subtitle}>
									{_.get(record, subTitle, '')}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={1} container alignItems='flex-start' justifyContent='flex-end'>
							<IconButton onClick={closeFxn} className={classes.closeButtonInDialog}>
								<Icon>close</Icon>
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent classes={{ root: classes.muiDialogContentRoot }}>
					<Grid xs={12} item container justifyContent='center'>
						{/* <div className={classes.imageContainer}>
							{imgSrc !== '-' ? (
								<img onError={loadSecondarySrc} src={imgSrc} alt={_.get(record, title, '')} />
							) : (
								<Typography variant='subtitle1'>Shoe</Typography>
							)}
						</div> */}
						<div className={classes.imageContainer}>
							<SwiperCarousel imgUrl={imgSrc} />
							{/* <Typography variant='subtitle1'>{imgSrc}</Typography> */}
						</div>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default ImagePopUp
