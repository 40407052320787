import React from 'react'
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import SearchBox from 'components/SearchBox'

const useStyles = makeStyles((theme) => ({
	headerRoot: {
		paddingBottom: theme.spacing(2),
	},
	titleContainer: {
		display: 'flex',
	},
	title: {
		width: '100%',
		fontWeight: '400',
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			lineHeight: 'unset',
		},
		'& span:first-child': {
			display: '-webkit-box',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
		},
	},
	searchSection: {
		textAlign: 'right',
		'& .searchbar-container': {
			padding: 0,
		},
	},
}))

function Header({ onBackBtnClick, onChangeSearchBox, searchPlaceholder }) {
	const classes = useStyles()

	return (
		<Grid container className={classes.headerRoot}>
			<Grid container item lg={8}>
				<Grid container item lg={8} sm={6} alignItems='center'>
					<div className={classes.titleContainer}>
						<IconButton size='small' onClick={onBackBtnClick}>
							<Icon fontSize='small'>arrow_back_ios</Icon>
						</IconButton>
						<Typography variant='h6' className={classes.title}>
							My wishlist
						</Typography>
					</div>
				</Grid>
				<Grid item lg={4} sm={6} className={classes.searchSection}>
					<SearchBox onChange={onChangeSearchBox} searchBarClassName={classes.searchBar} size='small' placeholder={searchPlaceholder}></SearchBox>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Header
