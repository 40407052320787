import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	makeStyles,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import _ from 'lodash'
import DatasourceTabs from './DatasourceTabs'
import update from 'immutability-helper'
import DiscoverxApi from 'services/discoverx/api'
import { useDispatch } from 'react-redux'
import Axios from 'axios'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'

ExportDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
	isSelectedAll: PropTypes.bool.isRequired,
	searchParams: PropTypes.object,
}

const useStyles = makeStyles((theme) => ({
	titleWrap: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	title: {
		lineHeight: 'unset',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	toolbar: {
		width: '100%',
		boxSizing: 'border-box',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	dialogContent: {
		padding: 0,
	},
}))

function ExportDialog({ open, handleClose, data, isSelectedAll, searchParams }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const classes = useStyles()
	const [{ datasourceDetails, isLoading, isUpdating }, setState] = useState({ datasourceDetails: [], isLoading: false, isUpdating: false })
	const dsFetchExec = useRef()
	const dsUpdateExec = useRef()
	const dispatch = useDispatch()

	const updateState = useCallback((updateObj) => {
		setState((prevState) => ({ ...prevState, ...updateObj }))
	}, [])

	useEffect(() => {
		if (open) {
			updateState({ isLoading: true, isUpdating: false, datasourceDetails: [] })
			DiscoverxApi.getExportDsList(searchParams, dsFetchExec).then((resp) => {
				updateState({ isLoading: false, datasourceDetails: _.get(resp, 'data.data.datasource', []) })
			})
		}
	}, [open, isSelectedAll, updateState, data, searchParams])

	const onClickExport = () => {
		dispatch(showLoader('Please wait...'))
		const exportRecordsParams = {
			query: searchParams.query,
			filter_data: searchParams.filter_data,
			is_select_all: isSelectedAll,
			...(isSelectedAll ? {} : { data_ids: data }),
			datasource: datasourceDetails,
		}
		const onSuccess = (res) => {
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
					handleClose()
				})
			)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		DiscoverxApi.export(exportRecordsParams).then(onSuccess, onFailure)
	}

	const updateDatasourceInfo = (dsId, dataSourceObj) => {
		const dsIdx = _.findIndex(datasourceDetails, { ds_id: dsId })
		const dsInfoToUpdate = update(datasourceDetails, { [dsIdx]: { $set: { ...dataSourceObj } } })
		updateState({ isUpdating: true, datasourceDetails: dsInfoToUpdate })
		DiscoverxApi.updateExportDs(dsId, dataSourceObj, dsUpdateExec).then(
			() => {
				//updated!
				updateState({ isUpdating: false })
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 3000))
					updateState({ isUpdating: false })
				}
			}
		)
	}

	return (
		<Dialog
			open={open}
			fullScreen={true}
			onClose={handleClose}
			aria-labelledby='task-add-dialog'
			scroll={fullScreen ? 'paper' : 'body'}
			maxWidth={'md'}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
					<Icon>close</Icon>
				</IconButton>
				<div className={classes.titleWrap}>
					<Typography className={classes.title} component='div' variant='h6'>
						Export Results
					</Typography>
					<Typography variant='caption' component='div' color='textSecondary'>
						<span className={classes.subtitle}>Note: Maximum 100,000 records only exported.</span>
					</Typography>
				</div>
				<Button variant='outlined' size='small' onClick={onClickExport} startIcon={<Icon fontSize='small'>file_upload</Icon>}>
					Export
				</Button>
			</Toolbar>

			<DialogContent className={classes.dialogContent}>
				<Grid container>
					<Grid item xs={12}>
						{isLoading ? (
							<LinearProgress />
						) : (
							<DatasourceTabs
								datasourceDetails={datasourceDetails}
								updateDatasourceInfo={updateDatasourceInfo}
								data={data}
								isSelectedAll={isSelectedAll}
								searchParams={searchParams}
								isDsUpdating={isUpdating}
							/>
						)}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ExportDialog
