import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ButtonBase, Icon, IconButton, Typography, LinearProgress } from '@material-ui/core'
import Timer from './Timer'
import LogsTable from './LogsTable'
import _ from 'lodash'
import LogForm from './LogForm'
import moment from 'moment'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	disableOnUpdate: {
		opacity: (props) => (props.isLoading ? 0.8 : 1),
		pointerEvents: (props) => (props.isLoading ? 'none' : 'unset'),
	},
	addSessionBtn: {
		width: '100%',
		padding: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		border: `1px solid ${theme.palette.divider}`,
	},
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
	},
	paper: {
		margin: theme.spacing(1),
	},
	paperFullWidth: {
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	backBtn: {
		marginRight: theme.spacing(1),
	},
}))

LogsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	logs: PropTypes.arrayOf(
		PropTypes.shape({
			time_log_id: PropTypes.string,
			created_by: PropTypes.shape({
				first_name: PropTypes.string,
				last_name: PropTypes.string,
				email: PropTypes.string,
				profile_img: PropTypes.string,
			}),
			from: PropTypes.string,
			to: PropTypes.string,
		})
	),
	onAddTimeLog: PropTypes.func.isRequired,
	onUpdateTimeLog: PropTypes.func.isRequired,
	onRemoveTimeLog: PropTypes.func.isRequired,
	onClearTimeLogs: PropTypes.func.isRequired,
}

export default function LogsDialog({ isLoading, open, handleClose, logs = [], onAddTimeLog, onUpdateTimeLog, onRemoveTimeLog, onClearTimeLogs }) {
	const classes = useStyles({ isLoading })
	const logFormRef = useRef()
	const [selectedLog, setSelectedLog] = useState({})

	const onClickAddSessionBtn = () => {
		openLogForm({ from: moment().subtract(1, 'hours').format('MM/DD/YYYY HH:mm'), to: moment().format('MM/DD/YYYY HH:mm'), is_manual: true })
	}

	const onTblRowClick = (log) => {
		openLogForm(log)
	}

	const onRemoveLog = (log) => {
		onRemoveTimeLog(log)
	}

	const onClickClearBtn = () => {
		onClearTimeLogs()
	}

	const onSubmitLogForm = (log) => {
		const onSuccess = () => {
			setSelectedLog({})
		}
		if (log?.time_log_id) onUpdateTimeLog(log, onSuccess)
		else onAddTimeLog(log, onSuccess)
	}

	const openLogForm = (log) => {
		setSelectedLog(log)
	}

	const closeLogForm = () => {
		setSelectedLog({})
	}

	const isLogFormOpened = useMemo(() => !_.isEmpty(selectedLog), [selectedLog])

	useEffect(() => {
		if (open) setSelectedLog({})
	}, [open])

	//skip the active running time log by "to" is empty condition
	const logsToShow = useMemo(() => _.filter(logs, (log) => !_.isEmpty(log?.to)), [logs])
	//find the active log
	const activeLog = useMemo(
		() =>
			_.find(logs, (log) => {
				return _.isEmpty(log?.to)
			}),
		[logs]
	)

	const onClickAddOrUpdateBtn = () => {
		logFormRef.current?.submitForm()
	}

	return (
		<Dialog
			fullWidth={true}
			classes={{ paper: classes.paper, paperFullWidth: classes.paperFullWidth }}
			maxWidth={'sm'}
			open={open}
			onClose={handleClose}
			aria-labelledby='time-log-dialog'
			disableBackdropClick={isLoading}
		>
			{isLoading && <LinearProgress variant='indeterminate' />}
			<DialogTitle disableTypography={true} className={classes.dialogTitle} id='time-log-dialog-title'>
				{isLogFormOpened && (
					<IconButton className={classes.backBtn} size='small' onClick={closeLogForm}>
						<Icon>arrow_back</Icon>
					</IconButton>
				)}
				<Typography variant='h6' component='span'>
					{isLogFormOpened ? (selectedLog?.time_log_id ? 'Update Session' : 'Add Session') : 'Time Tracking'}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.disableOnUpdate}>
				{!isLogFormOpened ? (
					<>
						{!_.isEmpty(activeLog) && <Timer startMs={moment() - moment(activeLog?.from)} />}
						<ButtonBase className={classes.addSessionBtn} onClick={onClickAddSessionBtn}>
							<Typography variant='body2' align='center'>
								+ Add manually
							</Typography>
						</ButtonBase>
						<LogsTable data={logsToShow} onTableRowClick={onTblRowClick} onRemoveLog={onRemoveLog} />
					</>
				) : (
					<LogForm ref={logFormRef} existingLogs={logs} initialValues={selectedLog} onSubmit={onSubmitLogForm} />
				)}
			</DialogContent>
			<DialogActions className={classes.disableOnUpdate}>
				{isLogFormOpened ? (
					<Button onClick={onClickAddOrUpdateBtn} color='primary'>
						{selectedLog?.time_log_id ? 'Update Session' : 'Add Session'}
					</Button>
				) : (
					<>
						{!_.isEmpty(logsToShow) && (
							<Button onClick={onClickClearBtn} color='primary'>
								Clear All
							</Button>
						)}
						<Button onClick={handleClose} color='primary'>
							Close
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	)
}
