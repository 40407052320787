import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import AgGridCustom from 'components/AgGridCustom'
import { withCellRenderState, NameCellRenderer, ActionCellRenderer, RoleCellRenderer, RemoveCellRenderer } from './CustomCells'
import _ from 'lodash'
import { withWidth } from '@material-ui/core'
import { useSelector } from 'react-redux'

SavedSearchTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			user_id: PropTypes.string.isRequired,
			first_name: PropTypes.string.isRequired,
			last_name: PropTypes.string,
			email: PropTypes.string.isRequired,
			role: PropTypes.string.isRequired,
		})
	),
	isOwner: PropTypes.bool.isRequired,
	onChangeMemberRole: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	nameCellContent: {
		display: 'flex',
		// alignItems: 'center',
		height: '100%',
		padding: theme.spacing(0.8, 0),
		justifyContent: 'flex-start',
		[theme.breakpoints.up('sm')]: {
			// alignItems: 'flex-start',
			// padding: theme.spacing(0.8, 0),
		},
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	memberCellContainer: {
		'& .roleTxt': {
			padding: theme.spacing(1, 0),
			display: 'flex',
			alignItems: 'center',
			'& .dicon': {
				color: theme.palette.text.secondary,
			},
		},
	},
}))

function SavedSearchTable({ width, data, onRemoveSearch, isLoading, onRowClicked }) {
	const classes = useStyles()
	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const tblHeaders = useMemo(() => {
		const { nameCellContent, /* actionCellContainer, */ memberCellContainer } = classes
		const isMobile = _.isEqual('xs', width)
		return [
			{
				headerName: _.isEqual('xs', width) ? 'USERS' : 'Name',
				cellRenderer: 'nameCellRenderer',
				field: 'first_name',
				maxWidth: 250,
				cellRendererParams: {
					containerClass: nameCellContent,
					authToken: authToken,
					isRenderMobView: isMobile,
				},
			},
			{
				headerName: 'Query',
				field: 'query',
			},
			{
				headerName: 'Saved Date',
				field: 'role',
				cellRenderer: 'roleCellRenderer',
				maxWidth: 140,
				cellRendererParams: {
					containerClass: memberCellContainer,
				},
			},
		]
	}, [classes, width, authToken])

	return (
		<div className={classes.root}>
			<AgGridCustom
				columnDefs={tblHeaders}
				rowData={data}
				onRowClicked={onRowClicked}
				loading={isLoading}
				rowHeight={_.isEqual(width, 'xs') ? 70 : 50}
				frameworkComponents={{
					nameCellRenderer: withCellRenderState(NameCellRenderer),
					actionCellRenderer: withCellRenderState(ActionCellRenderer),
					roleCellRenderer: withCellRenderState(RoleCellRenderer),
					removeCellRenderer: withCellRenderState(RemoveCellRenderer),
				}}
				suppressRowClickSelection={true}
				noDataTxt='No results found'
			/>
		</div>
	)
}

export default withWidth()(SavedSearchTable)
