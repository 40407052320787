import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import classnames from 'classnames'
import {
	Badge,
	Button,
	Fab,
	Grid,
	Icon,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
	Chip,
	TextField,
	Checkbox,
	Hidden,
	Paper,
	Divider,
	Popper,
} from '@material-ui/core'
import { Pagination, Autocomplete } from '@material-ui/lab'
import DiscoverXSvgImg from 'images/discover-x-svg.svg'
import DiscoverXImg from 'images/discover-x-fallback.png'
import ResultsList from './ResultsList'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import { Link as RouterLink } from 'react-router-dom'
import DisXInpBox from './DisXInpBox'
import { initialState, reducer } from './reducer'
import DiscoverxApi from 'services/discoverx/api'
import _ from 'lodash'
import Axios from 'axios'
import withDashboardModal from 'hoc/withDashboardModal'
import { compose } from 'redux'
import withAddStickyDialog from 'hoc/withAddStickyDialog'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { ROLE_MODULES } from 'constants/modules'
import Filters from './Filters'
import { API_ENDPOINTS } from '../../constants'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import FilterMaster from './FilterMaster'
import DiscoverXSlider from './DiscoverXSlider'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import WishList from './WishList'
import SavedSearch from './SavedSearch'
import ExportDialog from './ExportDialog'
import LinearProgress from '@material-ui/core/LinearProgress'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		borderRadius: '20px',
		backgroundColor: '#ebecf052',
		height: '35px',
		border: '1px solid #0000000d',
	},
	paperContainer: {
		justifyContent: 'space-evenly',
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	dsTextField: {
		border: 'none',
		'& .MuiTypography-root': {
			margin: 0,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			maxWidth: 'calc(100% - 42%)',
		},
	},
	wrapper: {
		display: 'flex',
	},
	constraintedWrapper: {
		paddingTop: 0,
		overflow: 'hidden',
		maxHeight: '100vh',
	},
	pageContent: {
		padding: theme.spacing(2),
		// border: `1px solid red`,
		[theme.breakpoints.up('sm')]: {
			// padding: theme.spacing(3, 4),
		},
	},
	resultsSection: {
		flex: 1,
		maxWidth: 1200,
		margin: '0 auto',
		[theme.breakpoints.up(1200)]: {
			marginLeft: `calc((100vw - 1200px) / 2)`,
		},
	},
	filterSection: {
		height: 'auto',
		position: 'relative',
		width: 0,
		minWidth: 0,
		minHeight: 0,
		transition: `width 0.2s`,
	},
	filterSectionOpened: {
		minWidth: 300,
		width: 320,
		minHeight: `calc(100vh - 66px)`,
		height: `calc(100vh - 66px)`,
		// boxShadow: `0px 0px 4px 0px rgb(100 100 100 / 40%)`,
		position: 'relative',
		// border: `1px solid blue`,
	},
	dNone: {
		display: 'none',
	},
	filterContent: {
		width: 350,
		height: `calc(100vh - 64px)`,
		// height: '100%',
		// background: 'rgba(120, 65, 200, 0.2)',
		position: 'fixed',
		bottom: 0,
		right: -500, //left: 0,
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: 320,
			right: -320,
		},
	},
	filterContentVisible: {
		right: 0,
		transition: `right 0.2s`,
		boxShadow: `0px 4px 4px 0px rgb(100 100 100 / 40%)`,
		background: 'white',
	},
	searchWrapper: {
		maxWidth: '75%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	stickyWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: theme.spacing(0.6),
		marginBottom: theme.spacing(1),
	},
	logoWrapper: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	autoComplete: {
		display: 'inline',
	},
	pageHeader: {
		textAlign: 'left',
	},
	searchBox: {
		maxWidth: '420px',
		margin: 0,
		[theme.breakpoints.down('xs')]: {
			maxWidth: 'unset',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '24px',
			[theme.breakpoints.down('xs')]: {
				borderRadius: '20px',
			},
			'&:hover fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
				[theme.breakpoints.up('md')]: {
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},
			'&.Mui-focused fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
				[theme.breakpoints.up('md')]: {
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '14px',
			},
			padding: '0px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			[theme.breakpoints.up('md')]: {
				borderColor: 'transparent',
			},
		},
	},
	discoverXLogo: {
		display: 'inline-block',
		pointerEvents: 'none',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '140px',
			margin: '0 auto',
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: '150px',
		},
	},
	resultsInfo: {
		padding: theme.spacing(0, 1),
	},
	recommendedLink: {
		fontWeight: '500',
		marginRight: theme.spacing(1),
		textDecoration: 'underline',
	},
	paginationWrapper: {
		display: 'inline-block',
		paddingBottom: theme.spacing(8),
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	fabSticky: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(8.5),
			fontSize: '12px',
		},
		'& button': {
			color: '#fff',
		},
	},
	stickyIco: {
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
	stickyTxt: {
		marginRight: theme.spacing(0.5),
		fontWeight: 'bold',
	},
	recomSec: {
		marginTop: theme.spacing(2),
	},
	searchBtn: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: theme.spacing(2),
		width: '38px',
		height: '38px',
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
	stickyBtn: {
		marginLeft: theme.spacing(2),
	},
	infoContainer: {
		maxWidth: '350px',
		padding: theme.spacing(3),
		textAlign: 'center',
	},
	notFoundIco: {
		fontSize: '4em',
		marginBottom: theme.spacing(1),
	},
	overflowHidden: {
		overflow: 'hidden',
		height: '100vh',
		boxSizing: 'border-box',
	},
	loadingContainer: {
		maxWidth: 80,
		margin: '0 auto',
		textAlign: 'center',
		paddingTop: 'calc(100% - 85%)',
		'& span': {
			paddingTop: theme.spacing(2),
			display: 'block',
		},
		[theme.breakpoints.only('md')]: {
			paddingTop: 'calc(100% - 80%)',
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: 'calc(100% - 60%)',
		},
	},
	selectWrapper: {
		margin: '16px 16px 8px 0px',
		maxWidth: '250px',
		[theme.breakpoints.down('sm')]: {
			margin: '6px 16px 6px 0px',
		},
	},
	dataSourceSelect: {
		'& .MuiInputBase-root': {
			borderRadius: '2rem',
			padding: '6px !important',
			[theme.breakpoints.down('sm')]: {
				padding: '3px !important',
			},
		},
		'& .MuiTextField-root': {
			margin: 0,
		},
		'& .MuiAutocomplete-tagSizeSmall': {
			maxWidth: 'calc(100% - 40%)',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '24px',
			[theme.breakpoints.down('xs')]: {
				borderRadius: '20px',
			},
			'&:hover fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
				[theme.breakpoints.up('md')]: {
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},
			'&.Mui-focused fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
				[theme.breakpoints.up('md')]: {
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},
		},
		'& .MuiInputBase-input': {
			fontSize: '14px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			[theme.breakpoints.up('md')]: {
				borderColor: 'transparent',
			},
		},
	},
	dsListBox: {
		margin: '0',
		padding: '8px 0',
		overflow: 'auto',
		listStyle: 'none',
		maxHeight: '40vh',
		'& .MuiAutocomplete-option': {
			padding: '6px',
		},
	},
	dsOption: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	popperComponent: {
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '5px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[500],
			height: '10px',
			borderRadius: '5px',
		},
	},
}))

const queryTypes = {
	pageNo: Number,
	q: String,
	openResult: Boolean,
	wishList: Boolean,
	savedSearch: Boolean,
}

// const ITEM_HEIGHT = 48
// const ITEM_PADDING_TOP = 8
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 	},
// }

const Discoverx = ({ history, openDashboard, match, closeDashboard, isDashDialogOpened, openStickyDialog, isStickySuccess }) => {
	const classes = useStyles()
	const theme = useTheme()
	const cancelExecutor = useRef()
	const filtersApicancelExecutor = useRef()
	const [activeFilters, setActiveFilters] = useState(undefined)
	const [discoverDetails, setDiscoverDetails] = useState({})
	const searchTxtBoxRef = useRef()
	const querySynced = useRef('')
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
	const isMideDevice = useMediaQuery(theme.breakpoints.down('md'))
	const [state, dispatch] = useReducer(reducer, initialState)
	const reduxDispatch = useDispatch()
	const { fetching, results, sortable_fields, sorting_details, isError, totalRecords, limit } = state
	const [isFilterMasterOpen, setIsFilterMasterOpen] = useState(false)
	const [isFiltersOpen, setIsFiltersOpen] = useState(false)
	const [allChecked, setAllChecked] = useState(false)
	const [openSlider, setOpenSlider] = useState(false)
	const [openExportDialog, setOpenExportDialog] = useState(false)
	const [reload, setReload] = useState(false)
	const [selectedDS, setSelectedDS] = useState([])
	const [listOpen, setListOpen] = useState(false)
	const [toggle, setToggle] = useState(false)

	const params = _.split(window?.location?.search, '&')
	const pageParam = _.split(params[2], '=')
	const currentPage = pageParam[1]
	const currentActive = activeFilters?.common?.data_source || []

	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{ q: '', openResult: false, pageNo: currentPage ? currentPage : 1, wishList: false, savedSearch: false },
		queryTypes,
		true
	)
	const { q, pageNo, openResult, wishList, savedSearch } = pageQuery

	const filterComponents = {
		dropdown: { type: 'check', isMulti: true, isSearchable: true },
		autocomplete: { type: 'text', isMulti: true, isSearchable: false },
		daterange: {
			type: 'date',
			isMulti: false,
			isSearchable: false,
			defaultEmptyValue: {},
			options: [{ label: 'Custom', key: 'custom_dates' }],
		},
		numberrange: {
			type: 'numberRange',
			isMulti: false,
			isSearchable: false,
			defaultEmptyValue: {},
		},
	}

	const [filterState, setFiltersState] = useState({
		structure: {},
		q: '',
		isLoading: true,
		isError: false,
	})

	const [exportList, setExportList] = useState([])
	const [dsLoading, setDsLoading] = useState(false)
	const [tableData, setTableData] = useState([])

	let tempSelectedList = Object.assign([], exportList)
	let tempSelectedData = Object.assign([], tableData)
	let keyForCheckBoxSelectedList = 'id'

	const [allDataSelected, setAllDataSelected] = useState(false)

	const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
	const checkedIcon = <CheckBoxIcon fontSize='small' />

	useEffect(() => {
		getSearchResults()
		setReload(false)
	}, [reload])

	useEffect(() => {
		_.isEqual(exportList.length, results.length) ? setAllChecked(true) : setAllChecked(false)
	}, [exportList, results])

	const getFormattedFiltersForAPI = useCallback(
		(activeFilters) => {
			let tempFilterDataForAPI = {}
			_.map(filterState?.structure || {}, (o, d) => {
				if (!_.isEmpty(_.get(activeFilters, o?.path))) {
					if (o?.type === 'date') {
						let temp = {}
						if (!!_.get(activeFilters, o?.path)?.startDate && !!_.get(activeFilters, o?.path)?.endDate) {
							temp.from = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.startDate)
							temp.to = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.endDate)
							_.set(tempFilterDataForAPI, o?.path, {
								value: temp,
								...(o?.additionalDataForFilterApply || {}),
							})
						}
						return true
					}
					_.set(tempFilterDataForAPI, o?.path, {
						value: _.get(activeFilters, o?.path),
						...(o?.additionalDataForFilterApply || {}),
					})
				}
			})
			return tempFilterDataForAPI
		},
		[filterState]
	)

	const onClickCheckbox = (e, record) => {
		e.stopPropagation()
		let tempIds = []
		let tempDsIds = []
		_.map(tempSelectedList, (item) => {
			tempIds.push(item.id)
			tempDsIds.push(item.ds_id)
		})
		const isAlreadyAvailable =
			_.indexOf(tempIds, _.get(record, keyForCheckBoxSelectedList)) !== -1 && _.indexOf(tempDsIds, _.get(record, 'ds_id')) !== -1
		if (!allDataSelected) {
			if (!isAlreadyAvailable) {
				tempSelectedList.push({
					id: _.get(record, keyForCheckBoxSelectedList),
					ds_id: _.get(record, 'ds_id'),
				})
				tempSelectedData.push(record)
			} else {
				_.remove(tempSelectedList, (objectID) => objectID?.id === _.get(record, keyForCheckBoxSelectedList))
				_.remove(tempSelectedData, (p) => _.get(p, keyForCheckBoxSelectedList) === _.get(record, keyForCheckBoxSelectedList))
			}
			setExportList(tempSelectedList)
			setTableData(tempSelectedData)
		} else {
			let filteredData = _.filter(results, (item) => !_.isEqual(item.id, record.id))
			let filteredTempList = []
			_.map(filteredData, (item) => {
				filteredTempList.push({
					id: _.get(item, keyForCheckBoxSelectedList),
					ds_id: _.get(item, 'ds_id'),
				})
			})
			setAllDataSelected(false)
			setExportList(filteredTempList)
			setTableData(filteredData)
		}
	}

	const checkAllSelected = (e, records) => {
		e.stopPropagation()
		let tempSelectedList = []
		_.map(records, (record) => {
			tempSelectedList.push({
				id: _.get(record, keyForCheckBoxSelectedList),
				ds_id: _.get(record, 'ds_id'),
			})
		})
		let ischecked = e.target.checked
		!ischecked && setAllDataSelected(false)
		setExportList(ischecked ? tempSelectedList : [])
		setTableData(ischecked ? records : [])
	}

	const onStickyClick = () => {
		if (_.isEmpty(exportList) && !allDataSelected) {
			reduxDispatch(showSnackbarWithTimeout('Please select the Search results', 3000))
		} else if (_.isEmpty(exportList) && allDataSelected) {
			openStickyDialog([], ROLE_MODULES.DISCOVERX, '', { discover: results })
		} else {
			openStickyDialog([], ROLE_MODULES.DISCOVERX, '', { discover: tableData })
		}
	}

	const onSliderStickyClick = (resultItemData) => {
		openStickyDialog([], ROLE_MODULES.DISCOVERX, '', { discover: [resultItemData] })
	}

	const onChangeSearchBox = (e, value) => {
		if (value) setPageQuery({ q: value, pageNo: 1 })
	}

	const getSearchFilters = useCallback(() => {
		setFiltersState({ ...filterState, isLoading: true, isError: false })
		DiscoverxApi.fetchFilters(querySynced.current, filtersApicancelExecutor)
			.then((resp) => {
				const filterDefinitions = resp?.data?.data
				//generate filter structure skeleton
				let filterStruct = _.map(filterDefinitions, (o) => {
					let componentData = _.get(filterComponents, o?.component, {})
					return {
						label: o?.name,
						path: `${!!o?.ds_id ? o?.ds_id : o?.category}.${o?.column}`,
						idKey: 'id',
						labelKey: 'name',
						defaultOptions: [...(_.isArray(o?.applied_values) ? [...o?.applied_values] : []), ...o?.default_options],
						category: o?.category,
						filterId: o?.filter_id,
						defaultEmptyValue: [],
						...(!!o?.filter_id ? { endpoint: API_ENDPOINTS.GET_FILTERS_SUGGESTIONS } : {}),
						bodyDataAdapterFunction: (d) =>
							_.isEqual(o?.category, 'common') || _.isEqual(o?.category, 'ner')
								? {
										column_name: o?.column,
										query: querySynced.current,
										filter_query: d?.search_text || '',
										offset: d?.offset || 0,
										limit: d?.limit || 20,
								  }
								: {
										filter_id: o?.filter_id,
										query: querySynced.current,
										filter_query: d?.search_text || '',
										offset: d?.offset || 0,
										limit: d?.limit || 20,
								  },
						...componentData,
						// isSearchable: !_.isEmpty(o?.default_options),
						additionalDataForFilterApply: _.isEqual(o?.category, 'common')
							? {
									condition: o?.condition || 'must',
									// ...(!!o?.id ? { filter_id: o?.id } : { column: o?.column }),
							  }
							: {
									condition: o?.condition || 'must',
									filter_id: o?.filter_id,
							  },
					}
				})
				setFiltersState((prevState) => ({
					...prevState,
					structure: filterStruct,
					isError: false,
					isLoading: false,
				}))

				//generate active filters to show the persisted filter values
				const activeFiltersToShow = _.omitBy(
					_.reduce(
						filterDefinitions,
						(result, filterObj) => {
							const category = _.isEqual(filterObj?.category, 'custom') ? filterObj?.ds_id : filterObj?.category
							return {
								...result,
								[category]: {
									..._.get(result, category, {}),
									...(!_.isEmpty(filterObj?.applied_values) ? { [filterObj?.column]: filterObj?.applied_values } : {}),
								},
							}
						},
						{}
					),
					_.isEmpty
				)
				setActiveFilters(activeFiltersToShow)
				setDsLoading(false)
			})
			.catch((err) => {
				setDsLoading(false)
				if (!Axios.isCancel(err)) {
					setFiltersState({ ...filterState, isLoading: false, isError: true })
				}
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!_.isEqual(q, filterState?.q)) {
			setFiltersState((prevState) => ({ ...prevState, q }))
			getSearchFilters()
		}
	}, [q, filterState.q, getSearchFilters])

	const getSearchResults = useCallback(
		(filterData, sortData) => {
			setExportList([])
			setTableData([])
			setDsLoading(true)
			setAllDataSelected(false)
			dispatch({ type: 'fetchResultsPending' })
			if (pageNo === 1) setFiltersState((prevState) => ({ ...prevState, isLoading: true }))
			const onSuccess = (resp) => {
				dispatch({ type: 'fetchResultsCompleted', data: resp.data })
				if (pageNo === 1) getSearchFilters()
			}
			const onErr = (err) => {
				if (!Axios.isCancel(err)) {
					dispatch({ type: 'fetchResultsError' })
				}
				setDsLoading(false)
			}
			DiscoverxApi.fetchSearchResults(
				{
					query: q,
					limit,
					filter_data: filterData,
					sorting_details: sortData,
					offset: pageNo,
				},
				cancelExecutor
			).then(onSuccess, onErr)
		},
		[q, limit, pageNo]
	)

	const toggleExportDialog = () => {
		setOpenExportDialog(!openExportDialog)
	}

	const onClickExport = () => {
		toggleExportDialog()
	}

	const onClickAnalyse = () => {
		const data = !allDataSelected
			? _.map(exportList, (item) => ({ data_id: item?.id, ds_id: item?.ds_id }))
			: _.map(results, (item) => ({ data_id: item?.id, ds_id: item?.ds_id }))
		reduxDispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			window.open(res?.data?.data?.url_path, '_blank')
			reduxDispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					setExportList([])
					setTableData([])
					setAllDataSelected(false)
					reduxDispatch(hideLoader())
				})
			)
		}
		const onFailure = (err) => {
			reduxDispatch(showErrorMessage(getErrMsg(err), 'Close', () => reduxDispatch(hideLoader())))
		}
		DiscoverxApi.analyse({ data }).then(onSuccess, onFailure)
	}

	const onAllDataClick = () => {
		setExportList([])
		setTableData([])
		setAllDataSelected(true)
	}

	const exportSearchParams = useMemo(
		() => ({
			query: q,
			limit,
			filter_data: _.isObject(activeFilters) ? getFormattedFiltersForAPI(activeFilters) : undefined,
			offset: 1,
		}),
		[q, limit, activeFilters, getFormattedFiltersForAPI]
	)

	useEffect(() => {
		querySynced.current = q
		getSearchResults(_.isObject(activeFilters) ? getFormattedFiltersForAPI(activeFilters) : undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [q, limit, pageNo])

	// const onClickSearchBtn = (e) => {
	// 	console.log(searchTxt, 'vale')
	// 	onChangeSearchBox(e, _.get(searchTxtBoxRef.current, 'value', ''))
	// }

	useEffect(() => {
		setExportList([])
		setTableData([])
	}, [pageQuery.q])

	const onChangePageNo = (e, pageNo) => {
		setPageQuery({ pageNo })
	}

	const onCloseSlider = () => {
		setOpenSlider(false)
	}

	const onclickDashboard = (result) => {
		setPageQuery({ openResult: true })
		openDashboard(
			result?.dashboard_id,
			{
				title: result?.title,
				filterColumn: result?.dashboard_filter_column,
				filterValue: result?.dashboard_filter_column_value,
				dsId: result?.ds_id,
				history: history,
				subtitle: result?.data_source,
				tag: result?.doc_type,
				viewLink: result?.url,
			},
			null,
			false,
			() => {
				history.goBack()
			}
		)
	}

	const onClickResult = (e, result) => {
		setOpenSlider(true)
		setDiscoverDetails(result)
	}

	useEffect(() => {
		if (!openResult && isDashDialogOpened) {
			closeDashboard()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openResult, isDashDialogOpened])

	const onClickWishList = () => {
		setPageQuery({ wishList: true })
	}

	const onClickSavedSearch = () => {
		setPageQuery({ savedSearch: true })
	}

	const onBackBtnClick = () => {
		setPageQuery({ wishList: false })
	}

	const onSearchResultBackClick = () => {
		setPageQuery({ savedSearch: false })
	}

	// "filter_data":{"611b9b8eb48d7cd638cf178c":{"phone":{"value":["0965965433","3873843422"],"condition":"must","filter_id":"6167c6733329067733902e76"}}}

	const onSearchRowClicked = (result) => {
		const filterData = result?.filter_data[0]
		const activeResults = _.reduce(
			_.keys(filterData),
			(result, key) => {
				return {
					...result,
					[key]: _.reduce(
						_.keys(filterData[key]),
						(filterObj, filterKey) => {
							return { ...filterObj, [filterKey]: _.get(filterData[key][filterKey], 'value', []) }
						},
						{}
					),
				}
			},
			{}
		)
		setActiveFilters(activeResults)
		setPageQuery({ savedSearch: false, q: result?.query || q, pageNo: 1 })
	}
	const updateFuction = (filterData, sortData) => {
		if (pageNo === 1) {
			getSearchResults(_.isObject(filterData, sortData) ? getFormattedFiltersForAPI(filterData, sortData) : undefined)
		} else {
			setPageQuery({ ...pageQuery, pageNo: 1 })
		}
		setActiveFilters(filterData, sortData)
	}

	const updateSortFuction = (undefined, sortData) => {
		getSearchResults(undefined, sortData)
	}

	const selectedResultsIds = useMemo(
		() => _.map(tableData, (result) => ({ data_id: _.get(result, 'id'), ds_id: _.get(result, 'ds_id') })),
		[tableData]
	)

	const eliminateCommon = _.size(activeFilters) === 1 && activeFilters && 'common' in activeFilters ? false : true

	// const groupedData = _.groupBy(filterState?.structure, 'category')

	// const groupedOptions = groupedData?.common?.map((option, idx) => option?.defaultOptions)

	let result = _.find(filterState?.structure, function (obj) {
		if (obj.category === 'common') {
			return true
		}
	})
	const dataSourceOptions = result?.defaultOptions || []

	const onTagsChange = (value) => {
		setSelectedDS(value)
		setActiveFilters((prevState) => ({
			...prevState,
			common: { data_source: value },
		}))
	}

	useEffect(() => {
		updateFuction(activeFilters)
	}, [selectedDS])

	return (
		<>
			<div className={classnames('page-content', classes.wrapper, { [classes.overflowHidden]: wishList || savedSearch })}>
				{wishList ? <WishList onBackBtnClick={onBackBtnClick} /> : null}
				{savedSearch ? (
					<SavedSearch
						onRowClicked={(result) => {
							onSearchRowClicked(result?.data)
						}}
						onBackBtnClick={onSearchResultBackClick}
					/>
				) : null}
				<div className={classnames('container-no-fluid', classes.resultsSection)}>
					<Grid container className={classes.pageContent}>
						<Grid item xs={12} className={classes.pageHeader}>
							<Grid item md={4} xs={12} className={classes.logoWrapper}>
								{/* <RouterLink to={'/discoverx'}> */}
								<object data={DiscoverXSvgImg} className={classes.discoverXLogo} type='image/svg+xml'>
									<img src={DiscoverXImg} className={classes.discoverXLogo} alt='DiscoverX' />
								</object>
								{/* </RouterLink> */}
							</Grid>
							<Grid container alignItems='center'>
								{/* <Hidden smDown>
									<Grid item md={3} xs={12} className={classes.selectWrapper}>
										<Autocomplete
											multiple
											disableCloseOnSelect
											className={classes.dataSourceSelect}
											options={dataSourceOptions ? dataSourceOptions : []}
											value={currentActive}
											loading={dsLoading}
											// disabled={filterState?.isLoading && _.isEmpty(currentActive)}
											loadingText='Loading...'
											noOptionsText={dsLoading ? 'Loading...' : 'No Data Sources Found'}
											size='small'
											getOptionLabel={(option) => option}
											getOptionSelected={(option, value) => option === value}
											onChange={(event, newValue) => {
												onTagsChange(newValue)
											}}
											onFocus={() => {
												setListOpen(true)
											}}
											onBlur={() => {
												setListOpen(false)
											}}
											limitTags={1}
											margin='dense'
											renderTags={(value, getTagProps) =>
												(_.isArray(value) ? value : [value]).map((option, index) => (
													<Chip
														variant='outlined'
														size='small'
														label={option}
														{...getTagProps({ index })}
														style={{ margin: listOpen && selectedDS?.length > 1 ? '5px' : '1px' }}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													margin='dense'
													variant='outlined'
													size='small'
													placeholder={
														filterState?.isLoading && _.isEmpty(currentActive) ? 'Loading...' : _.isEmpty(currentActive) ? 'Select Data Source' : ''
													}
													fullWidth
												/>
											)}
											renderOption={(option, { selected }) => (
												<>
													<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
													{option}
												</>
											)}
										/>
									</Grid>
								</Hidden> */}
								<Grid container item md={12} xs={12} lg={12} className={classes.searchWrapper}>
									<Hidden mdUp>
										<Grid item md={8} xs={12}>
											<DisXInpBox
												key={q}
												className={classes.autoComplete}
												inputClassName={classes.searchBox}
												defaultValue={q}
												onChange={onChangeSearchBox}
												textboxRef={searchTxtBoxRef}
											/>
										</Grid>
									</Hidden>
									<Hidden smDown>
										<Grid item md={10} xs={12}>
											<Paper rounded variant='outlined' component='form' className={classes.root}>
												<Grid container alignItems='center' className={classes.paperContainer}>
													<Grid item md={4} xs={12}>
														<Autocomplete
															multiple
															disableCloseOnSelect
															className={classes.dataSourceSelect}
															options={dataSourceOptions ? dataSourceOptions : []}
															value={currentActive}
															loading={dsLoading}
															loadingText='Loading...'
															noOptionsText={dsLoading ? 'Loading...' : 'No Data Sources Found'}
															size='small'
															getOptionLabel={(option) => option}
															getOptionSelected={(option, value) => option === value}
															onChange={(event, newValue) => {
																onTagsChange(newValue)
															}}
															limitTags={1}
															margin='dense'
															ListboxProps={{ className: classes.dsListBox }}
															PaperComponent={({ children }) => <Paper style={{ fontSize: '15px' }}>{children}</Paper>}
															PopperComponent={(props) => <Popper {...props} className={classes.popperComponent} />}
															renderTags={(value, getTagProps) => {
																const numTags = value.length

																return (
																	<Typography variant='body2'>
																		{numTags > 1
																			? ` ${numTags}+ DS selected`
																			: value
																					.slice(0, 1)
																					.map((option, _) => option)
																					.join(', ')}
																	</Typography>
																)
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	margin='dense'
																	variant='outlined'
																	size='small'
																	autoComplete='id'
																	className={classes.dsTextField}
																	placeholder={
																		filterState?.isLoading && _.isEmpty(currentActive)
																			? 'Loading...'
																			: _.isEmpty(currentActive)
																			? 'Select Data Source'
																			: ''
																	}
																	fullWidth
																/>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
																	<div className={classes.dsOption} title={option}>
																		{option}
																	</div>
																</>
															)}
														/>
													</Grid>

													<Divider className={classes.divider} orientation='vertical' />
													<Grid item md={7} xs={12}>
														<DisXInpBox
															key={q}
															className={classes.autoComplete}
															inputClassName={classes.searchBox}
															defaultValue={q}
															onChange={onChangeSearchBox}
															textboxRef={searchTxtBoxRef}
														/>
													</Grid>

													{/* <Divider className={classes.divider} orientation='vertical' /> */}
													{/* {!isSmallDevice && (
												<> */}
													{/* <Grid item md={1} xs={12}>
														<IconButton onClick={onClickSearchBtn} size='small' className={classes.searchBtn}>
															<Icon>search</Icon>
														</IconButton>
													</Grid> */}
												</Grid>
											</Paper>
										</Grid>
									</Hidden>
									<Hidden smDown>
										<Grid item md={2} xs={12} className={classes.stickyWrapper}>
											<Button size='small' color='primary' className={classes.stickyBtn} variant='contained' disableElevation onClick={onStickyClick}>
												<Icon fontSize='small' className={classes.stickyIco}>
													sticky_note_2
												</Icon>
												TakeDown
											</Button>
										</Grid>
									</Hidden>
									{/* </>
											)} */}

									{/* {!isSmallDevice && (
										<>
											<IconButton onClick={onClickSearchBtn} size='small' className={classes.searchBtn}>
												<Icon>search</Icon>
											</IconButton>
											<Button size='small' color='primary' className={classes.stickyBtn} variant='contained' disableElevation onClick={onStickyClick}>
												<Icon fontSize='small' className={classes.stickyIco}>
													sticky_note_2
												</Icon>
												Sticky
											</Button>
										</>
									)} */}
								</Grid>
							</Grid>
							<Hidden mdUp>
								<Grid container alignItems='center'>
									<Grid item md={3} xs={12} className={classes.selectWrapper}>
										<Autocomplete
											multiple
											disableCloseOnSelect
											className={classes.dataSourceSelect}
											options={dataSourceOptions ? dataSourceOptions : []}
											value={currentActive}
											loading={dsLoading}
											loadingText='Loading...'
											noOptionsText={dsLoading ? 'Loading...' : 'No Data Sources Found'}
											size='small'
											getOptionLabel={(option) => option}
											getOptionSelected={(option, value) => option === value}
											onChange={(event, newValue) => {
												onTagsChange(newValue)
											}}
											onFocus={() => {
												setListOpen(true)
											}}
											onBlur={() => {
												setListOpen(false)
											}}
											limitTags={1}
											margin='dense'
											renderTags={(value, getTagProps) =>
												(_.isArray(value) ? value : [value]).map((option, index) => (
													<Chip
														variant='outlined'
														size='small'
														label={option}
														{...getTagProps({ index })}
														style={{ margin: listOpen ? '5px' : '2px' }}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													margin='dense'
													variant='outlined'
													size='small'
													placeholder={
														filterState?.isLoading && _.isEmpty(currentActive) ? 'Loading...' : _.isEmpty(currentActive) ? 'Select Data Source' : ''
													}
													fullWidth
												/>
											)}
											renderOption={(option, { selected }) => (
												<>
													<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
													{option}
												</>
											)}
										/>
									</Grid>
								</Grid>
							</Hidden>
							{!fetching && !isError && !_.isEmpty(results) ? (
								<>
									<Grid item xs={12} className={classes.resultsInfo}>
										<Typography variant='caption' color='textSecondary' gutterBottom>
											About {Number(totalRecords).toLocaleString('en-UK')} results found
										</Typography>
									</Grid>

									<Grid item xs={12} md={9}>
										<ResultsList
											onClickResult={onClickResult}
											onClickCheckbox={onClickCheckbox}
											checkAllSelected={checkAllSelected}
											onAllDataClick={onAllDataClick}
											onClickExport={onClickExport}
											toggle={toggle}
											setToggle={setToggle}
											// getFormattedFiltersForAPI={getFormattedFiltersForAPI}
											query={q}
											filterData={filterState.structure}
											activeFilters={activeFilters}
											allDataSelected={allDataSelected}
											data={results}
											totalData={totalRecords}
											isMobileView={isSmallDevice}
											selectedList={exportList}
											isAllChecked={allChecked}
											onClickWishList={onClickWishList}
											onClickSavedSearch={onClickSavedSearch}
											onClickAnalyse={onClickAnalyse}
											updateFunc={updateSortFuction}
											sortable_fields={sortable_fields}
											sorting_details={sorting_details}
											filters={filterState.structure}
											isFiltersOpen={isFiltersOpen}
											setAllChecked={setAllChecked}
										/>
									</Grid>

									<Grid item xs={12} md={9} container justifyContent='center'>
										<div className={classes.paginationWrapper}>
											<Pagination page={pageNo} onChange={onChangePageNo} count={Math.ceil(totalRecords / limit)} color='primary' />
										</div>
									</Grid>

									<Fab
										variant='extended'
										className={classes.fabSticky}
										disableFocusRipple
										disableRipple
										color='primary'
										size='medium'
										onClick={onStickyClick}
									>
										<Icon fontSize='small' className={classes.stickyIco}>
											sticky_note_2
										</Icon>
										<Typography variant='caption' className={classes.stickyTxt}>
											TakeDown
										</Typography>
									</Fab>
								</>
							) : fetching ? (
								<Grid item xs={12} md={9}>
									<div className={classes.loadingContainer}>
										<LinearProgress color='primary' />
										<span>Loading...</span>
									</div>
								</Grid>
							) : (
								<div className={classes.infoContainer}>
									<Icon color='disabled' className={classes.notFoundIco}>
										{isError ? 'search_off' : 'search'}
									</Icon>
									<Typography color='textSecondary' variant='body2'>
										{isError ? (
											'Something went wrong'
										) : (
											<span
												dangerouslySetInnerHTML={{
													__html: `Your search - <b>${q}</b> - did not match any documents.`,
												}}
											></span>
										)}
									</Typography>
								</div>
							)}
						</Grid>
					</Grid>
				</div>
				<div
					className={classnames(classes.filterSection, {
						[classes.filterSectionOpened]: isFiltersOpen && !isMideDevice,
					})}
				>
					<div
						className={classnames(classes.filterContent, {
							[classes.filterContentVisible]: isFiltersOpen && !isMideDevice,
						})}
					>
						{/* Filter comes here */}
						<Filters
							filterStructure={filterState.structure}
							isLoading={filterState?.isLoading}
							isError={filterState?.isError}
							isOpen={isFiltersOpen}
							isMobileView={isMideDevice}
							secondaryKey={{
								icon: 'tune',
								onClick: () => setIsFilterMasterOpen(true),
							}}
							handleClose={() => setIsFiltersOpen(false)}
							activeFilters={activeFilters}
							updateFunc={updateFuction}
						/>
					</div>
				</div>
				{!isFiltersOpen && (
					<Fab className={classes.fab} color='primary' size='small' onClick={() => setIsFiltersOpen(true)}>
						{!_.isEmpty(activeFilters) && eliminateCommon ? (
							<Badge color='error' badgeContent={''} variant='dot'>
								<Icon fontSize='small'>filter_list</Icon>
							</Badge>
						) : (
							<Icon fontSize='small'>filter_list</Icon>
						)}
					</Fab>
				)}
			</div>

			{isFilterMasterOpen && (
				<FilterMaster
					callBack={setReload}
					isOpen={isFilterMasterOpen}
					handleClose={(hasChanges = false) => {
						setIsFilterMasterOpen(false)
						if (hasChanges) {
							setActiveFilters({})
							getSearchFilters()
						}
					}}
				/>
			)}
			<DiscoverXSlider
				data={discoverDetails}
				open={openSlider}
				onClose={onCloseSlider}
				onclickDashboard={onclickDashboard}
				onStickyClick={onSliderStickyClick}
				isMobileView={isSmallDevice}
				isStickySuccess={isStickySuccess}
			/>
			<ExportDialog
				open={openExportDialog}
				handleClose={toggleExportDialog}
				searchParams={exportSearchParams}
				data={selectedResultsIds}
				isSelectedAll={allDataSelected}
			/>
		</>
	)
}

Discoverx.propTypes = {}

export default compose(withAddStickyDialog)(withDashboardModal(Discoverx))
