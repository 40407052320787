import { Typography, makeStyles, Menu, MenuItem, Icon, IconButton, TextField, Button, CircularProgress, Box } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'
import BoardContext from '../BoardContext'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2, 0, 2),
	},
	laneTitle: {
		display: 'flex',
		alignItems: 'center',
		'& div:first-child': {
			flex: '1',
			display: '-webkit-box',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
			fontWeight: '500',
		},
		'& div': {
			fontWeight: 500,
			padding: '0 2px',
		},
	},
	btnSec: {
		textAlign: 'right',
		'& button': {
			margin: theme.spacing(0.5),
		},
		'& button:last-child': {
			marginRight: 0,
		},
	},
}))

const LaneHeader = (props) => {
	const { pane_name, pane_id, pane_position, is_edit } = props
	const { onClickPaneDelete, onClickPaneUpdate, paneUpdateData, isEditAllowed, isDeleteAllowed, isOwner } = useContext(BoardContext)

	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [renameLane, setRenameLane] = useState(false)
	const [laneTitle, setLaneTitle] = useState(pane_name)
	const { loading, paneId } = paneUpdateData

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleClickRename = () => {
		setRenameLane(true)
		handleClose()
	}

	const onClickCancel = () => {
		setRenameLane(false)
	}

	const onClickRenameBtn = (title) => {
		setRenameLane(false)
		setLaneTitle(title)
		onClickPaneUpdate(pane_id, title, pane_position)
	}

	const handleClickDelete = () => {
		handleClose()
		onClickPaneDelete(pane_id)
	}

	useEffect(() => {
		setLaneTitle(pane_name)
	}, [pane_name])

	return (
		<>
			{renameLane ? (
				<RenameForm classes={classes} title={laneTitle} onClickCancel={onClickCancel} onClickRenameBtn={onClickRenameBtn} />
			) : (
				<div className={classes.root}>
					<Box className={classes.laneTitle}>
						<Typography variant='subtitle1' component='div'>
							{laneTitle}
						</Typography>
						<Typography variant='caption' component='div'>
							({props?.cards?.length})
						</Typography>
					</Box>
					{is_edit && (isDeleteAllowed || isEditAllowed) && isOwner && (
						<div>
							{/* <IconButton size='small' onClick={handleClick}>
								<Icon>more_horiz</Icon>
							</IconButton> */}
							{loading && _.isEqual(paneId, pane_id) ? (
								<CircularProgress size={16} />
							) : (
								<IconButton size='small' onClick={handleClick}>
									<Icon>more_horiz</Icon>
								</IconButton>
							)}

							<Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
								{isEditAllowed && isOwner && <MenuItem onClick={handleClickRename}>Rename</MenuItem>}
								{isDeleteAllowed && isOwner && <MenuItem onClick={handleClickDelete}>Delete</MenuItem>}
							</Menu>
						</div>
					)}
				</div>
			)}
		</>
	)
}

const LaneSchema = Yup.object().shape({
	title: Yup.string().trim().max(60, 'Pane name should not be more than 60 characters').required('Please enter the Pane name'),
})

const RenameForm = ({ classes, onClickCancel, onClickRenameBtn, title }) => {
	const formik = useFormik({
		initialValues: {
			title,
		},
		onSubmit: ({ title }) => {
			onClickRenameBtn(title)
		},
		validationSchema: LaneSchema,
	})

	return (
		<div className={classes.formRoot}>
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					variant='outlined'
					value={formik.values.title}
					error={Boolean(formik.errors.title)}
					helperText={formik.errors?.title}
					onChange={(e) => formik.setFieldValue('title', e.target.value)}
					label='Enter pane name'
					margin='dense'
				/>
				<div className={classes.btnSec}>
					<Button variant='contained' size='small' type='submit' color='primary' disableElevation>
						Save
					</Button>
					<Button variant='outlined' color='primary' size='small' onClick={onClickCancel} disableElevation>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	)
}

export default LaneHeader
