import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	forgotPassword: (data) => {
		return Axios.post(API_ENDPOINTS.FORGOT_PASSWORD, data, { headers: { 'Content-Type': 'application/json' } })
	},
	setPassword: (data) => {
		return Axios.post(API_ENDPOINTS.SET_PASSWORD, data, { headers: { 'Content-Type': 'application/json' } })
	},
}
