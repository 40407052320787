import React, { useMemo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import { Typography, Button, Chip } from '@material-ui/core'
import _ from 'lodash'
import TeamsTreeView from 'components/TeamsTreeView'
import UserApi from 'services/users/api'
import LoadingIndicator from 'components/LoadingIndicator'
import update from 'immutability-helper'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
	},
	roleViewRoot: {
		textAlign: 'center',
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	fieldLabel: {
		display: 'block',
	},
	btnClear: {
		marginTop: theme.spacing(1),
	},
}))

const getTeamData = (teamArry) => {
	const groupByTeam = _.groupBy(teamArry, 'parent_tu_code')
	const formNestedLvlData = (arryToFil) => {
		const result = _.reduce(
			arryToFil,
			(arry, team) => {
				const reportingTeams = _.get(groupByTeam, _.get(team, 'tu_code'))
				arry.push({
					id: _.get(team, 'tu_code'),
					name: _.get(team, 'tu_name'),
					children: _.isEmpty(reportingTeams) ? [] : formNestedLvlData(reportingTeams),
				})
				return arry
			},
			[]
		)
		return result
	}
	return formNestedLvlData(groupByTeam[null])
}

const findParentTuCode = (teamsArry, tuCode, resultArry) => {
	const parentTeamCode = _.get(_.find(teamsArry, { tu_code: tuCode }), 'parent_tu_code')
	if (_.isEmpty(parentTeamCode)) return resultArry
	else {
		resultArry.push(parentTeamCode)
		return findParentTuCode(teamsArry, parentTeamCode, resultArry)
	}
}

const TeamUnit = ({ isCreateMode, teamDetails, isViewMode }) => {
	const classes = useStyles()
	const [teamState, setTeamState] = useState({ loading: true, teams: [] })
	const { loading, teams } = teamState

	useEffect(() => {
		if (isCreateMode && loading)
			UserApi.fetchAllUserTeamUnits().then(
				(resp) => {
					setTeamState({
						loading: false,
						teams: _.get(resp, 'data.data.team_units', []),
					})
				},
				(err) => {
					console.log('error', err)
					setTeamState({ loading: false, teams: [] })
				}
			)
	}, [loading, isCreateMode])

	const teamData = useMemo(() => getTeamData(teams), [teams])

	const getDefExpanded = useCallback(
		(values) => {
			return _.reduce(
				values,
				(expandedArry, teamCode) => {
					return findParentTuCode(teams, teamCode, expandedArry)
				},
				[]
			)
		},
		[teams]
	)

	const getAssignedTeamUnitChips = (teamDetails) => {
		if (_.isEmpty(teamDetails)) {
			return <Typography variant='caption'>No Team Units Assigned.</Typography>
		}
		return _.map(teamDetails, (team) => (
			<Chip key={_.get(team, 'tu_code')} className={classes.chip} label={_.startCase(_.get(team, 'tu_name'))} color='primary' />
		))
	}

	return (
		<div className={classes.root}>
			<Field type='text' name='tu_code'>
				{({ field }) =>
					isViewMode ? (
						<div className={classes.roleViewRoot}>{getAssignedTeamUnitChips(teamDetails)}</div>
					) : loading ? (
						<LoadingIndicator />
					) : (
						<>
							<Typography className={classes.fieldLabel} variant='caption' color='textSecondary' gutterBottom>
								Choose the team unit to assign this user
							</Typography>
							<TeamsTreeView
								data={teamData}
								makeInput={true}
								defaultExpanded={getDefExpanded(field.value)}
								name={field.name}
								onChange={(e) => {
									const opToPerform = e.target.checked
										? { $push: [e.target.value] }
										: {
												$set: _.filter(field.value, (val) => !_.isEqual(val, e.target.value)),
										  }
									field.onChange({
										target: {
											name: field.name,
											value: update(field.value || [], opToPerform),
										},
									})
								}}
								value={field.value}
							/>
							<Button
								size='small'
								onClick={() => {
									field.onChange({ target: { name: field.name, value: [] } })
								}}
								className={classes.btnClear}
							>
								Reset
							</Button>
						</>
					)
				}
			</Field>
		</div>
	)
}

TeamUnit.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
}

export default TeamUnit
