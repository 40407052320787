import React from 'react'
import _ from 'lodash'
import { makeStyles, Typography } from '@material-ui/core'
import { isValid } from 'date-fns'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: theme.spacing(1),
	},
	container: {
		borderRadius: theme.spacing(0.5),
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.almostBlack[400]}`,
		overflow: 'hidden',
		'&:hover': {
			transition: `border 250ms, box-shadow 250ms`,
			border: `1px solid ${theme.palette.almostBlack[400]}`,
			boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		},
	},
	imageSection: {
		width: '100%',
		height: 150,
		'& img': {
			objectFit: 'cover',
			width: '100%',
			height: '100%',
		},
	},
	mainSection: {
		padding: theme.spacing(1.5),
	},
	labelSection: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	label: {
		width: 27,
		height: 4,
		borderRadius: 2,
		margin: theme.spacing(0.5, 0.5, 0.5, 0),
		backgroundColor: 'grey',
	},
	title: {
		fontSize: theme.typography.pxToRem(16),
		lineHeight: theme.typography.pxToRem(22),
		fontWeight: 500,
		paddingTop: theme.spacing(0.5),
	},
	flexBox: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(0.5),
	},
	avatar: {
		width: 30,
		height: 30,
		fontSize: 13,
		lineHeight: 'unset',
		marginRight: theme.spacing(0.5),
	},
	dueSection: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		color: `${theme.palette.almostBlack[700]}d6`,
		marginTop: theme.spacing(0.5),
		'& .MuiIcon-root': {
			width: 20,
			height: 20,
			lineHeight: `20px`,
			fontSize: 22,
			textAlign: 'left',
			marginRight: 4,
		},
		'& .MuiTypography-root': {
			letterSpacing: `0px`,
			fontSize: theme.typography.pxToRem(14),
			overflowX: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			lineHeight: `19px`,
		},
	},
	assigneeSection: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		flex: 1,
		borderTop: `1px solid ${theme.palette.almostBlack[200]}`,
		padding: theme.spacing(1.5),
		color: `${theme.palette.almostBlack[700]}f7`,
		'& .MuiIcon-root': {
			width: 20,
			height: 20,
			lineHeight: `20px`,
			fontSize: 18,
			textAlign: 'left',
			marginRight: 4,
		},
		'& .MuiTypography-root': {
			letterSpacing: `0px`,
			fontSize: theme.typography.pxToRem(14),
			overflowX: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			fontWeight: 400,
		},
	},
}))

const GlassCard = (props = {}) => {
	const classes = useStyles()
	const { assignee } = props

	return (
		<div className={classes.wrapper}>
			<div className={classes.container} role='button' onClick={props?.onClick}>
				<div className={classes.mainSection}>
					<div className={classes.labelSection}>
						{_.get(props, 'labels', []).map((o, i) => (
							<Skeleton key={`skeleton_label_${i}_${props?.laneId}_${props?.id}`} className={classes.label} />
						))}
					</div>
					<Typography variant='subtitle1' className={classes.title}>
						<Skeleton />
					</Typography>
					<div className={classes.flexBox}>
						{isValid(_.isString(_.get(props, 'dueDate')) ? new Date(_.get(props, 'dueDate')) : _.get(props, 'dueDate')) && (
							<div className={classes.dueSection}>
								<Skeleton style={{ width: '40%', height: 32 }} />
							</div>
						)}
					</div>
				</div>
				{_.isEmpty(_.get(assignee, 'first_name')) ? null : (
					<div className={classes.assigneeSection}>
						<Skeleton className={classes.avatar} variant='circle' />
						<Skeleton style={{ width: '50%', height: 32 }} />
					</div>
				)}
			</div>
		</div>
	)
}

export default GlassCard
