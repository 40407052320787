import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Grid, Icon, IconButton, makeStyles, Paper, Typography, withWidth } from '@material-ui/core'
import _ from 'lodash'

SettingsList.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			color: PropTypes.string,
			isEdit: PropTypes.bool,
			position: PropTypes.number,
		})
	),
	onClickAction: PropTypes.func.isRequired,
	hideActionMenu: PropTypes.bool.isRequired,
}

const useStyles = makeStyles((theme) => ({
	item: {
		padding: theme.spacing(1, 1),
		display: 'flex',
		alignItems: 'center',
		height: '24px',
	},
	square: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginRight: theme.spacing(1),
		'& svg': {
			display: 'none',
		},
	},
	text: {
		flexGrow: 1,
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
}))

const ListItem = ({ hideActionMenu, data, classes, onClickAction }) => {
	return (
		<Grid item xs={12} sm={4} lg={3}>
			<Paper variant='outlined' className={classes.item}>
				{!_.isEmpty(data?.color) && <Avatar style={{ backgroundColor: data.color }} variant='square' className={classes.square} />}
				<Typography title={data?.name} variant='body2' className={classes.text}>
					{data?.name}
				</Typography>
				{!hideActionMenu && !_.isEqual(data?.isEdit, false) && (
					<IconButton onClick={(e) => onClickAction(e, data)} title='actions' size='small'>
						<Icon fontSize='small'>more_horiz</Icon>
					</IconButton>
				)}
			</Paper>
		</Grid>
	)
}

function SettingsList({ hideActionMenu, data, width, className, onClickAction }) {
	const classes = useStyles()

	return (
		<Grid className={className} container spacing={_.isEqual(width, 'xs') ? 1 : 2}>
			{_.map(_.sortBy(data, ['position']), (item, index) => (
				<ListItem hideActionMenu={hideActionMenu} onClickAction={onClickAction} data={item} classes={classes} key={item?.id} />
			))}
		</Grid>
	)
}

export default withWidth()(SettingsList)
