import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid, TextField } from '@material-ui/core'
import * as Yup from 'yup'

const ActivitySchema = Yup.object().shape({
	activity_name: Yup.string()
		//   .min(2, 'Too Short!')
		//   .max(50, 'Too Long!')
		.required('Please enter the activity name'),
})

const ActivityForm = React.forwardRef(({ onSubmit, initialValues }, ref) => {
	const formik = useFormik({
		initialValues: initialValues || {
			activity_name: '',
			activity_id: '',
		},
		onSubmit: onSubmit,
		validationSchema: ActivitySchema,
	})

	useEffect(() => {
		ref.current = formik
		return () => {
			ref.current = null
		}
	}, [ref, formik])

	return (
		<form ref={ref} onSubmit={formik.handleSubmit}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField
						name='activity_name'
						value={formik.values.activity_name}
						error={Boolean(formik.errors.activity_name)}
						helperText={formik.errors?.activity_name}
						onChange={formik.handleChange}
						label='Activity Name*'
						id='activity-name'
						variant='outlined'
						size='small'
						fullWidth
					/>
				</Grid>
			</Grid>
		</form>
	)
})

ActivityForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	initialValues: PropTypes.shape({
		activity_id: PropTypes.string,
		activity_name: PropTypes.string,
	}),
}

export default ActivityForm
