import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { cancelled } from 'redux-saga/effects'
import api from '../api'
import Axios from 'axios'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import { setUserDashboard } from '../actions'
import { getErrMsg, getSuccessMsg } from 'utils'

function* fetchProfile(action) {
	const cancelSource = Axios.CancelToken.source()
	yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_PENDING })
	try {
		const resp = yield call(api.fetchProfile, cancelSource)
		yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_FULFILLED, payload: resp })
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_REJECTED, payload: err })
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchProfileWatcher() {
	yield takeLatest(SAGA_ACTIONS.FETCH_PROFILE, fetchProfile)
}

function* changeCurrentUserPwd(action) {
	yield put(showLoader('Please wait changing password...'))
	const { formData, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.changeCurrentUserPassword, formData)
		yield put(hideLoader())
		yield call(successCallback, resp)
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* changeCurrentUserPwdWatcher() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_PASSWORD, changeCurrentUserPwd)
}

function* updateUserHomeDashboard(action) {
	yield put(showLoader('Updating Home Settings...'))
	try {
		const resp = yield call(api.setUserHomeDashboard, action.dashboardId, action.config_landing)
		yield put(showSuccessMessage(getSuccessMsg(resp, 'Configuration Settings Updated Successfully!'), 'Close', action.dialogCloseBtnCallback))
		yield put(setUserDashboard(action.dashboardId, action.config_landing))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', action.dialogCloseBtnCallback))
	}
}

function* setUserHomePageDashboard() {
	yield takeLatest(SAGA_ACTIONS.SET_USER_HOMEPAGE_DASHBOARD, updateUserHomeDashboard)
}

const sagaGenerators = [fetchProfileWatcher(), changeCurrentUserPwdWatcher(), setUserHomePageDashboard()]

export default sagaGenerators
