import React, { useState, useEffect } from 'react'
import {
	Table,
	TableContainer,
	Paper,
	IconButton,
	TableBody,
	makeStyles,
	Checkbox,
	Typography,
	Grid,
	Button,
	Icon,
	MenuItem,
	Select,
	CircularProgress,
	TextField,
	Switch,
	Grow,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TableCell from './CustomTableCell'
import TableRow from './CustomTableRow'
import TableHeaders from './TableHeaders'
import _ from 'lodash'
import classnames from 'classnames'
import { NoRecordsIllustration } from '../../illustrators'
import { ACTION_COL_OPTIONS, EDIT_COMPONENTS } from '../../constants'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const useStyles = makeStyles((theme) => ({
	noPadding: {
		'& $muiTableContainerRoot': {
			padding: 0,
		},
		'& $paginationContainer': {
			padding: theme.spacing(2, 0),
			// borderTop: `none`,
		},
	},
	paper: {
		// padding: theme.spacing(1),
		boxSizing: 'border-box',
		// borderRadius: 20,
		paddingTop: 20,
		padding: theme.spacing(1),
		'&$noPadding': {
			padding: theme.spacing(1, 0, 0),
		},
		'& .MuiOutlinedInput-root': {
			marginLeft: '12px',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			// borderRadius: '8px',
			border: `1px solid ${theme.palette.almostBlack[300]}`,
		},
		'& .MuiOutlinedInput-root:hover': {
			'&  .MuiOutlinedInput-notchedOutline': {
				// borderRadius: '8px',
				border: `1px solid ${theme.palette.almostBlack[300]}`,
			},
			'& .MuiSelect-root': {
				background: `rgba(0,0,0, 0.04)`,
				transition: `background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
			},
		},
	},
	muiTableContainerRoot: {
		padding: theme.spacing(1),
		'&$noPadding': {
			padding: theme.spacing(0),
		},
		boxSizing: 'border-box',
		// borderRadius: '20px',
		paddingTop: theme.spacing(0),
		// maxHeight: `calc(100vh - 300px)`,
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '10px',
			background: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
		},
	},
	muiTableRoot: {
		borderCollapse: 'separate',
		tableLayout: 'auto',
	},
	tableLayoutFixed: {
		tableLayout: 'fixed',
	},
	muiTableHeadRoot: {
		height: '20px',
	},
	muiTableRowRoot: {
		height: 'unset',
	},
	muiTableRowHead: {
		height: '38px',
		textTransform: 'uppercase',
	},
	imageDiv: {
		width: '50px',
		minWidth: '50px',
		height: '50px',
		maxHeight: '50px',
		border: `1px solid ${theme.palette.almostBlack[300]}`,
		marginRight: theme.spacing(2),
		borderRadius: '5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		overflow: 'hidden',
		'& img': {
			width: '100%',
			height: '100%',
			borderRadius: '5px',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			fontSize: '1rem',
		},
		'&:hover $eyeIconContainer': {
			// width: '100%',
			// height: '100%',
			// display: 'flex',
			// color: theme.palette.almostBlack[300],
			// transition: 'width 0.3s'
			right: 0,
			transition: 'right 0.3s',
		},
	},
	eyeIconContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: `${theme.palette.almostBlack[500]}cc`,
		// color: 'transparent',
		color: theme.palette.almostBlack[0],
		position: 'absolute',
		top: 0,
		right: '-100%',
		transition: 'right 0.3s',
	},
	isLargeImage: {
		width: '140px',
		minWidth: '140px',
		height: '140px',
		maxHeight: '140px',
	},
	cellWithImageDiv: {
		display: 'flex',
		alignItems: 'center',
		// paddingLeft:theme.spacing(1),
		paddingRight: theme.spacing(1),
		// flexDirection: 'column-reverse'
	},
	textInImageWithTextContainer: {
		// width: '100%',
		overflow: 'hidden',
	},
	flagCircle: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		display: 'inline-block',
		marginRight: theme.spacing(1),
		borderRadius: '50%',
		background: theme.palette.warning.light,
	},
	active: {
		background: theme.palette.success.main,
	},
	deActive: {
		background: theme.palette.error.main,
	},
	overflowTextDots: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	loadingIndicatorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
	tableBody2: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.1),
	},
	muiIconButtonRoot: {
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		// borderRadius: `8px`,
		padding: `1px`,
		margin: '0px 8px',
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[600],
			fontSize: `1.75rem`,
		},
	},
	muiIconButtonDisabled: {
		// border: `1px solid ${theme.palette.almostBlack[200]}`,
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[400],
			fontSize: `1.75rem`,
		},
	},
	muiButtonRoot: {
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(14),
		borderRadius: '50%',
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		fontWeight: '500',
		margin: '0px 8px',
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.active': {
			color: theme.palette.almostBlack[0],
			background: theme.palette.primary.main,
		},
	},
	threeDotsDiv: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	muiSelectOutlined: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		padding: `7px`,
		paddingRight: `${theme.spacing(4.5)}px !important`,
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		'&.MuiSelect-select:focus': {
			borderRadius: '4px',
		},
		borderRadius: `4px`,
		'&::after': {
			content: '""',
			position: 'absolute',
			height: '100%',
			width: 1,
			background: theme.palette.almostBlack[300],
			top: '0px',
			right: '26px',
		},
	},
	muiSelectIconOutlined: {
		right: `3px`,
	},
	pageLinksContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
	},
	viewSummaryContainer: {
		display: 'flex',
		// justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		letterSpacing: theme.typography.pxToRem(0.1),
		lineHeight: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
	},
	paginationContainer: {
		borderTop: `1px solid ${theme.palette.almostBlack[200]}`,
		padding: `${theme.spacing(4)}px 8px`,
	},
	tableRowBorderBottom: {
		'& th:first-child, td:first-child': {
			borderRadius: '0px 0px 0px 0px !important',
			borderLeft: `1px solid transparent`,
		},
		'& th:first-child:not(.MuiTableCell-paddingCheckbox), td:first-child:not(.MuiTableCell-paddingCheckbox)': {
			paddingLeft: theme.spacing(2),
		},
		'& td:last-child': {
			borderRadius: '0px 0px 0px 0px !important',
			borderRight: `1px solid transparent`,
		},
		'& td': {
			borderTop: `1px solid transparent`,
			borderBottom: `1px solid ${theme.palette.almostBlack[200]} !important`,
		},
	},
	searchContainer: {
		'&$noPadding': {
			padding: theme.spacing(1, 0, 2),
		},
		padding: theme.spacing(1, 1.5, 3),
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 3,
		},
		'& .MuiOutlinedInput-root': {
			marginLeft: '0px !important',
			height: 40,
			'&:not(:hover).Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: `2px solid ${theme.palette.primary.main} !important`,
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				border: `2px solid ${theme.palette.primary.main} !important`,
			},
			'& .MuiIcon-root': {
				fontSize: theme.typography.pxToRem(18),
				color: theme.palette.almostBlack[300],
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(1.5, 1.5),
			fontFamily: ['"Poppins"', '"Robotot"', 'sans-serif'].join(','),
			fontSize: theme.typography.pxToRem(14),
			fontWeight: '400',
			lineHeight: theme.typography.pxToRem(14),
		},
	},
	pageInput: {
		// color: theme.palette.almostBlack[300],
		// border: `1px solid ${theme.palette.almostBlack[300]} !important`,
		// borderRadius: '2px 2px 2px 2px !important',
		width: 50,
		paddingLeft: 10,
		// '& .MuiInputBase-root': {
		// 	'&:hover': {
		// 		'& .MuiInput-root': {
		// 			'& .MuiInput-underline': {
		// 				border: `2px solid ${theme.palette.primary.main} !important`,
		// 			},
		// 		},
		// 	},
		// },
		// height: 20,
		// '&:hover': {
		// 	border: `1px solid ${theme.palette.primary.main} !important`,
		// },
		// '&:focus': {
		// 	border: `1px solid ${theme.palette.primary.main} !important`,
		// },
		// '&:active': {
		// 	border: `1px solid ${theme.palette.primary.main} !important`,
		// },
	},
	searchButton: {
		minWidth: 'auto',
		'&.MuiButton-containedSizeSmall': {
			padding: theme.spacing(1, 1.25),
		},
		'& .MuiButton-startIcon': {
			marginRight: 0,
		},
	},
	disabledRow: {
		opacity: 0.3,
	},
	selectedSummaryInfoRow: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
	},
	selectionInfoContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.almostBlack[100],
		// borderRadius: 5
	},
	selectionSummarySpan: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		cursor: 'pointer',
		// borderRadius: 5,
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
		},
	},
	groupedRowTitleRow: {
		background: `${theme.palette.almostBlack[600]}1a`,
		'& td:only-child': {
			// borderRadius: '10px',
			height: '40px',
			paddingRight: theme.spacing(1),
			'& .MuiTypography-h6': {
				fontSize: theme.typography.pxToRem(14),
			},
			'& .MuiTypography-subtitle1': {
				fontSize: theme.typography.pxToRem(12),
			},
			'& .MuiTypography-subtitle2': {
				fontSize: theme.typography.pxToRem(10),
				letterSpacing: theme.typography.pxToRem(2),
				fontWeight: 700,
			},
		},
	},
	openedRowTitle: {
		'& td:only-child': {
			// borderRadius: '10px 10px 0px 0px'
		},
	},
	groupedRow: {
		// background: theme.palette.almostBlack[200],
		'& td': {
			borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		},
		'& td:first-child': {
			// borderRadius: `0px`,
			borderLeft: `1px solid ${theme.palette.almostBlack[200]}`,
		},
		'& td:last-child': {
			// borderRadius: `0px`,
			borderRight: `1px solid ${theme.palette.almostBlack[200]}`,
		},
		'&$tableRowBorderBottom': {
			'& th:first-child, td:first-child': {
				borderRadius: '0px 0px 0px 0px !important',
				borderLeft: `1px solid transparent`,
			},
			'& th:first-child:not(.MuiTableCell-paddingCheckbox), td:first-child:not(.MuiTableCell-paddingCheckbox)': {
				paddingLeft: theme.spacing(2),
			},
			'& td:last-child': {
				borderRadius: '0px 0px 0px 0px !important',
				borderRight: `1px solid transparent`,
			},
			'& td': {
				borderTop: `1px solid transparent`,
				borderBottom: `1px solid ${theme.palette.almostBlack[200]} !important`,
			},
		},
	},
	groupedRowSubTitle: {
		color: theme.palette.almostBlack[700],
		fontFamily: '"Roboto"',
	},
	lastInGroupedRow: {
		// background: theme.palette.almostBlack[200],
		'& th:first-child, td:first-child': {
			// borderRadius: '0px 0 0 10px',
			borderLeft: `1px solid ${theme.palette.almostBlack[200]}`,
		},
		'& th:first-child:not(.MuiTableCell-paddingCheckbox), td:first-child:not(.MuiTableCell-paddingCheckbox)': {
			paddingLeft: theme.spacing(2),
		},
		'& td:last-child': {
			// borderRadius: '0 0px 10px 0',
			borderRight: `1px solid ${theme.palette.almostBlack[200]}`,
		},
		'& td': {
			borderTop: `1px solid transparent`,
			borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		},
	},
	dividerForGroupedRows: {
		height: '10px',
		'& td': {
			height: '10px',
		},
	},
}))

const ProductTable = (props) => {
	const {
		tableKey,
		goToPage,
		page,
		paginationFooterDetails,
		isSquarePaper = true,
		elevation = 0,
		fixedLayout,
		headers,
		data,
		wholeData,
		limit,
		handleCellEdit,
		setPageLimit,
		isLoading,
		isCheckBoxNeeded,
		keyForCheckBoxSelectedList,
		keyForCheckBoxDisabling,
		onCheckBoxClick,
		handleCheckBoxClick,
		handleAllSelectCheckBoxClick,
		exportList,
		enableBorderBottom,
		bodyScroll,
		maxHeight,
		onClickRow,
		sortSettings,
		handleSearchInputChange,
		searchQuery,
		keyForEditToggle,
		clearSelection,
		handleSelectAllProducts,
		groupDataBy,
		fxnToFormatGroupByKey,
	} = props

	const classes = useStyles()

	const [isEditModeOn, setIsEditModeOn] = useState(false)

	useEffect(() => {
		if (!_.isEmpty(wholeData) && isEditModeOn) {
			setIsEditModeOn(false)
		}
	}, [wholeData, isEditModeOn])

	useEffect(() => {
		setOpenedGroups([])
	}, [data])

	const checkForCheckBox = () => {
		if (isCheckBoxNeeded === true) {
			return [
				{
					name: '',
					isCheck: true,
					isEditEnabled: true,
					align: 'left',
					allCheckFxn: handleAllSelectCheckBoxClick,
					checkFxn: handleCheckBoxClick,
					selectedList: exportList,
					total: _.get(paginationFooterDetails, 'total', 0),
				},
				...headers,
			]
		} else {
			return [...headers]
		}
	}

	const modifiedHeaders = checkForCheckBox()

	const [rowToEdit, setRowToEdit] = useState('')
	const [recordToEdit, setRecordToEdit] = useState({})

	const [openedGroups, setOpenedGroups] = useState([])

	const [toUpdateObj, setToUpdateObj] = useState({})

	const disEngageEditMode = () => {
		setRowToEdit('')
		setIsEditModeOn(false)
		setRecordToEdit({})
	}

	useEffect(() => {
		setRowToEdit(_.get(recordToEdit, keyForEditToggle))
	}, [recordToEdit, keyForEditToggle])

	const NoRecordsFound = () => {
		return (
			<TableRow>
				<TableCell colSpan={modifiedHeaders.length}>
					<div className={classes.infoContainer}>
						<NoRecordsIllustration className={classes.noRecordsIllustration} />
						<Typography variant='body1'>No Records Found</Typography>
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const LoadingIndicator = () => {
		return (
			<TableRow>
				<TableCell colSpan={modifiedHeaders.length}>
					<div className={classes.loadingIndicatorContainer}>
						<CircularProgress size={30} />
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const navigateToNextPage = () => {
		goToPage(parseInt(page) + 1)
	}

	const navigateToPrevPage = () => {
		goToPage(parseInt(page) - 1)
	}

	const navigateToLastPage = () => {
		goToPage(parseInt(_.last(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const navigateToFirstPage = () => {
		goToPage(parseInt(_.first(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const handleOpeningGroupedRows = (key) => {
		//  console.log("group opening fxn", key, openedGroups)
		if (_.indexOf(openedGroups, key) !== -1) {
			let tempList = [...openedGroups]
			_.remove(tempList, (iteratedKey) => iteratedKey === key)
			setOpenedGroups([...tempList])
		} else {
			setOpenedGroups([...openedGroups, key])
		}
	}

	const getTableRows = (data) => {
		if (_.isEmpty(data)) {
			if (isLoading) {
				return <LoadingIndicator />
			} else {
				return <NoRecordsFound />
			}
		}

		const renderTableRow = (isGrouped, isLastInGroup, record, recordIdx, isThisRowOnEdit) => {
			const isSelected = _.indexOf(exportList, record[keyForCheckBoxSelectedList]) !== -1 || _.indexOf(exportList, 'all') === 0
			const isDisabledRow = _.isNil(isThisRowOnEdit) ? false : !isThisRowOnEdit

			return (
				<Grow
					key={recordIdx}
					in={_.isNil(isThisRowOnEdit) ? true : !isThisRowOnEdit}
					timeout={100 * recordIdx}
					style={{ transformOrigin: 'center top' }}
				>
					<TableRow
						hover
						selected={isSelected}
						style={{ width: '100%' }}
						onClick={_.isNil(onClickRow) ? null : isDisabledRow ? null : (e) => onClickRow(e, record, recordIdx)}
						role='checkbox'
						className={classnames({
							[classes.tableRowBorderBottom]: enableBorderBottom,
							[classes.disabledRow]: isDisabledRow,
							[classes.groupedRow]: isGrouped,
							[classes.lastInGroupedRow]: isLastInGroup,
						})}
					>
						{modifiedHeaders.map((header, idx) => (
							<TableCell
								key={idx}
								style={
									_.isNil(header.style)
										? {
												...(_.isEmpty(header.width) ? {} : { width: header.width, maxWidth: header.width }),
										  }
										: {
												...header.style,
												...(_.isEmpty(header.width) ? {} : { width: header.width, maxWidth: header.width }),
										  }
								}
								align={_.get(header, 'align', 'left')}
								{...(header.isImage || header.isActionCol ? { padding: 'none' } : header.isCheck ? { padding: 'checkbox' } : {})}
							>
								{renderCellContent(header, record, isDisabledRow)}
							</TableCell>
						))}
					</TableRow>
				</Grow>
			)
		}

		const groupedData =
			data &&
			!_.isEmpty(data) &&
			data.reduce((acc, row, index) => {
				let key = fxnToFormatGroupByKey && fxnToFormatGroupByKey(row[groupDataBy])
				let groupedRows = acc[key] || []
				acc[key] = groupedRows.concat([row])
				// if(index===0){
				//     if(_.isEmpty(openedGroups)){
				//         console.log("key set for opening Fxn", key)
				//         let tempArr = [key, 'alreadyOpened']
				//         setOpenedGroups(tempArr)
				//     }
				// }
				return acc
			}, {})

		const renderRowBasedOnEditMode = (record, recordIdx, isGrouped, isLastInGroup) => {
			if (isEditModeOn && rowToEdit === _.get(record, keyForEditToggle, '-')) {
				return renderTableRow(isGrouped, isLastInGroup, record, recordIdx, true)
			}
			return renderTableRow(isGrouped, isLastInGroup, record, recordIdx)
		}

		const renderGroupedRows = () => {
			if (_.isEmpty(openedGroups)) {
				// let tempArr = [Object.keys(groupedData)[0], 'alreadyOpened'] Enable to open only the first group
				let tempArr = [...Object.keys(groupedData), 'alreadyOpened']
				setOpenedGroups(tempArr)
			}

			return Object.keys(groupedData).map((key, idx) => {
				function groupedRowLabelMaker(rowKey) {
					let total = groupedData[rowKey].length
					let selectedInThisGroup = groupedData[rowKey].reduce((count, currRecord) => {
						if (_.includes(exportList, currRecord[keyForCheckBoxSelectedList])) {
							return count + 1
						} else {
							return count
						}
					}, 0)
					return ` (${
						selectedInThisGroup === 0
							? 'Showing ' + total + ' Item' + (total === 1 ? '' : 's')
							: selectedInThisGroup + ' of ' + total + ' shown item' + (total === 1 ? '' : 's') + ' selected'
					})`
				}
				return (
					<React.Fragment key={key + `${idx}`}>
						<TableRow className={classes.dividerForGroupedRows}>
							<TableCell colSpan={modifiedHeaders.length}>
								<div></div>
							</TableCell>
						</TableRow>
						{openedGroups.indexOf(key) === -1 ? (
							<TableRow
								onClick={() => handleOpeningGroupedRows(key)}
								className={classnames(classes.groupedRowTitleRow, {
									[classes.tableRowBorderBottom]: enableBorderBottom,
								})}
							>
								<TableCell colSpan={modifiedHeaders.length}>
									<Grid item container justifyContent='space-between' alignItems='center'>
										<Typography variant='subtitle2'>
											{key}
											<Typography variant='subtitle2' component='span' className={classes.groupedRowSubTitle}>
												{groupedRowLabelMaker(key)}
											</Typography>
										</Typography>
										<IconButton size='small'>
											<Icon>expand_more</Icon>
										</IconButton>
									</Grid>
								</TableCell>
							</TableRow>
						) : (
							<>
								<TableRow
									onClick={() => handleOpeningGroupedRows(key)}
									className={classnames(classes.groupedRowTitleRow, classes.openedRowTitle, { [classes.tableRowBorderBottom]: enableBorderBottom })}
								>
									<TableCell colSpan={modifiedHeaders.length}>
										<Grid item container justifyContent='space-between' alignItems='center'>
											<Typography variant='subtitle2'>
												{key}
												<Typography variant='subtitle2' component='span' className={classes.groupedRowSubTitle}>
													{groupedRowLabelMaker(key)}
												</Typography>
											</Typography>
											<IconButton size='small'>
												<Icon>expand_less</Icon>
											</IconButton>
										</Grid>
									</TableCell>
								</TableRow>
								{groupedData[key].map((row, rowIdx) => renderRowBasedOnEditMode(row, rowIdx, true, rowIdx === groupedData[key].length - 1))}
							</>
						)}
						{Object.keys(groupedData).length === idx + 1 ? (
							<TableRow className={classes.dividerForGroupedRows}>
								<TableCell colSpan={modifiedHeaders.length}>
									<div></div>
								</TableCell>
							</TableRow>
						) : null}
					</React.Fragment>
				)
			})
		}

		const renderRows = () => data.map((record, recordIdx) => renderRowBasedOnEditMode(record, recordIdx))

		return groupDataBy && fxnToFormatGroupByKey && _.get(data[0], groupDataBy, '-') !== '-' ? renderGroupedRows() : renderRows()
	}

	const renderCellContent = (cellProperties, record, isDisabledRow) => {
		const backUpForEmpty = _.get(cellProperties, 'isEmpty', false) === true ? '' : '-'
		const data = _.get(record, cellProperties.dataKey, backUpForEmpty)
		const isCellOnEditMode =
			isEditModeOn &&
			_.get(cellProperties, 'isEditEnabled', false) &&
			_.get(cellProperties, 'editComponent', false) &&
			rowToEdit === _.get(record, keyForEditToggle)

		if (isCellOnEditMode) {
			const EditComponent = () => {
				const [tempRecord, setTempRecord] = useState(Object.assign({}, recordToEdit))

				const handleBlur = () => {
					if (_.get(tempRecord, cellProperties.dataKey, '-') !== _.get(recordToEdit, cellProperties.dataKey, '-')) {
						setToUpdateObj(
							Object.assign({}, toUpdateObj, {
								[cellProperties.dataKey]: _.get(tempRecord, cellProperties.dataKey, '-'),
							})
						)
						// handleCellEdit({[cellProperties.dataKey]: _.get(tempRecord, cellProperties.dataKey, '-')});
						setRecordToEdit(Object.assign({}, tempRecord))
					}
				}

				const handleChange = (value) => {
					setTempRecord(Object.assign({}, tempRecord, { [cellProperties.dataKey]: value }))
					// setToUpdateObj(Object.assign({}, toUpdateObj, {[cellProperties.dataKey]: value}));
				}

				const renderEditComponent = () => {
					switch (_.get(cellProperties, 'editComponent', '-')) {
						case EDIT_COMPONENTS.TEXTFIELD: {
							return (
								<TextField
									size='small'
									value={_.get(tempRecord, cellProperties.dataKey, '')}
									onBlur={handleBlur}
									onChange={(e) => handleChange(e.target.value)}
								/>
							)
						}

						case EDIT_COMPONENTS.NUMBER_FIELD: {
							return (
								<TextField
									size='small'
									type='number'
									value={_.get(tempRecord, cellProperties.dataKey, '')}
									onBlur={handleBlur}
									onChange={(e) => handleChange(parseFloat(e.target.value))}
								/>
							)
						}

						case EDIT_COMPONENTS.SWITCH: {
							return (
								<Switch
									checked={!_.get(tempRecord, cellProperties.dataKey)}
									onChange={(e) => handleChange(!_.get(tempRecord, cellProperties.dataKey))}
									onBlur={handleBlur}
								/>
							)
						}

						case EDIT_COMPONENTS.SELECT: {
							const renderSelectOptions = () => {
								return _.get(cellProperties, 'selectOptions', []).map((option, index) => {
									return (
										<MenuItem key={option.text + index} value={option.value}>
											{option.text}
										</MenuItem>
									)
								})
							}
							return (
								<TextField
									select
									size='small'
									value={_.get(tempRecord, cellProperties.dataKey, '')}
									onBlur={handleBlur}
									onChange={(e) => handleChange(e.target.value) /* handleChange(e.target.value) */}
								>
									{renderSelectOptions()}
								</TextField>
							)
						}

						case EDIT_COMPONENTS.AUTO_COMPLETE: {
							return (
								<Autocomplete
									multiple
									size='small'
									options={_.get(cellProperties, 'selectOptions', [])}
									getOptionLabel={(option) => option}
									disableCloseOnSelect
									onChange={(e, value) => handleChange(value)}
									onBlur={handleBlur}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												icon={<Icon fontSize='small'>check_box_outline_blank</Icon>}
												checkedIcon={<Icon fontSize='small'>check_box</Icon>}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option}
										</>
									)}
									value={_.get(tempRecord, cellProperties.dataKey, [])}
									renderInput={(params) => <TextField {...params} variant='standard' placeholder='Type something...' />}
								/>
							)
						}
						default:
							return null
					}
				}

				return renderEditComponent()
			}

			return (
				<div style={_.isNil(cellProperties.style) ? {} : cellProperties.style}>
					<EditComponent />
				</div>
			)
		} else {
			switch (true) {
				case cellProperties.isImage === true:
					return <img alt='logo' src={``} />
				case cellProperties.isImageWithText === true: {
					let imgSrc = _.get(record, cellProperties.imgKey, '-')
					const secImgSrc = _.get(record, cellProperties.secImgKey, '-')
					let secImgSrcCount = secImgSrc !== '-' ? secImgSrc.length - 1 : -1
					const loadSecondarySrc = (e) => {
						if (secImgSrcCount !== -1) {
							e.target.src = secImgSrc[secImgSrcCount]
							secImgSrcCount -= 1
						}
					}
					const isLarge = _.get(cellProperties, 'isLargeImage', false)
					const isPopUpEnabled = _.get(cellProperties, 'isImagePopUpEnabled', false)
					const popUpFxn = _.get(cellProperties, 'imgPopUpFxn', () => null)

					function handlePopUpFxn(e) {
						e.stopPropagation()
						popUpFxn(record)
					}

					return (
						<div className={classes.cellWithImageDiv} /* style={_.isNil(cellProperties.style) ? {} :cellProperties.style} */>
							<div
								className={classnames(classes.imageDiv, {
									[classes.isLargeImage]: isLarge,
									[classes.showEyeIcon]: isPopUpEnabled,
								})}
							>
								{imgSrc !== '-' ? (
									<img className={classes.imgInImageWithText} onError={loadSecondarySrc} src={imgSrc} alt={record.name} />
								) : (
									<Typography variant='subtitle1'>Image</Typography>
								)}
								<div className={classes.eyeIconContainer} onClick={handlePopUpFxn}>
									<Icon>visibility</Icon>
								</div>
							</div>
							{/* <div className={classes.textInImageWithTextContainer}>
								<Typography
									variant={_.get(cellProperties, 'textElementProps.variant', 'body2')}
									title={data}
									{..._.get(cellProperties, 'textElementProps', {})}
								>
									{data}
								</Typography>
								{!_.isNil(_.get(cellProperties, 'subTitleRenderFxn')) ? _.get(cellProperties, 'subTitleRenderFxn')(record) : null}
							</div> */}
						</div>
					)
				}
				case cellProperties.isCustom === true:
					const customRenderFxn = _.get(cellProperties, 'customRenderFxn', null)
					return (customRenderFxn && customRenderFxn(data, record)) || data
				case cellProperties.isFlag === true:
					const checkFlagStatus = (data, flagConditions) => {
						if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.yes)) || data === true) {
							return true
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.no)) || data === false) {
							return false
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.Investigation)) || data === false) {
							return 'Investigation'
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.Confirmed)) || data === false) {
							return 'Confirmed'
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.Removed)) || data === false) {
							return 'Removed'
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.NFA)) || data === false) {
							return 'NFA'
						} else if (_.lowerCase(_.toString(data)) === _.lowerCase(_.toString(flagConditions.Counterfeit)) || data === false) {
							return 'Counterfeit'
						} else {
							return flagConditions.indeterminate
						}
					}

					const flagStatus = !_.isEmpty(_.get(cellProperties, 'flagCondition', {})) ? checkFlagStatus(data, cellProperties.flagCondition) : data

					const renderFlagComponentFxn = (componentKey) => {
						const isObject = _.isObject(_.get(cellProperties, componentKey))
						const isFunction = _.isFunction(_.get(cellProperties, componentKey))

						if (isObject && isFunction) {
							return _.get(
								cellProperties,
								componentKey
							)(_.get(cellProperties, 'textKey', '-') !== '-' ? { text: _.get(record, _.get(cellProperties, 'textKey')) } : {})
						} else if (isObject && !isFunction) {
							return _.get(cellProperties, componentKey)
						}
					}

					return (
						<div style={_.isNil(cellProperties.style) ? {} : cellProperties.style}>
							{_.get(cellProperties, 'flagComponents', '-') !== '-' ? (
								flagStatus === true ? (
									renderFlagComponentFxn('flagComponents.yes')
								) : flagStatus === false ? (
									renderFlagComponentFxn('flagComponents.no')
								) : flagStatus === 'NFA' ? (
									renderFlagComponentFxn('flagComponents.NFA')
								) : flagStatus === 'Removed' ? (
									renderFlagComponentFxn('flagComponents.Removed')
								) : flagStatus === 'Confirmed' ? (
									renderFlagComponentFxn('flagComponents.Confirmed')
								) : flagStatus === 'Investigation' ? (
									renderFlagComponentFxn('flagComponents.Investigation')
								) : flagStatus === 'Counterfeit' ? (
									renderFlagComponentFxn('flagComponents.Counterfeit')
								) : (
									renderFlagComponentFxn('flagComponents.indeterminate')
								)
							) : (
								<>
									<span
										className={classnames(classes.flagCircle, {
											[classes.active]: flagStatus === true,
											[classes.deActive]: flagStatus === false,
										})}
									></span>
									<Typography variant='body2' component='span' className={classes.tableBody2}>
										{flagStatus === true
											? _.get(cellProperties, 'flagCondition.yes', 'Yes')
											: flagStatus === false
											? _.get(cellProperties, 'flagCondition.no', 'No')
											: _.get(cellProperties, 'flagCondition.indeterminate')}
									</Typography>
								</>
							)}
						</div>
					)
				case cellProperties.isCheck === true: {
					if (onCheckBoxClick) {
						const isChecked = _.indexOf(exportList, record[keyForCheckBoxSelectedList]) !== -1 || _.indexOf(exportList, 'all') === 0
						const isDisabled = keyForCheckBoxDisabling && !_.isEmpty(_.get(record, keyForCheckBoxDisabling, ''))
						return (
							<Checkbox onClick={(e) => handleCheckBoxClick(e, record)} /* disabled={isDisabled} */ indeterminate={isDisabled} checked={isChecked} />
						)
					}
					break
				}
				case cellProperties.isSwitch === true: {
					const switchTrueCondition = _.get(cellProperties, 'trueCondition', '-')
					const isDataTrue = switchTrueCondition === '-' ? data === true : data === switchTrueCondition
					const trueData = switchTrueCondition === '-' ? true : switchTrueCondition
					const switchFalseCondition = _.get(cellProperties, 'falseCondition', '-')
					const falseData = switchFalseCondition === '-' ? false : switchFalseCondition
					const disableSwitch = _.get(cellProperties, 'disableSwitch', false)
					// const isDisabled = keyForCheckBoxDisabling && hasCheckBoxDisabled && hasCheckBoxDisabled(_.get(record, keyForCheckBoxDisabling, ''));//!_.isEmpty(_.get(record, keyForCheckBoxDisabling, ''));
					return (
						<Switch
							checked={isDataTrue}
							disabled={disableSwitch}
							// onChange={()=>console.log('toggle')}
							onChange={() => _.get(cellProperties, 'onChange', () => null)(isDataTrue ? falseData : trueData, record)}
						/>
					)
				}
				case cellProperties.isActionCol === true: {
					const onClickFxn = (e, actionObj) => {
						if (_.get(actionObj, 'action', '') === ACTION_COL_OPTIONS.EDIT) {
							setIsEditModeOn(true)
							setRecordToEdit({ ...record })
							actionObj.onClick(e, record)
						} else {
							actionObj.onClick(e, record)
						}
					}

					if (isEditModeOn && !isDisabledRow) {
						return (
							<>
								<IconButton
									onClick={() => {
										setTimeout(() => handleCellEdit(toUpdateObj, recordToEdit /* ['website'] */, tableKey), 150)
									}}
									title='Save'
									aria-label='save'
									className={classes.margin}
								>
									<i className='material-icons'>done</i>
								</IconButton>

								<IconButton onClick={disEngageEditMode} title='Cancel' aria-label='cancel' className={classes.margin}>
									<i className='material-icons'>clear</i>
								</IconButton>
							</>
						)
					} else {
						return cellProperties.actions.map((actionObj, idx) => {
							if (actionObj.action === ACTION_COL_OPTIONS.COPY) {
								return (
									<CopyToClipboard
										key={actionObj.icon + idx}
										text={record[actionObj.textKey]}
										onCopy={isDisabledRow ? null : () => actionObj.onClick()}
									>
										<IconButton
											title={actionObj.action}
											size={_.get(actionObj, 'size', 'medium')}
											aria-label={actionObj.action}
											className={classes.margin}
										>
											<i className='material-icons'>{actionObj.icon}</i>
										</IconButton>
									</CopyToClipboard>
								)
							}
							return (
								<IconButton
									key={actionObj.icon + idx + actionObj.title}
									size={_.get(actionObj, 'size', 'medium')}
									onClick={isDisabledRow ? null : (e) => onClickFxn(e, actionObj)}
									title={actionObj.action}
									aria-label={actionObj.action}
									className={classes.margin}
								>
									{/* <i className='material-icons'>{actionObj.icon}</i> */}
									<Icon>{actionObj.icon}</Icon>
								</IconButton>
							)
						})
					}
				}
				default: {
					return (
						<>
							<div
								title={_.parseInt(cellProperties.width) > 0 ? `${data}` : ''}
								className={classnames({
									[classes.overflowTextDots]: _.parseInt(cellProperties.width) > 0,
								})}
								/* style={_.isNil(cellProperties.style) ? {} : cellProperties.style} */
							>
								<Typography
									variant='body2'
									component='span'
									/* title={data} */ className={classes.tableBody2}
									{..._.get(cellProperties, 'textElementProps', {})}
								>
									{_.isEmpty(data) && !_.isNumber(data)
										? _.get(cellProperties, 'isEmpty', false)
											? ''
											: '-'
										: cellProperties.isDate && data !== '-'
										? new Date(data).toLocaleString('en-GB')
										: cellProperties.format && data !== '-'
										? cellProperties.format(data)
										: data}
								</Typography>
							</div>
							{!_.isNil(_.get(cellProperties, 'subTitleRenderFxn')) ? _.get(cellProperties, 'subTitleRenderFxn')(record) : null}
						</>
					)
				}
			}
		}
	}

	const renderSelectionSummary = () => {
		let summaryString = ''
		if (exportList.length === 0) {
			summaryString = '-'
		} else {
			if (_.indexOf(exportList, 'all') === -1 && exportList.length > 0) {
				summaryString = `${exportList.length} item${exportList.length === 1 ? '' : 's'} ${exportList.length === 1 ? 'is' : 'are'} selected.`
			} else if (_.indexOf(exportList, 'all') >= 0) {
				summaryString = `${_.get(paginationFooterDetails, 'total', 0)} items selected.`
			}
		}
		return summaryString
	}

	const OnTextChange = (e) => {
		let value = parseInt(e.target.value)
		let compare =
			parseInt(e.target.value) > _.last(_.get(paginationFooterDetails, 'paginationNumbers')) ||
			parseInt(e.target.value) < _.first(_.get(paginationFooterDetails, 'paginationNumbers'))
		setTimeout(() => {
			if (_.isNaN(value) || compare) {
				goToPage(1)
			} else {
				goToPage(value)
			}
		}, 1500)
	}

	// console.log("_.isEqual((data.map(product => product.objectID)).sort(), exportList.sort())", _.isEqual((data.map(product => product[keyForCheckBoxSelectedList])).sort(), exportList.sort()))
	// console.log("(data.map(product => product.objectID)).sort()", (data.map(product => product[keyForCheckBoxSelectedList])).sort());
	// console.log("exportList.sort()", exportList.sort())

	const [inputSearchQuery, setInputSearchQuery] = useState(searchQuery ? searchQuery : '')
	useEffect(() => {
		if (!_.isEmpty(inputSearchQuery) && inputSearchQuery !== searchQuery) {
			setInputSearchQuery(searchQuery)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery])

	// console.log(paginationFooterDetails, 'page')

	return (
		<>
			<Paper className={classnames(classes.paper, { [classes.noPadding]: true })} elevation={elevation} square={isSquarePaper}>
				{handleSearchInputChange ? (
					<Grid
						container
						item
						xs={12}
						className={classnames(classes.searchContainer, {
							[classes.noPadding]: true,
						})}
					>
						<TextField
							variant='outlined'
							onChange={(e) => setInputSearchQuery(e.target.value)}
							// defaultValue={searchQuery}
							value={inputSearchQuery}
							onKeyUp={(e) => {
								if (['Escape'].indexOf(e.key) !== -1) {
									e.target.blur()
								} else if (['Enter'].indexOf(e.key) !== -1) {
									handleSearchInputChange(inputSearchQuery)
									e.target.blur()
								}
							}}
							fullWidth
							placeholder={'Search here...'}
							InputProps={{
								endAdornment: (
									<>
										{_.isEmpty(inputSearchQuery) ? null : (
											<IconButton
												size='small'
												style={{ marginRight: 4 }}
												onClick={(e) => {
													setInputSearchQuery('')
													handleSearchInputChange('')
													e.target.blur()
												}}
											>
												<Icon>close</Icon>
											</IconButton>
										)}
										<Button
											size='small'
											className={classes.searchButton}
											variant='contained'
											color='primary'
											startIcon={<Icon style={{ color: 'white', fontWeight: 'bold' }}>search</Icon>}
											onClick={(e) => {
												handleSearchInputChange(inputSearchQuery)
												e.target.blur()
											}}
											disableElevation
										/>
									</>
								),
							}}
							inputProps={{
								spellCheck: false,
							}} /* InputProps={{startAdornment: <Icon>search</Icon>}} */
						/>
					</Grid>
				) : null}

				<TableContainer
					component={Paper}
					classes={{ root: classes.muiTableContainerRoot }}
					elevation={0}
					style={bodyScroll && bodyScroll === true ? { maxHeight: !_.isNil(maxHeight) ? maxHeight : '400px' } : {}}
				>
					<Table
						aria-label='new-products-table'
						stickyHeader
						classes={{ root: classes.muiTableRoot }}
						className={classnames({ [classes.tableLayoutFixed]: fixedLayout })}
					>
						<TableHeaders headers={modifiedHeaders} sortSettings={sortSettings} />
						<TableBody>
							{data && data.length > 0 && exportList && exportList.length > 0 ? (
								<TableRow className={classes.selectedSummaryInfoRow}>
									<TableCell colSpan={modifiedHeaders.length}>
										<div className={classes.selectionInfoContainer}>
											<Typography variant='subtitle1'>
												{renderSelectionSummary()}
												{_.isEqual(data.map((product) => product[keyForCheckBoxSelectedList]).sort(), exportList.sort()) ? (
													_.get(paginationFooterDetails, 'total', 0) > 10 ? (
														_.isNil(handleSelectAllProducts) ? (
															<span className={classes.selectionSummarySpan} onClick={clearSelection}>
																Clear Selection
															</span>
														) : (
															<span className={classes.selectionSummarySpan} onClick={handleSelectAllProducts}>
																Select all {_.get(paginationFooterDetails, 'total', 0)} products.
															</span>
														)
													) : (
														<span className={classes.selectionSummarySpan} onClick={clearSelection}>
															Clear Selection
														</span>
													)
												) : (
													<span className={classes.selectionSummarySpan} onClick={clearSelection}>
														Clear Selection
													</span>
												)}

												{/* {exportList.length === limit ? <span>{}</span>} */}
											</Typography>
										</div>
									</TableCell>
								</TableRow>
							) : null}

							{getTableRows(data, isLoading)}
						</TableBody>
					</Table>
				</TableContainer>
				{!_.isEmpty(data) && !_.isEmpty(_.get(paginationFooterDetails, 'paginationNumbers')) && (
					<Grid container className={classes.paginationContainer}>
						<Grid item xs={4} sm={2} container className={classes.viewSummaryContainer} justifyContent='flex-start'>
							<Typography variant='body2'>{_.get(paginationFooterDetails, 'viewSummary', '')}</Typography>
						</Grid>
						{/* <Grid item xs={4} sm={2} container className={classes.viewSummaryContainer} justifyContent='flex-end'>
							<Typography variant='body2'>{_.get(paginationFooterDetails, 'selectedSummary', '')}</Typography>
						</Grid> */}

						<Grid item xs={4} sm={10} className={classes.pageLinksContainer}>
							{/* <input className={classes.pageInput} defaultValue={parseInt(page)} onChange={(e) => OnTextChange(e)} /> */}
							<IconButton
								classes={{
									root: classes.muiIconButtonRoot,
									disabled: classes.muiIconButtonDisabled,
								}}
								disableRipple
								onClick={navigateToFirstPage}
								disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
							>
								<Icon>keyboard_double_arrow_left</Icon>
							</IconButton>
							<IconButton
								classes={{
									root: classes.muiIconButtonRoot,
									disabled: classes.muiIconButtonDisabled,
								}}
								disableRipple
								onClick={navigateToPrevPage}
								disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
							>
								<Icon>chevron_left</Icon>
							</IconButton>
							{_.get(paginationFooterDetails, 'paginationNumbers').map((element, idx) => {
								if (typeof element === 'number')
									return (
										<Button
											key={element}
											classes={{ root: classes.muiButtonRoot }}
											onClick={() => {
												goToPage(element)
											}}
											className={classnames({
												active: element === parseInt(page),
											})}
											disableRipple
										>
											{element}
										</Button>
									)
								else
									return (
										<div key={element + idx} className={classes.threeDotsDiv}>
											<span key={`${idx}-dot`}>{element}</span>
										</div>
									)
							})}
							<IconButton
								classes={{
									root: classes.muiIconButtonRoot,
									disabled: classes.muiIconButtonDisabled,
								}}
								disableRipple
								onClick={navigateToNextPage}
								disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
							>
								<Icon>chevron_right</Icon>
							</IconButton>
							<IconButton
								classes={{
									root: classes.muiIconButtonRoot,
									disabled: classes.muiIconButtonDisabled,
								}}
								disableRipple
								onClick={navigateToLastPage}
								disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
							>
								<Icon>keyboard_double_arrow_right</Icon>
							</IconButton>
							<TextField size='small' className={classes.pageInput} defaultValue={parseInt(page)} onChange={(e) => OnTextChange(e)} />
							<Typography variant='body1' style={{ paddingRight: 10 }}>
								of {_.get(paginationFooterDetails, 'endPageNo', '')}
							</Typography>
							<Select
								value={limit}
								classes={{
									outlined: classes.muiSelectOutlined,
									iconOutlined: classes.muiSelectIconOutlined,
								}}
								variant='outlined'
								onChange={(e) => setPageLimit(e.target.value)}
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</Grid>
					</Grid>
				)}
			</Paper>
		</>
	)
}

export default ProductTable

/* <Pagination 
                                count={_.get(paginationFooterDetails, 'total', 0)}
                                page={page+1}
                                siblingCount={3}
                                defaultPage={1}
                                onChange={(e,pageNo)=>goToPage(pageNo)}
                                hidePrevButton={!_.get(paginationFooterDetails, 'isPrevAvail', true)}
                                hideNextButton={!_.get(paginationFooterDetails, 'isNextAvail', true)}
                                boundaryCount={1}/> */
