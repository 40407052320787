import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { ClickAwayListener, makeStyles, MenuItem, MenuList, Paper, Popover } from '@material-ui/core'
import AgGridCustom from 'components/AgGridCustom'
import { withCellRenderState, NameCellRenderer, ActionCellRenderer, RoleCellRenderer, RemoveCellRenderer } from './CustomCells'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { withWidth } from '@material-ui/core'
import ActionSheet from './ActionSheet'
import { GLASS_ROLES } from '../../constants'
import { hideLoader, showConfirmMessage, showWarningMessage } from 'services/loader/actions'

MembersTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			user_id: PropTypes.string.isRequired,
			first_name: PropTypes.string.isRequired,
			last_name: PropTypes.string,
			email: PropTypes.string.isRequired,
			role: PropTypes.string.isRequired,
		})
	),
	isOwner: PropTypes.bool.isRequired,
	onChangeMemberRole: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'flex-start',
		height: '100%',
		padding: theme.spacing(0.8, 0),
		justifyContent: 'flex-start',
		[theme.breakpoints.up('sm')]: {
			alignItems: 'center',
			padding: 0,
		},
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	memberCellContainer: {
		'& .roleTxt': {
			padding: theme.spacing(1, 0),
			display: 'flex',
			alignItems: 'center',
			'& .dicon': {
				color: theme.palette.text.secondary,
			},
		},
	},
}))

function RolePopupOver({ open, anchorEl, onClickAction, userId, value, onClose }) {
	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault()
			onClose()
		}
	}

	const onChangeMenu = (value) => {
		let eventName = ''
		switch (value) {
			case GLASS_ROLES.OWNER:
				eventName = 'make_owner'
				break
			case GLASS_ROLES.MEMBER:
				eventName = 'make_member'
				break
			case GLASS_ROLES.GUEST:
				eventName = 'make_guest'
				break
			default:
				break
		}
		onClickAction(eventName, userId)
		onClose()
	}

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Paper>
				<ClickAwayListener onClickAway={onClose}>
					<MenuList dense autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
						{_.map(_.values(GLASS_ROLES), (role) => (
							<MenuItem onClick={(e) => onChangeMenu(role)} key={role} selected={value === role}>
								{_.capitalize(role)}
							</MenuItem>
						))}
					</MenuList>
				</ClickAwayListener>
			</Paper>
		</Popover>
	)
}

function MembersTable({ width, data, isOwner, onChangeMemberRole, onRemoveMember }) {
	const classes = useStyles()
	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const [actionSheetState, openActionSheet] = useState({ open: false, role: '', userId: '' })
	const [roleDropdownState, openRoleDropdown] = useState({ openDropdown: false, anchorEl: null, roleValue: '', dropdownUserId: '' })
	const dispatch = useDispatch()

	const { open, role, userId } = actionSheetState
	const { openDropdown, anchorEl, roleValue, dropdownUserId } = roleDropdownState

	const onClickMoreOptions = useCallback((event, data) => {
		openActionSheet({ open: true, role: _.get(data, 'role'), userId: _.get(data, 'user_id') })
	}, [])

	const onClickRoleDropdown = useCallback((event, data) => {
		openRoleDropdown({ openDropdown: true, anchorEl: event.currentTarget, roleValue: _.get(data, 'role'), dropdownUserId: _.get(data, 'user_id') })
	}, [])

	const onCloseDropdown = () => {
		openRoleDropdown((prev) => ({ ...prev, openDropdown: false }))
	}

	const onCloseActionSheet = () => {
		openActionSheet((prev) => ({ ...prev, open: false }))
	}

	const askConfirmMsg = useCallback(
		({ msgToShow, btnConfirmTxt, btnCancelTxt, icon, showWarning = false }, onConfirm) => {
			const hideConfirmBox = () => {
				dispatch(hideLoader())
			}

			const confirmBoxFunc = showWarning ? showWarningMessage : showConfirmMessage

			dispatch(
				confirmBoxFunc(
					msgToShow,
					'',
					btnConfirmTxt,
					() => {
						hideConfirmBox()
						onConfirm()
					},
					btnCancelTxt,
					hideConfirmBox,
					icon
				)
			)
		},
		[dispatch]
	)

	const onPerformAction = useCallback(
		(actionName, userId) => {
			const changeRoleActions = ['make_owner', 'make_member', 'make_guest']
			switch (true) {
				case changeRoleActions.indexOf(actionName) > -1:
					const roleToChange = _.split(actionName, '_')[1]
					askConfirmMsg(
						{
							msgToShow: `Are you sure, you want to assign this user as a “${_.capitalize(roleToChange)}”?`,
							btnConfirmTxt: 'Confirm',
							btnCancelTxt: 'Cancel',
							icon: 'help_outline',
							showWarning: true,
						},
						() => {
							onChangeMemberRole(userId, roleToChange)
						}
					)
					break
				case actionName === 'remove_member':
					askConfirmMsg({ msgToShow: `Are you sure, you want to remove this Member?`, btnConfirmTxt: 'Confirm', btnCancelTxt: 'Cancel' }, () => {
						onRemoveMember(userId)
					})
					break
				default:
					break
			}
			onCloseActionSheet()
		},
		[onChangeMemberRole, askConfirmMsg, onRemoveMember]
	)

	const tblHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, memberCellContainer } = classes
		const isMobile = _.isEqual('xs', width)
		return [
			{
				headerName: _.isEqual('xs', width) ? 'USERS' : 'Name',
				cellRenderer: 'nameCellRenderer',
				field: 'first_name',
				maxWidth: 250,
				cellRendererParams: {
					containerClass: nameCellContent,
					authToken: authToken,
					isRenderMobView: isMobile,
				},
			},
			{
				headerName: 'Email',
				field: 'email',
				hide: isMobile,
			},
			{
				headerName: 'Role',
				field: 'role',
				hide: isMobile,
				cellRenderer: 'roleCellRenderer',
				maxWidth: 140,
				cellRendererParams: {
					containerClass: memberCellContainer,
					onClick: onClickRoleDropdown,
					disabled: !isOwner,
				},
			},
			{
				headerName: '',
				field: '',
				hide: isMobile || !isOwner,
				cellRenderer: 'removeCellRenderer',
				cellRendererParams: {
					onClick: (data) => {
						onPerformAction('remove_member', _.get(data, 'user_id'))
					},
				},
				maxWidth: 80,
			},
			{
				headerName: '',
				field: '',
				maxWidth: 80,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContainer,
					onClickMoreOptions,
				},
				hide: !isOwner || !isMobile,
			},
		]
	}, [classes, width, onPerformAction, onClickRoleDropdown, onClickMoreOptions, isOwner, authToken])

	return (
		<div className={classes.root}>
			<AgGridCustom
				columnDefs={tblHeaders}
				rowData={data}
				isLoading={false}
				rowHeight={_.isEqual(width, 'xs') ? 70 : 50}
				frameworkComponents={{
					nameCellRenderer: withCellRenderState(NameCellRenderer),
					actionCellRenderer: withCellRenderState(ActionCellRenderer),
					roleCellRenderer: withCellRenderState(RoleCellRenderer),
					removeCellRenderer: withCellRenderState(RemoveCellRenderer),
				}}
				suppressRowClickSelection={true}
				noDataTxt='No users found'
			/>
			<ActionSheet open={open} role={role} userId={userId} onClickAction={onPerformAction} onClose={onCloseActionSheet} />
			<RolePopupOver
				open={openDropdown}
				onClickAction={onPerformAction}
				anchorEl={anchorEl}
				value={roleValue}
				userId={dropdownUserId}
				onClose={onCloseDropdown}
			/>
		</div>
	)
}

export default withWidth()(MembersTable)
