import React, { useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, Divider, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch } from 'react-redux'
import _, { initial, values } from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { showAdvanceSnackbar } from 'services/snackbarAdvance/actions'
import DateRangeIcon from '@material-ui/icons/DateRange'

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},

		'& .MuiDialog-paperScrollPaper': {
			width: '100%',
		},
	},
	dialogTitle: {
		paddingBottom: 10,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(3),
	},
	dialogFooter: {
		padding: '10px 15px 10px 15px',
	},

	textField: {
		paddingBottom: theme.spacing(2),
	},

	divider: {
		marginBottom: theme.spacing(2),
	},
}))

function GoogleAnalyticsDialog({ triggerClose, dialogProps, submitForm, isEditMode, formData }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const formRef = useRef()
	// const [selectedValues, setSelectedValues] = useState([]);
	const [state, setState] = useState({
		loading: false,
		ga_accounts: [],
		ga_properties: [],
		ga_views: [],
		ga_metrics: [],
		ga_dimensions: [],
		ga_customMetrics: [],
		ga_customDimensions: [],
		ga_allMetrics: [],
		ga_allDimensions: [],
		ga_id: '',
		ga_segment: [],
	})
	const [startDate, setStartDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
	const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))

	const {
		loading,
		ga_accounts,
		ga_properties,
		ga_views,
		ga_metrics,
		ga_dimensions,
		ga_customMetrics,
		ga_customDimensions,
		ga_allMetrics,
		ga_allDimensions,
		ga_id,
		ga_segment,
	} = state

	const handleStartDateChange = (date) => {
		let currentDate = date?.format('YYYY-MM-DD')
		setStartDate(currentDate)
	}

	const handleEndDateChange = (date) => {
		let currentDate = date?.format('YYYY-MM-DD')
		setEndDate(currentDate)
	}

	const onClickCreate = (values) => {
		submitForm(values)
	}

	useEffect(() => {
		if (dialogProps && dialogProps.data && dialogProps.data.data && dialogProps.data.data[0]) {
			let tempData = dialogProps?.data?.data?.[0]

			setState((prevState) => {
				return {
					...prevState,
					ga_segment: tempData.segment,
					ga_accounts: tempData.accounts,
					ga_dimensions: tempData.query_param.dimension,
					ga_metrics: tempData.query_param.metrics,
					ga_allDimensions: tempData.query_param.dimension,
					ga_allMetrics: tempData.query_param.metrics,
				}
			})
		}
	}, [dialogProps])

	const formatData = (field, value) => {
		if (field === 'properties') {
			let mergedDimensions = [...ga_dimensions]
			let mergedMetrics = [...ga_metrics]
			for (let item of value.custom_dimension) {
				mergedDimensions.push(item)
			}
			for (let item of value.custom_metric) {
				mergedMetrics.push(item)
			}
			setState((prevState) => {
				return {
					...prevState,
					ga_allDimensions: mergedDimensions,
					ga_allMetrics: mergedMetrics,
					ga_views: value?.views,
				}
			})
		} else if (field === 'views') {
			setState((prevState) => {
				return { ...prevState, ga_id: value?.id }
			})
		} else {
			setState((prevState) => {
				return { ...prevState, ga_properties: value?.properties }
			})
		}
	}

	let mapProperties = _.find(ga_accounts, (obj) => {
		return _.includes(
			_.map(obj.properties, (item) => {
				return item.name
			}),
			_.get(formData, 'dc_config.ga_property', '')
		)
	})
	let selectedProperties = mapProperties?.properties?.find((prop) => {
		return prop.name === _.get(formData, 'dc_config.ga_property', '')
	})

	let selectedView = selectedProperties?.views?.find((view) => {
		return view.name === _.get(formData, 'dc_config.view', '')
	})
	const initialValues = {
		accounts: isEditMode
			? _.find(ga_accounts, (obj) => {
					return _.isEqual(obj.name, _.get(formData, 'dc_config.account', ''))
			  })
			: null,
		properties: isEditMode
			? _.find(ga_accounts, (obj) => {
					return _.includes(
						_.map(obj.properties, (item) => {
							return item.name
						}),
						_.get(formData, 'dc_config.ga_property', '')
					)
			  })
			: null,
		views: isEditMode ? selectedView : '',
		id: isEditMode ? selectedView?.id : '',
		start_date: isEditMode ? _.get(formData, 'dc_config.start_date', '') : moment(new Date()).format('YYYY-MM-DD'),
		end_date: isEditMode ? _.get(formData, 'dc_config.end_date', '') : moment(new Date()).format('YYYY-MM-DD'),
		metrics: isEditMode
			? ga_allMetrics
					.map((item) => {
						return _.get(formData, 'dc_config.metrics', []).includes(item.metric_id) ? item : null
					})
					?.filter((item) => item != null)
			: [],
		dimensions: isEditMode
			? ga_allDimensions
					.map((item) => {
						return _.get(formData, 'dc_config.dimensions', []).includes(item.dimension_id) ? item : null
					})
					?.filter((item) => item != null)
			: [],
		segment: isEditMode
			? _.find(ga_segment, (obj) => {
					return _.isEqual(obj.id, _.get(formData, 'dc_config.segment', ''))
			  })
			: null,
		filters: isEditMode ? _.get(formData, 'dc_config.filters', '') : '',
	}

	useEffect(() => {
		if (initialValues && initialValues.id && !ga_id) {
			setState((prevState) => {
				return { ...prevState, ga_id: initialValues.id }
			})

			setStartDate(initialValues?.start_date)
			setEndDate(initialValues?.end_date)
		}
	}, [initialValues])

	const validationSchema = Yup.object().shape({
		accounts: Yup.object().nullable().required('Account is mandatory.'),
		properties: Yup.object().nullable().required('Property is mandatory'),
		views: Yup.object().nullable().required('View is mandatory'),
		// id: Yup.object().nullable().required("Id is mandatory"),
		// start_date: Yup.object().nullable().required("Start date is mandatory"),
		// end_date: Yup.object().nullable().required("End date is mandatory"),
		metrics: Yup.array().min(1, 'Metrics is mandatory').required('Metrics is mandatory'),
		dimensions: Yup.array().min(1, 'Dimensions is mandatory').required('Dimensions is mandatory'),
		// segment:Yup.object().nullable().required("Segment is mandatory"),
	})

	return (
		<div className={classes.dialogContainer}>
			<Dialog open={dialogProps?.open} onClose={triggerClose} className={classes.dialog}>
				<Formik ref={formRef} enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onClickCreate}>
					{(props) => {
						const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = props

						return (
							<>
								<DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>
									{'Fill the below fields'}
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									<Autocomplete
										id='accounts'
										name='accounts'
										options={ga_accounts}
										loading={loading}
										value={_.get(values, 'accounts', [])}
										onChange={(event, newValue) => {
											formatData('accounts', newValue)
											setFieldValue('accounts', newValue)
										}}
										onBlur={handleBlur}
										disableClearable
										loadingText='Fetching accounts...'
										getOptionLabel={(option) => option.name}
										getOptionSelected={
											(option, value) => option.accounts === value.accounts
											// formData?.dc_config?.account
										}
										autoComplete
										size={'small'}
										renderInput={(params) => (
											<TextField
												{...params}
												className={classes.textField}
												name='accounts'
												label='Account'
												variant='outlined'
												error={errors.accounts && touched.accounts}
												helperText={errors.accounts && touched.accounts && errors.accounts}
											/>
										)}
									/>

									<Autocomplete
										id='properties'
										name='properties'
										options={ga_properties}
										loading={loading}
										value={_.get(values, 'properties', [])}
										onChange={(event, newValue) => {
											formatData('properties', newValue)
											setFieldValue('properties', newValue)
										}}
										onBlur={handleBlur}
										loadingText='Fetching accounts...'
										getOptionLabel={(option) => option.name}
										getOptionSelected={(option, value) => option.properties === value.properties}
										autoComplete
										disableClearable
										size={'small'}
										renderInput={(params) => (
											<TextField
												{...params}
												className={classes.textField}
												name='properties'
												label='Property'
												variant='outlined'
												error={errors.properties && touched.properties}
												helperText={errors.properties && touched.properties && errors.properties}
											/>
										)}
									/>

									<Autocomplete
										id='views'
										name='views'
										options={ga_views}
										loading={loading}
										value={_.get(values, 'views', [])}
										onChange={(event, newValue) => {
											formatData('views', newValue)
											setFieldValue('views', newValue)
										}}
										onBlur={handleBlur}
										disableClearable
										loadingText='Fetching accounts...'
										getOptionLabel={(option) => option.name}
										getOptionSelected={(option, value) => option.views === value.views}
										autoComplete
										size={'small'}
										renderInput={(params) => (
											<TextField
												{...params}
												className={classes.textField}
												name='views'
												label='View'
												variant='outlined'
												error={errors.views && touched.views}
												helperText={errors.views && touched.views && errors.views}
											/>
										)}
									/>
									<Typography variant='h6'>Set query parameters</Typography>
									<Divider className={classes.divider} />

									<TextField
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										id='id'
										disabled
										name='id'
										label='Id'
										type='text'
										autoComplete='off'
										onChange={(event, newValue) => {
											setFieldValue('id', newValue)
										}}
										onBlur={handleBlur}
										value={ga_id}
										error={errors.id && touched.id}
										helperText={errors.id && touched.id && errors.id}
										className={classes.textField}
									/>
									<DatePicker
										name='start_date'
										id='start_date'
										className={classes.textField}
										autoOk
										fullWidth
										size='small'
										variant='inline'
										inputVariant='outlined'
										label='Start Date'
										format='MM/DD/YYYY'
										value={startDate}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton>
														<DateRangeIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
										onChange={(date) => {
											handleStartDateChange(date)
											setFieldValue('start_date', moment(date).format('YYYY-MM-DD'))
										}}
									/>
									<DatePicker
										name='end_date'
										id='end_date'
										className={classes.textField}
										autoOk
										fullWidth
										size='small'
										variant='inline'
										inputVariant='outlined'
										label='End Date'
										format='MM/DD/YYYY'
										value={endDate}
										minDate={startDate}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton>
														<DateRangeIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
										onChange={(date) => {
											handleEndDateChange(date)
											setFieldValue('end_date', moment(date).format('YYYY-MM-DD'))
										}}
									/>
									<Autocomplete
										multiple
										id='metrics'
										onBlur={handleBlur}
										disableCloseOnSelect
										onChange={(event, newValue) => {
											if (newValue.length <= 10) {
												setFieldValue('metrics', newValue)
												return
											}
											dispatch(
												showAdvanceSnackbar({
													msg: 'Cannot select more than 10 metrics',
													severity: 'error',
													onClose: true,
												})
											)
										}}
										value={_.get(values, 'metrics', [])}
										// getOptionDisabled={()=>}
										options={ga_allMetrics}
										getOptionLabel={(option) => option.metric_id}
										getOptionSelected={(option, value) => option.metric_id === value.metric_id}
										filterSelectedOptions
										renderOption={(option) => {
											return (
												<Grid key={option.metric_id} container alignItems='center'>
													<Grid item xs>
														<Typography variant='body2'>{option.metric_name}</Typography>
														<Typography variant='body2' color='textSecondary'>
															{option.metric_id}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='outlined'
												margin='dense'
												id='metrics'
												name='metrics'
												label='Metrics'
												className={classes.textField}
												error={errors.metrics && touched.metrics}
												helperText={errors.metrics && touched.metrics && errors.metrics}
												onBlur={handleBlur}
											/>
										)}
									/>

									<Autocomplete
										multiple
										id='dimensions'
										onBlur={handleBlur}
										disableCloseOnSelect
										onChange={(event, newValue) => {
											if (newValue.length <= 7) {
												setFieldValue('dimensions', newValue)
												return
											}
											dispatch(
												showAdvanceSnackbar({
													msg: 'Cannot select more than 7 dimensions',
													severity: 'error',
													onClose: true,
												})
											)
										}}
										value={_.get(values, 'dimensions', [])}
										options={ga_allDimensions}
										label='Dimensions'
										getOptionLabel={(option) => option.dimension_id}
										getOptionSelected={(option, value) => option.dimension_id === value.dimension_id}
										filterSelectedOptions
										renderOption={(option) => {
											return (
												<Grid key={option.dimension_id} container alignItems='center'>
													<Grid item xs>
														<Typography variant='body2'>{option.dimension_name}</Typography>
														<Typography variant='body2' color='textSecondary'>
															{option.dimension_id}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='outlined'
												margin='dense'
												id='dimensions'
												name='dimensions'
												label='Dimensions'
												className={classes.textField}
												error={errors.dimensions && touched.dimensions}
												helperText={errors.dimensions && touched.dimensions && errors.dimensions}
												onBlur={handleBlur}
											/>
										)}
									/>

									<TextField
										id='filters'
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										name='filters'
										label='Filters'
										type='text'
										autoComplete='off'
										onChange={handleChange}
										onBlur={handleBlur}
										value={_.get(values, 'filters', '')}
										error={errors.filters && touched.filters}
										helperText={errors.filters && touched.filters && errors.filters}
										className={classes.textField}
									/>
									<Autocomplete
										id='segment'
										name='segment'
										options={ga_segment}
										loading={loading}
										value={_.get(values, 'segment', '')}
										onChange={(event, newValue) => {
											setFieldValue('segment', newValue)
										}}
										onBlur={handleBlur}
										loadingText='Fetching accounts...'
										getOptionLabel={(option) => option.id}
										getOptionSelected={(option, value) => option.id === value.id}
										autoComplete
										renderOption={(option) => {
											return (
												<Grid key={option.id} container alignItems='center'>
													<Grid item xs>
														<Typography variant='body2'>{option.name}</Typography>
														<Typography variant='body2' color='textSecondary'>
															{option.id}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
										size={'small'}
										renderInput={(params) => (
											<TextField
												{...params}
												className={classes.textField}
												name='segment'
												label='Segment'
												variant='outlined'
												error={errors.segment && touched.segment}
												helperText={errors.segment && touched.segment && errors.segment}
											/>
										)}
									/>
								</DialogContent>
								<Divider />
								<DialogActions className={classes.dialogFooter}>
									<Button onClick={triggerClose} color='primary' variant='outlined' autoFocus>
										Close
									</Button>
									<Button onClick={handleSubmit} color='primary' variant='contained' autoFocus>
										{isEditMode ? 'Save' : 'Create'}
									</Button>
								</DialogActions>
							</>
						)
					}}
				</Formik>
			</Dialog>
		</div>
	)
}

export default GoogleAnalyticsDialog
