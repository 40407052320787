import React, { useState, useImperativeHandle } from 'react'
import { IconButton, Icon, Avatar, ButtonBase, Typography } from '@material-ui/core'
import _ from 'lodash'
import { formTheName, getImgUrl, stringToHslColor, convertGmtToLocalTime } from 'utils'
export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)

		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}
export const CodeCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? data.sticky_code : ''}
		</div>
	)
}
export const NameCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')

	return (
		<div className={containerClassName} title={value}>
			<span className='details-span'>{value}</span>
		</div>
	)
}

export const AssignCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	const token = _.get(props, 'colDef.cellRendererParams.token', '')
	const avatarData = data?.assigned_to || data?.assignee
	return (
		<div>
			{!_.isEmpty(avatarData) ? (
				<Avatar
					title={`${formTheName(avatarData?.first_name || '', avatarData?.last_name || '')} - ${avatarData?.email || ''}`}
					style={{
						width: 30,
						height: 30,
						fontSize: 14,
						margin: '0 auto',
						marginTop: '8px',
						backgroundColor: stringToHslColor(formTheName(avatarData?.first_name || '', avatarData?.last_name || '')),
					}}
					src={avatarData?.profile_img ? getImgUrl(token, avatarData?.profile_img) : null}
				>
					{_.slice(avatarData?.first_name, 0, 1)}
				</Avatar>
			) : (
				<div style={{ textAlign: 'center' }}>-</div>
			)}
		</div>
	)
}

export const ReportingCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	const token = _.get(props, 'colDef.cellRendererParams.token', '')
	const avatarData = data?.reporting_to || {}
	return (
		<div>
			{!_.isEmpty(avatarData) ? (
				<Avatar
					title={`${formTheName(avatarData?.first_name || '', avatarData?.last_name || '')} - ${avatarData?.email || ''}`}
					style={{
						width: 30,
						height: 30,
						fontSize: 14,
						margin: '0 auto',
						marginTop: '8px',
						backgroundColor: stringToHslColor(formTheName(avatarData?.first_name || '', avatarData?.last_name || '')),
					}}
					src={avatarData?.profile_img ? getImgUrl(token, avatarData?.profile_img) : null}
				>
					{_.slice(avatarData?.first_name, 0, 1)}
				</Avatar>
			) : (
				<div>-</div>
			)}
		</div>
	)
}

export const labelCellRederer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	return (
		<div style={{ textAlign: 'left' }}>
			{!_.isEmpty(value) ? (
				_.map(value, (label) => (
					<ButtonBase
						onClick={null}
						disabled={false}
						style={{ background: _.get(label, 'label_color'), color: '#fff', padding: '1px 4px', marginRight: '4px' }}
						// className={classes.labelBtnBase}
						key={_.get(label, 'label_id')}
					>
						<Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
					</ButtonBase>
				))
			) : (
				<div style={{ textAlign: 'left' }}>-</div>
			)}
		</div>
	)
}

// dueDateCellRenderer

export const dueDateCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClassName')

	return (
		<div className={containerClassName}>
			{!_.isEmpty(value) ? (
				<Typography variant='caption'>{convertGmtToLocalTime(value, 'DD MMM YYYY hh:mm a')}</Typography>
			) : (
				<div style={{ textAlign: 'center' }}>-</div>
			)}
		</div>
	)
}

export const parentCodeCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClassName')

	return (
		<div className={containerClassName}>
			{!_.isEmpty(value) ? <div style={{ textAlign: 'center' }}>{value}</div> : <div style={{ textAlign: 'center' }}>-</div>}
		</div>
	)
}

export const StatusCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	return (
		<div>
			<span title={_.startCase(data?.sticky_status?.status)} className='details-span'>
				{_.startCase(data?.sticky_status?.status) || ''}
			</span>
		</div>
	)
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}

	return (
		<div className={containerClassName}>
			<IconButton size='small' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</IconButton>
		</div>
	)
}

export const PriorityCellRenderer = (props) => {
	const { data } = props
	return (
		<div style={{ textAlign: 'center' }}>
			{data?.priority ? (
				<Icon
					fontSize='small'
					title={data?.priority}
					style={{ color: _.isEqual(data?.priority, 'low') ? '#82C43C' : _.isEqual(data?.priority, 'medium') ? '#ffa500' : '#ff0000' }}
				>
					{_.isEqual(data?.priority, 'low') ? 'south' : 'north'}
				</Icon>
			) : (
				'-'
			)}
		</div>
	)
}
