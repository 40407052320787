import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const stickyList = (state, action, paneList) => {
	const stickyCode = _.get(action.payload, 'sticky_code')
	const paneId = _.get(action.payload, 'pane_id')
	const paneIdx = _.findIndex(state, { pane_id: paneId })
	const paneData = _.find(paneList, { pane_id: paneId })
	switch (action.type) {
		case ACTION_TYPES.ADD_GLASS_STICKY:
			return paneIdx > -1
				? update(state, { [paneIdx]: { sticky: { $push: [_.get(action, 'payload')] } } })
				: paneData
				? update(state, {
						$push: [
							{
								pane_id: paneData?.pane_id,
								pane_position: paneData?.pane_position,
								pane_name: paneData?.pane_name,
								sticky: [_.get(action, 'payload')],
							},
						],
				  })
				: state
		case ACTION_TYPES.UPDATE_GLASS_STICKY:
			//find the sticky existing pane, sticky index details
			const stickyExstPaneData = _.find(state, _.flow(_.property('sticky'), _.partialRight(_.some, { sticky_code: stickyCode })))
			const stickyExstPaneIdx = _.indexOf(state, stickyExstPaneData)
			const stickyCurrentIdx = _.findIndex(stickyExstPaneData?.sticky, { sticky_code: stickyCode })
			// If sticky or pane not exist
			if (stickyExstPaneIdx === -1 || stickyCurrentIdx === -1) {
				return state
			}
			//Check sticky has moved or updated within pane
			else if (stickyExstPaneIdx === paneIdx) {
				return update(state, { [stickyExstPaneIdx]: { sticky: { [stickyCurrentIdx]: { $set: _.get(action, 'payload') } } } })
			}
			//Sticky moved from one pane to another pane
			else {
				//remove the sticky from current pane
				let newState = update(state, {
					[stickyExstPaneIdx]: { sticky: { $splice: [[stickyCurrentIdx, 1]] } },
				})
				//move the sticky to the new pane
				const moveOperation =
					paneIdx > -1
						? { [paneIdx]: { sticky: { $push: [_.get(action, 'payload')] } } }
						: {
								$push: [
									{
										pane_id: paneData?.pane_id,
										pane_position: paneData?.pane_position,
										pane_name: paneData?.pane_name,
										sticky: [_.get(action, 'payload')],
									},
								],
						  }
				return update(newState, moveOperation)
			}
		case ACTION_TYPES.REMOVE_GLASS_STICKY:
			const stickyPane = _.find(state, _.flow(_.property('sticky'), _.partialRight(_.some, { sticky_code: stickyCode })))
			const stickyPaneIdx = _.indexOf(state, stickyPane)
			const stickyIdx = _.findIndex(stickyPane?.sticky, { sticky_code: stickyCode })
			return update(state, {
				[stickyPaneIdx]: { sticky: { $splice: [[stickyIdx, 1]] } },
			})
		default:
			return state
	}
}

export default stickyList
