import React from 'react'
import { Dialog, DialogContent, CircularProgress, Typography, Button } from '@material-ui/core'
import { LOADER_MODE } from '../../constants'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import classnames from 'classnames'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
	loaderDialog: {
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '8px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[500],
			height: '10px',
			borderRadius: '5px',
		},
	},
	container: {
		textAlign: 'center',
		padding: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(0),
		},
	},
	loaderTxt: {
		marginTop: theme.spacing(1.5),
	},
	successIco: {
		color: theme.palette.success.main,
		fontSize: theme.typography.pxToRem(50),
	},
	errIcon: {
		color: theme.palette.error.main,
		fontSize: theme.typography.pxToRem(50),
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.typography.pxToRem(35),
		},
	},
	warnIcon: {
		color: theme.palette.warning.main,
		fontSize: theme.typography.pxToRem(50),
	},
	actionBtn: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(2),
			marginRight: theme.spacing(1),
		},
	},
	subtitleTxt: {
		fontSize: theme.typography.pxToRem(13),
		marginTop: theme.spacing(1),
	},
	errBtn: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
}))

const Loader = ({ showLoader, loaderMode, loaderTxt, loaderDescTxt, successBtnTxt, errorBtnTxt, successBtnCallback, errorBtnCallback, icon }) => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const getLoaderInfo = (loaderMode) => {
		switch (loaderMode) {
			case LOADER_MODE.LOADING:
				return <CircularProgress color='secondary' />
			case LOADER_MODE.CONFIRM:
				return <i className={classnames('material-icons', classes.errIcon)}>{icon}</i>
			case LOADER_MODE.SUCCESS:
				return <i className={classnames('material-icons', classes.successIco)}>check_circle</i>
			case LOADER_MODE.ERROR:
				return <i className={classnames('material-icons', classes.errIcon)}>error</i>
			case LOADER_MODE.WARNING:
				return <i className={classnames('material-icons', classes.warnIcon)}>{icon}</i>
			default:
				return <CircularProgress color='secondary' />
		}
	}

	const getFooter = (loaderMode) => {
		switch (loaderMode) {
			case LOADER_MODE.CONFIRM:
				return (
					<>
						<Button
							variant='contained'
							size={isMobile ? 'small' : 'medium'}
							className={classnames(classes.actionBtn, classes.errBtn)}
							onClick={successBtnCallback}
							disableElevation
						>
							{successBtnTxt}
						</Button>
						<Button
							variant='contained'
							size={isMobile ? 'small' : 'medium'}
							className={classnames(classes.actionBtn)}
							onClick={errorBtnCallback}
							color='default'
							disableElevation
						>
							{errorBtnTxt}
						</Button>
					</>
				)
			case LOADER_MODE.SUCCESS:
				return (
					<Button
						variant='contained'
						size={isMobile ? 'small' : 'medium'}
						className={classes.actionBtn}
						onClick={successBtnCallback}
						color='default'
						disableElevation
					>
						{successBtnTxt}
					</Button>
				)
			case LOADER_MODE.ERROR:
				return (
					<Button
						variant='contained'
						size={isMobile ? 'small' : 'medium'}
						className={classnames(classes.actionBtn)}
						onClick={errorBtnCallback}
						color='default'
						disableElevation
					>
						{errorBtnTxt}
					</Button>
				)
			case LOADER_MODE.WARNING:
				return (
					<>
						<Button
							variant='contained'
							size={isMobile ? 'small' : 'medium'}
							className={classnames(classes.actionBtn)}
							color='primary'
							onClick={successBtnCallback}
							disableElevation
						>
							{successBtnTxt}
						</Button>
						<Button
							variant='contained'
							size={isMobile ? 'small' : 'medium'}
							className={classnames(classes.actionBtn)}
							onClick={errorBtnCallback}
							color='default'
							disableElevation
						>
							{errorBtnTxt}
						</Button>
					</>
				)
			default:
				return undefined
		}
	}

	return (
		<Dialog fullWidth={true} maxWidth='xs' open={showLoader} aria-labelledby='Loader Popup' className={classes.loaderDialog}>
			<DialogContent>
				<div className={classes.container}>
					{getLoaderInfo(loaderMode)}
					<Typography className={classes.loaderTxt} variant='body1'>
						{loaderTxt}
					</Typography>
					{loaderDescTxt && (
						<Typography className={classes.subtitleTxt} variant='subtitle1'>
							{loaderDescTxt}
						</Typography>
					)}
					{getFooter(loaderMode)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default Loader
