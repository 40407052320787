import { ACTION_TYPES } from '../../../constants'

const getDefaultState = () => ({
	unreadCount: 0,
})

const notification = (state = getDefaultState(), action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_NOTIFICATION_COUNT:
			return {
				unreadCount: action?.payload?.unreadCount || 0,
			}
		default:
			return state
	}
}

export default notification
