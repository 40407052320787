import { ACTION_TYPES } from '../../../constants'

const createJobs = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.CREATE_JOB_PENDING: {
			return {
				...state,
				isFetchingCreateJobs: true,
				isFetchingCreateJobsFailed: false,
				isFetchingCreateJobsError: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.CREATE_JOB_FULFILLED: {
			return {
				...state,
				isFetchingCreateJobs: false,
				isFetchingCreateJobsFailed: false,
				isFetchingCreateJobsError: undefined,
				data: action.payload,
			}
		}
		case ACTION_TYPES.CREATE_JOB_REJECTED: {
			return {
				...state,
				isFetchingCreateJobs: false,
				isFetchingCreateJobsFailed: true,
				isFetchingCreateJobsError: action.payload,
				data: {},
			}
		}
		case ACTION_TYPES.RESET_CREATE_JOB: {
			return state
		}
		default:
			return (state = {})
	}
}

export default createJobs
