import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, IconButton, Icon } from '@material-ui/core'
import { CHART_DRAGGABLE_ITEM_TYPE, AXIS_ACCEPT } from '../../../../../constants'
import { makeStyles } from '@material-ui/core/styles'
import DraggableContainer from '../../../DnDComponents/DraggableContainer'
import BoxItemContainer from '../../../DnDComponents/BoxItemContainer'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from '../../../../../services/snackbar/actions'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {},
	boxContainer: {
		margin: theme.spacing(2),
	},
	boxModel: {
		textAlign: 'center',
		border: `2px dashed ${theme.palette.grey[300]}`,
		paddingTop: theme.spacing(1.5),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		position: 'relative',
		transform: 'translate3d(0, 0, 0)',
	},
	boxModelActive: {
		border: `2px dashed ${theme.palette.primary.main}`,
		background: `${theme.palette.primary.main}17`,
	},
	boxModelDrop: {
		border: `2px dashed ${theme.palette.primary.main}`,
		background: `${theme.palette.primary.main}51`,
	},
	boxModelTitle: {
		letterSpacing: '2px',
		color: theme.palette.common.black,
	},
	boxModelDesc: {
		paddingBottom: theme.spacing(1),
	},
	iconBtn: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}))

const BoxModelEditor = (props) => {
	const {
		children,
		boxModelCols,
		boxModels,
		onDropColOnEditor,
		getChartColDetail,
		openAggreDialog,
		removeColOnEditor,
		onChangeColOrder,
		openAddColListDialog,
	} = props
	const classes = useStyles()
	const orderedBoxModels = _.orderBy(boxModels, ['order'])

	const onDropColOnBoxModel = (col, boxModelId, targetScale) => {
		onDropColOnEditor(col, boxModelId, targetScale)
	}

	const onClickAddCol = (boxId, boxAccepts, targetScale) => {
		const extCols = _.get(boxModelCols, boxId, [])
		openAddColListDialog(boxId, extCols, boxAccepts, targetScale)
	}

	return (
		<div className={classes.root}>
			<div className={classes.boxContainer}>
				<Grid container spacing={1}>
					{orderedBoxModels.map((boxModelProps) => (
						<BoxModel
							key={boxModelProps.id}
							classes={classes}
							onClickAddCol={onClickAddCol}
							extCols={_.get(boxModelCols, boxModelProps.id, [])}
							onDropColOnBoxModel={onDropColOnBoxModel}
							{...boxModelProps}
						>
							<BoxItemContainer
								targetScale={boxModelProps.targetScale}
								onChangeColOrder={onChangeColOrder}
								getChartColDetail={getChartColDetail}
								onClickItem={boxModelProps.isAggrEnabled && openAggreDialog}
								removeCol={removeColOnEditor}
								extCols={_.get(boxModelCols, boxModelProps.id, [])}
								containerId={boxModelProps.id}
							/>
						</BoxModel>
					))}
				</Grid>
			</div>
			{children}
		</div>
	)
}

const BoxModel = (props) => {
	const dispatch = useDispatch()
	const { classes, id, title, children, extCols, onDropColOnBoxModel, desc, allowCols, accepts, targetScale, onClickAddCol } = props

	const isAllowToAddColOnBox = () => !(_.size(extCols) >= 1 && allowCols === AXIS_ACCEPT.SINGLE)

	const showSnackBar = (msg) => {
		dispatch(showSnackbarWithTimeout(msg, 2000))
	}

	const onDrop = (col) => {
		if (isAllowToAddColOnBox()) onDropColOnBoxModel(col, id, targetScale)
		else showSnackBar("You can't add more than one columns!")
	}

	const onClickAddBtn = () => {
		if (isAllowToAddColOnBox()) onClickAddCol(id, accepts, targetScale)
		else showSnackBar("You can't add more than one columns!")
	}

	return (
		<Grid item md={4} xs={12}>
			<DraggableContainer
				dropClassName={classes.boxModelDrop}
				activeClassName={classes.boxModelActive}
				accept={accepts}
				onDrop={onDrop}
				className={classes.boxModel}
			>
				<div>
					<Typography className={classes.boxModelTitle} variant='body2'>
						{title}
					</Typography>
					<Typography className={classes.boxModelDesc} variant='caption' component={'div'} gutterBottom>
						{desc}
					</Typography>
					<IconButton size='small' className={classes.iconBtn} onClick={onClickAddBtn}>
						<Icon fontSize='small'>add</Icon>
					</IconButton>
					{children}
				</div>
			</DraggableContainer>
		</Grid>
	)
}

BoxModelEditor.propTypes = {
	boxModels: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			desc: PropTypes.string.isRequired,
			accepts: PropTypes.arrayOf(PropTypes.oneOf([CHART_DRAGGABLE_ITEM_TYPE.DIMENSIONS, CHART_DRAGGABLE_ITEM_TYPE.MEASURES])).isRequired,
			allowCols: PropTypes.oneOf([AXIS_ACCEPT.SINGLE, AXIS_ACCEPT.MULTIPLE]).isRequired,
		})
	),
	boxModelCols: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
	getChartColDetail: PropTypes.func.isRequired,
	onDropColOnEditor: PropTypes.func.isRequired,
	removeColOnEditor: PropTypes.func.isRequired,
	onChangeColOrder: PropTypes.func.isRequired,
	openAggreDialog: PropTypes.func.isRequired,
	openAddColListDialog: PropTypes.func.isRequired,
}

export default BoxModelEditor
