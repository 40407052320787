import { ACTION_TYPES } from '../../../constants'
import _ from 'lodash'

const jobs = (state = { isFetching: false, isError: false, error: undefined, data: {} }, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_JOBS_PENDING: {
			return {
				...state,
				isFetching: true,
				isError: false,
				error: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.FETCH_JOBS_FULFILLED: {
			return {
				...state,
				isFetching: false,
				isError: false,
				error: undefined,
				data: action.payload,
			}
		}
		case ACTION_TYPES.FETCH_JOBS_REJECTED: {
			return {
				...state,
				isFetching: false,
				isError: true,
				error: action.payload,
				data: {},
			}
		}
		case ACTION_TYPES.FETCH_JOB_BY_JOB_ID_FULFILLED: {
			const tempData = state.data.data
			const idx = action.recIdx !== -1 && !_.isNil(action.recIdx) ? action.recIdx : _.findIndex(tempData, (job) => job.job_id === action.jobId)
			if (idx >= 0 && !_.isEmpty(action.payload)) {
				tempData[idx] = _.get(action.payload, 'data.data[0]')
			}
			return {
				// ...state,
				isFetching: false,
				isError: false,
				error: undefined,
				data: { ...state.data, data: tempData },
			}
		}
		default: {
			return state
		}
	}
}

export default jobs
