import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Button, Icon, Zoom, Fab } from '@material-ui/core'
import PageHeader from 'components/PageHeader'
import _ from 'lodash'
import { PAGE_MODES } from '../../../constants'
import React, { useMemo } from 'react'
import AgGridCustom from 'components/AgGridCustom'
import { withCellRenderState, ActionCellRenderer, CodeCellRenderer, NameCellRenderer, DateCellRenderer } from './customCells'
const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			'& .subText': {
				fontSize: '11px',
				color: '#999',
			},
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	codeCellContent: {
		height: '100%',
		justifyContent: 'flex-start',
	},
	dateCellContent: {
		height: '100%',
		justifyContent: 'flex-start',
	},
	actionCellContent: {
		// float: 'right',
		'& .actionBtn': {
			marginRight: theme.spacing(1),
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '24px',
		right: '24px',
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
}))
const ProjectPageFilter = (props) => {
	const { isEmbedded, changeEmbededState, onChangeSearch, title, titleIcon, history, baseUrl, hideAddBtn, width } = props

	const onClickAddBtn = (event) => {
		if (!isEmbedded) history.push(`${baseUrl}/${PAGE_MODES.CREATE}`)
		else changeEmbededState({ id: null, action: PAGE_MODES.CREATE })
	}

	const classes = useStyles()
	return (
		<>
			<PageHeader
				title={title}
				titleIcon={titleIcon}
				actions={
					_.isEqual(hideAddBtn, false) && isWidthUp('sm', width) ? (
						<Button
							onClick={onClickAddBtn}
							color='primary'
							variant='contained'
							size='medium'
							startIcon={<i className='material-icons'> add </i>}
							disableElevation
						>
							Add New
						</Button>
					) : null
				}
				onChangeSearch={onChangeSearch}
			></PageHeader>
			{!hideAddBtn && !isWidthUp('sm', width) && (
				<Zoom in={true} style={{ transitionDelay: '500ms' }}>
					<Fab variant='round' size='medium' color='primary' aria-label='add' className={classes.fab} onClick={onClickAddBtn}>
						<Icon fontSize='medium'>add</Icon>
					</Fab>
				</Zoom>
			)}
		</>
	)
}
const ProjectTableList = ({
	width,
	data,
	baseUrl,
	title,
	titleIcon,
	onChangeSearch,
	history,
	hideAddBtn,
	onClickEditMenu,
	onClickDeleteMenu,
	showLoading,
	projectPerms,
	isEmbedded,
	changeEmbededState,
}) => {
	const classes = useStyles()
	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContent, dateCellContent, codeCellContent } = classes
		return [
			{
				headerName: 'Project Code',
				field: 'project_code',
				cellRenderer: 'codeCellRenderer',
				maxWidth: '170',
				cellRendererParams: {
					containerClass: codeCellContent,
				},
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Project Name',
				field: 'project_name',
				cellRenderer: 'nameCellRenderer',
				// minWidth: _.isEqual('xs', width) && 120,
				flex: _.isEqual('xs', width) ? 3 : 2,
				cellRendererParams: {
					containerClass: nameCellContent,
					isRenderMobView: _.isEqual('xs', width),
				},
			},
			{
				headerName: 'Created Date & Time',
				field: 'created_date',
				flex: 2,
				cellRenderer: 'dateCellRenderer',
				cellRendererParams: {
					containerClass: dateCellContent,
				},
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Actions',
				field: ' ',
				// maxWidth: 115,
				flex: _.isEqual('xs', width) ? 2 : 1,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContent,
					onClickEditMenu,
					onClickDeleteMenu,
					projectPerms,
				},
			},
		].filter(Boolean)
	}, [width, classes, onClickEditMenu, onClickDeleteMenu, projectPerms])

	const onRowClicked = (event, record) => {
		// console.log('record', event.data.project_code)
		const isSuppressed = event.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
		if (!isSuppressed) {
			if (!isEmbedded) history.push(`${baseUrl}/${PAGE_MODES.VIEW}/${event.data.project_code}`)
			else changeEmbededState({ id: event.data.project_code, action: PAGE_MODES.VIEW })
		}
	}

	return (
		<div className={classes.root}>
			<ProjectPageFilter
				titleIcon={titleIcon}
				title={title}
				onChangeSearch={onChangeSearch}
				history={history}
				baseUrl={baseUrl}
				hideAddBtn={hideAddBtn}
				width={width}
				isEmbedded={isEmbedded}
				changeEmbededState={changeEmbededState}
			></ProjectPageFilter>
			<AgGridCustom
				columnDefs={gridHeaders}
				rowData={data}
				loading={showLoading}
				frameworkComponents={{
					codeCellRenderer: withCellRenderState(CodeCellRenderer),
					nameCellRenderer: withCellRenderState(NameCellRenderer),
					dateCellRenderer: withCellRenderState(DateCellRenderer),
					actionCellRenderer: withCellRenderState(ActionCellRenderer),
				}}
				onRowClicked={onRowClicked}
				disableClickSelectionRenderers={['actionCellRenderer']}
				rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
				noDataTxt='No Projects found'
			/>
		</div>
	)
}
export default withWidth()(ProjectTableList)
