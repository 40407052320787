import React, { useState, useImperativeHandle } from 'react'
import { IconButton, Icon, Typography } from '@material-ui/core'
import { PERMISSION_TYPE } from 'constants/modules'
import { convertGmtToLocalTime } from 'utils'

import _ from 'lodash'
export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)

		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}
export const CodeCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? data.email_template_code : ''}
		</div>
	)
}
export const TitleCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	if (isRenderMobView) {
		return (
			<div className={containerClassName}>
				<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
				<div className='m-details'>
					<Typography variant='body2'>{data ? data.name : ''}</Typography>
					<Typography className='subText'>
						{data.email_template_code ? data.email_template_code : ''} -{' '}
						{data.created_date ? new Date(data.created_date).toString().slice(0, 25) : ''}
					</Typography>
				</div>
			</div>
		)
	} else {
		return (
			<div className={containerClassName}>
				<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
				<span className='details-span'>{data ? data.name : ' '}</span>
			</div>
		)
	}
}
export const TypeCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	if (isRenderMobView) {
		return (
			<div className={containerClassName}>
				<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
				<div className='m-details'>
					<Typography variant='body2'>{data ? data.module_name : ''}</Typography>
					<Typography className='subText'>
						{data.email_template_code ? data.email_template_code : ''} -{' '}
						{data.created_date ? new Date(data.created_date).toString().slice(0, 25) : ''}
					</Typography>
				</div>
			</div>
		)
	} else {
		return (
			<div className={containerClassName}>
				<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
				<span className='details-span'>{data ? data.module_name : ' '}</span>
			</div>
		)
	}
}
export const DateCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? convertGmtToLocalTime(data.created_date, 'DD MMM YYYY hh:mm a') : ' '}
		</div>
	)
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickEdit = _.get(props, 'colDef.cellRendererParams.onClickEditMenu')
	const onClickDelete = _.get(props, 'colDef.cellRendererParams.onClickDeleteMenu')
	const emailPerms = _.get(props, 'colDef.cellRendererParams.emailPerms')
	const { data } = props
	return (
		<div className={containerClassName}>
			{_.get(emailPerms, PERMISSION_TYPE.EDIT, false) ? (
				<IconButton
					size='small'
					onClick={(e) => {
						onClickEdit(data.email_template_code)
					}}
					title='Edit'
					aria-label='edit-button'
					className='actionBtn'
				>
					<Icon fontSize='small'>edit</Icon>
				</IconButton>
			) : null}
			{_.get(emailPerms, PERMISSION_TYPE.DELETE, false) ? (
				<IconButton
					size='small'
					onClick={(e) => {
						onClickDelete(data.email_template_code)
					}}
					title='Delete'
					aria-label='delete-button'
				>
					<Icon fontSize='small'>delete</Icon>
				</IconButton>
			) : null}
		</div>
	)
}
