import { withStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'

const AccordionSummary = withStyles({
	root: {
		minHeight: 40,
		paddingLeft: 0,
		paddingRight: 0,
		border: 'none',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		'&$expanded': {
			minHeight: 40,
		},
	},
	content: {
		flexGrow: 'unset',
		margin: 0,
		'&$expanded': {
			margin: 0,
		},
	},
	expanded: {},
	expandIcon: {
		padding: 0,
		marginLeft: '8px',
	},
})(MuiAccordionSummary)

export default AccordionSummary
