import { call, cancelled, takeEvery } from 'redux-saga/effects'
import { SAGA_ACTIONS } from '../../../constants'
import api from '../api'
import Axios from 'axios'

function* fetchSharedList(action) {
	const { apiUrl, pathParams, successCallback, errCallback } = action.payload
	const cancelSource = Axios.CancelToken.source()
	try {
		const resp = yield call(api.fetchSharedList, apiUrl, pathParams)
		yield call(successCallback, resp)
	} catch (err) {
		yield call(errCallback, err)
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchSharedListWatcher() {
	yield takeEvery(SAGA_ACTIONS.FETCH_SHARED_LIST, fetchSharedList)
}

function* shareResource(action) {
	const { apiUrl, bodyParams, successCallback, errCallback } = action.payload
	const cancelSource = Axios.CancelToken.source()
	try {
		const resp = yield call(api.shareResource, apiUrl, bodyParams)
		yield call(successCallback, resp)
	} catch (err) {
		yield call(errCallback, err)
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* shareResourceWatcher() {
	yield takeEvery(SAGA_ACTIONS.SHARE_RESOURCE, shareResource)
}

function* saveSharedListChanges(action) {
	const { actionApiUrl, resourceId, updateBodyParams, deleteBodyParams, successCallback, errCallback } = action.payload
	const cancelSource = Axios.CancelToken.source()
	try {
		const resp = yield call(api.updateAndDeleteShareList, actionApiUrl, resourceId, updateBodyParams, deleteBodyParams)
		yield call(successCallback, resp)
	} catch (err) {
		yield call(errCallback, err)
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* saveSharedListChangesWatcher() {
	yield takeEvery(SAGA_ACTIONS.SAVE_SHARED_LIST_CHANGES, saveSharedListChanges)
}

//all the saga watchers
// eslint-disable-next-line import/no-anonymous-default-export
export default [fetchSharedListWatcher(), shareResourceWatcher(), saveSharedListChangesWatcher()]
