import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetails, Icon, makeStyles, Typography } from '@material-ui/core'
import Accordion from './Components/Accordion'
import AccordionSummary from './Components/AccordionSummary'
import { isModuleHasPerm } from 'utils'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import SavedSearchTable from 'components/SavedSearchTable'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
	root: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	nodataTxt: {
		textAlign: 'center',
		padding: theme.spacing(1, 0),
	},
}))

const ResultsAccordion = ({ onRemoveSearch, label, data, onClickAccordian, expanded, isLoading, onRowClicked }) => {
	const classes = useStyles()
	return (
		<Accordion expanded={expanded} onChange={onClickAccordian}>
			<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>} aria-controls={label} id={label}>
				<Typography className={classes.accTitle} variant='body2'>
					{label}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accSummary}>
				<SavedSearchTable onRemoveSearch={onRemoveSearch} data={data} isLoading={isLoading} onRowClicked={onRowClicked} />
			</AccordionDetails>
		</Accordion>
	)
}

ResultsAccordion.propTypes = {
	label: PropTypes.string.isRequired,
	isOwner: PropTypes.bool.isRequired,
	onChangeMemberRole: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
}

const ResultList = ({ isOwner = true, data, categories, onAccordianChange, onRemoveSearch, isLoading, categoryLoading, onRowClicked }) => {
	const classes = useStyles()
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)
	const [currentCategory, setCurrentCategory] = useState('')
	const [isExpanded, setIsExpanded] = useState(false)

	const onClickAccordian = (expanded, category) => {
		setIsExpanded(expanded)
		if (expanded) {
			onAccordianChange(category)
			setCurrentCategory(category)
		}
	}

	return (
		<>
			<div className={classes.root}>
				{categoryLoading ? (
					<Typography variant='subtitle2' className={classes.nodataTxt}>
						Loading Searches...
					</Typography>
				) : !_.isEmpty(categories) && !categoryLoading ? (
					<>
						{_.map(categories, (category, i) => {
							const currentIdx = _.findIndex(categories, { category_id: currentCategory?.category_id })
							return (
								<ResultsAccordion
									onClickAccordian={(e, expanded) => onClickAccordian(expanded, category)}
									expanded={_.isEqual(currentIdx, i) && isExpanded}
									onRowClicked={onRowClicked}
									onRemoveSearch={onRemoveSearch}
									data={data}
									isLoading={isLoading}
									classes={classes}
									label={category?.category_name}
									isOwner={isAccessible}
								/>
							)
						})}
					</>
				) : (
					<Typography variant='subtitle2' className={classes.nodataTxt}>
						No searches found
					</Typography>
				)}
			</div>
		</>
	)
}

ResultList.propTypes = {
	isOwner: PropTypes.bool.isRequired,
}

export default ResultList
