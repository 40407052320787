import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Icon, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core'
import LabelList from './LabelList'
import LabelForm from 'scences/GlassX/Settings/Tabs/Labels/LabelForm'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
	},
	content: {
		width: '260px',
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: theme.spacing(0),
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 1.5),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	headerText: {
		flex: '1',
	},
	contentWrapper: {
		padding: theme.spacing(1.5),
	},
	searchBox: {
		fontSize: theme.typography.pxToRem(14),
	},
	loadingTxt: {
		padding: theme.spacing(1.5),
	},
	deleteBtn: {
		float: 'right',
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.error.main,
		},
	},
}))

const PopoverHeader = ({ classes, title, showBackBtn, onClickBackBtn, onClickCloseBtn }) => {
	return (
		<div className={classes.header}>
			{showBackBtn && (
				<IconButton size='small' className={classes.backBtn} onClick={onClickBackBtn}>
					<Icon fontSize='inherit'>arrow_back</Icon>
				</IconButton>
			)}
			<Typography className={classes.headerText} variant='body2' align='center'>
				{title}
			</Typography>
			<IconButton size='small' onClick={onClickCloseBtn} className={classes.backBtn}>
				<Icon fontSize='inherit'>close</Icon>
			</IconButton>
		</div>
	)
}

const LabelPopover = ({
	open,
	onClose,
	anchorEl,
	openLblForm,
	onSubmitLblForm,
	onClickAddLblBtn,
	onClickBackBtn,
	labels,
	onEditLabel,
	onClickLabel,
	value,
	searchText,
	onChangeSearchBox,
	formLabelId,
	formLabelName,
	formLabelColor,
	onDeleteLabel,
	loading,
	hideAddLabel,
	hideEditLabel,
	hideDeleteLabel,
}) => {
	const classes = useStyles()
	const title = openLblForm ? (_.isEmpty(formLabelId) ? 'Add New Label' : 'Edit Label') : 'Labels'
	const labelFormRef = useRef()

	const isCreateMode = _.isEmpty(formLabelId)

	const labelListToShow = _.isEmpty(searchText)
		? labels
		: _.filter(labels, (label) => _.includes(_.get(label, 'label_name').toLowerCase(), searchText.toLowerCase()))

	const formInitialValues = { label_id: formLabelId, label_name: formLabelName, label_color: formLabelColor }

	const onSubmitForm = () => {
		labelFormRef.current?.submitForm()
	}

	return (
		<Popover
			className={classes.root}
			anchorPosition={{ top: 20, left: 0 }}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			classes={{ paper: classes.content }}
		>
			<PopoverHeader title={title} onClickBackBtn={onClickBackBtn} onClickCloseBtn={onClose} showBackBtn={openLblForm} classes={classes} />
			{!loading &&
				(!openLblForm ? (
					<div className={classes.contentWrapper}>
						<TextField
							size='small'
							placeholder='Search labels...'
							inputProps={{
								className: classes.searchBox,
							}}
							value={searchText}
							onChange={onChangeSearchBox}
							fullWidth
						/>
						<LabelList
							value={value}
							hideEditLabel={hideEditLabel}
							labels={labelListToShow}
							onClickLabelItem={onClickLabel}
							onEditLabelItem={onEditLabel}
						/>
						{!hideAddLabel && (
							<Button size='small' onClick={onClickAddLblBtn}>
								+ New Label
							</Button>
						)}
					</div>
				) : (
					<div className={classes.contentWrapper}>
						<LabelForm ref={labelFormRef} hideDeleteLabel={hideDeleteLabel} initialValues={formInitialValues} onSubmit={onSubmitLblForm} />
						<Box paddingTop={1}>
							<Button
								onClick={onSubmitForm}
								color={isCreateMode ? 'default' : 'primary'}
								size='small'
								className={classes.createBtn}
								disableElevation
								variant={isCreateMode ? 'outlined' : 'contained'}
							>
								{isCreateMode ? 'Create' : 'Save'}
							</Button>
							{!isCreateMode && !hideDeleteLabel && (
								<Button
									onClick={() => {
										onDeleteLabel(formLabelId)
									}}
									disableElevation
									size='small'
									variant='contained'
									className={classes.deleteBtn}
								>
									Delete
								</Button>
							)}
						</Box>
					</div>
				))}

			{loading && (
				<Typography align='center' className={classes.loadingTxt} component='div' variant='caption' gutterBottom>
					Loading...
				</Typography>
			)}
		</Popover>
	)
}

LabelPopover.prototype = {
	loading: PropTypes.bool,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	anchorEl: PropTypes.node,
	labels: PropTypes.arrayOf(
		PropTypes.shape({
			label_id: PropTypes.string,
			label_name: PropTypes.string,
			label_color: PropTypes.string,
		})
	),
	openLblForm: PropTypes.bool,
	onClickAddLblBtn: PropTypes.func,
	onClickBackBtn: PropTypes.func,
	value: PropTypes.arrayOf(PropTypes.string),
	searchText: PropTypes.string,
	onChangeSearchBox: PropTypes.func,
	onEditLabel: PropTypes.func,
	onClickLabel: PropTypes.func,
	onSubmitLblForm: PropTypes.func,
	onDeleteLabel: PropTypes.func,
	hideAddLabel: PropTypes.bool,
	hideEditLabel: PropTypes.bool,
	hideDeleteLabel: PropTypes.bool,
}

export default LabelPopover
