import React, { useState, useEffect, useMemo } from 'react'
import {
	Grid,
	makeStyles,
	TextField,
	Typography,
	Icon,
	Button,
	Popper,
	Paper,
	ClickAwayListener,
	InputAdornment,
	IconButton,
} from '@material-ui/core'
import classnames from 'classnames'
import File from 'components/File'
import { Form, Field } from 'formik'
import Validator from './validator'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import labelAdjectiveImg1 from '../../../images/counterfeit-toggle.png'
import AppSettingsApi from '../../../services/appsettings/api'
import { fetchAppConfigs } from 'services/appsettings/actions'
import { PERMISSION_TYPE } from 'constants/modules'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg, userHasAdminRole } from 'utils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		// margin: 'auto',
		padding: theme.spacing(1),
		boxSizing: 'border-box',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0),
		},
	},
	titleContainer: {
		padding: theme.spacing(0, 0, 2),
	},
	titleText: {
		letterSpacing: '1px',
		fontSize: 18,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
		},
	},
	itemRow: {
		padding: theme.spacing(2, 0),
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
	},
	inputFieldContainer: {
		// maxWidth: 360
	},
	logoRatioClass: {
		width: 320,
		height: 80,
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
		// margin: '0 auto'
	},
	faviconRatioClass: {
		width: 48,
		height: 48,
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
	},
	activityTypeChip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	stepperRoot: {
		padding: theme.spacing(0, 0, 3),
		'& .MuiStep-vertical': {
			height: 20,
			display: 'flex',
			'& .MuiStepLabel-root': {
				display: 'flex',
				alignItems: 'center',
				'& .MuiStepLabel-labelContainer': {
					width: '250px',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					'& .MuiStepLabel-label': {
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					},
				},
			},
		},
		'& .MuiStepConnector-vertical': {
			padding: theme.spacing(0.5, 0),
			marginLeft: 9,
			'& .MuiStepConnector-lineVertical': {
				borderLeftStyle: 'dashed',
				borderLeftWidth: 2,
			},
		},
	},
	stepIcon: {
		width: 20,
		height: 20,
		borderRadius: '50%',
		borderWidth: 4,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		boxSizing: 'border-box',
	},
	firstStep: {
		position: 'relative',
		'&::after': {
			content: '"DEFAULT"',
			fontSize: 11,
			color: theme.palette.almostBlack[600],
			position: 'absolute',
			right: '2px',
			bottom: '0px',
		},
		'& $stepIcon': {
			borderColor: theme.palette.secondary.main,
		},
		'& .MuiStepLabel-labelContainer': {
			borderBottom: `1px solid ${theme.palette.almostBlack[400]}`,
		},
	},
	finalStep: {
		position: 'relative',
		'&::after': {
			content: '"END STATUS"',
			fontSize: 11,
			color: theme.palette.almostBlack[600],
			position: 'absolute',
			right: '2px',
			bottom: '0px',
		},
		'& $stepIcon': {
			borderColor: theme.palette.error.main,
		},
		'& .MuiStepLabel-labelContainer': {
			borderBottom: `1px solid ${theme.palette.almostBlack[400]}`,
		},
	},
	buttonContainer: {
		width: 278,
	},
	deleteJobStatusIcon: {
		'& .MuiIcon-root': {
			fontSize: '20px',
		},
	},
	infoSpan: {
		fontSize: '16px',
		color: theme.palette.error.light,
		marginLeft: theme.spacing(1),
		cursor: 'pointer',
	},
	popperImage: {
		objectFit: 'cover',
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(0),
		},
	},
}))

const BasicDetails = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { data, perms, openIframeDialog } = props

	const isEditAllowed = useMemo(() => _.get(perms, PERMISSION_TYPE.EDIT, false), [perms])

	const [initVals, setInitVals] = useState({})
	const [isEdited, setIsEdited] = useState(false)

	const user = useSelector((state) => _.get(state, 'session.user.userDetails', ''))
	const isUserHasAdminRole = userHasAdminRole(_.get(user, 'roles', []))

	useEffect(() => {
		let tempVals = {
			labelTitle: _.get(data, 'company_name', ''),
			labelName: _.get(data, 'company_adjective', ''),
			logoImg: _.get(data, 'logo', ''),
			logoFavicon: _.get(data, 'favicon', ''),
			workflowURL: _.get(data, 'workflow_url', ''),
			dataflowURL: _.get(data, 'dataflow_url', ''),
		}
		setInitVals({ ...tempVals })
	}, [data])

	const [anchorEl, setAnchorEl] = useState(null)
	const [isEditMode, setIsEditMode] = useState(true)

	const handleOpenPopper = (e) => setAnchorEl(anchorEl ? null : e.currentTarget)

	const handleSave = (newData) => {
		const formData = new FormData()

		formData.append('company_name', _.get(newData, 'labelTitle', ''))
		formData.append('company_adjective', _.get(newData, 'labelName', ''))
		formData.append('logo', _.get(newData, 'logoImg'))
		formData.append('favicon', _.get(newData, 'logoFavicon'))
		formData.append('workflow_url', _.get(newData, 'workflowURL'))
		formData.append('dataflow_url', _.get(newData, 'dataflowURL'))

		dispatch(showLoader('Please wait...'))
		AppSettingsApi.saveAppConfigs(formData)
			.then((resp) => {
				dispatch(fetchAppConfigs())
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 1500))
				setIsEdited(false)
			})
			.catch((err) => {
				dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			})
	}

	const checkForChange = (old, newVal) => {
		if (_.isEqual(old, newVal)) {
			setIsEdited(false)
		} else {
			setIsEdited(true)
		}
	}

	const openNewLinkOnNewTab = (title, link) => {
		openIframeDialog(title, link)
	}

	if (_.isEmpty(initVals)) {
		return null
	} else {
		return (
			<Grid container item xs={12}>
				<Validator initVals={initVals} onSubmit={handleSave} checkForChange={(currVal) => checkForChange(initVals, currVal)}>
					{({ resetForm }) => (
						<Form id='brandForm'>
							<Grid container item xs={12} lg={8} className={classes.root}>
								<Grid container alignItems='center' justifyContent='space-between'>
									<Grid item xs={6}>
										<Typography variant='subtitle1' className={classes.titleText}>
											Label Management
										</Typography>
									</Grid>
									{isEditAllowed && (
										<Grid item xs={6} container justifyContent='flex-end'>
											{isEditMode ? (
												<>
													<Button
														disableElevation
														disabled={!isEdited}
														variant='outlined'
														size='small'
														color='primary'
														type='reset'
														style={{ marginRight: 12 }}
														onClick={() => {
															resetForm()
															setIsEdited(false)
														}}
													>
														Cancel
													</Button>
													<Button disableElevation disabled={!isEdited} variant='contained' size='small' color='primary' type='submit'>
														Save
													</Button>
												</>
											) : (
												<Button disableElevation variant='contained' size='small' color='primary' onClick={() => setIsEditMode(true)}>
													Edit
												</Button>
											)}
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid container item xs={12} lg={8} className={classnames(classes.root)}>
								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Company Logo</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													The logo will be used in Header. So use an image with a minimum resolution of 320x80 px or higher with Aspect ratio of 4:1{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field type='text' name='logoImg'>
											{({ field, form }) => (
												<File
													disabled={!isEditMode || !isEditAllowed}
													value={form.values.logoImg}
													name='logoImg'
													label='Active'
													field={field}
													form={form}
													acceptsExts={['png']}
													resolution={[320, 80]}
													placeholder={'Choose Logo'}
													imgRatioClass={classes.logoRatioClass}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Company Name</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Also will be used in Page Title.{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='labelTitle'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12} container alignItems='center'>
												<Typography variant='subtitle2' component='span'>
													Label Adjective
												</Typography>
												<Icon onClick={handleOpenPopper} className={classes.infoSpan}>
													info_outlined
												</Icon>
											</Grid>
											<Grid item xs={10}>
												<Typography variant='caption' color='textSecondary' component='span'>
													To be used in places like toggling between objective products only and all products.{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='labelName'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={field.onChange}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name] && form.touched[field.name] && String(form.errors[field.name])}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Favicon</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Use 48x48px resolution image. Image must be one of png, jpg, ico formats.
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field type='text' name='logoFavicon'>
											{({ field, form }) => (
												<File
													disabled={!isEditMode || !isEditAllowed}
													value={form.values.logoFavicon}
													label='Fav icon'
													name='logoFavicon'
													field={field}
													form={form}
													acceptsExts={['png']}
													resolution={[48, 48]}
													placeholder={''}
													imgRatioClass={classes.faviconRatioClass}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Workflow URL</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Set and Execute the workflow in the URL
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='workflowURL'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
													InputProps={{
														endAdornment: isUserHasAdminRole && field.value && !form.errors[field.name] && (
															<InputAdornment position='end'>
																<IconButton
																	size='small'
																	aria-label='open workflow'
																	onClick={(e) => {
																		openNewLinkOnNewTab('Zreya Workflow', field.value)
																	}}
																	edge='end'
																>
																	<Icon fontSize='small'>open_in_new</Icon>
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Dataflow URL</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Set and Execute the dataflow in the URL
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='dataflowURL'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													InputProps={{
														endAdornment: isUserHasAdminRole && field.value && !form.errors[field.name] && (
															<InputAdornment position='end'>
																<IconButton
																	size='small'
																	aria-label='open dataflow'
																	onClick={(e) => {
																		openNewLinkOnNewTab('Zreya Dataflow', field.value)
																	}}
																	edge='end'
																>
																	<Icon fontSize='small'>open_in_new</Icon>
																</IconButton>
															</InputAdornment>
														),
													}}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					)}
				</Validator>
				<Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
					<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
						<Paper>
							<img src={labelAdjectiveImg1} className={classes.popperImage} alt='Example for Label Adjective' />
						</Paper>
					</ClickAwayListener>
				</Popper>
			</Grid>
		)
	}
}

export default BasicDetails
