import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import StickyEscForm from './StickyEscForm'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { getErrMsg, getSuccessMsg, isModuleHasPerm } from 'utils'
import { hideLoader, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { updateGlassStickyEsc } from 'services/glass/actions'
import SocketContext from '../../../socket-context'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: 0,
	},
	root: {
		padding: theme.spacing(3, 2, 10),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2, 2, 8),
		},
	},
	pt15: {
		paddingTop: theme.spacing(2.5),
	},
	btnWrapper: {
		padding: theme.spacing(2, 0),
	},
}))

const StickyEsclation = ({ glassCode, isOwner }) => {
	const classes = useStyles()
	const escFormRef = useRef()
	const dispatch = useDispatch()

	const { sessionId } = useContext(SocketContext)
	const stickyEscVal = useSelector((state) => _.get(state, 'glass.settings.sticky_escalation'))
	const stickyReminderVal = useSelector((state) => _.get(state, 'glass.settings.sticky_reminder', ''))

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isEditable = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.EDIT)

	const onSaveBtnClick = () => {
		escFormRef.current.submitForm()
	}

	const hideAnyInfoDialog = () => {
		dispatch(hideLoader())
	}

	const onSubmitEscForm = (values) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		dispatch(showLoader('Please wait...'))
		dispatch(updateGlassStickyEsc(sessionId, glassCode, values?.sticky_escalation, values?.sticky_reminder, onSuccess, onError))
	}

	useEffect(() => {
		escFormRef.current?.resetForm()
	}, [stickyEscVal, stickyReminderVal])

	return (
		<Container className={classes.container} maxWidth={'md'}>
			<Grid className={classes.root} container>
				<Grid item xs={12} className={classes.pt15}>
					<StickyEscForm
						onSubmit={onSubmitEscForm}
						key={stickyEscVal + stickyReminderVal}
						isViewMode={!isEditable}
						ref={escFormRef}
						initialValues={{ sticky_escalation: stickyEscVal, sticky_reminder: stickyReminderVal }}
					/>
				</Grid>
				{isEditable && (
					<Grid item xs={12} className={classes.btnWrapper} container justifyContent='flex-end'>
						<Button size='small' onClick={onSaveBtnClick} variant='contained' color='primary' disableElevation>
							Save
						</Button>
					</Grid>
				)}
			</Grid>
		</Container>
	)
}

StickyEsclation.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
}

export default StickyEsclation
