import { Avatar, Grid, Icon, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { getImgUrl, isModuleHasPerm, stringToHslColor } from 'utils'
import ChangePassword from './ChangePassword'
import HomeConfig from './HomeConfig'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import { useMemo } from 'react'
import TabPanel from 'components/TabPanel'

const SETTINGS_TAB_IDS = {
	CHANGE_PWD: 'changepassword',
	CONFIG_HOMEPAGE: 'configurehomepage',
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 500,
		margin: '16px auto',
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	avatar: {
		width: 60,
		height: 60,
		[theme.breakpoints.down('xs')]: {
			width: 40,
			height: 40,
		},
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	tabWrapper: {
		paddingTop: theme.spacing(2),
		'& .MuiTabs-scrollable': {
			display: 'flex',
			justifyContent: 'space-around',
		},
	},
	tab: {
		letterSpacing: '2px',
		fontSize: '12px',
		'& span': {
			verticalAlign: 'middle',
		},
	},
	tabIco: {
		paddingRight: theme.spacing(1),
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
}))

function TabLabel({ classes, icon, label }) {
	return (
		<Typography className={classes.tab} variant='body2'>
			<Icon fontSize='small' className={classes.tabIco}>
				{icon}
			</Icon>
			<span>{label}</span>
		</Typography>
	)
}

function MyAccount(props) {
	const classes = useStyles()
	const userDetails = useSelector((state) => _.get(state, 'session.user.userDetails'))
	const userDisplayName = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '))
	const avatarChar = userDisplayName.substring(0, 1)
	const userEmail = _.get(userDetails, 'email', '-')
	const token = useSelector((state) => _.get(state, 'session.authToken', ''))
	const userProfilePic = _.get(userDetails, 'profile_pic_url') && getImgUrl(token, _.get(userDetails, 'profile_pic_url'))
	const homeDashboardId = useSelector((state) => _.get(userDetails, 'home_dashboard_id'))
	const config_landing = useSelector((state) => _.get(userDetails, 'config_landing'))

	const SETTINGS_TABS = [
		{
			name: SETTINGS_TAB_IDS.CONFIG_HOMEPAGE,
			label: 'Configuration      ',
			icon: 'home',
			tabPanel: <HomeConfig homeDashboardId={homeDashboardId} config_landing={config_landing} />,
		},
		{
			name: SETTINGS_TAB_IDS.CHANGE_PWD,
			label: 'Change Password',
			icon: 'vpn_key',
			tabPanel: <ChangePassword history={props.history} />,
		},
	]

	const dashboardPerms = usePermission(ROLE_MODULES.DASHBOARDS)
	const isAbleToViewDash = isModuleHasPerm(dashboardPerms, PERMISSION_TYPE.VIEW)

	const discoverxPerms = usePermission(ROLE_MODULES.DISCOVERX)
	const isAbleToViewDiscoverx = isModuleHasPerm(discoverxPerms, PERMISSION_TYPE.VIEW)

	//show only available tab example if user doesn't have dashboard view permission then don't need to show the configure homepage tab
	const tabsToShow = SETTINGS_TABS.filter(
		(tab) => isAbleToViewDash || isAbleToViewDiscoverx || !_.isEqual(_.get(tab, 'name'), SETTINGS_TAB_IDS.CONFIG_HOMEPAGE)
	)

	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{ settings: isAbleToViewDash ? SETTINGS_TAB_IDS.CONFIG_HOMEPAGE : SETTINGS_TAB_IDS.CHANGE_PWD },
		{ settings: String }
	)
	const tabIdx = useMemo(() => _.findIndex(tabsToShow, { name: pageQuery?.settings }), [tabsToShow, pageQuery])

	const handleChange = (e, tabIdx) => {
		setPageQuery({ settings: _.get(tabsToShow, `[${tabIdx}].name`) })
	}
	return (
		<div className='page-content'>
			<Paper className={classes.root} variant='outlined' square>
				<Grid container className={classes.gridWrapper}>
					<Grid item xs={3} align='center'>
						<Avatar
							className={classes.avatar}
							style={{ backgroundColor: stringToHslColor(userDisplayName) }}
							alt={userDisplayName}
							src={userProfilePic}
						>
							<Typography>{avatarChar}</Typography>
						</Avatar>
					</Grid>
					<Grid container direction='column' item xs={9} justifyContent='center' alignItems='flex-start'>
						<Typography className={classes.title} variant='h5'>
							{userDisplayName}
						</Typography>
						<Typography className={classes.subtitle} variant='subtitle1' color='textSecondary'>
							{userEmail}
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.tabWrapper}>
						<Tabs
							variant='scrollable'
							value={tabIdx}
							indicatorColor='primary'
							textColor='primary'
							onChange={handleChange}
							aria-label='account settings tabs'
						>
							{_.map(tabsToShow, (tab, index) => (
								<Tab key={index} label={<TabLabel classes={classes} icon={tab.icon} label={tab.label} />} />
							))}
						</Tabs>
					</Grid>
					<Grid item xs={12} className={classes.tabPanelWrapper}>
						{_.map(tabsToShow, (tab, index) => (
							<TabPanel key={index} index={index} label={tab.label} value={tabIdx}>
								{tab.tabPanel}
							</TabPanel>
						))}
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

export default MyAccount
