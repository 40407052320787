import React, { useState } from 'react'
import ShareDialog from 'components/ShareDialog'

const withShareDialog = (Component, shareListApiUrl, shareActionApiUrl) => (props) => {
	const [dialogState, setDialogState] = useState({
		openShareDialog: false,
		resourceId: null,
		resourceParams: {},
	})

	const { openShareDialog, resourceId, resourceParams } = dialogState

	const openDialog = (resourceId, resourceParams) => {
		setDialogState({ openShareDialog: true, resourceId, resourceParams })
	}

	const closeDialog = () => {
		setDialogState({
			openShareDialog: false,
			resourceId: null,
			resourceParams: {},
		})
	}

	return (
		<>
			<Component openSharingDialog={openDialog} {...props} />
			<ShareDialog
				open={openShareDialog}
				resourceId={resourceId}
				shareListApiUrl={shareListApiUrl}
				shareActionApiUrl={shareActionApiUrl}
				resourceParams={resourceParams}
				handleClose={closeDialog}
			/>
		</>
	)
}

export default withShareDialog
