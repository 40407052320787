import {
	Card,
	CardHeader,
	makeStyles,
	Typography,
	Avatar,
	CardContent,
	CardActions,
	ButtonBase,
	Icon,
	Grid,
	Button,
	Divider,
	Box,
	Snackbar,
	IconButton,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import Attachments from 'components/Attachments'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { convertGmtToLocalTime, formTheName, getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from '../../services/glass/api'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import useLazyPaginate from 'hooks/useLazyPaginate'
import CommentBox from 'components/CommentBox'
import update from 'immutability-helper'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 0),
		paddingBottom: theme.spacing(2),
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 1, 0, 7),
		},
		'& .MuiCardHeader-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardHeader-action': {
			marginTop: 0,
			marginRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiCardHeader-root': {
				padding: theme.spacing(1),
				paddingBottom: 0,
			},
			'& .MuiCardContent-root': {
				padding: theme.spacing(0, 1, 1, 1),
			},
			'& .MuiCardHeader-avatar': {
				marginRight: theme.spacing(1),
			},
		},
		'& .wysiwyg-mention': {
			textDecoration: 'none',
			color: '#1236ff',
			backgroundColor: '#f0fbff',
			padding: '1px 2px',
			borderRadius: '2px',
		},
	},
	cardRoot: {
		backgroundColor: '#fcfcfc',
		margin: theme.spacing(1, 0),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		// marginRight: theme.spacing(1),
	},
	// title: {
	// 	marginBottom: theme.spacing(1),
	// },
	description: {
		margin: theme.spacing(1, 0),
		fontSize: '14px',
	},
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	pinnedIcon: {
		transform: 'rotate(45deg)',
	},
	attachmentsSection: {
		width: '100%',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
	},
	activitiesHead: { justifyContent: 'space-between' },
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
}))

const Activities = ({
	title,
	stickyId,
	glassCode,
	is_pinned,
	sessionId,
	isEditEnabled,
	showCommentBox,
	activityTypes,
	glassLabels,
	placeholder,
	socketRef,
	isSocketConnected,
	suggestions,
	showExcludeSystemMessage = false,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buttonUI, setButtonUI] = useState({ btnText: 'Newest first', btnIcon: 'arrow_upward' })
	const [activityState, setActivityState] = useState({
		activityList: [],
		loading: true,
		limit: 10,
		offset: 0,
		totalCount: 0,
		sort_by: 'desc',
		showRefreshSnackbar: false,
		excludeSysLog: true,
	})

	const { activityList, limit, offset, totalCount, sort_by, loading, showRefreshSnackbar, excludeSysLog } = activityState

	const activityListSynced = useRef(activityList)

	useEffect(() => {
		activityListSynced.current = activityList
	}, [activityList])

	const activityAddListener = useRef()
	const activityUpdateListener = useRef()
	const token = useSelector((state) => _.get(state, 'session.authToken'))

	useEffect(() => {
		if (loading) {
			GlassApi.getStickyActivities(
				sort_by,
				offset,
				limit,
				is_pinned ? true : null,
				glassCode,
				stickyId,
				excludeSysLog,
				(res) => {
					let actions = _.get(res, 'data.data.activities', [])
					setActivityState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: _.get(res, 'data.data.total_count'),
						activityList: offset === 0 ? actions : [...prevState.activityList, ...actions],
					}))
				},
				(err) => {
					setActivityState((prevState) => ({ ...prevState, loading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		}
	}, [dispatch, glassCode, is_pinned, limit, offset, sort_by, stickyId, loading, excludeSysLog])

	const updateActivityItem = useCallback(
		(activityItemToUpt) => {
			let opToPerform = null
			let subtractTotal = 0
			if (sort_by === 'desc') {
				let activityIndex = _.findIndex(activityListSynced.current, { activity_id: activityItemToUpt?.activity_id })
				if (activityIndex > -1) {
					const updateActivity = { [activityIndex]: { $set: { ...activityItemToUpt } } }
					opToPerform = !is_pinned ? updateActivity : !activityItemToUpt?.is_pinned ? { $splice: [[activityIndex, 1]] } : updateActivity
					subtractTotal = opToPerform === updateActivity ? 0 : -1
				} else {
					opToPerform = is_pinned ? { $unshift: [activityItemToUpt] } : null
					subtractTotal = is_pinned ? 1 : 0
				}
			}
			if (opToPerform === null) {
				setActivityState((prevState) => ({
					...prevState,
					showRefreshSnackbar: true,
				}))
			} else {
				setActivityState((prevState) => ({
					...prevState,
					activityList: update(prevState?.activityList, opToPerform),
					totalCount: prevState.totalCount + subtractTotal,
				}))
			}
		},
		[sort_by, is_pinned]
	)

	const updateActivity = (activity) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('is_pinned', _.toString(!_.get(activity, 'is_pinned', false)))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivities(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const handleSort = () => {
		let buttonText = buttonUI.btnText === 'Newest first' ? 'Oldest first' : 'Newest first'
		let buttonIcon = buttonUI.btnIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward'
		setButtonUI({ btnText: buttonText, btnIcon: buttonIcon })
		setActivityState((prevState) => ({
			...prevState,
			sort_by: buttonText === 'Newest first' ? 'desc' : 'asc',
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
		}))
	}
	const [commentLoading, setCommentLoading] = useState(false)

	const onCommentBoxSubmit = (data, formik) => {
		setCommentLoading(true)
		let formData = new FormData()
		formData.append('desc', data?.desc)
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))

		const onSuccess = (res, formik) => {
			formik.resetForm()
			setCommentLoading(false)
			setActivityState((prevState) => ({
				...prevState,
				activityList: [res?.data?.data, ...prevState.activityList],
			}))
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setCommentLoading(false)
		}
		GlassApi.postStickyActivities(glassCode, stickyId, sessionId, formData).then((e) => onSuccess(e, formik), onFailure)
	}
	const bottomDividerRef = useRef()

	useLazyPaginate(bottomDividerRef, () => {
		if (!loading && totalCount > activityList.length) {
			setActivityState((prevState) => ({ ...prevState, offset: activityList.length, loading: true }))
		}
	})

	useEffect(() => {
		if (isSocketConnected) {
			activityAddListener.current = (resp) => {
				if (sort_by === 'desc') {
					if ((!is_pinned && (excludeSysLog ? !resp?.data?.is_log : true)) || (is_pinned && resp?.data?.is_pinned)) {
						setActivityState((prevState) => ({
							...prevState,
							offset: prevState.offset + 1,
							total_count: prevState.total_count + 1,
							activityList: [resp.data, ...prevState.activityList],
						}))
					}
				} else setActivityState((prevState) => ({ ...prevState, showRefreshSnackbar: true }))
			}
			activityUpdateListener.current = (resp) => {
				updateActivityItem(resp?.data)
			}
			socketRef.current?.on('sticky_activity_create', activityAddListener.current)
			socketRef.current?.on('sticky_activity_update', activityUpdateListener.current)
		}
		return () => {
			if (isSocketConnected && activityAddListener.current && activityUpdateListener.current) {
				socketRef.current?.off('sticky_activity_create', activityAddListener.current)
				// eslint-disable-next-line react-hooks/exhaustive-deps
				socketRef.current?.off('sticky_activity_update', activityUpdateListener.current)
			}
		}
	}, [socketRef, sort_by, updateActivityItem, isSocketConnected, is_pinned, excludeSysLog])

	const closeRefreshSnackBar = () => {
		setActivityState((prevState) => ({ ...prevState, showRefreshSnackbar: false }))
	}

	const onChangeSysExecCb = () => {
		setActivityState((prevState) => ({
			...prevState,
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
			excludeSysLog: !excludeSysLog,
		}))
	}

	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={showRefreshSnackbar}
				autoHideDuration={6000}
				onClose={closeRefreshSnackBar}
				message={'Activities Updates Found!'}
				action={
					<React.Fragment>
						<Button color='secondary' size='small' onClick={handleSort}>
							Refresh
						</Button>
						<IconButton aria-label='close' color='inherit' className={classes.close} onClick={closeRefreshSnackBar}>
							<Icon>close</Icon>
						</IconButton>
					</React.Fragment>
				}
			/>

			{showCommentBox && (
				<div className={classes.mb2}>
					<CommentBox
						glassLabels={glassLabels}
						glassCode={glassCode}
						activityTypes={activityTypes}
						sessionId={sessionId}
						placeholder={placeholder}
						onSubmit={onCommentBoxSubmit}
						loading={commentLoading}
						suggestions={suggestions}
					/>
				</div>
			)}
			<Grid container direction='row' alignItems='center' className={classes.activitiesHead}>
				<Grid item>
					<Typography variant='subtitle2' component='p'>
						{title ? title : 'Activities'}
					</Typography>
				</Grid>
				<Grid item>
					{showExcludeSystemMessage && (
						<FormControlLabel
							control={<Checkbox size='small' checked={excludeSysLog} onChange={onChangeSysExecCb} name='SysExCb' />}
							label={
								<Typography color='textSecondary' variant='body2'>
									Exclude System Messages
								</Typography>
							}
						/>
					)}
					<Button className={classes.sortButton} endIcon={<Icon>{buttonUI.btnIcon}</Icon>} onClick={handleSort}>
						{buttonUI.btnText}
					</Button>
				</Grid>
			</Grid>

			{!_.isEmpty(activityList) ? (
				_.map(activityList, (activity) => {
					let name = formTheName(activity?.created_by?.first_name, activity?.created_by?.last_name)
					return (
						<Card key={activity?.activiy_id} className={classes.cardRoot} raised={false} elevation={0}>
							<CardHeader
								avatar={
									<Avatar
										alt={name}
										src={getImgUrl(token, activity?.created_by?.profile_img) || null}
										size='small'
										className={classes.avatar}
										style={{ backgroundColor: stringToHslColor(name) }}
									>
										{_.slice(_.words(name), 0, 1).reduce((a, t) => (a += t[0]), '')}
									</Avatar>
								}
								action={
									<Typography variant='subtitle2' component='p'>
										{activity?.activity_type?.activity_name}
									</Typography>
								}
								title={name}
								subheader={convertGmtToLocalTime(_.get(activity, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
							/>
							<CardContent>
								{activity?.desc && <div className={classes.description} dangerouslySetInnerHTML={{ __html: activity?.desc }}></div>}
								{!_.isEmpty(_.get(activity, 'attachments', [])) && (
									<div className={classes.attachmentsSection}>
										<Attachments
											attachmentList={_.get(activity, 'attachments', [])}
											showDivider={false}
											isActivity={true}
											disableDelete={true}
											enableAddBtn={false}
										/>
									</div>
								)}
							</CardContent>
							<CardActions disableSpacing>
								<div className={classes.labelsWrap}>
									{!_.isEmpty(activity?.labels) &&
										_.map(activity?.labels, (label) => (
											<ButtonBase
												key={label?.label_id}
												onClick={null}
												disabled={true}
												style={{ background: label?.label_color }}
												className={classes.labelBtnBase}
											>
												<Typography variant='caption'>{label?.label_name}</Typography>
											</ButtonBase>
										))}
									{isEditEnabled && !activity?.is_log && (
										<>
											{activity?.is_pinned ? (
												<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
													<Icon className={classes.pinnedIcon} fontSize='small'>
														push_pin
													</Icon>
													{'  '}
													&nbsp; unpin
												</Button>
											) : (
												<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
													<Icon fontSize='small'>push_pin</Icon>
													{'  '}
													&nbsp; pin
												</Button>
											)}
										</>
									)}
								</div>
							</CardActions>
							<Divider variant='inset' />
						</Card>
					)
				})
			) : (
				<>
					{!loading && (
						<Box padding={2}>
							<Typography component='div' align='center' variant='caption'>
								No Activites Found!
							</Typography>
						</Box>
					)}
				</>
			)}

			{loading && (
				<div className={classes.loadingSkeleton}>
					<Skeleton animation='wave' variant='circle' width={39} height={36} />
					<div className={classes.lineSkeleton}>
						<Grid container direction='row'>
							<Grid item xs={6}>
								<Skeleton animation='wave' height={18} width='90%' />
							</Grid>
							<Grid item xs={6} className={classes.activityTypeItem}>
								<Skeleton animation='wave' height={18} width='50%' />
							</Grid>
						</Grid>
						<Skeleton animation='wave' height={18} width='40%' />
						<Skeleton animation='wave' height={45} width='100%' />
					</div>
				</div>
			)}

			<div ref={bottomDividerRef}></div>
		</div>
	)
}

Activities.propTypes = {
	title: PropTypes.string,
	stickyId: PropTypes.string,
	glassCode: PropTypes.string,
	is_pinned: PropTypes.bool,
	sessionId: PropTypes.string,
	isEditEnabled: PropTypes.bool,
	showCommentBox: PropTypes.bool,
	activityTypes: PropTypes.array,
	glassLabels: PropTypes.array,
	placeholder: PropTypes.string,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	suggestions: PropTypes.array,
	showExcludeSystemMessage: PropTypes.bool,
}

export default Activities
