import update from 'immutability-helper'
import _ from 'lodash'

export const initialState = {
	isFetchingProducts: false,
	products: [],
	totalProducts: 0,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchigproductsPending':
			return { ...state, isFetchingProducts: true }
		case 'fetchingProductsSuccess':
			return {
				...state,
				isFetchingProducts: false,
				products: action.data,
				totalProducts: action.totalProducts,
			}
		case 'productUpdate':
			let tempActIndex = _.findIndex(state.products, (o) => o?.product_id === action?.data?.product_id)
			if (tempActIndex > -1) {
				let tempState = update(state, {
					products: { [tempActIndex]: { $merge: action.data } },
				})
				return tempState
			}
			return state
		default:
			throw new Error()
	}
}
