import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SearchAutocomplete from '../SearchAutocomplete'
import DiscoverApi from 'services/discoverx/api'
import _ from 'lodash'

DisXInpBox.propTypes = {
	inputClassName: PropTypes.string,
	className: PropTypes.string,
	defaultValue: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	textboxRef: PropTypes.any,
}

function DisXInpBox({ className, inputClassName, textboxRef, defaultValue, onChange }) {
	const isMounted = useRef(true)
	const cancelExecutor = useRef()
	const [state, setState] = useState({ searchTxt: '', suggestions: [] })
	const { searchTxt, suggestions } = state

	useEffect(
		() => () => {
			isMounted.current = false
		},
		[]
	)

	const safeUptState = useCallback((updateObj) => {
		if (isMounted.current) setState((prevState) => ({ ...prevState, ...updateObj }))
	}, [])

	const delayedSearchQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	useEffect(() => {
		const resetSuggestions = () => {
			safeUptState({ suggestions: [] })
		}
		if (searchTxt) {
			DiscoverApi.fetchSearchSuggestions(searchTxt, cancelExecutor).then((resp) => {
				//TODO get the response set to the state
				let stringifiedData = _.map(_.get(resp, 'data.data.auto_suggest', []), (item) => (typeof item === 'number' ? _.toString(item) : item))

				safeUptState({
					suggestions: stringifiedData.filter(Boolean),
				})
			}, resetSuggestions)
		} else {
			resetSuggestions()
		}
	}, [searchTxt, safeUptState])

	const onInputChange = useCallback(
		(e, value) => {
			delayedSearchQuery(value, (val) => safeUptState({ searchTxt: val }))
		},
		[delayedSearchQuery, safeUptState]
	)

	return (
		<SearchAutocomplete
			options={suggestions}
			onChange={onChange}
			className={className}
			defaultValue={defaultValue}
			inputClassName={inputClassName}
			onInputChange={onInputChange}
			textboxRef={textboxRef}
		/>
	)
}

export default DisXInpBox
