import React, { useRef } from 'react'
import ColorPickerButton from '../../../components/ColorPicker'
import PrimaryButton from '../../../components/PrimaryButton'
import { Grid, Icon, InputBase, Button, ButtonGroup, IconButton, Badge, Typography, makeStyles } from '@material-ui/core'
import ActionContainer from '../../../components/ActionContainer'
import classnames from 'classnames'
import { TASK_BUTTON_TXT } from '../../../constants'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	saveBtn: {
		marginRight: '12px !important',
	},
}))

const GraphPageHeader = (props) => {
	const {
		title,
		graphBgColor,
		graphBorderColor,
		isFilterAvail,
		openBgColorPicker,
		openBorderColorPicker,
		toggleColorPicker,
		changeColorPicker,
		isVisibleColorPicks,
		onClickFilterBtn,
		onClickSaveBtn,
		addOrModifyGraphProp,
		onCancel,
		isEditMode,
		isTaskAddAllowed,
		isEditAllowed,
		isShareAllowed,
		onClickShareBtn,
		onClickAddTaskBtn,
		onClickSortBtn,
		visualType,
		isSortAvail,
		isSortEnabled,
	} = props

	const classes = useStyles()

	const typeTimeOut = useRef(null)

	const onChangeGraphNameInput = (e) => {
		const value = e.target.value
		if (typeTimeOut != null) {
			clearTimeout(typeTimeOut.current)
		}
		typeTimeOut.current = setTimeout(() => {
			addOrModifyGraphProp({ graph_name: value })
		}, 400)
	}

	const cancelBtnTxt = isEditMode ? 'Cancel' : 'Back'
	const okBtnTxt = isEditMode ? 'Save' : 'Edit'

	return (
		<Grid container className='graph-header'>
			<Grid item xs={12} sm={12} md={6} lg={3} className='graph-title-container'>
				<div className='graph-page-title'>
					<IconButton onClick={onCancel} size='small'>
						<Icon className='v-middle'>keyboard_backspace</Icon>
					</IconButton>{' '}
					&nbsp;
					{isEditMode ? (
						<InputBase
							className={classnames('graph-title-input')}
							defaultValue={title}
							placeholder='Graph Name'
							inputProps={{ 'aria-label': 'Graph Name', readOnly: !isEditMode }}
							onChange={onChangeGraphNameInput}
						/>
					) : (
						<Typography variant='body1'>{title}</Typography>
					)}
				</div>
			</Grid>
			<Grid item lg={3} md={2} sm={6} xs={12} className='graph-action-container'>
				{isVisibleColorPicks && isEditMode && (
					<>
						<ButtonGroup size='small' className='graph-btn-group' aria-label='small outlined button group'>
							<ColorPickerButton
								className='color-picker-btn'
								size='small'
								open={openBgColorPicker}
								handleOpen={() => {
									toggleColorPicker('openBgColorPicker')
								}}
								handleClose={() => {
									toggleColorPicker('openBgColorPicker')
								}}
								handleChange={(color) => {
									changeColorPicker('graphBgColor', color.rgb)
								}}
								color={graphBgColor}
							></ColorPickerButton>
							<ColorPickerButton
								icon='border_color'
								className='color-picker-btn'
								open={openBorderColorPicker}
								handleOpen={() => {
									toggleColorPicker('openBorderColorPicker')
								}}
								handleClose={() => {
									toggleColorPicker('openBorderColorPicker')
								}}
								handleChange={(color) => {
									changeColorPicker('graphBorderColor', color.rgb)
								}}
								color={graphBorderColor}
							></ColorPickerButton>
							<Button onClick={onClickFilterBtn}>
								{isFilterAvail ? (
									<Badge color='primary' size='small' variant='dot' className='filter-badge'>
										<Icon fontSize='small'>filter_list</Icon>
									</Badge>
								) : (
									<Icon fontSize='small'>filter_list</Icon>
								)}
							</Button>
							{isSortEnabled && (
								<Button onClick={onClickSortBtn}>
									{isSortAvail ? (
										<Badge color='primary' size='small' variant='dot' className='filter-badge'>
											<Icon fontSize='small'>sort_by_alpha</Icon>
										</Badge>
									) : (
										<Icon fontSize='small'>sort_by_alpha</Icon>
									)}
								</Button>
							)}
						</ButtonGroup>
					</>
				)}
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={6}>
				<ActionContainer align='right'>
					{isShareAllowed && (
						<Button variant='outlined' size='small' onClick={onClickShareBtn} startIcon={<Icon fontSize='small'>share</Icon>}>
							Share
						</Button>
					)}
					{isTaskAddAllowed && (
						<Button variant='outlined' size='small' onClick={onClickAddTaskBtn} startIcon={<Icon fontSize='small'>sticky_note_2</Icon>}>
							{TASK_BUTTON_TXT}
						</Button>
					)}
					<Button onClick={onCancel} variant='outlined' size='small'>
						{cancelBtnTxt}
					</Button>
					{isEditAllowed && (
						<PrimaryButton size='small' onClick={onClickSaveBtn} className={classes.saveBtn}>
							{okBtnTxt}
						</PrimaryButton>
					)}
				</ActionContainer>
			</Grid>
		</Grid>
	)
}

export default GraphPageHeader
