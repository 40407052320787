import React from 'react'
import { Grid, Typography, Icon, AccordionDetails } from '@material-ui/core'
import Accordion from 'scences/GlassX/Settings/Components/Accordion'
import AccordionSummary from 'scences/GlassX/Settings/Components/AccordionSummary'
import { convertGmtToLocalTime } from 'utils'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	moreInfoRoot: {
		// paddingTop: theme.spacing(4),
	},
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 0),
		},
	},
	detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0.5, 0),
		},
	},
	detailsTitle: {
		fontSize: 'small',
		color: theme.palette.almostBlack[700],
	},
	detailsValue: {
		fontSize: 'small',
		fontWeight: 400,
	},
}))
const MoreInfo = ({ stickyDetails }) => {
	const classes = useStyles()
	return (
		<div className={classes.moreInfoRoot}>
			<Accordion defaultExpanded={true}>
				<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>}>
					<Typography className={classes.accTitle} variant='body2'>
						More Information
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accSummary}>
					<Grid container>
						<Grid item container sm={6} className={classes.detailsContent}>
							<Grid item xs={12} sm={4}>
								<Typography className={classes.detailsTitle} gutterBottom>
									Created By
								</Typography>
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography className={classes.detailsValue} gutterBottom>
									{_.join([_.get(stickyDetails, 'created_by.first_name'), _.get(stickyDetails, 'created_by.last_name')], ' ')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container sm={6} className={classes.detailsContent}>
							<Grid item xs={12} sm={6}>
								{
									<Typography className={classes.detailsTitle} gutterBottom>
										Created Date &amp; Time
									</Typography>
								}
							</Grid>
							<Grid item xs={12} sm={6}>
								{
									<Typography className={classes.detailsValue} gutterBottom>
										{convertGmtToLocalTime(_.get(stickyDetails, 'created_date'), 'DD MMM YYYY hh:mm a')}
									</Typography>
								}
							</Grid>
						</Grid>
						<Grid item container sm={6} className={classes.detailsContent}>
							<Grid item xs={12} sm={4}>
								{
									<Typography className={classes.detailsTitle} gutterBottom>
										Modified By
									</Typography>
								}
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography className={classes.detailsValue} gutterBottom>
									{_.join([_.get(stickyDetails, 'modified_by.first_name'), _.get(stickyDetails, 'modified_by.last_name')], ' ')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container sm={6} className={classes.detailsContent}>
							<Grid item xs={12} sm={6}>
								{
									<Typography className={classes.detailsTitle} gutterBottom>
										Modified Date &amp; Time
									</Typography>
								}
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography className={classes.detailsValue} gutterBottom>
									{convertGmtToLocalTime(_.get(stickyDetails, 'modified_date'), 'DD MMM YYYY hh:mm a')}{' '}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}
MoreInfo.propTypes = {
	stickyDetails: PropTypes.object,
}

export default MoreInfo
