import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CHART_EDITOR, CHART_DRAGGABLE_ITEM_TYPE, AXIS_ACCEPT } from '../../../../constants'
import AxisEditor from './AxisEditor'
import BoxModel from './BoxModelEditor'
import AggregationDialog from '../AggregationDialog'
import ListDialog from '../ListDialog'
import _ from 'lodash'

const RenderEditor = ({ type, children, axis, ...props }) => {
	switch (type) {
		case CHART_EDITOR.BOX_MODELS:
			return <BoxModel {...props}>{children}</BoxModel>
		default:
			const axisEditorProps = { ...axis, ...props }
			return <AxisEditor {...axisEditorProps}>{children}</AxisEditor>
	}
}

const Editor = ({ getChartColDetail, getAvailColsOnEditor, onAddColOnEditor, changeColProps, children, ...props }) => {
	/* ADD COLUMN DIALOG FUNCTIONS START */

	const [addColListDialog, setAddColListDialog] = useState({
		open: false,
		axisOrBoxId: '',
		targetScale: '',
		colsToShow: [],
	})

	const getAddColsListForEditorScale = (axisOrBoxAccepts, extColsOnAxisOrBox) => {
		const allAvailColsToAdd = getAvailColsOnEditor(axisOrBoxAccepts)
		const availColsToAdd = _.filter(allAvailColsToAdd, (colObj) => _.indexOf(extColsOnAxisOrBox, _.get(colObj, 'column_name', '')) === -1)
		return _.map(availColsToAdd, (col) => _.get(col, 'column_name', '')) || []
	}

	const openAddColListDialog = (axisOrBoxId, extColsOnAxisOrBox, axisOrBoxAccepts, targetScale) => {
		const colsToShowOnDialog = getAddColsListForEditorScale(axisOrBoxAccepts, extColsOnAxisOrBox)
		setAddColListDialog({
			open: true,
			axisOrBoxId,
			targetScale,
			colsToShow: colsToShowOnDialog,
		})
	}

	const closeAddColListDialog = () => {
		setAddColListDialog({ ...addColListDialog, open: false })
	}

	const onClickColOnAddDialog = (colName) => {
		onAddColOnEditor(colName, addColListDialog.axisOrBoxId, addColListDialog.targetScale)
		closeAddColListDialog()
	}

	/* ADD COLUMN DIALOG FUNCTIONS END */

	/* COLUMN AGGREGATION DIALOG FUNCTIONS START */

	const [colAggreDialog, setColAggreDialog] = useState({
		open: false,
		axisOrBoxId: '',
		targetScale: '',
		colName: undefined,
	})

	const openAggreDialog = (colName, axisOrBoxId, targetScale) => {
		setColAggreDialog({ open: true, colName, axisOrBoxId, targetScale })
	}

	const closeAggreDialog = () => {
		setColAggreDialog({ ...colAggreDialog, open: false })
	}

	const aggrDialogColProps = colAggreDialog.open ? getChartColDetail(colAggreDialog.colName, colAggreDialog.targetScale) : {}

	/* COLUMN AGGREGATION DIALOG FUNCTIONS END */

	return (
		<>
			<RenderEditor getChartColDetail={getChartColDetail} openAddColListDialog={openAddColListDialog} openAggreDialog={openAggreDialog} {...props}>
				{children}
			</RenderEditor>
			<AggregationDialog
				key={`${colAggreDialog.axisOrBoxId}.${colAggreDialog.colName}`}
				open={colAggreDialog.open}
				handleClose={closeAggreDialog}
				colName={colAggreDialog.colName}
				colProps={aggrDialogColProps}
				targetScale={colAggreDialog.targetScale}
				onChangeProps={changeColProps}
				axisOrBoxId={colAggreDialog.axisOrBoxId}
			/>
			<ListDialog
				title={'Add Column'}
				subtitle={'Click (or) tap the column to add'}
				handleClose={closeAddColListDialog}
				open={addColListDialog.open}
				data={addColListDialog.colsToShow}
				isActionEnabled={false}
				emptyIcon={'view_column'}
				emptyMessage={'No Columns Found'}
				onClickListItem={onClickColOnAddDialog}
			/>
		</>
	)
}

Editor.propTypes = {
	type: PropTypes.oneOf([CHART_EDITOR.BOX_MODELS, CHART_EDITOR.AXIS]).isRequired,
	boxModels: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			desc: PropTypes.string.isRequired,
			accepts: PropTypes.arrayOf(PropTypes.oneOf([CHART_DRAGGABLE_ITEM_TYPE.DIMENSIONS, CHART_DRAGGABLE_ITEM_TYPE.MEASURES])).isRequired,
			allowCols: PropTypes.oneOf([AXIS_ACCEPT.SINGLE, AXIS_ACCEPT.MULTIPLE]).isRequired,
		})
	),
	boxModelCols: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
	onDropColOnEditor: PropTypes.func.isRequired,
	onAddColOnEditor: PropTypes.func.isRequired,
	removeColOnEditor: PropTypes.func.isRequired,
	axis: PropTypes.shape({
		x: PropTypes.bool.isRequired,
		y: PropTypes.bool.isRequired,
		y1: PropTypes.bool,
		children: PropTypes.node,
		xPlaceholder: PropTypes.string,
		yPlaceholder: PropTypes.string,
		xAccepts: PropTypes.arrayOf(PropTypes.oneOf([CHART_DRAGGABLE_ITEM_TYPE.DIMENSIONS, CHART_DRAGGABLE_ITEM_TYPE.MEASURES])).isRequired,
		yAccepts: PropTypes.arrayOf(PropTypes.oneOf([CHART_DRAGGABLE_ITEM_TYPE.DIMENSIONS, CHART_DRAGGABLE_ITEM_TYPE.MEASURES])).isRequired,
		y1Accepts: PropTypes.arrayOf(PropTypes.oneOf([CHART_DRAGGABLE_ITEM_TYPE.DIMENSIONS, CHART_DRAGGABLE_ITEM_TYPE.MEASURES])).isRequired,
		axisCols: PropTypes.shape({
			x: PropTypes.arrayOf(PropTypes.string),
			y: PropTypes.arrayOf(PropTypes.string),
			y1: PropTypes.arrayOf(PropTypes.string),
		}),
		//SIGNLE
		xAxisAllowCols: PropTypes.oneOf([AXIS_ACCEPT.SINGLE, AXIS_ACCEPT.MULTIPLE]).isRequired,
		yAxisAllowCols: PropTypes.oneOf([AXIS_ACCEPT.SINGLE, AXIS_ACCEPT.MULTIPLE]).isRequired,
		y1AxisAllowCols: PropTypes.oneOf([AXIS_ACCEPT.SINGLE, AXIS_ACCEPT.MULTIPLE]).isRequired,
	}),
	dimensions: PropTypes.arrayOf(
		PropTypes.shape({
			column_name: PropTypes.string.isRequired,
			data_type: PropTypes.string.isRequired,
		})
	).isRequired,
	measures: PropTypes.arrayOf(
		PropTypes.shape({
			column_name: PropTypes.string.isRequired,
			data_type: PropTypes.string.isRequired,
		})
	).isRequired,
	changeColProps: PropTypes.func.isRequired,
	getChartColDetail: PropTypes.func.isRequired,
	getAvailColsOnEditor: PropTypes.func.isRequired,
}

Editor.defaultProps = {
	type: CHART_EDITOR.AXIS,
}

export default Editor
