import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, Icon, DialogTitle, DialogContent, Grid, TextField, IconButton } from '@material-ui/core'
import PrimaryButton from 'components/PrimaryButton'
import _ from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
	cancelButton: {
		marginRight: theme.spacing(1),
	},
	dialogTitle: {
		fontSize: theme.typography.pxToRem(15),
		padding: '16px 24px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	sortIconHolder: {
		display: 'flex',
		alignItems: 'center',
	},
	limitField: {
		'& .MuiIcon-root': {
			fontSize: '1.25rem',
			visibility: 'hidden',
		},
		'& .MuiInputBase-root': {
			'&:hover': {
				'& .MuiIcon-root': {
					visibility: 'visible',
				},
			},
		},
	},
	resetBtnContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'end',
		},
		'& .MuiIconButton-root': {
			marginTop: '4px',
		},
		'& .MuiButton-text': {
			marginTop: '8px',
			textTransform: 'capitalize',
			fontWeight: 400,
		},
	},
}))

const ValidationSchema = Yup.object().shape({
	limit: Yup.number().typeError('Limit must be a number').min(1, 'Minimum limit should be 1').nullable().notRequired(),
})

const GraphSort = (props) => {
	const {
		open,
		handleApply,
		handleClose,
		dimensionCols,
		measureCols,
		orderBy,
		groupBy,
		sortBy,
		sortByVal,
		// groupByVal,
		limitBy,
		measures,
		dimensions,
		isSortByVal,
		isTableChart,
	} = props
	const classes = useStyles()
	const orderValues = isTableChart ? [...dimensionCols, ...measureCols] : [...dimensions, ...measures]
	const sortValues = [
		{ order_name: 'Ascending', order: 'ASC' },
		{ order_name: 'Descending', order: 'DESC' },
	]

	const sortByValues = [
		{ sort_by_value: 'field', sort_display_value: 'Field' },
		{ sort_by_value: 'value', sort_display_value: 'Value' },
	]

	// const groupByValues = [
	// 	{ group_by_value: 'sum', group_display_value: 'Sum' },
	// 	{ group_by_value: 'count', group_display_value: 'Count' },
	// 	{ group_by_value: 'min', group_display_value: 'Min' },
	// 	{ group_by_value: 'max', group_display_value: 'Max' },
	// 	{ group_by_value: 'avg', group_display_value: 'Average' },
	// ]

	const onSubmit = (values) => {
		let sortedValues = {
			// order: isTableChart ? values?.order_by?.column_name : values?.order_by?.field_name,
			// group: isTableChart ? values?.group_by?.column_name : values?.group_by?.field_name,
			order: values?.order_by?.field_name,
			group: values?.group_by?.field_name,
			sort: values?.sort_by?.order,
			sortVal: values?.sort_by_value?.sort_by_value,
			// groupVal: values?.group_by_value?.group_by_value,
			limit: values?.limit,
		}
		handleApply(sortedValues)
	}

	const formik = useFormik({
		initialValues: {
			order_by: null,
			group_by: null,
			sort_by: null,
			sort_by_value: null,
			// group_by_value: null,
			limit: '',
		},
		onSubmit: (values) => {
			onSubmit(values)
		},
		validationSchema: ValidationSchema,
	})

	useEffect(() => {
		let _orderValues = [...dimensionCols, ...measureCols]
		let order_Values = [...dimensions, ...measures]
		// formik.setFieldValue(
		// 	'order_by',
		// 	_.isEmpty(orderBy) ? null : isTableChart ? _.find(_orderValues, { column_name: orderBy }) : _.find(order_Values, { field_name: orderBy })
		// )
		// formik.setFieldValue(
		// 	'group_by',
		// 	_.isEmpty(groupBy) ? null : isTableChart ? _.find(_orderValues, { column_name: groupBy }) : _.find(order_Values, { field_name: groupBy })
		// )
		formik.setFieldValue('order_by', _.isEmpty(orderBy) ? null : _.find(order_Values, { field_name: orderBy }))
		formik.setFieldValue('group_by', _.isEmpty(groupBy) ? null : _.find(order_Values, { field_name: groupBy }))
		formik.setFieldValue('sort_by', _.isEmpty(sortBy) ? null : _.find(sortValues, { order: sortBy }))
		formik.setFieldValue('sort_by_value', _.isEmpty(sortByVal) ? null : _.find(sortByValues, { sort_by_value: sortByVal }))
		// formik.setFieldValue('group_by_value', _.isEmpty(groupByVal) ? null : _.find(groupByValues, { group_by_value: groupByVal }))
		formik.setFieldValue('limit', _.isEmpty(limitBy) ? '' : limitBy)
	}, [orderBy, sortBy, sortByVal, limitBy, dimensionCols, measureCols, open])

	return (
		<form onSubmit={formik.handleSubmit}>
			<Dialog open={open} className={classes.dialogRoot} fullWidth={true} maxWidth={'md'}>
				<DialogTitle id='sort-dialog' disableTypography={true} className={classes.dialogTitle}>
					<div className={classes.sortIconHolder}>
						<Icon fontSize='small'>sort</Icon>
						&nbsp;&nbsp;<span>Sort</span>
					</div>
					<div>
						<Button size='small' variant='outlined' onClick={handleClose} className={classes.cancelButton}>
							Cancel
						</Button>
						<PrimaryButton size='small' type='submit' onClick={formik.handleSubmit} disabled={!_.isEmpty(formik.errors) && true}>
							Apply
						</PrimaryButton>
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={12} md={isSortByVal ? 3 : 4}>
							<Autocomplete
								id='order_by'
								name='order_by'
								value={formik.values.order_by}
								// options={orderValues ? orderValues : []}
								options={isTableChart ? (dimensions ? dimensions : []) : orderValues ? orderValues : []}
								onChange={(event, newValue) => {
									formik.setFieldValue('order_by', newValue)
								}}
								// getOptionLabel={(option) => (isTableChart ? option?.column_name : option?.field_name)}
								// getOptionSelected={(option, value) =>
								// 	isTableChart ? option?.column_name === value?.column_name : option?.field_name === value?.field_name
								// }
								getOptionLabel={(option) => option?.field_name}
								getOptionSelected={(option, value) => option?.field_name === value?.field_name}
								size={'small'}
								noOptionsText='No Dimensions and Measures'
								renderInput={(params) => (
									<TextField {...params} name='order_by' label='Order By' variant='outlined' size='small' margin='normal' fullWidth />
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={isSortByVal ? 3 : 4}>
							<Autocomplete
								id='sort_by'
								name='sort_by'
								options={sortValues}
								value={formik.values.sort_by}
								onChange={(event, newValue) => {
									formik.setFieldValue('sort_by', newValue)
								}}
								getOptionLabel={(option) => option?.order_name}
								getOptionSelected={(option, value) => option?.order_name === value?.order_name}
								size={'small'}
								renderInput={(params) => (
									<TextField {...params} name='sort_by' label='Sort By' variant='outlined' size='small' margin='normal' fullWidth />
								)}
							/>
						</Grid>
						{isSortByVal && (
							<Grid item xs={12} sm={12} md={3}>
								<Autocomplete
									id='sort_by_value'
									name='sort_by_value'
									options={sortByValues}
									value={formik.values.sort_by_value}
									onChange={(event, newValue) => {
										formik.setFieldValue('sort_by_value', newValue)
									}}
									getOptionLabel={(option) => option?.sort_display_value}
									getOptionSelected={(option, value) => option?.sort_display_value === value?.sort_display_value}
									size={'small'}
									renderInput={(params) => (
										<TextField {...params} name='sort_by_value' label='Sort By Value' variant='outlined' size='small' margin='normal' fullWidth />
									)}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={12} md={isSortByVal ? 3 : 4}>
							<TextField
								id='limit'
								name='limit'
								label='Limit'
								variant='outlined'
								size='small'
								margin='normal'
								fullWidth={true}
								className={classes.limitField}
								value={formik.values.limit}
								error={Boolean(formik.errors.limit)}
								helperText={formik.errors.limit && formik.errors.limit}
								onChange={formik.handleChange}
								inputProps={{ min: '0' }}
								InputProps={{
									endAdornment: (
										<IconButton
											size='small'
											onClick={() => {
												formik.setFieldValue('limit', '')
											}}
										>
											<Icon>close</Icon>
										</IconButton>
									),
								}}
							></TextField>
						</Grid>
						<Grid item xs={12} sm={12} md={isSortByVal ? 3 : 4}>
							<Autocomplete
								id='group_by'
								name='group_by'
								value={formik.values.group_by}
								options={dimensions ? dimensions : []}
								onChange={(event, newValue) => {
									formik.setFieldValue('group_by', newValue)
								}}
								// getOptionLabel={(option) => (isTableChart ? option?.column_name : option?.field_name)}
								// getOptionSelected={(option, value) =>
								// 	isTableChart ? option?.column_name === value?.column_name : option?.field_name === value?.field_name
								// }
								getOptionLabel={(option) => option?.field_name}
								getOptionSelected={(option, value) => option?.field_name === value?.field_name}
								size={'small'}
								noOptionsText='No Dimensions and Measures'
								renderInput={(params) => (
									<TextField {...params} name='group_by' label='Group By' variant='outlined' size='small' margin='normal' fullWidth />
								)}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={12} md={3}>
							<Autocomplete
								id='group_by_value'
								name='group_by_value'
								options={groupByValues}
								value={formik.values.group_by_value}
								onChange={(event, newValue) => {
									formik.setFieldValue('group_by_value', newValue)
								}}
								getOptionLabel={(option) => option?.group_display_value}
								getOptionSelected={(option, value) => option?.group_display_value === value?.group_display_value}
								size={'small'}
								renderInput={(params) => (
									<TextField {...params} name='group_by_value' label='Group By Value' variant='outlined' size='small' margin='normal' fullWidth />
								)}
							/>
						</Grid> */}
						<Grid item xs={12} sm={12} md={isSortByVal ? 3 : 4} className={classes.resetBtnContainer}>
							{/* <IconButton>
								<Icon>clear</Icon>
							</IconButton> */}
							<Button color='primary' size='small' onClick={formik.handleReset}>
								Reset All
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</form>
	)
}

export default GraphSort
