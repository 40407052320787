import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Typography, Icon } from '@material-ui/core'
import _ from 'lodash'
import { useSelector } from 'react-redux'

GettingStarted.propTypes = {
	onClickNext: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
	},
}))

function GettingStarted({ onClickNext }) {
	const classes = useStyles()
	const userName = useSelector((state) => _.join([state?.session?.user?.userDetails?.first_name, state?.session?.user?.userDetails?.last_name], ' '))

	return (
		<div className={classes.root}>
			<Typography variant='h4' align='center' gutterBottom>
				Welcome, {userName}
			</Typography>
			<Typography variant='subtitle1' align='center' color='textSecondary' gutterBottom>
				I'll guide you from here, initially to start with GlassX creation.
			</Typography>
			<Button
				onClick={onClickNext}
				variant='contained'
				color='primary'
				disableElevation
				size='small'
				className={classes.button}
				endIcon={<Icon>east</Icon>}
			>
				Get Started
			</Button>
		</div>
	)
}

export default GettingStarted
