import React from 'react'
import { makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		height: 36,
		padding: theme.spacing(0),
		paddingLeft: 8,
	},
	flexBox: {
		display: 'flex',
	},
	titleInput: {
		fontWeight: 'bold',
		letterSpacing: 0.1,
	},
	title: {
		flex: 1,
		paddingTop: 2,
		fontWeight: 'bold',
		letterSpacing: 0.1,
	},
}))

const LaneHeader = () => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<Skeleton variant='text' height={32} style={{ width: '70%' }} />
		</div>
	)
}

export default LaneHeader
