import React from 'react'
import PropTypes from 'prop-types'
import { Icon, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { Autocomplete } from '@material-ui/lab'

SearchAutocomplete.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	defaultValue: PropTypes.string,
	textboxRef: PropTypes.any,
}

const useStyles = makeStyles((theme) => ({
	searchBox: {
		'& .MuiOutlinedInput-root': {
			borderRadius: '24px',
			[theme.breakpoints.down('xs')]: {
				borderRadius: '20px',
			},
			'&:hover fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'rgba(223,225,229,0)',
				boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
			},
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			[theme.breakpoints.down('xs')]: {
				padding: '0 0 0 8px',
				fontSize: '14px',
			},
			padding: '4px 34px 4px 12px',
		},
	},
	searchIco: {
		color: theme.palette.text.secondary,
		opacity: '0.8',
	},
	autocompleteOption: {
		[theme.breakpoints.down('xs')]: {
			minHeight: 'unset',
		},
	},
	optionTxt: {
		display: 'flex',
		fontSize: '16px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	optionIco: {
		color: theme.palette.text.secondary,
		opacity: '0.8',
		verticalAlign: 'middle',
		paddingRight: theme.spacing(1),
	},
}))

function SearchAutocomplete({ options, onInputChange, textboxRef, defaultValue, onChange, className, inputClassName }) {
	const classes = useStyles()

	return (
		<Autocomplete
			freeSolo
			options={options || []}
			classes={{ option: classes.autocompleteOption }}
			onChange={onChange}
			onInputChange={onInputChange}
			className={className}
			defaultValue={defaultValue}
			filterSelectedOptions={true}
			renderInput={({ InputProps, ...params }) => (
				<TextField
					{...params}
					label=''
					margin='normal'
					variant='outlined'
					inputRef={textboxRef}
					className={classnames(classes.searchBox, inputClassName)}
					InputProps={{
						...InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								{' '}
								<Icon fontSize='small' className={classes.searchIco}>
									search
								</Icon>{' '}
							</InputAdornment>
						),
					}}
				/>
			)}
			getOptionLabel={(option) => option}
			renderOption={(option) => {
				return (
					<div>
						<Typography className={classes.optionTxt}>
							<Icon fontSize='small' className={classes.optionIco}>
								search
							</Icon>
							<span>{option}</span>
						</Typography>
					</div>
				)
			}}
		/>
	)
}

export default SearchAutocomplete
