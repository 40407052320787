import { Button, Divider, Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	footerRoot: {
		// borderTop: `1px solid ${theme.palette.grey[300]}`,
		position: 'sticky',
		bottom: 0,
		zIndex: 999,
		background: theme.palette.common.white,
		marginTop: 25,
	},
	centerAlign: {
		textAlign: 'center',
		borderRight: `1px solid ${theme.palette.divider}`,
		borderLeft: `1px solid ${theme.palette.divider}`,
		'& button, & a': {
			padding: theme.spacing(1.5),
			width: '100%',
		},
	},
}))

const LoaderSkeleton = () => {
	const classes = useStyles()
	return (
		<>
			<div style={{ padding: 20 }}>
				<Grid container spacing={1}>
					<Grid item lg={8} sm={6} xs={6}>
						<Skeleton width='20%' height={20} variant={'h1'} />
						<Skeleton width='25%' height={10} variant={'h1'} style={{ marginTop: 5 }} />
						<Skeleton width='40%' height={30} variant={'h1'} style={{ marginTop: 5 }} />
						<Skeleton width='25%' height={15} variant={'h1'} style={{ marginTop: 5 }} />
					</Grid>
					<Grid item lg={3}>
						<Skeleton width='100%' height={30} variant={'h1'} />
					</Grid>
					<Grid item lg={1}>
						<Skeleton width='100%' height={30} variant={'h1'} />
					</Grid>
					<Grid container item={12} spacing={1} style={{ marginTop: '10px' }}>
						<Grid item lg={12}>
							<Skeleton width='100%' height={1} variant={'h1'} />
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={1} lg={10} style={{ margin: '10px auto' }}>
					<Skeleton width='100%' height={250} variant={'h1'} />
				</Grid>

				<Grid container spacing={1} style={{ marginTop: '10px' }}>
					<Grid lg={6}>
						<div style={{ padding: '12px 12px 12px 0' }}>
							<Skeleton width='50%' height={15} variant={'h1'} />
							<Skeleton width='100%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
						</div>
					</Grid>
					<Grid lg={6}>
						<div style={{ padding: '12px 12px 12px 0' }}>
							<Skeleton width='50%' height={15} variant={'h1'} />
							<Skeleton width='100%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
						</div>
					</Grid>
				</Grid>
			</div>
			<Grid container className={classes.footerRoot}>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={6} className={classes.centerAlign}>
					<Button target='_blank' disabled size='small' color='primary'>
						<Skeleton width='100%' height={25} />
					</Button>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.centerAlign}>
					<Button target='_blank' disabled size='small' color='primary'>
						<Skeleton width='100%' height={25} />
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
		</>
	)
}

export default LoaderSkeleton
