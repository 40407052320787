import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import _ from 'lodash'
import { convertLocalTimeToGmtStr, msToDurationTxt } from 'utils'

const LogFormSchema = Yup.object().shape({
	startDate: Yup.date()
		.required('Start time is required')
		.typeError('Invalid Start Time')
		.test('isFuture', "The start time can't be in the future", function (value) {
			return moment(value).isBefore(new Date())
		}),
	endDate: Yup.date()
		.required('End time is required')
		.typeError('Invalid End Time')
		.test('isFuture', "The end time can't be in the future", (value) => {
			return moment(value).isBefore(new Date()) || moment(value).isSame(new Date())
		})
		.test('isBeforeStartDate', 'The end time must be after the start time', function (value) {
			const startDate = this.options.parent?.startDate
			return moment(moment(value)).isAfter(startDate)
		})
		.test('isConflictWithExstLogs', 'You have an existing session at this time', function (value) {
			const existingLogs = this.options.parent?.existingLogs
			const currentLogStartTime = moment(this.options.parent?.startDate).valueOf()
			const currentLogEndTime = moment(value).valueOf()
			const allLogsExceptCurrent = _.filter(existingLogs, (log) => !_.isEqual(log?.time_log_id, this.options.parent?.time_log_id))
			return !_.some(allLogsExceptCurrent, (log) => {
				return moment(log?.from).valueOf() < currentLogEndTime && moment(log?.to || new Date()).valueOf() > currentLogStartTime
			})
		}),
})

const useStyles = makeStyles((theme) => ({
	calendarWrapper: {
		margin: '0 auto',
	},
}))

const LogForm = React.forwardRef(({ onSubmit, existingLogs, initialValues }, ref) => {
	const classes = useStyles()
	const lastValidDateRange = useRef()

	const getInitialValues = useCallback(
		() => ({
			time_log_id: initialValues?.time_log_id || '',
			startDate: moment(initialValues?.from),
			endDate: moment(initialValues?.to),
			is_manual: initialValues?.is_manual,
			existingLogs: existingLogs,
		}),
		[initialValues, existingLogs]
	)

	const formik = useFormik({
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			const valuesToSubmit = {
				time_log_id: values?.time_log_id,
				from: convertLocalTimeToGmtStr(values?.startDate.format('YYYY-MM-DD HH:mm:ss')),
				to: convertLocalTimeToGmtStr(values?.endDate.format('YYYY-MM-DD HH:mm:ss')),
			}
			onSubmit(valuesToSubmit)
		},
		validationSchema: LogFormSchema,
	})

	useEffect(() => {
		ref.current = formik
		return () => {
			ref.current = null
		}
	}, [ref, formik])

	const handleCalendarRangeSelect = (e) => {
		formik?.setValues({
			time_log_id: formik?.values?.time_log_id,
			startDate: moment(e.selection.startDate).set({ hours: formik.values?.startDate?.get('h'), minutes: formik.values?.startDate?.get('m') }),
			endDate: moment(e.selection.endDate).set({ hours: formik.values?.endDate?.get('h'), minutes: formik.values?.endDate?.get('m') }),
		})
	}

	const selectionRange = useMemo(() => {
		if (formik?.values?.startDate?.isValid() && formik?.values?.endDate?.isValid()) {
			lastValidDateRange.current = {
				startDate: formik?.values?.startDate?.toDate(),
				endDate: formik?.values?.endDate?.toDate(),
				key: 'selection',
			}
		}
		return lastValidDateRange.current
	}, [formik.values])

	const onChangeTimeField = (fieldName, value) => {
		var valueToUpdate = moment(value)
		valueToUpdate = valueToUpdate.isValid()
			? moment(_.get(lastValidDateRange.current, fieldName)).set({ hours: valueToUpdate.get('hours'), minutes: valueToUpdate.get('minutes') })
			: valueToUpdate
		formik?.setFieldValue(fieldName, valueToUpdate)
	}

	return (
		<form ref={ref}>
			<Grid container spacing={1}>
				<Grid container justifyContent='center' item xs={12}>
					<DateRange
						showDateDisplay={false}
						className={classes.calendarWrapper}
						ranges={[selectionRange]}
						maxDate={new Date()}
						onChange={handleCalendarRangeSelect}
					/>
				</Grid>
				<Grid item xs={6}>
					<KeyboardTimePicker
						mask='__:__ _M'
						name='startDate'
						value={formik?.values?.startDate}
						onChange={(value) => {
							onChangeTimeField('startDate', value)
						}}
						error={Boolean(formik?.errors?.startDate)}
						helperText={Boolean(formik?.errors?.startDate) && formik?.errors?.startDate}
						size='small'
						label='Start Time'
						inputVariant='outlined'
					/>
				</Grid>
				<Grid item xs={6}>
					<KeyboardTimePicker
						size='small'
						name='endDate'
						mask='__:__ _M'
						value={formik?.values?.endDate}
						onChange={(value) => {
							onChangeTimeField('endDate', value)
						}}
						error={Boolean(formik?.errors?.endDate)}
						helperText={Boolean(formik?.errors?.endDate) && formik?.errors?.endDate}
						label='End Time'
						inputVariant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<Box padding={1}>
						<Typography variant='caption' component='div' align='center'>
							Time Spent
						</Typography>
						<Typography variant='h6' component='div' align='center'>
							{msToDurationTxt(formik?.isValid ? formik.values?.endDate?.valueOf() - formik.values?.startDate?.valueOf() : 0, true)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</form>
	)
})

LogForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	initialValues: PropTypes.shape({
		time_log_id: PropTypes.string,
		from: PropTypes.string,
		to: PropTypes.string,
		is_manual: PropTypes.bool,
	}),
}

export default LogForm
