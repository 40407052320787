import React from 'react'
import { InputBase, Icon, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.almostBlack[300]}`,
		borderRadius: '6px',
		paddingLeft: theme.spacing(1),
		color: theme.palette.almostBlack[800],
	},
	searchIcon: {
		color: theme.palette.almostBlack[400],
	},
}))

const SearchBox = (props) => {
	const classes = useStyles()

	return (
		<>
			<InputBase
				fullWidth
				{...props}
				endAdornment={props.adornment ? props.adornment : <Icon className={classes.searchIcon}>search</Icon>}
				classes={{
					root: classes.root,
				}}
			/>
		</>
	)
}

export default SearchBox
