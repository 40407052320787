import { ACTION_TYPES } from '../../../constants'
import graphsDataRespReducer from './graphsData'
import updateDashboardReducer from './updateDashboard'
import _ from 'lodash'
import { buildUIFiltersFromApiResp, buildIPFiltersFromApiResp } from '../api'

const getDefaultState = () => ({
	isFetching: false,
	isError: false,
	errMsg: '',
	details: {},
	graphsDataResp: {},
	updateDashboard: {},
	filters: [],
	inPageFilters: [],
})

const dashboard = (state = getDefaultState(), action) => {
	let existingSate = _.cloneDeep(state)
	switch (action.type) {
		case ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_PENDING:
			return {
				...existingSate,
				isFetching: true,
				isError: false,
				errMsg: '',
				details: {},
				graphsDataResp: {},
				updateDashboard: {},
				filters: [],
			}
		case ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_FULFILLED:
			return {
				...existingSate,
				isFetching: false,
				isError: false,
				errMsg: '',
				details: _.get(action, 'payload.data.data', {}),
				filters: buildUIFiltersFromApiResp(_.get(action, 'payload.data.data.filter_definitions', [])),
				inPageFilters: buildIPFiltersFromApiResp(_.get(action, 'payload.data.data.in_page_filters', [])),
			}
		case ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_REJECTED:
			return {
				...existingSate,
				isFetching: false,
				isError: true,
				errMsg: '',
				details: {},
				filters: [],
			}
		case ACTION_TYPES.FETCHING_CUSTOM_DASHBOARD_DETAILS_PENDING:
			return {
				...existingSate,
				isFetching: true,
				isError: false,
				errMsg: '',
				details: {},
				graphsDataResp: {},
				updateDashboard: {},
				filters: [],
			}
		case ACTION_TYPES.FETCHING_CUSTOM_DASHBOARD_DETAILS_FULFILLED:
			return {
				...existingSate,
				isFetching: false,
				isError: false,
				errMsg: '',
				details: _.get(action, 'payload.data.data', {}),
				filters: buildUIFiltersFromApiResp(_.get(action, 'payload.data.data.filter_definitions', [])),
				inPageFilters: buildIPFiltersFromApiResp(_.get(action, 'payload.data.data.in_page_filters', [])),
			}
		case ACTION_TYPES.FETCHING_CUSTOM_DASHBOARD_DETAILS_REJECTED:
			return {
				...existingSate,
				isFetching: false,
				isError: true,
				errMsg: '',
				details: {},
				filters: [],
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DETAILS:
			return {
				...existingSate,
				inPageFilters: action.payload,
			}
		case ACTION_TYPES.CHANGE_DASHBOARD_NAME:
			_.set(existingSate, 'details.dashboard_name', action.payload)
			return existingSate
		case ACTION_TYPES.SET_DASHBOARD_LAYOUT:
			_.set(existingSate, 'details.dashboard_layout.layouts', action.payload)
			return existingSate
		case ACTION_TYPES.SET_DASHBOARD_STYLE:
			_.set(existingSate, 'details.dashboard_layout.style', action.payload)
			return existingSate
		case ACTION_TYPES.FETCHING_DASHBOARD_DATA_PENDING:
			return {
				...existingSate,
				graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action),
			}
		case ACTION_TYPES.FETCHING_DASHBOARD_DATA_FULFILLED:
			return {
				...existingSate,
				graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action),
			}
		case ACTION_TYPES.FETCHING_DASHBOARD_DATA_REJECTED:
			return {
				...existingSate,
				graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action),
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_PENDING:
			return {
				...existingSate,
				updateDashboard: updateDashboardReducer(state.updateDashboard, action),
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_FULFILLED:
			return {
				...existingSate,
				updateDashboard: updateDashboardReducer(state.updateDashboard, action),
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_REJECTED:
			return {
				...existingSate,
				updateDashboard: updateDashboardReducer(state.updateDashboard, action),
			}
		case ACTION_TYPES.RESET_DASHBOARD:
			return {
				isFetching: false,
				isError: false,
				errMsg: '',
				details: {},
				graphsDataResp: {},
				updateDashboard: {},
				filters: [],
				inPageFilters: [],
			}
		default:
			return state
	}
}

export default dashboard
