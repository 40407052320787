import { ACTION_TYPES } from '../../../constants'

const getBpId = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_BP_ID_PENDING: {
			return {
				...state,
				isFetchingGetbp: true,
				isFetchingGetbpFailed: false,
				isFetchingGetbpError: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.GET_BP_ID_FULFILLED: {
			return {
				...state,
				isFetchingGetbp: false,
				isFetchingGetbpFailed: false,
				isFetchingGetbpError: undefined,
				data: action.payload.data,
			}
		}
		case ACTION_TYPES.GET_BP_ID_REJECTED: {
			return {
				...state,
				isFetchingGetbp: false,
				isFetchingGetbpFailed: true,
				isFetchingGetbpError: action.payload,
				data: {},
			}
		}
		default:
			return state
	}
}

export default getBpId
