import { Drawer, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import Header from './Header'
import StickyTabs from './StickyTabs'
import GlassApi from '../../../services/glass/api'
import Axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import StickyLoader from './stickyloader'
import { convertGmtToLocalTime, convertLocalTimeToGmtStr, getErrMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import PropTypes from 'prop-types'
import { updateGlassSticky } from 'services/glass/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import session from 'services/session/reducers'

const useStyles = makeStyles((theme) => ({
	drawer: {
		'& .MuiDrawer-paperAnchorRight': {
			width: '45%',
			maxWidth: '600px',
			// padding: '10px 20px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: 0,
			},
		},
		'& .MuiBackdrop-root': {
			opacity: 0,
		},
	},
	loaderContainer: {
		padding: '10px 20px',
	},
}))

const stcikySchema = Yup.object().shape({
	sticky_title: Yup.string().required('Please enter the TakeDown Title'),
	// due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	is_approval: Yup.boolean(),
	reporting_to: Yup.object()
		.nullable()
		.when('is_approval', {
			is: true,
			then: Yup.object().nullable().required('Please select reporting to'),
			otherwise: null,
		}),
})

const StickySlider = ({
	match,
	onClose,
	glassCode,
	glassLabels,
	sessionId,
	glassMembers,
	isOwner,
	isMember,
	activityTypes,
	socketRef,
	isSocketConnected,
	authToken,
	history,
	isGuest,
	lanesData,
}) => {
	const cardId = _.get(match, 'params.cardId')
	const classes = useStyles()
	const [stickyData, setStickyData] = useState({ stickyDetails: {}, loading: true })
	const { stickyDetails, loading } = stickyData
	const currentEmail = useSelector((state) => _.get(state, 'session.user.userDetails.email'))
	const [attachmentLoader, setAttachmentLoader] = useState(false)
	const createdBy = useMemo(() => _.isEqual(_.get(stickyDetails, 'created_by.email'), currentEmail), [currentEmail, stickyDetails])
	const reportedTo = useMemo(() => _.isEqual(_.get(stickyDetails, 'reporting_to.email'), currentEmail), [currentEmail, stickyDetails])
	const assignedTo = useMemo(() => _.isEqual(_.get(stickyDetails, 'assigned_to.email'), currentEmail), [currentEmail, stickyDetails])

	const isEditEnabled = (createdBy || reportedTo || isOwner) && !isGuest
	const attachmentEditEnabled = (assignedTo || createdBy || reportedTo || isOwner) && !isGuest
	const isOwnerOrReporter = (isOwner || reportedTo) && !isGuest
	const isOwnerOrCreatedBy = (isOwner || createdBy) && !isGuest
	const isOwnerOrCreatedByOrReporter = (isOwner || createdBy || reportedTo) && !isGuest
	const stickyUpdatesListener = useRef()
	const stickyRoomJoinListener = useRef()
	const stickyDeleteListener = useRef()
	const autoSaveCancelExec = useRef()

	const dispatch = useDispatch()

	const updateStickyDetails = useCallback(
		(stickyDetails, skipReduxUpdate = false) => {
			//skip redux update flag is true then don't update on redux
			if (!skipReduxUpdate) dispatch(updateGlassSticky(stickyDetails))
			setStickyData((prevState) => ({ ...prevState, stickyDetails: stickyDetails }))
		},
		[dispatch]
	)

	useEffect(() => {
		if (isSocketConnected) {
			stickyUpdatesListener.current = (resp) => {
				if (_.isEqual(cardId, _.get(resp, 'data.sticky_code'))) {
					//skip redux update since it's from socket already we have listener on socket-events-watcher.js
					updateStickyDetails(resp?.data, true)
				}
			}
			stickyDeleteListener.current = (resp) => {
				if (_.isEqual(cardId, _.get(resp, 'data.sticky_code'))) {
					history.push(`/glassx/view/${glassCode}`)
					dispatch(showSnackbarWithTimeout('TakeDown got deleted!', 3000))
				}
			}
			socketRef.current?.on('sticky_update', stickyUpdatesListener.current)
			socketRef.current?.on('sticky_delete', stickyDeleteListener.current)
		}
		return () => {
			if (isSocketConnected && stickyUpdatesListener?.current && stickyDeleteListener.current) {
				socketRef.current?.off('sticky_update', stickyUpdatesListener.current)
				// eslint-disable-next-line react-hooks/exhaustive-deps
				socketRef.current?.off('sticky_delete', stickyDeleteListener.current)
			}
		}
	}, [socketRef, cardId, updateStickyDetails, isSocketConnected, glassCode, history, dispatch])

	useEffect(() => {
		if (cardId) {
			setStickyData((prevState) => ({ ...prevState, loading: true }))
			GlassApi.getStickyDetails(
				glassCode,
				cardId,
				(res) => {
					setStickyData((prevState) => ({ ...prevState, loading: false, stickyDetails: res?.data?.data }))
				},
				(err) => {
					if (!Axios.isCancel(err)) {
						dispatch(
							showErrorMessage(getErrMsg(err), 'Close', () => {
								dispatch(hideLoader())
								history.push(`/glassx/view/${glassCode}`)
							})
						)
						setStickyData((prevState) => ({ ...prevState, loading: false, stickyDetails: {} }))
					}
				}
			)
		}
		return () => setStickyData((prevState) => ({ ...prevState, loading: true, stickyDetails: {} }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glassCode, cardId, dispatch])

	const onAddAttachments = (event) => {
		setAttachmentLoader(true)
		const onSuccess = (res) => {
			setAttachmentLoader(false)
			updateStickyDetails(res?.data?.data)
		}
		const onFailure = (err) => {
			setAttachmentLoader(false)
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		const formData = new FormData()
		_.map(_.get(event, 'target.files', []), (file) => formData.append('attachments', file))
		GlassApi.uploadAttachment(glassCode, cardId, formData, sessionId).then(onSuccess, onFailure)
	}

	const onDeleteAttachment = (attachment) => {
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setStickyData((prevState) => ({ ...prevState, loading: false, stickyDetails: res?.data?.data, isInitialValChange: true }))
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		dispatch(
			showConfirmMessage(
				'Are you sure want to delete this attachment?',
				'',
				'Yes',
				() => {
					dispatch(showLoader('Deleting Attachment. Please wait...'))
					GlassApi.deleteAttachment(glassCode, cardId, attachment?.attachment_id, sessionId).then(onSuccess, onFailure)
				},
				'No',
				() => dispatch(hideLoader()),
				'delete'
			)
		)
	}

	const makeAsFormData = (rawData) => {
		return _.reduce(
			rawData,
			(acc, v, k) => {
				if (k !== 'id' && !_.isEmpty(v)) {
					acc.append(_.snakeCase(k), v)
				}
				return acc
			},
			new FormData()
		)
	}

	const onSubmit = (data) => {
		let formData = makeAsFormData({
			sticky_title: _.get(data, 'sticky_title', ''),
			assigned_to: _.get(data, 'assigned_to.user_id', ''),
			reporting_to: _.get(data, 'reporting_to.user_id', null),
			priority: _.get(data, 'priority', null),
			due_date: data.due_date ? convertLocalTimeToGmtStr(data.due_date) : null,
			sticky_desc: _.get(data, 'sticky_desc', ''),
			estimation_time: _.get(data, 'estimation_time', null),
			is_approval: _.toString(_.get(data, 'is_approval', '')),
			labels: _.join(_.get(data, 'labels', []), ','),
		})
		const onSuccess = (res) => {
			updateStickyDetails(res?.data?.data)
		}
		const onFailure = (err) => {
			if (!Axios.isCancel(err)) dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		if (autoSaveCancelExec.current) {
			autoSaveCancelExec.current()
		}
		GlassApi.updateStickyDetails(glassCode, cardId, sessionId, formData, autoSaveCancelExec).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			sticky_code: _.get(stickyDetails, 'sticky_code', ''),
			sticky_title: _.get(stickyDetails, 'sticky_title', ''),
			assigned_to: _.get(stickyDetails, 'assigned_to', null),
			sticky_rating_score: _.get(stickyDetails, 'sticky_rating_score', null),
			priority: _.get(stickyDetails, 'priority', null),
			estimation_time: _.get(stickyDetails, 'estimation_time', null),
			reporting_to: _.get(stickyDetails, 'reporting_to', null),
			tagged_users: _.get(stickyDetails, 'tagged_users', null),
			due_date: _.isEmpty(stickyDetails?.due_date) ? null : convertGmtToLocalTime(_.get(stickyDetails, 'due_date', null)),
			sticky_desc: _.get(stickyDetails, 'sticky_desc', ''),
			is_approval: _.get(stickyDetails, 'is_approval', false),
			labels: _.map(_.get(stickyDetails, 'labels', []), (label) => label?.label_id),
			attachments: _.get(stickyDetails, 'attachments', []),
			status: _.get(stickyDetails?.sticky_status, 'status', ''),
			status_comments: _.get(stickyDetails?.sticky_status, 'comments', ''),
		},
		enableReinitialize: true,
		validationSchema: stcikySchema,
		onSubmit: (e) => onSubmit(e),
	})

	const delayedQuery = React.useCallback(
		_.debounce((formik, callback) => {
			callback(formik)
		}, 300),
		[]
	)

	useEffect(() => {
		formik.dirty &&
			delayedQuery(formik.submitForm, (formikToSubmit) => {
				formikToSubmit()
			})
	}, [formik.dirty, formik.submitForm, formik.values, delayedQuery])

	//JOIN OR LEAVE CURRENT ACTIVE STICKY ROOM ON SOCKET CONNECTION
	useEffect(() => {
		if (isSocketConnected && cardId) {
			socketRef.current?.emit('join_sticky', { id: cardId, glass_code: glassCode, access_token: authToken })
			stickyRoomJoinListener.current = (resp) => {
				console.log('sticky room joined resp', resp)
			}
			//On Authentication of the user authToken
			socketRef.current?.on('join_sticky_status', stickyRoomJoinListener.current)
		}
		return () => {
			if (isSocketConnected && cardId) {
				socketRef.current?.off('join_sticky_status', stickyRoomJoinListener.current)
				// eslint-disable-next-line react-hooks/exhaustive-deps
				socketRef.current?.emit('left_room', { id: cardId })
			}
		}
	}, [authToken, isSocketConnected, dispatch, socketRef, cardId, glassCode])

	const onHeaderClick = (parentCode) => {
		history.replace(`/glassx/view/${glassCode}/${parentCode}`)
	}

	return (
		<Drawer anchor='right' open={cardId ? true : false} onClose={onClose} className={classes.drawer}>
			{loading ? (
				<div className={classes.loaderContainer}>
					<StickyLoader />
				</div>
			) : (
				<React.Fragment>
					<Header
						sessionId={sessionId}
						onClose={onClose}
						formik={formik}
						isEditEnabled={isEditEnabled}
						isOwnerOrReporter={isOwnerOrReporter}
						stickyDetails={stickyDetails}
						glassCode={glassCode}
						stickyCode={cardId}
						isOwnerOrCreatedBy={isOwnerOrCreatedBy}
						isOwnerOrCreatedByOrReporter={isOwnerOrCreatedByOrReporter}
						glassMembers={glassMembers}
						lanesData={lanesData}
						onHeaderClick={onHeaderClick}
						history={history}
					/>
					<StickyTabs
						glassMembers={glassMembers}
						glassCode={glassCode}
						stickyId={cardId}
						glassLabels={glassLabels}
						sessionId={sessionId}
						formik={formik}
						loading={loading}
						stickyDetails={stickyDetails}
						isEditEnabled={isEditEnabled}
						isGuest={isGuest}
						attachmentEditEnabled={attachmentEditEnabled}
						isOwner={isOwner}
						onAddAttachments={onAddAttachments}
						attachmentLoading={attachmentLoader}
						onDeleteAttachment={onDeleteAttachment}
						updateStickyDetails={updateStickyDetails}
						activityTypes={activityTypes}
						socketRef={socketRef}
						isSocketConnected={isSocketConnected}
						isMember={isMember}
						isOwnerOrReporter={isOwnerOrReporter}
						isTimerEnabled={attachmentEditEnabled}
						history={history}
					/>
				</React.Fragment>
			)}
		</Drawer>
	)
}

StickySlider.propTypes = {
	onClose: PropTypes.func.isRequired,
	glassCode: PropTypes.string.isRequired,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	glassMembers: PropTypes.array,
	isOwner: PropTypes.bool,
	match: PropTypes.object,
	activityTypes: PropTypes.array,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	authToken: PropTypes.string,
	history: PropTypes.object,
}

export default StickySlider
