import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	wrapper: (props) => ({
		width: '100%',
		background: theme.palette.glassX.laneColor,
		position: 'sticky',
		bottom: `0px`,
	}),
	bottomSeam: {
		background: `linear-gradient(270deg, rgba(232, 236, 238, 0), #e8ecee 3%, #e8ecee 97%, rgba(232, 236, 238, 0))`,
		height: 1,
		left: 0,
		opacity: 1,
		position: 'absolute',
		width: '100%',
		right: 0,
		top: 0,
		transition: `opacity 100ms`,
		zIndex: 300,
	},
	addBtn: {
		textTransform: 'capitalize',
		color: theme.palette.almostBlack[700],
		'&:hover': {
			color: theme.palette.almostBlack[800],
		},
	},
}))

const AddCardLink = (props) => {
	const { onClick } = props
	const classes = useStyles(props)

	return (
		<div className={classes.wrapper}>
			<Button className={classes.addBtn} fullWidth onClick={onClick}>
				Add TakeDown
			</Button>
		</div>
	)
}

export default AddCardLink
