import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import TeamsDetails from './TeamsDetails'
import TeamMembers from './TeamMembers'
import _ from 'lodash'
import TeamUnitFormDialog from './TeamUnitFormDialog'
import { PAGE_MODES } from '../../../constants'
import { fetchAllTeams, deleteTeam } from '../../../services/teams/actions'
import { useDispatch } from 'react-redux'
import { hideLoader, showConfirmMessage } from 'services/loader/actions'
import { PERMISSION_TYPE } from 'constants/modules'
import update from 'immutability-helper'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: ({ isEmbedded }) => (isEmbedded ? 0 : theme.spacing(2)),
		paddingBottom: ({ isEmbedded }) => (isEmbedded ? 0 : theme.spacing(2)),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(0),
			paddingBottom: ({ isEmbedded }) => (isEmbedded ? 0 : 80),
		},
	},
	sectionContentWrapper: {
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(0),
			marginRight: theme.spacing(0),
		},
	},
	teamDetailsEmbeded: {
		margin: 0,
	},
	teamMembersEmbeded: {
		marginRight: 0,
	},
}))

const getTeamData = (teamArry) => {
	const groupByTeam = _.groupBy(teamArry, 'parent_tu_code')
	const formNestedLvlData = (arryToFil) => {
		const result = _.reduce(
			arryToFil,
			(arry, team) => {
				const reportingTeams = _.get(groupByTeam, _.get(team, 'tu_code'))
				arry.push({
					id: _.get(team, 'tu_code'),
					name: _.get(team, 'tu_name'),
					children: _.isArray(reportingTeams) && !_.isEmpty(reportingTeams) ? formNestedLvlData(reportingTeams) : [],
				})
				return arry
			},
			[]
		)
		return result
	}
	return formNestedLvlData(groupByTeam[null])
}

const TeamUnits = ({ action, teamId, history, perms, isEmbedded = false, changeEmbededState, ...otherProps }) => {
	const classes = useStyles({ isEmbedded })
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSmallDevices = useMediaQuery(theme.breakpoints.down('md'))

	const isCreateAllowed = _.get(perms, PERMISSION_TYPE.CREATE, false)
	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const [selectedTeamId, setSelectedTeamId] = useState(null)
	const [shwMobTeamMem, setShwMobTeamMem] = useState(false)
	const [teamState, setTeamState] = useState({ loading: true, teams: [] })

	const { loading, teams } = teamState

	const openFormDialog = !_.isEmpty(action)

	const updateTeamState = useCallback(
		(newState, embededState = {}) => {
			setTeamState((prevState) => {
				const updatedState = { ...prevState, ...newState }
				isEmbedded && changeEmbededState({ teamunitsLength: updatedState?.teams?.length || 0, ...embededState })
				return updatedState
			})
		},
		[changeEmbededState, isEmbedded]
	)

	const handleCloseFormDialog = (e, isRefreshList, pageMode, teamUnitObj) => {
		switch (pageMode) {
			case PAGE_MODES.CREATE:
				updateTeamState(update(teamState, { teams: { $push: [teamUnitObj] } }))
				break
			case PAGE_MODES.EDIT:
				const idxToUpdate = _.findIndex(teamState?.teams, {
					tu_code: _.get(teamUnitObj, 'tu_code'),
				})
				updateTeamState(
					update(teamState, {
						teams: { [idxToUpdate]: { $set: teamUnitObj } },
					})
				)
				break
			default:
				break
		}

		if (!isEmbedded)
			history.replace({
				pathname: `/usermanagement/teamunits`,
				isRefreshList: _.isEqual(isRefreshList, true),
			})
		else changeEmbededState({ action: '', teamId: null, parentTuCode: null, isRefreshList: _.isEqual(isRefreshList, true) })
	}

	const openCreateDialog = (parentTuCode) => {
		if (!isEmbedded)
			history.replace({
				pathname: `/usermanagement/teamunits/create`,
				parentTuCode,
			})
		else changeEmbededState({ action: PAGE_MODES.CREATE, parentTuCode })
	}

	const openEditDialog = (teamCode) => {
		if (!isEmbedded)
			history.replace({
				pathname: `/usermanagement/teamunits/${PAGE_MODES.EDIT}/${teamCode}`,
			})
		else changeEmbededState({ action: PAGE_MODES.EDIT, teamId: teamCode })
	}

	const performTeamDelAction = useCallback(
		(teamUnitCode) => {
			const onSuccess = () => {
				dispatch(hideLoader())
				updateTeamState({ loading: true, teams: [] })
				setSelectedTeamId(null)
			}
			const onErrorClose = () => {
				dispatch(hideLoader())
			}
			dispatch(deleteTeam(teamUnitCode, onSuccess, onErrorClose))
		},
		[dispatch, updateTeamState]
	)

	const deleteTeamUnit = useCallback(
		(teamNode) => {
			dispatch(
				showConfirmMessage(
					`Do you want to delete the "${_.get(teamNode, 'name')}" team unit?`,
					_.isEmpty(_.get(teamNode, 'children')) ? '' : 'Note: All sub-units will be deleted under this team unit if you delete.',
					'Delete',
					(e) => {
						dispatch(hideLoader())
						performTeamDelAction(_.get(teamNode, 'id'))
					},
					'Cancel',
					(e) => {
						dispatch(hideLoader())
					}
				)
			)
		},
		[dispatch, performTeamDelAction]
	)

	const addTeamBtnClick = () => {
		openCreateDialog()
	}

	const onTeamNodeSelected = (e, value) => {
		setSelectedTeamId(value)
	}

	const teamBackBtnClick = () => {
		setShwMobTeamMem(false)
		setSelectedTeamId(null)
	}

	const onTeamNodeAction = (teamNode, actionName) => {
		const teamCode = _.get(teamNode, 'id')
		switch (actionName) {
			case PAGE_MODES.VIEW:
				setSelectedTeamId(teamCode)
				setShwMobTeamMem(true)
				break
			//add sub team unit menu option
			case PAGE_MODES.CREATE:
				openCreateDialog(teamCode)
				break
			case PAGE_MODES.EDIT:
				openEditDialog(teamCode)
				break
			case PAGE_MODES.DELETE:
				deleteTeamUnit(teamNode)
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (loading)
			dispatch(
				fetchAllTeams(
					(resp) => {
						updateTeamState({
							loading: false,
							teams: _.get(resp, 'data.data.team_units', []),
						})
					},
					(err) => {
						console.log('error', err)
					}
				)
			)
	}, [dispatch, loading, updateTeamState])

	const isRefreshList = isEmbedded ? otherProps?.isRefreshList : _.get(history, 'location.isRefreshList', false)

	useEffect(() => {
		if (isRefreshList) updateTeamState({ loading: true, teams: [] })
	}, [isRefreshList, updateTeamState])

	const parentTuCode = isEmbedded ? otherProps?.parentTuCode : _.get(history, 'location.parentTuCode', null)

	const teamName = useMemo(() => {
		if (!_.isEmpty(teamId)) return _.get(_.find(teams, { tu_code: teamId }), 'tu_name')
		return ''
	}, [teams, teamId])

	const teamData = useMemo(() => getTeamData(teams), [teams])
	const selectedTeamName = useMemo(() => _.get(_.find(teams, { tu_code: selectedTeamId }), 'tu_name'), [selectedTeamId, teams])

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12} sm={12} md={6}>
				<TeamsDetails
					className={classnames({ [classes.teamDetailsEmbeded]: isEmbedded })}
					loading={loading}
					teamData={teamData}
					onTeamNodeAction={onTeamNodeAction}
					showViewOption={isSmallDevices}
					isCreateAllowed={isCreateAllowed}
					isEditAllowed={isEditAllowed}
					isDeleteAllowed={isDeleteAllowed}
					addBtnClick={addTeamBtnClick}
					onTeamNodeSelected={onTeamNodeSelected}
					isShowTeamView={isSmallDevices && shwMobTeamMem && !_.isEmpty(selectedTeamId)}
					selectedTeamId={selectedTeamId}
					selectedTeamName={selectedTeamName}
					teamBackBtnClick={teamBackBtnClick}
				/>
			</Grid>
			{!isSmallDevices && (
				<Grid item xs={12} sm={12} md={6}>
					<TeamMembers
						className={classnames({ [classes.teamMembersEmbeded]: isEmbedded })}
						selectedTeamId={selectedTeamId}
						showAddMemberBtn={isEditAllowed}
						isEditAllowed={isEditAllowed}
						selectedTeamName={selectedTeamName}
					/>
				</Grid>
			)}
			{!loading && (
				<TeamUnitFormDialog
					open={openFormDialog}
					action={action}
					parentTuCode={parentTuCode}
					teamCode={teamId}
					teamName={teamName}
					handleClose={handleCloseFormDialog}
				/>
			)}
		</Grid>
	)
}

export default TeamUnits
