import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Fab, Grid, Icon, makeStyles } from '@material-ui/core'
import SearchBox from 'components/SearchBox'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 2, 10),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2, 2, 8),
		},
	},
	searchBoxContainer: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			bottom: theme.spacing(2),
		},
		[theme.breakpoints.up('lg')]: {
			right: 'calc(calc(100vw / 2) - 600px)',
		},
	},
}))

const SearchFabWrapper = ({ isOwner, searchPlaceholder, onChangeSearchBox, fabIcon, onClickFab, children }) => {
	const classes = useStyles()

	const delayedQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 200),
		[]
	)

	const onChangeSearch = (e) => {
		delayedQuery(e.target.value, (q) => {
			onChangeSearchBox(q)
		})
	}

	return (
		<>
			<Grid className={classes.root} container>
				<Grid item xs={12}>
					<SearchBox onChange={onChangeSearch} containerClassName={classes.searchBoxContainer} placeholder={searchPlaceholder} size='small' />
				</Grid>
				<Grid item xs={12}>
					{children}
				</Grid>
			</Grid>
			{isOwner && (
				<Fab onClick={onClickFab} className={classes.fab} color='primary' aria-label='add'>
					<Icon>{fabIcon}</Icon>
				</Fab>
			)}
		</>
	)
}

SearchFabWrapper.propTypes = {
	searchPlaceholder: PropTypes.string,
	onChangeSearchBox: PropTypes.func.isRequired,
	fabIcon: PropTypes.string.isRequired,
	onClickFab: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	isOwner: PropTypes.bool.isRequired,
}

export default SearchFabWrapper
