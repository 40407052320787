import React, { useEffect, useState, useRef } from 'react'
import { Dialog, makeStyles, Button, DialogContent, DialogActions, DialogTitle, Slide, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import TemplateForm from './TemplateForm'
import { PAGE_MODES } from '../../../constants'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import TemplateApi from '../../../services/emails/api'

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '6px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[500],
			height: '10px',
			borderRadius: '5px',
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(0),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Create Template'
		case PAGE_MODES.EDIT:
			return 'Edit Template'
		case PAGE_MODES.VIEW:
			return 'View Template'
		default:
			return ''
	}
}

const FormDialog = ({ open, handleClose, action, dataId, changeEditMode, refreshData, isEditAllowed }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)
	const [isFetchingTemplate, setFetchingTemplate] = useState(false)

	const formikRef = useRef()
	const [values, setValues] = useState({
		module: [],
		temp_name: '',
		cc: [],
		bcc: [],
		subject: '',
		body_of_mail: '',
		attachment_name: '',
		// signature: '',
	})
	const [modules, setModules] = useState({
		moduleList: [],
		isFetchingModule: false,
		newModuleCreated: false,
	})
	const dialogTitle = getDialogTitle(action)

	const { newModuleCreated } = modules

	const validationSchema = Yup.object({
		temp_name: Yup.string().trim().required('Please enter the Template name'),
		subject: Yup.string().trim().required('Please enter the Subject'),
		body_of_mail: Yup.string().required('Please enter the Body of the mail'),
		// signature: Yup.string().trim().required('Please enter the Signature'),
		module: Yup.object().nullable().required('Please select the Module.'),
		cc: Yup.array().of(Yup.string().email('Enter a valid Email Id')),
		bcc: Yup.array().of(Yup.string().email('Enter a valid Email Id')),
	})
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		setModules((prevState) => ({
			...prevState,
			isFetchingModule: true,
		}))
		TemplateApi.fetchCategories()
			.then((res) => {
				setModules((prevState) => ({
					...prevState,
					moduleList: res?.data?.data,
					isFetchingModule: false,
				}))
			})
			.catch((err) => {
				setModules((prevState) => ({
					...prevState,
					isFetchingModule: false,
				}))
				dispatch(showSnackbarWithTimeout(_.get(err, 'data.more_info', 'Cannot fetch modules list, Please try again'), 2000))
			})
	}, [newModuleCreated])

	const getSingleTemplate = (idx) => {
		setFetchingTemplate(true)
		dispatch(showLoader('Please wait, getting template details'))
		TemplateApi.fetchSingleTemplate(
			idx,
			(res) => {
				setFetchingTemplate(false)
				setValues({
					...values,
					module: _.find(modules?.moduleList, { module_id: _.get(res, 'data.data.module_id', '') }),
					temp_name: _.get(res, 'data.data.name', ''),
					cc: _.get(res, 'data.data.cc', ''),
					bcc: _.get(res, 'data.data.bcc', ''),
					subject: _.get(res, 'data.data.subject', ''),
					body_of_mail: _.get(res, 'data.data.mail_body', ''),
					attachment_name: _.get(res, 'data.data.attachment_name', ''),
					// signature: _.get(res, 'data.data.signature', ''),
				})
				dispatch(hideLoader())
			},
			(err) => {
				setFetchingTemplate(false)
				handleClose()
				dispatch(
					showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
						dispatch(hideLoader())
					})
				)
			}
		)
	}

	useEffect(() => {
		if (!_.isEmpty(dataId)) {
			getSingleTemplate(dataId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataId])

	useEffect(() => {
		if (isCreateMode) {
			setValues({
				module: [],
				temp_name: '',
				cc: [],
				bcc: [],
				subject: '',
				body_of_mail: '',
				attachment_name: '',
				// signature: '',
			})
		}
	}, [isCreateMode])

	const handleSubmitForm = (value) => {
		if (isCreateMode) {
			dispatch(showLoader('Please wait...'))
			const data = {
				module_id: value?.module?.module_id,
				name: value?.temp_name,
				cc: value?.cc,
				bcc: value?.bcc,
				subject: value?.subject,
				mail_body: value?.body_of_mail,
				attachment_name: value?.attachment_name,
				// signature: value?.signature,
			}
			TemplateApi.createTemplate(
				data,
				(res) => {
					dispatch(hideLoader())
					handleClose()
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Successfully created email template.'), 2000))
					setValues({
						module: '',
						temp_name: '',
						cc: '',
						bcc: '',
						subject: '',
						body_of_mail: '',
						attachment_name: '',
						// signature: '',
					})
					formikRef.current?.resetForm()
					refreshData()
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		} else if (isEditMode) {
			dispatch(showLoader('Please wait...'))
			const data = {
				module_id: value?.module?.module_id,
				name: value?.temp_name,
				cc: value?.cc,
				bcc: value?.bcc,
				subject: value?.subject,
				mail_body: value?.body_of_mail,
				attachment_name: value?.attachment_name,
				// signature: value?.signature,
			}
			TemplateApi.editTemplate(
				data,
				dataId,
				(res) => {
					dispatch(hideLoader())
					handleClose()
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Success!'), 2000))
					refreshData()
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		}
	}
	const [resetErr, setResetErr] = useState(false)

	const onClickClose = () => {
		setResetErr(true)
		handleClose()
	}

	const onClickCancel = () => {
		handleClose()
	}

	useEffect(() => {
		if (open) setResetErr(false)
	}, [open])

	useEffect(() => {
		setResetErr(true)
	}, [])

	return (
		<>
			{!isFetchingTemplate && (
				<Dialog
					classes={{ paper: classes.dialog }}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					fullWidth
					fullScreen={fullScreen}
					maxWidth={'sm'}
					onClose={isViewMode ? onClickCancel : onClickClose}
					aria-labelledby='add-project-form'
				>
					<DialogTitle>{dialogTitle}</DialogTitle>
					<Divider />
					<Formik innerRef={formikRef} enableReinitialize initialValues={values} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
						{(props) => (
							<>
								<DialogContent className={classes.dialogContent}>
									<TemplateForm
										{...props}
										resetErr={resetErr}
										cancelBtnClick={onClickClose}
										isEditMode={isEditMode}
										isCreateMode={isCreateMode}
										isViewMode={isViewMode}
										changeEditMode={changeEditMode}
										isEditAllowed={isEditAllowed}
										onClickCancel={onClickCancel}
										modules={modules}
										setModules={setModules}
									/>
								</DialogContent>
								<DialogActions className={classes.dialogFooter}>
									<Button
										variant='outlined'
										size={'medium'}
										onClick={
											isViewMode
												? onClickCancel
												: () => {
														props.resetForm()
														handleClose()
												  }
										}
										color='primary'
									>
										{isEditMode ? 'Cancel' : 'Close'}
									</Button>
									{isViewMode && !isEditAllowed ? null : (
										<Button
											variant='contained'
											onClick={isViewMode && isEditAllowed ? changeEditMode : props.handleSubmit}
											size={'medium'}
											color='primary'
											// endIcon={<i className='material-icons'> arrow_forward </i>}
											disableElevation
										>
											{isViewMode && isEditAllowed ? 'Edit' : isEditMode ? 'Save' : 'Save'}
										</Button>
									)}
								</DialogActions>
							</>
						)}
					</Formik>
				</Dialog>
			)}
		</>
	)
}
FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default FormDialog
