import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Card, CardHeader, makeStyles } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiCardHeader-root': {
			alignItems: 'flex-start',
			padding: 0,
			marginTop: '16px',
		},
	},
}))

const ResultSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			{_.map(_.range(2), (item) => (
				<Card elevation={0} className={classes.root}>
					<CardHeader
						title={
							<>
								<Skeleton animation='wave' height={20} width='60%' />{' '}
								<Skeleton animation='wave' height={20} width='40%' style={{ marginBottom: 6 }} />
							</>
						}
						subheader={
							<>
								<Skeleton animation='wave' height={15} width='100%' />
								<Skeleton animation='wave' height={15} width='60%' />
							</>
						}
					/>
				</Card>
			))}
		</>
	)
}

export default ResultSkeleton
