import React, { useRef, useState } from 'react'
import { Avatar, Box, Button, Icon, makeStyles, TextField, Typography } from '@material-ui/core'
import _ from 'lodash'
import { Field } from 'formik'
import Validator from './validator'
import { useDispatch } from 'react-redux'
import { changeCurrentUsrPwd } from 'services/session/actions'
import { hideLoader, showSuccessMessage } from 'services/loader/actions'
import { getSuccessMsg } from 'utils'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 300,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
}))

function ChangePassword({ history }) {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const dispatch = useDispatch()
	const formRef = useRef(null)
	const formFields = [
		{ name: 'current_password', label: 'Current password*', type: 'password' },
		{ name: 'new_password', label: 'New password*', type: 'password' },
		{
			name: 're_type_password',
			label: 'Re-type new password*',
			type: 'password',
		},
	]

	const onSubmitForm = (values) => {
		const successCallback = (resp) => {
			dispatch(
				showSuccessMessage(getSuccessMsg(resp, 'Password changed successfully'), 'Close', (e) => {
					dispatch(hideLoader())
					formRef.current.resetForm()
					history.replace('/home')
				})
			)
		}
		const failureCallback = () => {
			dispatch(hideLoader())
			formRef.current.resetForm()
		}
		dispatch(changeCurrentUsrPwd(values, successCallback, failureCallback))
	}

	const onClickChngPwd = () => {
		formRef.current && formRef.current.submitForm()
	}

	return (
		<Box className={classes.contentWrapper} component='div'>
			{isPwdUpdated ? (
				<>
					<Avatar alt={'success'} className={classes.successIco}>
						<Icon>done</Icon>
					</Avatar>
					<Typography variant='body1' gutterBottom>
						Password Updated Successfully
					</Typography>
				</>
			) : (
				<>
					<Validator onSubmit={onSubmitForm} ref={formRef}>
						{(formik) => {
							return (
								<form>
									{_.map(formFields, (formField) => (
										<Field key={formField.name} type='text' name={formField.name}>
											{({ form, field }) => {
												return (
													<TextField
														variant='outlined'
														margin='none'
														size='small'
														fullWidth
														name={field.name}
														label={formField.label}
														type={formField.type}
														autoComplete='off'
														onChange={(e) => {
															e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
														}}
														onBlur={field.onBlur}
														value={field.value || ''}
														className={classes.textField}
														helperText={form.touched[field.name] && form.errors[field.name]}
														error={Boolean(form.errors[field.name] && form.touched[field.name])}
													/>
												)
											}}
										</Field>
									))}
								</form>
							)
						}}
					</Validator>
					<Button onClick={onClickChngPwd} className={classes.button} color='primary' variant='contained' disableElevation>
						Change Password
					</Button>
				</>
			)}
		</Box>
	)
}

export default ChangePassword
