import { makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import ResultList from './ResultList'
import DiscoverxApi from 'services/discoverx/api'
import { useDispatch } from 'react-redux'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		boxSizing: 'border-box',
		backgroundColor: '#fff',
		zIndex: 99,
		overflowY: 'auto',
		paddingTop: theme.spacing(8),
	},
	contentWrapper: {
		maxWidth: '1200px',
		margin: '0 auto',
		padding: theme.spacing(3, 4),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 2),
		},
	},
}))

const SavedSearch = ({ onBackBtnClick, onRowClicked }) => {
	const classes = useStyles()
	const [categories, setCategories] = useState([])
	const [searches, setSearches] = useState([])
	const [currentCategoryId, setCategoryId] = useState('')
	const [isLoading, setLoading] = useState(false)
	const [categoryLoading, setCategoryLoading] = useState(false)
	const [searchTxt, setSearchTxt] = useState('')
	const cancelExecutor = useRef()

	const dispatch = useDispatch()

	useEffect(() => {
		setCategoryLoading(true)
		const onSuccess = (res) => {
			setCategoryLoading(false)
			setCategories(res?.data?.data)
		}
		const onFailure = (err) => {
			setCategoryLoading(false)
			setCategories([])
		}
		DiscoverxApi.getSavedCategories().then(onSuccess, onFailure)
	}, [])

	const onAccordianChange = (category) => {
		setCategoryId(category?.category_id)
	}

	const onChangeSearchBox = _.debounce((e) => {
		setSearchTxt(e.target.value)
	}, 300)

	useEffect(() => {
		setSearches([])
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setSearches(res?.data?.data)
		}

		const onFailure = (err) => {
			setLoading(false)
		}
		if (!_.isEmpty(currentCategoryId)) {
			DiscoverxApi.getSavedSearches(searchTxt, currentCategoryId, cancelExecutor).then(onSuccess, onFailure)
		}
	}, [currentCategoryId, searchTxt])

	const onRemoveSearch = (catId) => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}

		const onFailure = (err) => {
			dispatch(
				showErrorMessage(getErrMsg(err), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}
		DiscoverxApi.removeSavedSearch(catId).then(onSuccess, onFailure)
	}

	return (
		<div className={classes.root}>
			<div className={classes.contentWrapper}>
				<Header onBackBtnClick={onBackBtnClick} onChangeSearchBox={onChangeSearchBox} searchPlaceholder={'Search by name or Query'} />
				<ResultList
					categoryLoading={categoryLoading}
					categories={categories}
					data={searches}
					onAccordianChange={onAccordianChange}
					isLoading={isLoading}
					onRemoveSearch={onRemoveSearch}
					onRowClicked={onRowClicked}
				/>
			</div>
		</div>
	)
}

export default SavedSearch
