import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Avatar,
	Button,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core'
import _ from 'lodash'
import { convertGmtToLocalTime, getImgUrl, readFileAsDataURL, getFileSize } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { showAdvanceSnackbar } from 'services/snackbarAdvance/actions'
import { Skeleton } from '@material-ui/lab'
import { getFileFormatIconAndColor } from 'utils'
import FilePreviewDialog from '../FilePreviewDialog'

const imgExtensions = ['jpeg', 'png', 'jpg', 'gif']

const useStyles = makeStyles((theme) => ({
	attachmentRoot: {
		padding: theme.spacing(0, 0, 0, 0),
		'& .MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItem-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},

	ActivityattachmentRoot: {
		'& .MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItem-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItem-container': {
			position: 'relative',
			width: '49.4%',
			'& .MuiListItem-root': {
				marginBottom: 0,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0.5, 0, 0, 0),
			'& .MuiList-padding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'& .MuiListItem-root': {
				paddingTop: theme.spacing(0.5),
				paddingBottom: theme.spacing(0.5),
			},
		},
	},
	activityList: {
		display: 'flex',
		flexDirection: 'row',
		gap: '5px',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		margin: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	listAvatar: {
		minWidth: 110,
	},
	listAvatarSmall: {
		minWidth: 60,
	},
	avatar: {
		boxSizing: 'border-box',
	},
	link: {},
	linkDivider: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	inline: {
		display: 'block',
		fontSize: '0.8rem',
		marginBottom: '8px',
	},
	attachmentTitle: {
		'& span': {
			display: 'block',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	attachmentTitleSmall: {
		fontSize: '14px',
	},
	actionButtons: {
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		marginRight: '10px',
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
		'& .MuiIcon-root': {
			fontSize: '1.3rem',
		},
	},
	addNewButton: {
		minWidth: '85px',
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		marginRight: '10px',
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
	addNewButtonWithPadding: {
		minWidth: '85px',
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		marginRight: '10px',
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
	title: {
		paddingBottom: theme.spacing(1),
	},
	listItem: {
		marginBottom: theme.spacing(1),
		'&.MuiListItem-secondaryAction': {
			paddingRight: theme.spacing(10),
			paddingLeft: theme.spacing(0.7),
			border: `1px solid ${theme.palette.grey[300]}`,
		},
	},
	listItemActionRoot: {
		right: 16,
		display: 'flex',
		columnGap: '8px',
	},
	skeletonWrapper: {
		marginBottom: theme.spacing(1),
	},
}))

Attachments.propTypes = {
	name: PropTypes.string,
	attachmentList: PropTypes.array,
	onAddAttachments: PropTypes.func,
	onDeleteAttachment: PropTypes.func,
	isActivity: PropTypes.bool,
	attachmentUserId: PropTypes.string,
	enableAddBtn: PropTypes.bool,
}

function Attachments({
	name,
	attachmentList,
	onAddAttachments,
	onDeleteAttachment,
	enableAddBtn = true,
	attachmentUserId,
	disableDelete,
	isActivity = false,
	loading,
}) {
	const classes = useStyles()
	const [filePrevs, setFilePrevs] = useState([])
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const userId = useSelector((state) => _.get(state, 'user.userDetails.user_id'))
	const dispatch = useDispatch()
	const [{ openFilePrevDialog, openFileIdx }, setFilePrevState] = useState({ openFilePrevDialog: false, openFileIdx: 0 })

	const checkAddAttachments = (e) => {
		let imgArray = Array.from(e.target.files)
		let result = _.find(imgArray, function (file) {
			if (_.get(file, 'size', 0) >= 1073741824) return true
		})
		let isFileExceeded = result ? true : false

		if (isFileExceeded) {
			dispatch(
				showAdvanceSnackbar({
					msg: 'File size is too large. maximum file size is 1 gb.',
					severity: 'error',
					onClose: true,
				})
			)
			e.target.value = ''
		} else {
			onAddAttachments(e)
			e.target.value = ''
		}
	}

	const onDownloadAttachment = (fileObjToDownload) => {
		window.open(getImgUrl(accessToken, _.get(fileObjToDownload, 'file_path', '')), '_blank')
	}

	useEffect(() => {
		Promise.all(
			_.map(attachmentList, async (file) => {
				const isLocalFile = file instanceof File
				let fileName = _.get(file, isLocalFile ? 'name' : 'file_name', '')
				let fileExt = _.split(fileName, '.').pop()
				return {
					ext: fileExt,
					source: isLocalFile
						? _.indexOf(imgExtensions, fileExt.toLowerCase()) > -1
							? await readFileAsDataURL(file)
							: ''
						: getImgUrl(accessToken, _.get(file, 'file_path'), 80),
					name: fileName,
					isLocalFile,
					subTitle: isLocalFile ? 'New file' : _.get(file, 'created_date', ''),
					fileSize: parseInt(_.get(file, 'file_size', 0)),
				}
			})
		).then((filePrevList) => {
			setFilePrevs(filePrevList)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentList])

	const onClickFileListItem = (index) => {
		setFilePrevState({ openFilePrevDialog: true, openFileIdx: index })
	}

	const closeFilePrevDialog = () => {
		setFilePrevState({ openFilePrevDialog: false, openFileIdx: 0 })
	}

	return (
		<div className={isActivity ? classes.ActivityattachmentRoot : classes.attachmentRoot}>
			{!isActivity && (
				<Typography variant='subtitle2' component='p' className={classes.title}>
					Attachments
				</Typography>
			)}
			{!_.isEmpty(filePrevs) && (
				<List dense className={isActivity ? classes.activityList : {}}>
					{_.map(filePrevs, (value, index) => {
						const { fileIcon, background } = getFileFormatIconAndColor(_.get(value, 'ext'))
						const isLocalFile = _.get(value, 'isLocalFile')
						return (
							<ListItem
								button
								onClick={() => {
									if (!isLocalFile) onClickFileListItem(index)
								}}
								key={`attachment-${index}`}
								className={classes.listItem}
								disableGutters={true}
								divider={false}
							>
								<ListItemAvatar>
									<Avatar style={{ background: background }} className={classes.avatar} variant='square' src={_.get(value, 'source')}>
										<Icon>{fileIcon}</Icon>
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									className={classes.attachmentTitle}
									primary={_.get(value, 'name', '')}
									secondary={
										<Typography color='textSecondary' variant='caption'>
											{_.get(value, 'subTitle', '') !== 'New file'
												? isActivity
													? getFileSize(_.get(value, 'fileSize', 0))
													: `${getFileSize(_.get(value, 'fileSize', 0))} ▪ ${convertGmtToLocalTime(
															_.get(value, 'subTitle', ''),
															'DD MMM YYYY | hh:mm a'
													  )}`
												: _.get(value, 'subTitle', '')}
										</Typography>
									}
								/>
								<ListItemSecondaryAction className={classes.listItemActionRoot}>
									{!isLocalFile && (
										<IconButton size='small' edge='end' aria-label='download' onClick={() => onDownloadAttachment(attachmentList[index])}>
											<Icon fontSize='small'>get_app</Icon>
										</IconButton>
									)}
									{((isActivity && !disableDelete) || (!isActivity && !disableDelete && _.isEqual(userId, attachmentUserId))) && (
										<IconButton size='small' edge='end' aria-label='delete' onClick={() => onDeleteAttachment(attachmentList[index])}>
											<Icon fontSize='small'>delete</Icon>
										</IconButton>
									)}
								</ListItemSecondaryAction>
							</ListItem>
						)
					})}
				</List>
			)}
			{loading && (
				<List>
					{_.map(_.range(1), (value, index) => (
						<ListItem className={classes.skeletonWrapper} key={`loading-${index}`} disableGutters={true}>
							<Skeleton animation='wave' variant='rect' width='100%' height='56px' />
						</ListItem>
					))}
				</List>
			)}
			{enableAddBtn && (
				<Button size='small' component='label' className={_.isEmpty(filePrevs) ? classes.addNewButton : classes.addNewButtonWithPadding}>
					+ Add New
					<input
						type='file'
						multiple
						name={name}
						onChange={(e) => {
							checkAddAttachments(e)
						}}
						hidden
					/>
				</Button>
			)}
			<FilePreviewDialog
				open={openFilePrevDialog}
				accessToken={accessToken}
				openFileIdx={openFileIdx}
				fileList={attachmentList}
				onClose={closeFilePrevDialog}
			/>
		</div>
	)
}

export default Attachments
