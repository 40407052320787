import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import _ from 'lodash'
import { getImgUrl, stringToHslColor } from 'utils'
import { fetchAllUsers } from 'services/users/actions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { throttle } from 'lodash'
import { ROLE_MODULES } from 'constants/modules'
import { fetchAllTeams } from 'services/teams/actions'
import PermSelectBox from '../PermSelectBox'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		paddingTop: theme.spacing(0.5),
	},
	autoCompleteWrapper: {
		flex: 1,
		marginRight: (props) => (props.isFormMode ? 10 : 0),
	},
	autoComplete: {
		autocomplete: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing(2),
			},
		},
	},
	userPic: {
		width: 37,
		height: 37,
		marginRight: theme.spacing(1),
	},
	boldTxt: {
		fontWeight: '500',
		fontSize: 16,
	},
	msgBoxWrapper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}))

ShareForm.propTypes = {
	formModule: PropTypes.string,
	isFormMode: PropTypes.bool,
	onChangeAutoComplete: PropTypes.func,
	onChangePermSelBox: PropTypes.func,
	onChangeMsgInput: PropTypes.func,
	selectedUsers: PropTypes.array,
	selectedTeams: PropTypes.array,
	permissions: PropTypes.array,
	permission: PropTypes.string,
	isMobileView: PropTypes.bool,
	message: PropTypes.string,
}

function checkIsUserModule(formModule) {
	return _.isEqual(formModule, ROLE_MODULES.USER_MANAGEMENT)
}

const getAutoCompleteConfig = (formModule) => {
	const isUserModule = checkIsUserModule(formModule)
	return {
		id: isUserModule ? 'userList' : 'teamList',
		noOptionsText: isUserModule ? 'No users found' : 'No teams found',
		loadingText: isUserModule ? 'Finding users...' : 'Finding teams...',
		getOptionLabel: (option) => (typeof option === 'string' ? option : isUserModule ? option.full_name : option.tu_name),
		textFieldProps: {
			label: isUserModule ? 'Add Users' : 'Add Teams',
		},
	}
}

function ShareForm({
	formModule,
	permissions,
	permission,
	onChangeAutoComplete,
	selectedTeams,
	message,
	selectedUsers,
	isFormMode,
	isMobileView,
	onChangePermSelBox,
	onChangeMsgInput,
}) {
	const [sugListState, setSugListState] = useState({
		loading: false,
		suggestionList: [],
	})
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const dispatch = useDispatch()
	const classes = useStyles({ isFormMode })

	const { suggestionList, loading } = sugListState

	const getPicUrl = useCallback(
		(picLoc) => {
			return _.isEmpty(picLoc) ? '' : getImgUrl(accessToken, picLoc, 50)
		},
		[accessToken]
	)

	const fetchSuggestions = useMemo(() => {
		return throttle((searchTxt, callback) => {
			if (checkIsUserModule(formModule)) dispatch(fetchAllUsers({ search_text: searchTxt, limit: 10, offset: 0 }, callback, () => {}))
			else
				dispatch(
					fetchAllTeams(callback, callback, {
						search_text: searchTxt,
						limit: 10,
						offset: 0,
					})
				)
		}, 500)
	}, [dispatch, formModule])

	const fetchAutoCompleteOptions = useCallback(
		(value) => {
			setSugListState((prevState) => ({
				...prevState,
				loading: true,
				suggestionList: [],
			}))
			fetchSuggestions(value, (resp) => {
				const suggestionPath = checkIsUserModule(formModule) ? 'data.data.users' : 'data.data.team_units'
				setSugListState((prevState) => ({
					...prevState,
					loading: false,
					suggestionList: _.get(resp, suggestionPath, []),
				}))
			})
		},
		[formModule, fetchSuggestions]
	)

	useEffect(() => {
		fetchAutoCompleteOptions('')
	}, [fetchAutoCompleteOptions])

	const handleOwnerInpChng = useCallback(
		(e) => {
			fetchAutoCompleteOptions(e.target.value)
		},
		[fetchAutoCompleteOptions]
	)

	useEffect(() => {
		setSugListState((prevState) => ({
			...prevState,
			loading: true,
			suggestionList: [],
		}))
	}, [formModule])

	const { textFieldProps, ...autoCompletedProps } = getAutoCompleteConfig(formModule, loading)

	const autoCompleteVal = checkIsUserModule(formModule) ? selectedUsers : selectedTeams

	return (
		<div>
			<div className={classes.root}>
				<div className={classes.autoCompleteWrapper}>
					<Autocomplete
						{...autoCompletedProps}
						multiple
						loading={loading}
						filterOptions={(x) => x}
						options={suggestionList}
						autoComplete
						includeInputInList
						filterSelectedOptions
						size={'small'}
						renderOption={(option) => {
							const picUrl = getPicUrl(option.profile_pic_url)
							const tuName = _.get(option, 'tu_name')
							return (
								<Grid key={option.user_id} container alignItems='center'>
									<Grid item>
										<Avatar
											style={!option?.is_active ? {} : { backgroundColor: stringToHslColor(`${option?.first_name} ${option?.last_name}`) }}
											src={picUrl}
											size='small'
											className={classes.userPic}
										>
											{tuName && tuName.substring(0, 1)}
										</Avatar>
									</Grid>
									<Grid item xs>
										<Typography variant='body2'>{option.full_name || tuName}</Typography>
										<Typography variant='body2' color='textSecondary'>
											{option.email}
										</Typography>
									</Grid>
								</Grid>
							)
						}}
						onChange={onChangeAutoComplete}
						value={autoCompleteVal}
						className={classes.autocomplete}
						onInputChange={handleOwnerInpChng}
						renderInput={(params) => (
							<TextField
								{...params}
								dense='true'
								// error={showErrMsg}
								// helperText={showErrMsg && 'No users selected'}
								variant='outlined'
								{...textFieldProps}
							/>
						)}
					/>
				</div>
				{isFormMode && (
					<div>
						<PermSelectBox permissions={permissions} showIconOnly={isMobileView} variant='filled' value={permission} onChange={onChangePermSelBox} />
					</div>
				)}
			</div>
			{isFormMode && (
				<div className={classes.msgBoxWrapper}>
					<TextField
						id='outlined-multiline-static'
						label='Message'
						value={message}
						onChange={onChangeMsgInput}
						multiline
						fullWidth
						rows={4}
						variant='outlined'
					/>
				</div>
			)}
		</div>
	)
}

export default ShareForm
