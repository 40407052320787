import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormDialog from './FormDialog'
import _ from 'lodash'
import { PAGE_MODES } from '../../constants'
import projectApi from '../../services/projects/api'
import ProjectTableList from './ProjectListTable'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import Axios from 'axios'
const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
}))
const Projects = ({ history, match, isEmbedded = false, changeEmbededState, ...otherProps }) => {
	const dispatch = useDispatch()
	// states
	const classes = useStyles()
	const [params, setParams] = useState({ search: '', offset: 0, limit: 10 })
	const [state, setState] = useState({ loading: false, projectList: [], totalCount: 0 })
	const action = isEmbedded ? otherProps?.action : _.get(match, 'params.action', '')
	const dataId = isEmbedded ? otherProps?.id : _.get(match, 'params.id', '')
	const cancelExecutor = useRef()

	const { loading, projectList, totalCount } = state

	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)
	const isEditAllowed = useMemo(() => _.get(projectPerms, PERMISSION_TYPE.EDIT, false), [projectPerms])

	const handleSearchChange = (e) => {
		setParams({ ...params, search: e.target.value, offset: 0 })
	}

	const handleCloseFormDialog = (e, isRefreshList) => {
		// history.goBack()
		if (!isEmbedded)
			history.replace({
				pathname: `/projects`,
				isRefreshList: _.isEqual(isRefreshList, true),
			})
		else changeEmbededState({ action: '', id: null, isRefreshList: _.isEqual(isRefreshList, true) })
	}

	const changetoEditMode = (idx, record) => {
		if (!isEmbedded) history.replace({ pathname: `/projects/${PAGE_MODES.EDIT}/${idx}` })
		else changeEmbededState({ action: PAGE_MODES.EDIT, id: idx })
	}

	const editModeByBtn = (idx, record) => {
		if (!isEmbedded) history.replace({ pathname: `/projects/${PAGE_MODES.EDIT}/${dataId}` })
		else changeEmbededState({ action: PAGE_MODES.EDIT, id: dataId })
	}

	const getProjectList = (search, offset, limit) => {
		setState({ loading: true, projectList: [], totalCount: 0 })
		projectApi.fetchAllProjects(
			search,
			offset,
			limit,
			'project_name',
			cancelExecutor,
			(res) => {
				setState({ loading: false, projectList: _.get(res, 'data.data.projects', []), totalCount: _.get(res, 'data.data.total_count', 0) })
				isEmbedded && changeEmbededState({ projectsLength: _.get(res, 'data.data.total_count', 0) })
			},
			(err) => {
				if (!Axios.isCancel(err)) setState({ loading: false, projectList: [], totalCount: 0 })
			}
		)
	}

	const onClickDeleteMenu = (idx) => {
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to delete this Project?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					projectApi.deleteProject(
						idx,
						(res) => {
							dispatch(hideLoader())
							getProjectList('', 0, 10)
							setParams((prevState) => ({
								...prevState,
								limit: 10,
							}))
							dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
						},
						(err) => {
							dispatch(hideLoader())
							dispatch(
								showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
									dispatch(hideLoader())
								})
							)
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	useEffect(() => {
		getProjectList(params.search, params.offset, params.limit)
	}, [params])

	const refreshData = () => {
		getProjectList('', 0, 10)
		setParams((prevState) => ({
			...prevState,
			limit: 10,
		}))
	}

	const onChangePage = (e, pageNo) => {
		setParams({ ...params, offset: pageNo * params.limit })
	}

	const changeRowPerPage = (e) => {
		setParams({ ...params, limit: e.target.value, offset: 0 })
	}

	// const onRenderCellVal = (cellProperties, value, record) => {
	// 	return value
	// }
	return (
		<div className={classes.root}>
			<div className={classnames({ 'container-no-fluid page-content': !isEmbedded })}>
				<ProjectTableList
					idKey='project_code'
					isEmbedded={isEmbedded}
					changeEmbededState={changeEmbededState}
					data={projectList}
					title='Projects'
					titleIcon='folder'
					onChangeSearch={handleSearchChange}
					history={history}
					baseUrl='/projects'
					showLoading={loading}
					onClickEditMenu={changetoEditMode}
					onClickDeleteMenu={onClickDeleteMenu}
					projectPerms={projectPerms}
					hideAddBtn={_.get(projectPerms, PERMISSION_TYPE.CREATE, false) === true ? false : true}
				/>
				{!loading && (
					<TablePagination
						component='div'
						count={totalCount}
						page={params.offset / params.limit}
						onChangePage={onChangePage}
						rowsPerPage={params.limit}
						onChangeRowsPerPage={changeRowPerPage}
					/>
				)}
			</div>
			<FormDialog
				open={!_.isEmpty(action)}
				action={action}
				isEditAllowed={isEditAllowed}
				handleClose={handleCloseFormDialog}
				history={history}
				dataId={dataId}
				changeEditMode={editModeByBtn}
				refreshData={() => {
					refreshData()
				}}
			/>
		</div>
	)
}

export default Projects
