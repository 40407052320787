import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { Chip, Icon } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	chip: ({ opacity }) => ({
		opacity: opacity,
		width: '100%',
		marginBottom: theme.spacing(1.4),
		borderRadius: '4px',
		display: 'flex',
		cursor: 'move',
		background: '#0ec5c7',
	}),
	chipLabel: {
		flex: '1',
		textAlign: 'left',
	},
	clickableColorPrimary: {
		background: '#0ec5c7',
		'&:hover': {
			background: '#0eb2b4',
		},
		'&:focus': {
			background: '#0eb2b4',
		},
	},
	chipIcon: {
		fontSize: '1rem',
	},
}))

export const BoxItem = ({ id, text, accepts, index, isAggreApplied, moveCard, handleDelete, onClickBoxItem }) => {
	const ref = useRef(null)
	const [, drop] = useDrop({
		accept: accepts,
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		type: accepts,
		item: { id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	const opacity = isDragging ? 0 : 1
	drag(drop(ref))

	const classes = useStyles({ opacity: opacity })

	const onClickDeleteBtn = () => {
		handleDelete(text)
	}

	const onClickChip = () => {
		onClickBoxItem(text)
	}

	return (
		<Chip
			ref={ref}
			className={classes.chip}
			onClick={onClickChip}
			label={text}
			component={'div'}
			avatar={
				isAggreApplied ? (
					<Avatar color='primary'>
						<Icon className={classes.chipIcon}>functions</Icon>
					</Avatar>
				) : (
					<></>
				)
			}
			classes={{
				label: classes.chipLabel,
				clickableColorPrimary: classes.clickableColorPrimary,
			}}
			onDelete={onClickDeleteBtn}
			color='primary'
			variant='default'
		/>
	)
}
