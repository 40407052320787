import React, { useEffect, useState } from 'react'
import AddTaskDialog from 'components/AddStickyDialog'

const withAddStickyDialog = (Component) => (props) => {
	const [dialogState, setDialogState] = useState({
		openStickyDialog: false,
		stickyFor: '',
		stickySourceId: '',
		stickyIntentDetails: [],
	})
	const [refreshNeeded, setRefreshNeeded] = useState(false)
	const { openStickyDialog, filesToAttach, stickyFor, stickySourceId, stickyIntentDetails } = dialogState

	useEffect(() => {
		return setRefreshNeeded(false)
	}, [refreshNeeded])

	const openDialog = (filesToAttach, stickyFor, stickySourceId, stickyIntentDetails = []) => {
		setDialogState({
			openStickyDialog: true,
			filesToAttach,
			stickyFor,
			stickySourceId,
			stickyIntentDetails,
		})
	}

	const closeDialog = () => {
		setDialogState({
			openStickyDialog: false,
			stickyFor: '',
			stickySourceId: '',
			stickyIntentDetails: [],
		})
	}

	const onStickySuccess = () => {
		setRefreshNeeded(true)
	}

	return (
		<>
			<Component openStickyDialog={openDialog} isStickySuccess={refreshNeeded} {...props} />
			<AddTaskDialog
				open={openStickyDialog}
				filesToAttach={filesToAttach}
				stickyFor={stickyFor}
				stickySourceId={stickySourceId}
				stickyIntentDetails={stickyIntentDetails}
				handleClose={closeDialog}
				onStickySuccess={onStickySuccess}
			/>
		</>
	)
}

export default withAddStickyDialog
